import { PAYMENT_GATEWAY_ENDPOINT } from "./payment-constants";

export const serverApis = {
  ESTIMATE_DETAILS: (estimateId) =>
    `/sales_quotations/${estimateId}/preview_quotation`,
  ACTIVATE_USER: "/user_activation",
  TICKET_CONVERSATIONS: (ticketId) =>
    `/freshdesk_tickets/ticket_conversations?ticket_id=${ticketId}`,
  ACCEPT_REJECT_STITCHES_APPROVAL:
    "/stitch_approvals/stitch_approval_accept_reject",
  ACCEPT_PREPRODUCTION_APPROVAL: "/pre_production_approval/approve_order",
  REJECT_PREPRODUCTION_APPROVAL: "/pre_production_approval/reject_order",
  GLOBAL_SEARCH: "/promo_customer/search",
  INVENTORY_ITEM_SEARCH: "/ship_estimates/fetch_items",
  OPEN_ORDERS: "/orders/open_orders",
  MISSING_ART_ORDERS: "/promo_sales/artworks",
  E_PROOF_ORDERS: "/promo_sales/list_customer_eproof",
  E_PROOF_ORDER_DETAILS: (orderId) => `/eproofs/${orderId}`,
  ESTIMATE_ORDERS: "/promo_sales/list_customer_estimate",
  DASHBOARD_DETAILS: "/portals/dashboard",
  HISTORY_ORDERS: "/orders/history_orders",
  LOGIN: "/authenticate",
  LOGIN_WITH_SOCIAL_CONNECT: "/social_login",
  CONNECT_SOCIAL_ACCOUNT: "/social_connect",

  SIGN_UP: "/registrations/sign_up",
  FORGOT_PASSWORD: "/registrations/forgot_password",
  LOGOUT: "/sign_out",
  PAY_BILLS: "/pay_bills",
  ORDER_DETAIL: (orderId) => `/sales_orders/${orderId}`,
  CHANGE_PASSWORD: "/registrations/change_password",
  STITCH_APPROVALS: "/stich_approvals",
  UPDATE_ORDER_EMAILS: (orderId) => `/sales_orders/${orderId}/update_emails`,
  ORDER_SHIPPING_DETAIL: (orderId) => `/shipping_address/${orderId}`,
  SAVE_SHIPPING_DETAIL: "/shipping_address",
  UPDATE_SHIPPING_DETAIL: (shippingId) => `/shipping_address/${shippingId}`,
  ORDER_ITEM_OPTION_DETAIL: (orderId) =>
    `/sales_orders/${orderId}/item_options_detail`,
  SUB_ORDER_DETAIL: (orderId) => `/sales_orders/${orderId}/suborder`,
  CONTACTS_LIST: "/contacts",
  //CONTACT_DETAIL: (contactId) => `/contacts/${contactId}`,
  CONTACT_DETAIL: "/contacts",
  UPDATE_CONTACT_DETAIL: (contactId) => `/contacts/${contactId}`,
  PRE_PRODUCTION_APPROVALS: "/pre_production_approval",
  USER_PROFILE: "/user_profile",
  UPDATE_USER_PROFILE: (profileId) => `/user_profile/${profileId}`,
  CHECK_INVENTORY: (item) => `/check_inventory/item_search?id=${item}`,
  FETCH_ITEM_PACKAGE_PROFILE: "/ship_estimates/fetch_item_packages",
  SHIPPING_ESTIMATE: "/ship_estimates/fetch_shipping_rates",
  FETCH_COUNTRIES: "/locations/countries",
  FETCH_JOB_TITLE: "/contacts/job_titles",

  FETCH_STATE_BY_ZIP: (zipCode) =>
    `/ship_estimates/fetch_postal_info?zip=${zipCode}`,
  FETCH_STATES: (countryId) => `/locations/states?id=${countryId}`,
  SHOW_INVOICE_PREVIEW: (invoiceId) =>
    `/sales_invoices/${invoiceId}/invoice_preview`,
  FETCH_ENDICIA_PACKAGE_TYPE: "/ship_estimates/fetch_endicia_package_types",
  SUPERVISOR_LIST: "/user_members/fetch_supervisor_list",
  UPDATE_SUPERVISOR_STATUS: "/user_members/update_supervisor_status",
  SUPERVISOR_MEMBER_LIST: (user_id) =>
    `/user_members/fetch_supervisor_user_members?user_id=${user_id}`,
  SUPERVISOR_POPUP_LIST: (user_id) =>
    `/user_members/fetch_supervisor_user_popup_list?user_id=${user_id}`,
  ADD_SUPERVISOR_MEMBER: `/user_members`,
  DELETE_SUPERVISOR_USER: (id) => `/user_members/${id}`,
  GET_ALL_USERS: `/user_members/fetch_all_customer_users`,
  CREATE_USER: `/user_members/create_user`,
  UPDATE_USER_ROLE: `/user_members/update_user_role`,
  ADD_REMOVE_SUPERVISOR_USERS: (user_id) =>
    `/user_members/fetch_add_remove_supervisor_users?user_id=${user_id}`,
  GET_TICKET_TYPES: "/freshdesk_types ",
  CREATE_TICKET: "/freshdesk_tickets",
  FETCH_ALL_TICKETS: "/freshdesk_tickets",
  SEND_EPROOF_EMAIL: (salesOrderId) =>
    `/eproofs/${salesOrderId}/send_eproof_email`,
  SEND_SHIP_ESTIMATE_EMAIL: "/ship_estimates/send_estimates_email",
  RESENT_ORDER_ACKNOWLEDGMENT: (salesOrderId) =>
    `/orders/${salesOrderId}/resend_order_acknowledgment`,
  ACCEPT_REJECT_EPROOF: "/eproofs/accept_reject",
  REJECT_EPROOF_WITH_ART: "/eproofs/eproof_reject_with_upload_artwork",

  UPLOAD_MISSING_ARTWORK: (salesOrderId) =>
    `/promo_sales/${salesOrderId}/upload_missing_artwork`,
  DOWNLOAD_STATEMENT: "/reports/download_statement",
  DOWNLOAD_TRACKING_INFO: "/shipping_address/export_shipping_address_to_excel",
  GET_ORDER_ACKNOWLEDGMENT_FILE_NAME: (salesOrderId) =>
    `/orders/${salesOrderId}/show_order_acknowledgment`,

  PAYMENT_AUTHORIZE: (salesOrderId) =>
    `/payments/${salesOrderId}/authorize_payment`,
  GET_INHAND_DATE: "/shipping_address/get_inhand_date",
  GET_SHIPPING_METHODS: "/shipping_address/get_shipping_methods",
  VALIDATE_ADDRESS: "/shipping_address/validate_address",
  GET_CUSTOMER_PROFILE_CREDENTIALS: "/payments/customer_profile_credentials",
  SAVE_CAPTURED_TRANSACTION: "/payments/save_captured_transaction",
  SAVE_AUTHORIZE_TRANSACTION: (salesOrderId) =>
    `/payments/${salesOrderId}/save_authorized_transaction`,
  CUSTOMER_PAYMENT_RECEIPT: "/payments/customer_payment_receipt",
  DELETE_PAYMENT_PROFILE: (paymentProfileId) =>
    `/payments/${paymentProfileId}/delete_payment_profile`,
  PAYMENT_GATEWAY: PAYMENT_GATEWAY_ENDPOINT,
  TEXT_NOTIFICATION_DETAIL: "/text_notifications",
  LOGIN_BY_SALT: "/salt_endpoints_login",
  CREATE_RESET_PASSWORD: "/reset_password_using_salt",
};
