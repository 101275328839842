import React from "react";
import { SvgIcon } from "../../../../components/common";
import { Modal, Button, Spin } from "antd";
import {
  validateAddress,
  getShippingMethods,
  resetStoreState,
  createLoadingSelector,
} from "../../../../redux/actions";
import {
  VALIDATE_ADDRESS,
  VALIDATE_ADDRESS_RESET,
} from "../../../../redux/actions/type";
import { connect } from "react-redux";
//Style
import "./multiaddress-modal.scss";
import { isObjectNotEmpty } from "../../../../common/common-methods";
import { showAlert } from "../../../../common/alert-messages";

class MultiaddressSelectModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      fetchValidateAddressList: true,
      selectedIndex: 0,
      shippingForValidation: {},
      clickedFlag: false,
    };
  }
  modalToggle = () => {
    if (this.state.visible) {
      this.props.resetStoreState(VALIDATE_ADDRESS_RESET);
    }

    this.setState({
      visible: false, // this.state.isValidatedAddressList ? !this.state.visible : false,
      fetchValidateAddressList: !this.state.visible,
      selectedIndex: 0,
      shippingForValidation: !this.state.visible
        ? this.props.shippingDetail
        : {},
      // isValidatedAddressList: false,
      clickedFlag: false,
    });
  };

  onSelect = (index, detail) => {
    if (!this.state.clickedFlag && this.state.visible) {
      this.setState(
        () => ({
          selectedIndex: index,
          clickedFlag: true,
        }),
        function () {
          this.props.validateHandler(detail);
          const shipDetail = this.getDetailForShippingMethodRequest();
          this.props.getShippingMethods(shipDetail);
          this.modalToggle();
        }
      );
    }
  };

  componentDidUpdate(prevProp) {
    if (this.props.autoValidationTrigger !== prevProp.autoValidationTrigger) {
      this.modalToggle();
    }

    if (
      !this.state.visible &&
      this.state.fetchValidateAddressList &&
      isObjectNotEmpty(this.state.shippingForValidation)
    ) {
      const detail = this.getDetailForValidateAddress();
      this.props.validateAddress(detail);
      this.setState({
        fetchValidateAddressList: false,
      });
    }

    if (
      isObjectNotEmpty(this.props.validateAddresses) &&
      !this.state.visible
      // &&
      // !this.state.isValidatedAddressList
    ) {
      if (this.props.validateAddresses.suggested_addresses.length === 0) {
        showAlert.error("No Suggested Address!");
        this.props.resetStoreState(VALIDATE_ADDRESS_RESET);
      } else {
        this.setState({
          // isValidatedAddressList: true,
          visible: true,
        });
      }
    }
  }

  getDetailForValidateAddress() {
    let shipping = this.state.shippingForValidation;
    return {
      shipping_code: shipping.shipping_code,
      ship_address1: shipping.ship_address1,
      // ship_address2: shipping.ship_address2,
      ship_city: shipping.ship_city,
      ship_state: shipping.ship_state,
      ship_zip: shipping.ship_zip,
      ship_country: shipping.ship_country,
    };
  }

  getDetailForShippingMethodRequest() {
    let shipping = this.state.shippingForValidation;

    return {
      id: shipping.id,
      trans_no: this.props.trans_no,
      ship_city: shipping.ship_city,
      ship_state: shipping.ship_state,
      ship_zip: shipping.ship_zip,
      ship_country: shipping.ship_country,
      estimated_ship_date: isObjectNotEmpty(shipping.ship_date)
        ? shipping.ship_date
        : shipping.estimated_ship_date,
      shipping_code: shipping.shipping_code,
      ship_method: shipping.ship_method,
      residential_indicator: shipping.residential_indicator,
      insurance_flag: shipping.insurance_flag,
      saturday_delivery_flag: shipping.saturday_delivery_flag,
    };
  }
  render() {
    const { visible } = this.state;
    return (
      <React.Fragment>
        <Button type="link" className="mt-4" onClick={this.modalToggle}>
          Validate Address
        </Button>
        <Spin spinning={this.props.isFetchingData} size="large">
          <Modal
            destroyOnClose={true}
            centered={true}
            style={{ maxWidth: 1020 }}
            bodyStyle={{ padding: "1rem" }}
            width={"100%"}
            closeIcon={
              <SvgIcon
                name="close"
                fill="#dc0100"
                width="13px"
                viewbox="0 0 298.667 298.667"
              />
            }
            className="full-screen-xs-modal footer-less"
            closable={true}
            onCancel={this.modalToggle}
            title="Validate Ship Address"
            visible={visible}
            footer={null}
          >
            <div className="address-grid">
              {isObjectNotEmpty(
                this.props.validateAddresses.suggested_addresses
              ) &&
                this.props.validateAddresses.suggested_addresses.map(
                  (address, index) => {
                    return (
                      <div
                        className={
                          index === this.state.selectedIndex
                            ? "address-block active"
                            : "address-block cursor-pointer"
                        }
                        onClick={(e) => {
                          this.onSelect(index, address);
                        }}
                      >
                        <div className="address-block-inner">
                          <div className="address-head">
                            {address.address1 instanceof Array
                              ? address.address1.join(",  ")
                              : address.address1}
                            ;
                            {/* // {address.isArray ? address.values : address.address1}, */}
                          </div>
                          <div className="address-content">
                            <ul>
                              <li>
                                <span className="label">City</span>
                                <span className="value">{address.city}</span>
                              </li>
                              <li>
                                <span className="label">State</span>
                                <span className="value">{address.state}</span>
                              </li>
                              <li>
                                <span className="label">Zip</span>
                                <span className="value">
                                  {address.zip_code}
                                </span>
                              </li>
                              <li>
                                <span className="label">Country</span>
                                <span className="value">{address.country}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
            </div>
          </Modal>
        </Spin>
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector([VALIDATE_ADDRESS]);

const mapStateToProps = (state) => ({
  validateAddresses: state.validateAddress,
  isFetchingData: loadingSelector(state),
});

export default connect(mapStateToProps, {
  resetStoreState,
  validateAddress,
  getShippingMethods,
})(MultiaddressSelectModalComponent);
