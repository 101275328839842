import React, { useState } from "react";
import { Radio } from "antd";
import OpenOrderComponent from "../open-order/open-order";
import OrderHistoryComponent from "../order-history/order-history";
import { useSelector } from "react-redux";
import MediaQuery from "react-responsive";
import { SvgIcon } from "../../../components/common";
import { Link } from "react-router-dom";
import { loggedInUserDetail } from "../../../common/common-methods";
import SupportDesk from "../support-desk/support-desk";
// Order Page Style
import "./order-list.scss";

const OPEN_ORDER_KEY = "openOrders";

/**
 * Order List Page Component
 * @param {*} e Event object
 */
const OrderListView = (props) => {
  const dashboard = useSelector((state) => state.dashboard);
  // const ordersList = useSelector((state) => state.orders);
  // const historyOrdersList = useSelector((state) => state.historyOrders);

  const [state, setState] = useState({ defaultView: OPEN_ORDER_KEY });

  const toggleView = (e) => {
    setState({ defaultView: e.target.value });
  };

  let count = 0;
  if (state.defaultView === OPEN_ORDER_KEY) {
    count = dashboard.open_orders;
  } else {
    count = dashboard.history_orders;
  }

  let userDetails = loggedInUserDetail();

  return (
    <React.Fragment>
      <div className="common-container">
        <div className="page-detail">
          <div className="page-info">
            <MediaQuery maxDeviceWidth={991}>
              <Link to="/dashboard" className="common-back-btn">
                <SvgIcon name="caretLeft" viewbox="0 0 451.846 451.847" />
                <span>Open Order</span>
              </Link>
            </MediaQuery>
            <MediaQuery minDeviceWidth={992}>
              <h1>Orders</h1>
            </MediaQuery>
            <div className="page-sub-info">
              {count} Orders for customer <span>#{userDetails.user_cd}</span>
            </div>
          </div>
          <Radio.Group
            className="view-toggle-btn"
            defaultValue="openOrders"
            size="large"
            onChange={toggleView}
          >
            <Radio.Button value="openOrders">Open Orders</Radio.Button>
            <Radio.Button value="orderHistory">Order History</Radio.Button>
          </Radio.Group>
        </div>
        <div className="table-wrapper">
          {state.defaultView === "openOrders" ? (
            <OpenOrderComponent {...props} />
          ) : (
            <OrderHistoryComponent {...props} />
          )}
        </div>
      </div>
      <SupportDesk />
    </React.Fragment>
  );
};

export default OrderListView;
