export const LOGIN_TOKEN_KEY = "token";
export const USER_DETAIL_KEY = "USERDETAILKEY";
export const DATE_FORMAT = "MM-DD-YYYY";
export const NAVIGATION_REFERENCE = "NAVIGATION_REFERENCE";
export const CAPTURE_REQUEST_DETAILS_KEY = "CAPTUREREQUESTDETAILSKEY";
export const AUTHORISE_REQUEST_DETAILS_KEY = "AUTHORISEREQUESTDETAILSKEY";
export const PAYMENT_FAILURE_COUNT_KEY = "PAYMENTFAILURECOUNTKEY";

export const requestCreatorKey = {
  REQUEST_ACTION_TYPE: "actionType",
  REQUEST_URL: "requestURL",
  REQUEST_HEADER: "requestHeader",
  REQUEST_PAYLOAD: "requestPayload",
  REQUEST_METHOD: "requestMethod",
  SHOW_ERROR_FROM_TRANSACTION: "showErrorFromTransaction",
};

export const buildType = {
  DEMO: "DEMO",
  PRODUCTION: "PRODUCTION",
  DEVELOPMENT: "DEVELOPMENT",
};

export const userRole = {
  ADMIN: "A",
  SUPERVISIOR: "B",
  USER: "C",
};

export const userActiveStatus = {
  ACTIVE: "A",
  DEACTIVE: "H",
};

export const userSortKey = {
  NAME: "first_name",
  ROLE: "customer_role",
  STATUS: "trans_flag",
  SUPERVISIOR: "supervisor_count",
  ASSIGN: "supervisor_user",
};

export const requestContentType = {
  APPLICATION_JSON: "application/json",
};
export const requestMethodType = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

export const SUGGESTION_API_DEBOUNCE_TIME = 500;

export const socialConnectAcoounts = {
  google: { name: "Google", type: "google" },
  amazon: { name: "Amazon", type: "amazon" },
  apple: { name: "Apple", type: "apple" },
};

export const saltTypes = {
  ARTWORK: "artwork",
  PAYMENT_AUTHORIZE: "order",
  MISSING_ARTWORK: "missing_artwork",
  MULTIORDER: "multiorder",
  STITCH_APPROVALS: "stitch_approval",
  INVOICE_LIST: "invoice_list",
};
