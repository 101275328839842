import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'promise-polyfill/src/polyfill';
import 'unfetch/polyfill';
import 'abortcontroller-polyfill';
import React from "react";
import ReactDOM from "react-dom";
// import './assets/scss/style.scss';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import rootReducer from "./redux/reducers/index";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import axios from "axios";

axios.defaults.baseURL = "http://localhost:5000/api/v1";

export const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
  <Provider store={store}>
    {" "}
    <App />{" "}
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
