import { dispatchWithResponseAndType } from "./actions";
import _ from "lodash";
import * as Type from "./type";
import { componentPaths } from "../../constants/path-constants";
import history from "../../common/history";
import { baseServerAdress } from "../../constants/server-address-constants";
import {
  getLoginToken,
  clearLoginData,
  isObjectNotEmpty,
  loggedInUserDetail,
  isObjectEmpty,
  getJsonRequestHeader,
  getJsonRequestHeaderWithAuthentication,
  getRequestHeaderFormData,
  saveCaptureRequestDetails,
  saveAuthoriseRequestDetails,
  saveLoginTokenAndUserDetails,
} from "../../common/common-methods";
import { serverApis } from "../../constants/server-apis";
import {
  requestMethodType,
  requestCreatorKey,
  PAYMENT_FAILURE_COUNT_KEY,
} from "../../constants";
import { showAlert } from "../../common/alert-messages";
import {
  paymentTransactionType,
  PAYMENT_VALIDATION_MODE,
} from "../../constants/payment-constants";
import { serialize } from "object-to-formdata";

const SERVER_ADDRESS = baseServerAdress.API;
export const ORDERS_PAGE_SIZE = 25;
const REQUEST_TIMEOUT = 60000;
const formDataSerializeOptions = {
  /**
   * include array indices in FormData keys
   * defaults to false
   */
  indices: false,

  /**
   * treat null values like undefined values and ignore them
   * defaults to false
   */
  nullsAsUndefineds: false,

  /**
   * convert true or false to 1 or 0 respectively
   * defaults to false
   */
  booleansAsIntegers: false,

  /**
   * store arrays even if they're empty
   * defaults to false
   */
  allowEmptyArrays: false,
};

export function resetStoreState(stateName) {
  return async (dispatch) => {
    dispatch(dispatchWithResponseAndType(null, stateName));
  };
}

const AbortController = window.AbortController;

export const createErrorMessageSelector = (actions) => (state) => {
  // returns the first error messages for actions
  // * We assume when any request fails on a page that
  //   requires multiple API calls, we shows the first error
  return (
    _(actions)
      .map((action) => _.get(state, `errorMessages.${action}`))
      .compact()
      .first() || ""
  );
};

export const createLoadingSelector = (actions) => (state) => {
  // returns true only when all actions is not loading
  return _(actions).some((action) => {
    return _.get(state, `loadingApis.${action}`);
  });
};

export async function requestCreator(requestObject, dispatch) {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), REQUEST_TIMEOUT);
  let responseData;
  dispatch(
    dispatchWithResponseAndType(
      null,
      Type.getActionStatusType(
        requestObject.actionType,
        Type.STATUS_TYPE_REQUEST
      )
    )
  );
  let responseJson = await fetch(requestObject.requestURL, {
    method: requestObject.requestMethod,
    headers: requestObject.requestHeader,
    body: requestObject.requestPayload,
    signal: controller.signal,
  })
    .then((data) => {
      clearTimeout(timeoutId);
      responseData = data;
      return data.json();
    })
    .catch((error) => {
      if (error.name === "AbortError") {
        responseData = { status: 1001 }; ///server request time out error
        return;
      } else {
        responseData = {
          status: 1000, /////other errors
          error: error.toString(),
        };
      }
    });

  return { responseData, responseJson };
}

export async function actionCreator(requestObject, dispatch) {
  const { responseData, responseJson } = await requestCreator(
    requestObject,
    dispatch
  );
  checkResponseStatusAndDispatchAction(
    dispatch,
    responseData,
    responseJson,
    requestObject.actionType
  );
}

export async function actionCreatorForPayment(requestObject, dispatch) {
  const responseObj = await requestCreator(requestObject, dispatch);
  const responseStatus = checkPaymentResponseStatusAndDispatchAction(
    dispatch,
    responseObj.showErrorMessageFromTransaction,
    responseObj.responseJson,
    requestObject.actionType
  );
  const response = responseObj.responseJson;
  return { responseStatus, response };
}

function checkPaymentResponseStatusAndDispatchAction(
  dispatch,
  showErrorMessageFromTransaction,
  response,
  actionType
) {
  if (response.messages.resultCode === "Ok") {
    return true;
  } else {
    if (showErrorMessageFromTransaction) {
      if (response.transactionResponse.errors.length > 0) {
        response.transactionResponse.errors.forEach((error) => {
          showAlert.error(error.errorText);
        });
      }
    } else {
      if (response.messages.message.length > 0) {
        response.messages.message.forEach((error) => {
          showAlert.error(error.text);
        });
      }
    }
    dispatch(
      dispatchWithResponseAndType(
        "",
        Type.getActionStatusType(actionType, Type.STATUS_TYPE_FAILURE)
      )
    );
    return false;
  }
}

function checkResponseStatusAndDispatchAction(
  dispatch,
  data,
  response,
  actionType
) {
  //console.log(data);
  // console.log(data.statusText);
  // console.log(response);
  let errorMessage;
  switch (data.status) {
    case 200:
      // if (actionType === Type.LOGIN) {
      //   history.push(componentPaths.dashboard);
      // }
      if (response && response.message.toLowerCase() === "success") {
        dispatch(
          dispatchWithResponseAndType(
            response,
            Type.getActionStatusType(actionType, Type.STATUS_TYPE_SUCCESS)
          )
        );
        return;
      } else {
        errorMessage = response.error;
      }

      break;
    case 422: //,400,404 405 422 authentication failed
      errorMessage = response.error;
      dispatch(
        dispatchWithResponseAndType(
          errorMessage,
          Type.getActionStatusType(actionType, Type.STATUS_TYPE_FAILURE)
        )
      );

      if (history.location.pathname !== componentPaths.login) {
        showAlert.error(errorMessage);

        history.push({
          pathname: componentPaths.login,
          state: { NAVIGATION_REFERENCE: history.location.pathname },
        });
      }
      return;
    // case 400:
    // case 405:
    // case 404:
    //   errorMessage = response.error;
    //   break;
    case 405:
      if (actionType === Type.LOGIN_WITH_SOCIAL_CONNECT) {
        dispatch(
          dispatchWithResponseAndType(
            true,
            Type.getActionStatusType(
              Type.SHOW_LOGIN_CONNECT_POPUP,
              Type.STATUS_TYPE_SUCCESS
            )
          )
        );
      }
      errorMessage = response ? response.error : data.statusText;

      break;
    case 1000:
      errorMessage = data.error; //// error code
      break;

    case 1001:
      errorMessage = "Request Time Out!"; ///  request time out error code
      break;

    default:
      errorMessage = response ? response.error : data.statusText;
  }

  if (actionType !== Type.SAVE_CAPTURED_TRANSACTION) {
    showAlert.error(errorMessage);
  }

  dispatch(
    dispatchWithResponseAndType(
      errorMessage,
      Type.getActionStatusType(actionType, Type.STATUS_TYPE_FAILURE)
    )
  );
}

export function getCustomerPaymentProfile(requestObject) {
  const getCutomerPaymentObject = {
    getCustomerProfileRequest: {
      merchantAuthentication: requestObject.merchantAuthentication,
      customerProfileId: requestObject.customerProfileId,
      unmaskExpirationDate: true,
      includeIssuerInfo: "true",
    },
  };

  return async (dispatch) => {
    let actionCreatorObject = {
      [requestCreatorKey.REQUEST_ACTION_TYPE]:
        Type.GET_CUSTOMER_PAYMENT_PROFILE,
      [requestCreatorKey.REQUEST_URL]: serverApis.PAYMENT_GATEWAY,
      [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
      [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify(
        getCutomerPaymentObject
      ),
      [requestCreatorKey.REQUEST_HEADER]: {
        ...getJsonRequestHeader(),
      },
      [requestCreatorKey.SHOW_ERROR_FROM_TRANSACTION]: false,
    };
    const { responseStatus, response } = await actionCreatorForPayment(
      actionCreatorObject,
      dispatch
    );
    if (responseStatus) {
      dispatch(
        dispatchWithResponseAndType(
          response,
          Type.getActionStatusType(
            Type.GET_CUSTOMER_PAYMENT_PROFILE,
            Type.STATUS_TYPE_SUCCESS
          )
        )
      );
    }
  };
}

export function createCustomerAndPaymentProfile(
  requestObject,
  orderId,
  arryBills,
  billIds,
  creditCardInfoForSaveProfile
) {
  const customerProfileRequestObject = {
    createCustomerProfileRequest: {
      merchantAuthentication: requestObject.merchantAuthentication,
      profile: {
        merchantCustomerId: requestObject.customer_code,
        description: Date(),
        email: requestObject.customer_email_id,
        paymentProfiles: {
          customerType: "individual",
          billTo: {
            ...requestObject.billInfo,
          },
          payment: {
            creditCard: {
              ...requestObject.cardDetails,
            },
          },
        },
      },
      validationMode: PAYMENT_VALIDATION_MODE,
    },
  };
  return async (dispatch) => {
    let actionCreatorObject = {
      [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.SAVE_CAPTURED_TRANSACTION,
      [requestCreatorKey.REQUEST_URL]: serverApis.PAYMENT_GATEWAY,
      [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
      [requestCreatorKey.REQUEST_HEADER]: {
        ...getJsonRequestHeader(),
      },
      [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify(
        customerProfileRequestObject
      ),
      [requestCreatorKey.SHOW_ERROR_FROM_TRANSACTION]: false,
    };
    let { responseStatus, response } = await actionCreatorForPayment(
      actionCreatorObject,
      dispatch
    );
    if (responseStatus) {
      const chargeCustomerProfileReqestObject = {
        ...requestObject,
        customerProfileId: response.customerProfileId,
        paymentProfileId: response.customerPaymentProfileIdList[0],
      };
      dispatch(
        chargeOrAuthorizeCustomerProfile(
          chargeCustomerProfileReqestObject,
          orderId,
          arryBills,
          billIds,
          creditCardInfoForSaveProfile
        )
      );
    }
  };
}

export function createCutomerPaymentProfile(
  requestObject,
  orderId,
  arryBills,
  billIds,
  creditCardInfoForSaveProfile
) {
  const cutomerPaymentObject = {
    createCustomerPaymentProfileRequest: {
      merchantAuthentication: requestObject.merchantAuthentication,
      customerProfileId: requestObject.customerProfileId,
      paymentProfile: {
        billTo: {
          ...requestObject.billInfo,
        },
        payment: {
          creditCard: {
            ...requestObject.cardDetails,
          },
        },
        defaultPaymentProfile:
          creditCardInfoForSaveProfile.default_card_flag === "Y" ? true : false,
      },
      validationMode: PAYMENT_VALIDATION_MODE,
    },
  };
  return async (dispatch) => {
    let actionCreatorObject = {
      [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.SAVE_CAPTURED_TRANSACTION,
      [requestCreatorKey.REQUEST_URL]: serverApis.PAYMENT_GATEWAY,
      [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
      [requestCreatorKey.REQUEST_HEADER]: {
        ...getJsonRequestHeader(),
      },
      [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify(cutomerPaymentObject),
      [requestCreatorKey.SHOW_ERROR_FROM_TRANSACTION]: false,
    };
    const { responseStatus, response } = await actionCreatorForPayment(
      actionCreatorObject,
      dispatch
    );
    if (responseStatus) {
      const chargeCustomerProfileReqestObject = {
        ...requestObject,
        paymentProfileId: response.customerPaymentProfileId,
      };

      dispatch(
        chargeOrAuthorizeCustomerProfile(
          chargeCustomerProfileReqestObject,
          orderId,
          arryBills,
          billIds,
          creditCardInfoForSaveProfile
        )
      );
    }
  };
}

export function chargeOrAuthorizeCustomerCreditCard(
  requestObject,
  orderId,
  arryBills,
  billIds,
  creditCardInfo
) {
  let transactionDetailObject = {};
  let paymentRefId = "";
  let userDetail = loggedInUserDetail();
  paymentRefId = `PORTAL-${userDetail.id}`;
  if (
    requestObject.transactionType ===
    paymentTransactionType.AUTH_ONLY_TRANSACTION
  ) {
    transactionDetailObject = {
      order: {
        invoiceNumber: orderId,
      },
    };
  } else {
    transactionDetailObject = {
      order: {
        description: billIds.toString(),
      },
    };
  }

  const chargeCreditCardObject = {
    createTransactionRequest: {
      merchantAuthentication: requestObject.merchantAuthentication,
      refId: paymentRefId,
      transactionRequest: {
        transactionType: requestObject.transactionType,
        amount: requestObject.amount,
        currencyCode: "USD",
        payment: {
          creditCard: {
            ...requestObject.cardDetails,
          },
        },
        ...transactionDetailObject,
        billTo: {
          ...requestObject.billInfo,
        },
      },
    },
  };

  return async (dispatch) => {
    let actionCreatorObject = {
      [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.SAVE_CAPTURED_TRANSACTION,
      [requestCreatorKey.REQUEST_URL]: serverApis.PAYMENT_GATEWAY,
      [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
      [requestCreatorKey.REQUEST_HEADER]: {
        ...getJsonRequestHeader(),
      },
      [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify(
        chargeCreditCardObject
      ),
      [requestCreatorKey.SHOW_ERROR_FROM_TRANSACTION]: true,
    };
    const { responseStatus, response } = await actionCreatorForPayment(
      actionCreatorObject,
      dispatch
    );
    if (responseStatus) {
      const saveCaptureRequestObject = {
        card_details: {
          ...creditCardInfo,
        },
        transaction: {
          amount: requestObject.amount,
          ...response,
        },
      };

      if (
        requestObject.transactionType ===
        paymentTransactionType.AUTH_CAPTURE_TRANSACTION
      ) {
        dispatch(
          saveCaptureTransaction({
            ...saveCaptureRequestObject,
            customer_receipt: {
              customer_receipt_lines_attributes: arryBills,
            },
          })
        );
      } else {
        dispatch(saveAutorizeTransaction(saveCaptureRequestObject, orderId));
      }
    }
  };
}

export function chargeOrAuthorizeCustomerProfile(
  chargeCustomerRequestObject,
  orderId,
  arryBills,
  billIds,
  creditCardInfoForSaveProfile,
  expirationDateExistingProfile
) {
  let transactionDetailObject = [];
  let paymentRefId = "";
  let userDetail = loggedInUserDetail();
  paymentRefId = `PORTAL-${userDetail.id}`;
  if (
    chargeCustomerRequestObject.transactionType ===
    paymentTransactionType.AUTH_ONLY_TRANSACTION
  ) {
    transactionDetailObject = {
      order: {
        invoiceNumber: orderId,
      },
    };
  } else {
    transactionDetailObject = {
      order: {
        description: billIds.toString(),
      },
    };
  }

  var lineItemObject = {};

  if (isObjectNotEmpty(creditCardInfoForSaveProfile)) {
    lineItemObject = {
      lineItems: {
        lineItem: {
          itemId: "1",
          name: "expirationDate",
          description:
            creditCardInfoForSaveProfile.expiration_year +
            "-" +
            creditCardInfoForSaveProfile.expiration_month,
          quantity: "1",
          unitPrice: "0",
        },
      },
    };
  } else if (isObjectNotEmpty(expirationDateExistingProfile)) {
    lineItemObject = {
      lineItems: {
        lineItem: {
          itemId: "1",
          name: "expirationDate",
          description: expirationDateExistingProfile,
          quantity: "1",
          unitPrice: "0",
        },
      },
    };
  }

  const chargeCustomerProfileObject = {
    createTransactionRequest: {
      merchantAuthentication:
        chargeCustomerRequestObject.merchantAuthentication,
      refId: paymentRefId,
      transactionRequest: {
        transactionType: chargeCustomerRequestObject.transactionType,
        amount: chargeCustomerRequestObject.amount,
        currencyCode: "USD",
        profile: {
          customerProfileId: chargeCustomerRequestObject.customerProfileId,
          paymentProfile: {
            paymentProfileId: chargeCustomerRequestObject.paymentProfileId,
            cardCode: chargeCustomerRequestObject.cardCode,
          },
        },
        ...transactionDetailObject,
        ...lineItemObject,
      },
    },
  };
  return async (dispatch) => {
    let actionCreatorObject = {
      [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.SAVE_CAPTURED_TRANSACTION,
      [requestCreatorKey.REQUEST_URL]: serverApis.PAYMENT_GATEWAY,
      [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
      [requestCreatorKey.REQUEST_HEADER]: {
        ...getJsonRequestHeader(),
      },
      [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify(
        chargeCustomerProfileObject
      ),
      [requestCreatorKey.SHOW_ERROR_FROM_TRANSACTION]: false,
    };
    const { responseStatus, response } = await actionCreatorForPayment(
      actionCreatorObject,
      dispatch
    );
    if (responseStatus) {
      var card_details = isObjectEmpty(creditCardInfoForSaveProfile)
        ? {}
        : { card_details: creditCardInfoForSaveProfile };
      const saveCaptureRequestObject = {
        customer_profile: {
          customer_profile_id: chargeCustomerRequestObject.customerProfileId,
          customer_payment_profile_id:
            chargeCustomerRequestObject.paymentProfileId,
        },
        transaction: {
          amount: chargeCustomerRequestObject.amount,
          ...response,
        },
        ...card_details,
      };

      if (
        chargeCustomerRequestObject.transactionType ===
        paymentTransactionType.AUTH_CAPTURE_TRANSACTION
      ) {
        dispatch(
          saveCaptureTransaction(
            {
              ...saveCaptureRequestObject,
              customer_receipt: {
                customer_receipt_lines_attributes: [...arryBills],
              },
            }
            // arryBills
          )
        );
      } else {
        dispatch(saveAutorizeTransaction(saveCaptureRequestObject, orderId));
      }
    }
  };
}

export function deleteCustomerPaymentProfile(requestObject) {
  const deletePaymentProfileObject = {
    deleteCustomerPaymentProfileRequest: {
      merchantAuthentication: requestObject.merchantAuthentication,
      customerProfileId: requestObject.customerProfileId,
      customerPaymentProfileId: requestObject.paymentProfileId,
    },
  };
  return async (dispatch) => {
    let actionCreatorObject = {
      [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.DELETE_PAYMENT_PROFILE,
      [requestCreatorKey.REQUEST_URL]: serverApis.PAYMENT_GATEWAY,
      [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
      [requestCreatorKey.REQUEST_HEADER]: {
        ...getJsonRequestHeader(),
      },
      [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify(
        deletePaymentProfileObject
      ),
      [requestCreatorKey.SHOW_ERROR_FROM_TRANSACTION]: false,
    };
    const { responseStatus } = await actionCreatorForPayment(
      actionCreatorObject,
      dispatch
    );
    if (responseStatus) {
      dispatch(
        updateDeletedCustomerPaymentProfile(requestObject.paymentProfileId)
      );
    }
  };
}

export function updateDeletedCustomerPaymentProfile(paymentProfileId) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]:
      Type.UPDATE_DELETED_PAYMENT_PROFILE,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.DELETE_PAYMENT_PROFILE(paymentProfileId),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.DELETE,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function saveCaptureTransaction(requestObject) {
  if (requestObject[PAYMENT_FAILURE_COUNT_KEY] > 0) {
    requestObject[PAYMENT_FAILURE_COUNT_KEY] =
      requestObject[PAYMENT_FAILURE_COUNT_KEY] + 1;
  } else {
    requestObject[PAYMENT_FAILURE_COUNT_KEY] = 1;
  }

  saveCaptureRequestDetails(requestObject);

  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.SAVE_CAPTURED_TRANSACTION,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.SAVE_CAPTURED_TRANSACTION,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify(requestObject),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function saveAutorizeTransaction(requestObject, orderId) {
  if (requestObject[PAYMENT_FAILURE_COUNT_KEY] > 0) {
    requestObject[PAYMENT_FAILURE_COUNT_KEY] =
      requestObject[PAYMENT_FAILURE_COUNT_KEY] + 1;
  } else {
    requestObject[PAYMENT_FAILURE_COUNT_KEY] = 1;
  }

  saveAuthoriseRequestDetails(requestObject, orderId);

  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.SAVE_CAPTURED_TRANSACTION,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.SAVE_AUTHORIZE_TRANSACTION(orderId),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify(requestObject),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getCustomerProfileCredentials() {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]:
      Type.GET_CUSTOMER_PROFILE_CREDENTIALS,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.GET_CUSTOMER_PROFILE_CREDENTIALS,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  // let responseData;
  return async (dispatch) => {
    const { responseData, responseJson } = await requestCreator(
      actionCreatorObject,
      dispatch
    );

    //   dispatch(
    //     dispatchWithResponseAndType(
    //       null,
    //       Type.getActionStatusType(
    //         Type.GET_CUSTOMER_PROFILE_CREDENTIALS,
    //         Type.STATUS_TYPE_REQUEST
    //       )
    //     )
    //   );
    //   let response = await fetch(
    //     SERVER_ADDRESS + serverApis.GET_CUSTOMER_PROFILE_CREDENTIALS,
    //     {
    //       method: "GET",
    //       headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${getLoginToken()}`,
    //       },
    //     }
    //   )
    //     .then((data) => {
    //       responseData = data;
    //       return data.json();
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });

    if (responseData.status === 200) {
      if (responseJson && responseJson.message.toLowerCase() === "success") {
        const requestObject = {
          merchantAuthentication: {
            name: responseJson.credentials.api_login_id,
            transactionKey: responseJson.credentials.api_transaction_key,
          },
          customerProfileId: responseJson.customer_profile.customer_profile_id,
        };

        if (
          isObjectNotEmpty(responseJson.customer_profile.customer_profile_id)
        ) {
          dispatch(getCustomerPaymentProfile(requestObject));
        }
      }
    }

    checkResponseStatusAndDispatchAction(
      dispatch,
      responseData,
      responseJson,
      Type.GET_CUSTOMER_PROFILE_CREDENTIALS
    );
  };
}

export function getDashboardDetails() {
  let requestObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.DASHBOARD_DETAILS,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.DASHBOARD_DETAILS,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(requestObject, dispatch);
  };
}

export function fetchPreProductionApprovals() {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.PRE_PRODUCTION_APPROVALS,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.PRE_PRODUCTION_APPROVALS,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
    // dispatch(getDashboardDetails());
  };
}

export function acceptPreProduction(details) {
  let requestObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.ACCEPT_PREPRODUCTION_APPROVAL,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.ACCEPT_PREPRODUCTION_APPROVAL,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({ ...details }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(requestObject, dispatch);
  };
}

export function rejectPreProduction(details) {
  let requestObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.REJECT_PREPRODUCTION_APPROVAL,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.REJECT_PREPRODUCTION_APPROVAL,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({ ...details }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(requestObject, dispatch);
  };
}

export function globalSearch(query) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.GLOBAL_SEARCH,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + serverApis.GLOBAL_SEARCH,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({ ...query }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function inventoryItemSearch(query) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.INVENTORY_ITEM_SEARCH,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.INVENTORY_ITEM_SEARCH,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({ ...query }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function fetchEstimateOrders() {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.ESTIMATE_ORDERS,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.ESTIMATE_ORDERS,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
    // dispatch(getDashboardDetails());
  };
}

export function fetchEstimateDetails(estimateId) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.ESTIMATE_DETAILS,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.ESTIMATE_DETAILS(estimateId),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function fetchTicketConversations(ticketId) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.TICKET_CONVERSATIONS,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.TICKET_CONVERSATIONS(ticketId),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function fetchContactList() {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.CONTACTS_LIST,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + serverApis.CONTACTS_LIST,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function fetchContactDetail(contactId) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.CONTACT_DETAIL,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.CONTACT_DETAIL(contactId),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function saveContactDetail(contact) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.CONTACT_DETAIL,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + serverApis.CONTACT_DETAIL,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      customer_contact: { ...contact },
    }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function updateContactDetail(contact) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.CONTACT_DETAIL,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.UPDATE_CONTACT_DETAIL(contact.id),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.PUT,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      customer_contact: { ...contact },
    }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function fetchMissingArtOrders(pageNumber) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.MISSING_ART_ORDERS,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS +
      `${serverApis.MISSING_ART_ORDERS}?name=sp_promo_cust_portal_list_waiting_for_artworks&per_page=${ORDERS_PAGE_SIZE}&page=${pageNumber}`,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
    // dispatch(getDashboardDetails());
  };
}

// const getDispatchedResponse = (actionCreatorObject, extraAction) => {
//   return (dispatch) => {
//     dispatch(extraAction());
//     actionCreator(actionCreatorObject, dispatch);
//   }
// }

export function fetchStitchApprovals(pageNumber) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.STITCH_APPROVALS,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.STITCH_APPROVALS,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
    // dispatch(getDashboardDetails());
  };
}

export function acceptRejectStitchesApproval(details) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]:
      Type.ACCEPT_REJECT_STITCHES_APPROVAL,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.ACCEPT_REJECT_STITCHES_APPROVAL,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({ ...details }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function fetchPayBills(pageNumber) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.PAY_BILLS,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + serverApis.PAY_BILLS,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
    // dispatch(getDashboardDetails());
  };
}

export function fetchEproofOrders(pageNumber) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.E_PROOF_ORDERS,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS +
      `${serverApis.E_PROOF_ORDERS}?name=sp_promo_cust_portal_info_noresponse_orders&page=${pageNumber}&per_page=${ORDERS_PAGE_SIZE}`,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
    // dispatch(getDashboardDetails());
  };
}

export function fetchEproofOrderDetails(orderId) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.E_PROOF_ORDER_DETAILS,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.E_PROOF_ORDER_DETAILS(orderId),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function fetchOrders(params) {
  const searchParams = new URLSearchParams(params);
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.OPEN_ORDERS,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS +
      `${
        serverApis.OPEN_ORDERS
      }?per_page=${ORDERS_PAGE_SIZE}&${searchParams.toString()}`,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
    // dispatch(getDashboardDetails());
  };
}

export function fetchHistoryOrders(params) {
  const searchParams = new URLSearchParams(params);
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.HISTORY_ORDERS,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS +
      `${
        serverApis.HISTORY_ORDERS
      }?name=sp_promo_cust_portal_list_history_orders&per_page=${ORDERS_PAGE_SIZE}&${searchParams.toString()}`,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
    // dispatch(getDashboardDetails());
  };
}

export function activateUser(token) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.ACTIVATE_USER,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + `${serverApis.ACTIVATE_USER}?value=${token}`,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeader(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function loginAction(email, password, socialType, socialId) {
  return async (dispatch) => {
    let actionCreatorObject = {
      [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.LOGIN,
      [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + serverApis.LOGIN,
      [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
      [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
        user: {
          login: email,
          password: password,
        },
      }),
      [requestCreatorKey.REQUEST_HEADER]: {
        ...getJsonRequestHeader(),
      },
    };
    const { responseData, responseJson } = await requestCreator(
      actionCreatorObject,
      dispatch
    );
    if (responseData.status === 200) {
      saveLoginTokenAndUserDetails(responseJson.data, dispatch);

      if (isObjectNotEmpty(socialType) && isObjectNotEmpty(socialId)) {
        dispatch(connectSocialAccount(socialType, socialId));
      }
    }

    checkResponseStatusAndDispatchAction(
      dispatch,
      responseData,
      responseJson,
      Type.LOGIN
    );
  };
}

export function loginWithSocialConnect(socialType, socialId) {
  return async (dispatch) => {
    let actionCreatorObject = {
      [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.LOGIN_WITH_SOCIAL_CONNECT,
      [requestCreatorKey.REQUEST_URL]:
        SERVER_ADDRESS + serverApis.LOGIN_WITH_SOCIAL_CONNECT,
      [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
      [requestCreatorKey.REQUEST_HEADER]: {
        ...getJsonRequestHeader(),
      },
      [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
        provider_id: socialId,
        provider_type: socialType,
      }),
    };

    const { responseData, responseJson } = await requestCreator(
      actionCreatorObject,
      dispatch
    );
    if (responseData.status === 200) {
      saveLoginTokenAndUserDetails(responseJson.data, dispatch);
    }

    checkResponseStatusAndDispatchAction(
      dispatch,
      responseData,
      responseJson,
      Type.LOGIN_WITH_SOCIAL_CONNECT
    );
  };
}

export function connectSocialAccount(socialType, socialId, authToken) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.CONNECT_SOCIAL_ACCOUNT,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.CONNECT_SOCIAL_ACCOUNT,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,

    [requestCreatorKey.REQUEST_HEADER]: isObjectNotEmpty(authToken)
      ? { ...getJsonRequestHeader, Authorization: `Bearer  ${authToken}` }
      : {
          ...getJsonRequestHeaderWithAuthentication(),
        },
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      provider_id: socialId,
      provider_type: socialType,
    }),
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function logoutAction() {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.LOGOUT,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + serverApis.LOGOUT,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.DELETE,

    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function resetLoginDataAndStore() {
  clearLoginData();
  return async (dispatch) => {
    dispatch(dispatchWithResponseAndType(null, Type.RESET_ALL_STATES));
  };
}

export function signUpAction(userDetail, socialType, socialId) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.SIGN_UP,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + serverApis.SIGN_UP,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({ user: userDetail }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeader(),
    },
  };
  return async (dispatch) => {
    const { responseData, responseJson } = await requestCreator(
      actionCreatorObject,
      dispatch
    );

    checkResponseStatusAndDispatchAction(
      dispatch,
      responseData,
      responseJson,
      Type.SIGN_UP
    );

    if (responseData.status === 200) {
      if (
        isObjectNotEmpty(socialType) &&
        isObjectNotEmpty(socialId) &&
        isObjectNotEmpty(responseJson.data.auth_token)
      ) {
        dispatch(
          connectSocialAccount(
            socialType,
            socialId,
            responseJson.data.auth_token
          )
        );
      }
    }
  };
}

export function forgotPassword(email) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.FORGOT_PASSWORD,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.FORGOT_PASSWORD,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify(email),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeader(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function fetchOrderDetail(orderId) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.ORDER_DETAIL,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.ORDER_DETAIL(orderId),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function fetchSuborderDetail(orderId) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.SUB_ORDER_DETAIL,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.SUB_ORDER_DETAIL(orderId),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function changePassword(old_password, new_password) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.CHANGE_PASSWORD,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.CHANGE_PASSWORD,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      old_password: old_password,
      new_password: new_password,
    }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function updateOrderEmails(orderId, emailList) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.UPDATE_ORDER_EMAILS,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.UPDATE_ORDER_EMAILS(orderId),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.PATCH,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify(emailList),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function fetchOrderShippingDetail(orderId) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.ORDER_SHIPPING_DETAIL,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.ORDER_SHIPPING_DETAIL(orderId),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function saveShippingDetail(shipping) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.SAVE_SHIPPING_DETAIL,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.SAVE_SHIPPING_DETAIL,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      sales_order_shipping: { ...shipping },
    }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function updateShippingDetail(shipping) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.UPDATE_SHIPPING_DETAIL,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.UPDATE_SHIPPING_DETAIL(shipping.id),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.PUT,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      sales_order_shipping: { ...shipping },
    }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function fetchUserProfile() {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.USER_PROFILE,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + serverApis.USER_PROFILE,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function updateUserProfile(profileId, profile) {
  const data = serialize({ customer: profile }, formDataSerializeOptions);

  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.UPDATE_USER_PROFILE,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.UPDATE_USER_PROFILE(profileId),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.PATCH,
    [requestCreatorKey.REQUEST_PAYLOAD]: data,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getRequestHeaderFormData(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function checkInventory(item) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.CHECK_INVENTORY,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.CHECK_INVENTORY(encodeURIComponent(item)),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function fetchItemPackageProfile(item) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.ITEM_PACKAGE_PROFILE,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.FETCH_ITEM_PACKAGE_PROFILE,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({ item_code: item }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function fetchShippingEstimate(shippingDetail) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.SHIPPING_RATES,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.SHIPPING_ESTIMATE,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify(shippingDetail),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function fetchJobTitle() {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.FETCH_JOB_TITLE,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.FETCH_JOB_TITLE,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function fetchCountries() {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.FETCH_COUNTRIES,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.FETCH_COUNTRIES,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function fetchStates(countryId) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.FETCH_STATES,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.FETCH_STATES(countryId),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,

    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function fetchStateByZip(zipCode) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.FETCH_STATE_BY_ZIP,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.FETCH_STATE_BY_ZIP(zipCode),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function showInvoicePreview(invoiceId) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.INVOICE_PREVIEW,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.SHOW_INVOICE_PREVIEW(invoiceId),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getTicketTypes() {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.GET_TICKET_TYPES,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.GET_TICKET_TYPES,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function createTicket(ticket) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.CREATE_TICKET,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + serverApis.CREATE_TICKET,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({ ...ticket }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function fetchAllTickets() {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.FETCH_ALL_TICKETS,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.FETCH_ALL_TICKETS,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function sendEproofEmail(salesOrderId, detail) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.SEND_EPROOF_EMAIL,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.SEND_EPROOF_EMAIL(salesOrderId),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({ ...detail }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function sendShipEstimatesEmail(shippingDetail, estimates, emailDetail) {
  let shipping_methods = estimates;
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.SEND_SHIP_ESTIMATE_EMAIL,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.SEND_SHIP_ESTIMATE_EMAIL,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      ...shippingDetail,
      shipping_methods,
    }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function reSendOrderAcknowlegment(salesOrderId, emails) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.RE_SEND_ORDER_ACKNOWLEDGMENT,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.RESENT_ORDER_ACKNOWLEDGMENT(salesOrderId),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      recipient_email: emails,
    }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function acceptRejectEproof(details) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.ACCEPT_REJECT_EPROOF,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.ACCEPT_REJECT_EPROOF,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({ ...details }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function rejectEproofWithArt(details) {
  let data = new FormData();
  for (const [key, value] of Object.entries(details)) {
    data.append(key, value);
  }

  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.REJECT_EPROOF_WITH_ART,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.REJECT_EPROOF_WITH_ART,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: data,
    [requestCreatorKey.REQUEST_HEADER]: {
      Authorization: `Bearer  ${getLoginToken()}`,
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function uploadMissingArtwork(salesOrderId, File) {
  let data = new FormData();
  data.append("Filedata", File);
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.UPLOAD_MISSING_ART,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.UPLOAD_MISSING_ARTWORK(salesOrderId),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: data,
    [requestCreatorKey.REQUEST_HEADER]: {
      Authorization: `Bearer  ${getLoginToken()}`,
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getOrderAcknowledgementFileName(salesOrderId) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]:
      Type.GET_ORDER_ACKNOWLEDGEMENT_FILE_NAME,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS +
      serverApis.GET_ORDER_ACKNOWLEDGMENT_FILE_NAME(salesOrderId),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function downloadStatement() {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.DOWNLOAD_STATEMENT,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.DOWNLOAD_STATEMENT,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function downloadTrackingInfo(orderId) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.DOWNLOAD_TRACKING_INFO,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.DOWNLOAD_TRACKING_INFO,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({ id: orderId }),
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getInhandDate(details) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.GET_INHAND_DATE,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.GET_INHAND_DATE,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({ ...details }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getShippingMethods(details) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.GET_SHIPPING_METHODS,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.GET_SHIPPING_METHODS,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({ ...details }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function validateAddress(details) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.VALIDATE_ADDRESS,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.VALIDATE_ADDRESS,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({ ...details }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function customerPaymentReceipt(details) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.CUSTOMER_PAYMENT_RECEIPT,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.CUSTOMER_PAYMENT_RECEIPT,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({ ...details }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function getTextNotificationDetails(salesOrderId) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.GET_TEXT_NOTIFICATION_DETAIL,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.TEXT_NOTIFICATION_DETAIL + `/${salesOrderId}`,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function addEditTextNotificationDetails(detail) {
  const isUpdating = detail.id !== null;
  const urlAppend = isUpdating ? `/${detail.id}` : "";

  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]:
      Type.ADD_EDIT_TEXT_NOTIFICATION_DETAIL,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.TEXT_NOTIFICATION_DETAIL + urlAppend,

    [requestCreatorKey.REQUEST_METHOD]: isUpdating
      ? requestMethodType.PUT
      : requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      twilio_integrations: detail,
    }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

//////Not used

export function fetchOrderItemOptionDetail(orderId) {
  let responseData;
  return async (dispatch) => {
    dispatch(
      dispatchWithResponseAndType(
        null,
        Type.getActionStatusType(
          Type.ORDER_ITEM_OPTION_DETAIL,
          Type.STATUS_TYPE_REQUEST
        )
      )
    );
    let response = await fetch(
      SERVER_ADDRESS + serverApis.ORDER_ITEM_OPTION_DETAIL(orderId),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer  ${getLoginToken()}`,
        },
      }
    )
      .then((data) => {
        responseData = data;
        return data.json();
      })
      .catch((error) => {
        console.log(error);
      });
    checkResponseStatusAndDispatchAction(
      dispatch,
      responseData,
      response,
      Type.ORDER_ITEM_OPTION_DETAIL
    );
  };
}

export function fetchEndiciaPackageType() {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.ENDICIA_PACKAGE_TYPE,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.FETCH_ENDICIA_PACKAGE_TYPE,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function loginBySalt(saltType, saltValue) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.LOGIN_BY_SALT,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + serverApis.LOGIN_BY_SALT,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      type: saltType,
      salt: saltValue,
    }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return async (dispatch) => {
    const { responseData, responseJson } = await requestCreator(
      actionCreatorObject,
      dispatch
    );
    if (responseData.status === 200) {
      saveLoginTokenAndUserDetails(responseJson.data, dispatch);
    }

    checkResponseStatusAndDispatchAction(
      dispatch,
      responseData,
      responseJson,
      Type.LOGIN_BY_SALT
    );
  };
}

export function fetchSupervisorList() {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.SUPERVISOR_LIST,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.SUPERVISOR_LIST,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function updateSupervisorStatus(requestObject) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.UPDATE_SUPERVISOR_STATUS,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.UPDATE_SUPERVISOR_STATUS,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({ ...requestObject }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function fetchSupervisorMemberList(userId) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.SUPERVISOR_MEMBER_LIST,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.SUPERVISOR_MEMBER_LIST(userId),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function fetchSupervisorPopupList(userId) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.SUPERVISOR_POPUP_LIST,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.SUPERVISOR_POPUP_LIST(userId),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function addSupervisorMember(requestObject) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.ADD_SUPERVISOR_MEMBER,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.ADD_SUPERVISOR_MEMBER,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({ ...requestObject }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
export function deleteSupervisorUser(id) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.DELETE_SUPERVISOR_USER,

    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.DELETE_SUPERVISOR_USER(id),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.DELETE,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function fetchAllUsersList() {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.GET_ALL_USERS,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + serverApis.GET_ALL_USERS,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function updateUserRole(requestObject) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.UPDATE_USER_ROLE,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.UPDATE_USER_ROLE,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({ ...requestObject }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function createUser(requestObject) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.CREATE_USER,
    [requestCreatorKey.REQUEST_URL]: SERVER_ADDRESS + serverApis.CREATE_USER,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify({
      user: { ...requestObject },
    }),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function fetchAddRemoveSupervisorUsers(userId) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.ADD_REMOVE_SUPERVISOR_USERS,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.ADD_REMOVE_SUPERVISOR_USERS(userId),
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.GET,
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeaderWithAuthentication(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}

export function createResetPasswordAction(requestObject) {
  let actionCreatorObject = {
    [requestCreatorKey.REQUEST_ACTION_TYPE]: Type.CREATE_RESET_PASSWORD,
    [requestCreatorKey.REQUEST_URL]:
      SERVER_ADDRESS + serverApis.CREATE_RESET_PASSWORD,
    [requestCreatorKey.REQUEST_METHOD]: requestMethodType.POST,
    [requestCreatorKey.REQUEST_PAYLOAD]: JSON.stringify(requestObject),
    [requestCreatorKey.REQUEST_HEADER]: {
      ...getJsonRequestHeader(),
    },
  };
  return (dispatch) => {
    actionCreator(actionCreatorObject, dispatch);
  };
}
