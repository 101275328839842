import React from "react";
import { Router, Route, Redirect } from "react-router-dom";
import history from "./common/history";

import SvgSprite from "./utility/SvgSpriteLoader";
//App routes
import { privateRoutes, publicRoutes } from "./routes";

import "./ant.less";

//Svg Sprite
import svgFile from "./assets/images/svg/svg-sprite.svg";
import { isUserLoggedIn, isOpenPath } from "./common/common-methods";
import { componentPaths } from "./constants/path-constants";

class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <SvgSprite url={svgFile} />
        <Router
          history={history}
          basename={process.env.REACT_APP_BASENAME || ""}
        >
          {publicRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={(props) => {
                  return (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  );
                }}
              />
            );
          })}
          {privateRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={(props) => {
                  return isUserLoggedIn() || isOpenPath(route.path) ? (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  ) : (
                    <Redirect
                      to={{
                        pathname: componentPaths.login,
                        state: {
                          NAVIGATION_REFERENCE:
                            componentPaths.orderdetails() === route.path
                              ? ""
                              : route.path,
                        },
                      }}
                    />
                  );
                }}
              />
            );
          })}
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
