export const OPEN_UPDLOAD_CERTIFICATE_BY_DEFAULT =
  "OPENUPDLOADCERTIFICATEBYDEFAULT";

export const CREATE_RESET_PASSWORD = "CREATERESETPASSWORD";

export const ESTIMATE_DETAILS = "ESTIMATEDETAILS";
export const TICKET_CONVERSATIONS = "TICKETCONVERSATIONS";
export const GLOBAL_SEARCH = "GLOBALSEARCH";
export const INVENTORY_ITEM_SEARCH = "INVENTORYITEMSEARCH";

export const OPEN_ORDERS = "OPENORDERS";
export const MISSING_ART_ORDERS = "MISSINGARTORDERS";
export const E_PROOF_ORDERS = "EPROOF";
export const E_PROOF_ORDER_DETAILS = "EPROOFDETAILS";
export const ESTIMATE_ORDERS = "ESTIMATEORDERS";
export const DASHBOARD_DETAILS = "DASHBOARDDETAILS";
export const HISTORY_ORDERS = "HISTORYORDERS";
export const LOGIN = "LOGIN";
export const LOGIN_WITH_SOCIAL_CONNECT = "LOGINWITHSOCIALCONNECT";
export const CONNECT_SOCIAL_ACCOUNT = "CONNECTSOCIALACCOUNT";

export const SIGN_UP = "SIGNUP";
export const FORGOT_PASSWORD = "FORGOTPASSWORD";
export const PAY_BILLS = "PAYBILLS";
export const ORDER_DETAIL = "ORDERDETAIL";
export const LOGOUT = "LOGOUT";
export const CHANGE_PASSWORD = "CHANGEPASSWORD";
export const STITCH_APPROVALS = "STITCHAPPROVALS";
export const ACCEPT_REJECT_STITCHES_APPROVAL = "ACCEPTREJECTSTITCHAPPROVAL";
export const ACCEPT_PREPRODUCTION_APPROVAL = "ACCEPTPREPRODUCTIONAPPROVAL";
export const REJECT_PREPRODUCTION_APPROVAL = "REJECTPREPRODUCTIONAPPROVAL";

export const UPDATE_ORDER_EMAILS = "UPDATEORDEREMAILS";
export const ORDER_SHIPPING_DETAIL = "ORDERSHIPPINGDEAIL";
export const SAVE_SHIPPING_DETAIL = "SAVESHIPPINGDEAIL";
export const UPDATE_SHIPPING_DETAIL = "UPDATESHIPPINGDEAIL";
export const ORDER_ITEM_OPTION_DETAIL = "ORDERITEMOPTIONDETAIL";
export const SUB_ORDER_DETAIL = "SUBORDERDETAIL";
export const CONTACTS_LIST = "CONTACTSLIST";
export const CONTACT_DETAIL = "CONTACTDETAIL";
export const PRE_PRODUCTION_APPROVALS = "PREPRODUCTIONAPPROVALS";
export const USER_PROFILE = "USERPROFILE";
export const UPDATE_USER_PROFILE = "UPDATEUSERPROFILE";
export const CHECK_INVENTORY = "CHECKINVENTORY";
export const ITEM_PACKAGE_PROFILE = "ITEMPACKAGEPROFILE";
export const SHIPPING_RATES = "SHIPPINGRATES";
export const ACTIVATE_USER = "ACTIVATEUSER";
export const FETCH_COUNTRIES = "FETCHCOUNTRIES";
export const FETCH_STATES = "FETCHSTATES";
export const FETCH_STATE_BY_ZIP = "FETCHSTATEBYZIP";
export const FETCH_JOB_TITLE = "FETCHJOBTITLE";

export const INVOICE_PREVIEW = "INVOICEPREVIEW";
export const CREATE_TICKET = "CREATETICKET";
export const GET_TICKET_TYPES = "GETTICKETTYPES";

export const FETCH_ALL_TICKETS = "GETALLTICKETS";
export const SEND_EPROOF_EMAIL = "SENDEPROOFEMAIL";
export const SEND_SHIP_ESTIMATE_EMAIL = "SENDSHIPESTIMATEEMAIL";
export const RE_SEND_ORDER_ACKNOWLEDGMENT = "RESENDORDERACKNOWLEDGMENT";
export const ACCEPT_REJECT_EPROOF = "ACCEPTREJECTEPROOF";
export const UPLOAD_MISSING_ART = "UPLOADMISSINGART";
export const DOWNLOAD_STATEMENT = "DOWNLOADSTATEMENT";
export const DOWNLOAD_TRACKING_INFO = "DOWNLOADTRACKINGINFO";
export const REJECT_EPROOF_WITH_ART = "REJECTEPROOFWITHART";
export const GET_ORDER_ACKNOWLEDGEMENT_FILE_NAME =
  "GETORDERACKNOWLEDGMENTFILENAME";

export const AUTHORIZE_PAYMENT = "AUTHORIZEPAYMENT";
export const GET_INHAND_DATE = "GETINHANDDATE";
export const GET_SHIPPING_METHODS = "GETSHIPPINGMETHODS";
export const VALIDATE_ADDRESS = "VALIDATEADDRESS";

export const GET_CUSTOMER_PAYMENT_PROFILE = "GETCUSTOMERPAYMENTPROFILE";
export const GET_CUSTOMER_PROFILE_CREDENTIALS = "GETCUSTOMERPROFILECREDENTIALS";
export const SAVE_CAPTURED_TRANSACTION = "SAVECAPTUREDTRANSACTION";
export const CUSTOMER_PAYMENT_RECEIPT = "CUSTOMERPAYMENTRECEIPT";
export const DELETE_PAYMENT_PROFILE = "DELETEPAYMENTPROFILE";
export const UPDATE_DELETED_PAYMENT_PROFILE = "UPDATEDELETEDPAYMENTPROFILE";
export const GET_TEXT_NOTIFICATION_DETAIL = "TEXTNOTIFICATIONDETAIL";
export const REDIRECT_URL = "REDIECTURL";
export const ADD_EDIT_TEXT_NOTIFICATION_DETAIL =
  "ADDEDITTEXTNOTIFICATIONDETAIL";

export const SHOW_LOGIN_CONNECT_POPUP = "SHOWLOGINCONNECTPOPUP";
export const LOGIN_BY_SALT = "LOGINBYSALT";
export const SUPERVISOR_LIST = "SUPERVISORLIST";
export const UPDATE_SUPERVISOR_STATUS = "UPDATESUPERVISORSTATUS";
export const SUPERVISOR_MEMBER_LIST = "SUPERVISORMEMBERLIST";
export const SUPERVISOR_POPUP_LIST = "SUPERVISORPOPUPLIST";
export const ADD_SUPERVISOR_MEMBER = "ADDSUPERVISORMEMBER";
export const DELETE_SUPERVISOR_USER = "DELETESUPERVISORUSER";

export const GET_ALL_USERS = "GETALLUSERS";
export const CREATE_USER = "CREATEUSER";
export const UPDATE_USER_ROLE = "UPDATEUSERROLE";
export const ADD_REMOVE_SUPERVISOR_USERS = "ADDREMOVESUPERVISORUSERS";

// reset Actions

export const CREATE_RESET_PASSWORD_RESET = "CREATERESETPASSWORDRESET";

export const ADD_REMOVE_SUPERVISOR_USERS_RESET =
  "ADDREMOVESUPERVISORUSERSRESET";

export const CREATE_USER_RESET = "CREATEUSERRESET";

export const LOGIN_BY_SALT_RESET = "LOGINBYSALTRESET";

export const DOWNLOAD_TRACKING_INFO_RESET = "DOWNLOADTRACKINGINFORESET";

export const SHOW_LOGIN_CONNECT_POPUP_RESET = "SHOWLOGINCONNECTPOPUPRESET";

export const LOGIN_WITH_SOCIAL_CONNECT_RESET = "LOGINWITHSOCIALCONNECTRESET";

export const E_PROOF_ORDER_DETAILS_RESET = "EPROOFDETAILSRESET";

export const ADD_EDIT_TEXT_NOTIFICATION_DETAIL_RESET =
  "ADDEDITTEXTNOTIFICATIONDETAILRESET";

export const FETCH_JOB_TITLE_RESET = "FETCHJOBTITLERESET";

export const FETCH_STATE_BY_ZIP_RESET = "FETCHSTATEBYZIPRESET";
export const GLOBAL_SEARCH_RESET = "GLOBALSEARCHRESET";
export const INVENTORY_ITEM_SEARCH_RESET = "INVENTORYITEMSEARCHRESET";
export const ACCEPT_REJECT_PREPRODUCTION_APPROVAL_RESET =
  "ACCEPTREJECTPREPRODUCTIONAPPROVALRESET";
export const RESET_ALL_STATES = "RESETALLSTATES";
export const ACCEPT_REJECT_STITCH_APPROVAL_RESET =
  "ACCEPTREJECTSTITCHAPPROVALRESET";
export const ESTIMATE_DETAILS_RESET = "ESTIMATEDETAILSRESET";
export const ACTIVATE_USER_RESET = "ACTIVATEUSERRESET";
export const CONTACT_DETAIL_RESET = "CONTACTDETAILRESET";
export const SHIPPING_DETAIL_RESET = "SHIPPINGDETAILRESET";
export const REGISTRATION_DETAIL_RESET = "REGISTRATIONDETAILRESET";
export const FORGOT_PASSWORD_RESET = "FORGOTPASSWORDRESET";
export const STATE_LIST_RESET = "STATELISTRESET";
export const INVOICE_PREVIEW_PATH_RESET = "INVOICEPREVIEWPATHRESET";
export const USER_DETAIL_RESET = "USERDETAILRESET";
export const USER_UPDATE_MESSAGE_RESET = "USERUPDATEMESSAGERESET";
export const EMAIL_UPDATE_MESSAGE_RESET = "EMAILUPDATEMESSAGERESET";
export const SHIPPING_ESTIMATE_RESET = "SHIPPINGESTIMATERESET";
export const ENDICIA_PACKAGE_TYPE = "ENDICIAPACKAGETYPE";
export const ENDICIA_PACKAGE_TYPE_RESET = "ENDICIAPACKAGETYPERESET";
export const CREATE_TICKET_RESET = "CREATETICKETRESET";
export const TICKET_CONVERSATIONS_RESET = "TICKETCONVERSATIONSRESET";
export const ITEM_PACKAGE_PROFILE_RESET = "ITEMPACKAGEPROFILERESET";
export const SEND_EPROOF_EMAIL_RESET = "SENDEPROOFEMAILRESET";
export const SEND_SHIP_ESTIMATE_EMAIL_RESET = "SENDSHIPESTIMATEEMAILRESET";
export const RE_SEND_ORDER_ACKNOWLEDGMENT_RESET =
  "RESENDORDERACKNOWLEDGMENTRESET";
export const CHECK_INVENTORY_RESET = "CHECKINVENTORYRESET";
export const CHANGE_PASSWORD_RESET = "CHANGEPASSWORDRESET";
export const UPLOAD_MISSING_ART_RESET = "UPLOADMISSINGARTRESET";
export const DOWNLOAD_STATEMENT_RESET = "DOWNLOADSTATEMENTRESET";
export const ORDER_ITEM_OPTION_DETAIL_RESET = "ORDERITEMOPTIONDETAILRESET";
export const SUB_ORDER_DETAIL_RESET = "SUBORDERDETAILRESET";
export const ORDER_DETAIL_RESET = "ORDERDETAILRESET";
export const GET_ORDER_ACKNOWLEDGEMENT_FILE_NAME_RESET =
  "GETORDERACKNOWLEDGMENTFILENAMERESET";

export const ACCEPT_REJECT_EPROOF_RESET = "ACCEPTREJECTEPROOFRESET";
export const GET_INHAND_DATE_RESET = "GETINHANDDATERESET";
export const GET_SHIPPING_METHODS_RESET = "GETSHIPPINGMETHODSRESET";
export const VALIDATE_ADDRESS_RESET = "VALIDATEADDRESSRESET";
export const ORDER_SHIPPING_DETAIL_RESET = "ORDERSHIPPINGDEAILRESET";

export const GET_CUSTOMER_PAYMENT_PROFILE_RESET =
  "GETCUSTOMERPAYMENTPROFILERESET";
export const GET_CUSTOMER_PROFILE_CREDENTIALS_RESET =
  "GETCUSTOMERPROFILECREDENTIALSRESET";
export const SAVE_CAPTURED_TRANSACTION_RESET = "SAVECAPTUREDTRANSACTIONRESET";
export const CUSTOMER_PAYMENT_RECEIPT_RESET = "CUSTOMERPAYMENTRECEIPTRESET";
export const DELETE_PAYMENT_PROFILE_RESET = "DELETEPAYMENTPROFILERESET";
export const UPDATE_DELETED_PAYMENT_PROFILE_RESET =
  "UPDATEDELETEDPAYMENTPROFILERESET";
export const TEXT_NOTIFICATION_DETAIL_RESET = "TEXTNOTIFICATIONDETAILRESET";
export const SUPERVISOR_LIST_RESET = "SUPERVISORLISTRESET";
export const UPDATE_SUPERVISOR_STATUS_RESET = "UPDATESUPERVISORSTATUSRESET";
export const SUPERVISOR_MEMBER_LIST_RESET = "SUPERVISORMEMBERLISTRESET";
export const SUPERVISOR_POPUP_LIST_RESET = "SUPERVISORPOPUPLISTRESET";
export const ADD_SUPERVISOR_MEMBER_RESET = "ADDSUPERVISORMEMBERRESET";
export const DELETE_SUPERVISOR_USER_RESET = "DELETESUPERVISORUSERRESET";
export const ALL_USER_LIST_RESET = "ALLUSERLISTRESET";

//reset ErrorMessage
export const ERROR_MESSAGES_RESET_ALL = "ERRORMESSAGESRESETALL";

export const STATUS_TYPE_REQUEST = "REQUEST";
export const STATUS_TYPE_SUCCESS = "SUCCESS";
export const STATUS_TYPE_FAILURE = "FAILURE";

export function getActionStatusType(actionName, type) {
  return `GET_${actionName}_${type}`;
}

export function getResetAction(actionName) {
  return `${actionName}RESET`;
}
