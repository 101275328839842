import React from "react";
import { Form, Input, Button, Row, Col, Spin } from "antd";
import { SvgIcon } from "../../../components/common";
import {
  signUpAction,
  resetStoreState,
  createLoadingSelector,
} from "../../../redux/actions/index";
import { connect } from "react-redux";
import {
  SIGN_UP,
  REGISTRATION_DETAIL_RESET,
} from "../../../redux/actions/type";
import { showAlert } from "../../../common/alert-messages";
import { isObjectEmpty } from "../../../common/common-methods";

class RegistrationFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetail: {},
    };
  }
  onChange = (event) => {
    let userDetail = this.state.userDetail;
    let name = event.target.getAttribute("name"); //field name
    let value = event.target.value; //field value
    userDetail[name] = value;
    this.setState({ userDetail });
  };

  onFinish = (values) => {
    this.props.signUpAction(
      this.state.userDetail,
      this.props.socialAccountDetails.type,
      this.props.socialAccountDetails.id
    );
  };

  componentDidUpdate() {
    if (!isObjectEmpty(this.props.successMessage)) {
      showAlert.success(this.props.successMessage);
      this.props.closeConnectPopup();
      this.props.resetStoreState(REGISTRATION_DETAIL_RESET);
    }
  }

  render() {
    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        <div className="login-form-outer">
          <Form
            name="normal_login"
            className="signup-form"
            initialValues={{ remember: true }}
            onFinish={this.onFinish}
          >
            <Row>
              <Col sm={12} className="pr-1">
                <label htmlFor="first_name" className="w-100">
                  First Name
                </label>
                <Form.Item
                  id="first_name"
                  name="first_name"
                  className="mb-3"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your first name!",
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    name="first_name"
                    placeholder="First Name"
                    value={this.state.userDetail.first_name}
                    onChange={this.onChange.bind(this)}
                  />
                </Form.Item>
              </Col>
              <Col sm={12} className="pl-1">
                <label htmlFor="last_name" className="w-100">
                  Last Name
                </label>
                <Form.Item
                  id="last_name"
                  name="last_name"
                  className="mb-3"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your last name!",
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    name="last_name"
                    placeholder="Last Name"
                    value={this.state.userDetail.last_name}
                    onChange={this.onChange.bind(this)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <label htmlFor="login" className="w-100">
              Email
            </label>
            <Form.Item
              id="login"
              name="login"
              className="mb-3"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please enter your E-mail!",
                },
              ]}
            >
              <Input
                autoComplete="off"
                name="login"
                prefix={
                  <SvgIcon name="mail" viewbox="0 0 13 11" width="15px" />
                }
                placeholder="Email ID"
                value={this.state.userDetail.login}
                onChange={this.onChange.bind(this)}
              />
            </Form.Item>
            <label htmlFor="user_phone" className="w-100">
              Phone
            </label>
            <Form.Item
              id="user_phone"
              name="user_phone"
              className="mb-3"
              rules={[
                {
                  required: true,
                  message: "Please enter your phone number!",
                },
              ]}
            >
              <Input
                autoComplete="off"
                name="user_phone"
                prefix={
                  <SvgIcon name="phone" viewbox="0 0 13 13" width="15px" />
                }
                placeholder="Phone Number"
                value={this.state.userDetail.user_phone}
                onChange={this.onChange.bind(this)}
              />
            </Form.Item>
            <Row>
              <Col sm={12} className="pr-1">
                <label htmlFor="user_company" className="w-100">
                  Company
                </label>
                <Form.Item
                  id="user_company"
                  name="user_company"
                  className="mb-3"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your company!",
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    name="user_company"
                    placeholder="Company"
                    value={this.state.userDetail.user_company}
                    onChange={this.onChange.bind(this)}
                  />
                </Form.Item>
              </Col>
              <Col sm={12} className="pl-1">
                <label htmlFor="asi_number" className="w-100">
                  ASI
                </label>
                <Form.Item
                  id="asi_number"
                  className="mb-3"
                  name="asi_number"
                  value={this.state.userDetail.asi_number}
                  onChange={this.onChange.bind(this)}
                >
                  <Input name="asi_number" placeholder="Optional" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} className="pr-1">
                <label htmlFor="ppai" className="w-100">
                  PPAI
                </label>
                <Form.Item
                  id="ppai"
                  name="ppai"
                  className="mb-3"
                  value={this.state.userDetail.ppai}
                  onChange={this.onChange.bind(this)}
                >
                  <Input name="ppai" placeholder="Optional" />
                </Form.Item>
              </Col>
              <Col sm={12} className="pl-1">
                <label htmlFor="sage" className="w-100">
                  SAGE
                </label>
                <Form.Item
                  id="sage"
                  name="sage"
                  className="mb-3"
                  value={this.state.userDetail.sage}
                  onChange={this.onChange.bind(this)}
                >
                  <Input name="sage" placeholder="Optional" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} className="pr-1">
                <label htmlFor="dc" className="w-100">
                  DC
                </label>
                <Form.Item
                  id="dc"
                  name="dc"
                  className="mb-3"
                  value={this.state.userDetail.dc}
                  onChange={this.onChange.bind(this)}
                >
                  <Input name="dc" placeholder="Optional" />
                </Form.Item>
              </Col>
              <Col sm={12} className="pl-1">
                <label htmlFor="upic" className="w-100">
                  UPIC
                </label>
                <Form.Item
                  id="upic"
                  name="upic"
                  className="mb-3"
                  value={this.state.userDetail.upic}
                  onChange={this.onChange.bind(this)}
                >
                  <Input name="upic" placeholder="Optional" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} className="pr-1">
                <label htmlFor="password" className="w-100">
                  Password
                </label>
                <Form.Item
                  id="password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password
                    name="password"
                    value={this.state.userDetail.password}
                    onChange={this.onChange.bind(this)}
                  />
                </Form.Item>
              </Col>
              <Col sm={12} className="pl-1">
                <label htmlFor="confirm_password" className="w-100">
                  Confirm Password
                </label>
                <Form.Item
                  id="confirm_password"
                  name="confirm_password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                  ]}
                >
                  <Input.Password
                    name="confirm_password"
                    value={this.state.userDetail.confirm_password}
                    onChange={this.onChange.bind(this)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item className="mb-1">
              <Button
                type="primary"
                htmlType="submit"
                className="registration-form-button mr-3"
              >
                Submit
              </Button>
              <div className="text-center mt-3">
                Or back to{" "}
                <Button
                  type="link"
                  onClick={this.props.toggleConnectLoginAndRegistration}
                >
                  Login
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    );
  }
}
const loadingSelector = createLoadingSelector([SIGN_UP]);

const mapStateToProps = (state) => ({
  successMessage: state.signUpDetails,
  isFetchingData: loadingSelector(state),
});

export default connect(mapStateToProps, {
  signUpAction,
  resetStoreState,
})(RegistrationFormComponent);
