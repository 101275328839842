import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../../../components/common";
import { Checkbox, Table, Modal, Button } from "antd";
import MediaQuery from "react-responsive";
import { Row, Col } from "../../../../components/common/grid";
import { useDispatch, useSelector } from "react-redux";
import PaymentInfoModalComponent from "../../order-details/payment-info-modal/payment-info-modal";
import { paymentTransactionType } from "../../../../constants/payment-constants";

import {
  fetchPayBills,
  createLoadingSelector,
  showInvoicePreview,
  resetStoreState,
} from "../../../../redux/actions";

// Style
import "./pay-bills-list.scss";
import {
  PAY_BILLS,
  INVOICE_PREVIEW_PATH_RESET,
  INVOICE_PREVIEW,
} from "../../../../redux/actions/type";
import { Spin } from "antd";
import {
  dataFormatter,
  isObjectEmpty,
  isObjectNotEmpty,
  setScrollIn,
} from "../../../../common/common-methods";
import { baseServerAdress } from "../../../../constants/server-address-constants";
import { showFileViewer } from "../../../../common/common-views";
import { showAlert } from "../../../../common/alert-messages";

const { Column } = Table;
const BillBlock = ({
  checkBoxValue,
  paidAmount,
  balanceAmount,
  orderNumber,
  trackingNumber,
  invoiceNumber,
  invoiceDate,
  invoiceAmount,
  customerPO,
  shippingDate,
  shipping,
  invoiceId,
  invoiceClick,
}) => {
  const [selected, setSelected] = useState(false);
  const handleChange = (event) => {
    setSelected(event.target.checked);
  };

  return (
    <Col xs="12" md="12" lg="12" xl="6">
      <div className="border-box mb-20">
        <div className={"bill-block" + (selected ? " active" : "")}>
          <div className="bill-info">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="d-sm-flex flex-row align-items-center">
                <div className="block-label">Paid Amount</div>
                <div className="block-value">${paidAmount}</div>
              </div>
              <div className="d-sm-flex flex-row align-items-center">
                <div className="block-label">Balance Amount</div>
                <div className="block-value">${balanceAmount}</div>
              </div>
              <div className="checkbox-block">
                <Checkbox value={checkBoxValue} onChange={handleChange}>
                  Pay bills
                </Checkbox>
              </div>
            </div>
          </div>
        </div>
        <div className="bill-block-info">
          <Row>
            <Col xs="6" sm="4">
              <div className="block-label">Tracking #</div>
              <div className="block-value">{trackingNumber}</div>
            </Col>
            <Col xs="6" sm="4">
              <div className="block-label">Order #</div>
              <div className="block-value">{orderNumber}</div>
            </Col>
            <Col xs="6" sm="4">
              <div className="block-label">Invoice #</div>
              <div className="block-value">
                <Button type="link" onClick={() => invoiceClick(invoiceId)}>
                  <b>
                    <u>{invoiceNumber}</u>
                  </b>
                </Button>
              </div>
            </Col>
            <Col xs="6" sm="4">
              <div className="block-label">Invoice Date</div>
              <div className="block-value">{dataFormatter(invoiceDate)}</div>
            </Col>
            <Col xs="6" sm="4">
              <div className="block-label">Invoice Amount</div>
              <div className="block-value">{invoiceAmount}</div>
            </Col>
            <Col xs="6" sm="4">
              <div className="block-label">Customer PO #</div>
              <div className="block-value">{customerPO}</div>
            </Col>
            <Col xs="6" sm="4">
              <div className="block-label">Shipping Date</div>
              <div className="block-value">{shippingDate}</div>
            </Col>
            <Col xs="6" sm="4">
              <div className="block-label">Shipping #</div>
              <div className="block-value">{shipping}</div>
            </Col>
          </Row>
        </div>
      </div>
    </Col>
  );
};

const PayBillsListComponent = (props) => {
  const loadingSelector = createLoadingSelector([PAY_BILLS, INVOICE_PREVIEW]);

  const orders = useSelector((state) => state.payBills);
  const invoicePath = useSelector((state) => state.showInvoicePreview);
  const isFetchingData = useSelector((state) => loadingSelector(state));

  const dispatch = useDispatch();

  const [state, setState] = useState({
    checkedList: [],
    gridView: false,
    payBillOrders: [],
    totalAmount: 0.0,
  });

  const [downloadInvoiceNumber, setdownloadInvoiceNumber] = useState(false);

  useEffect(() => {
    setScrollIn();
    dispatch(fetchPayBills(1));
  }, [dispatch]);

  useEffect(() => {
    setState({
      checkedList: [],
      gridView: false,
      payBillOrders: [],
      totalAmount: 0.0,
    });
  }, [orders]);

  useEffect(() => {
    if (
      isObjectNotEmpty(invoicePath) &&
      isObjectNotEmpty(downloadInvoiceNumber)
    ) {
      setTimeout(() => {
        fetch(baseServerAdress.REPORTS + invoicePath.invoice_path).then(
          (response) => {
            response.blob().then((blob) => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement("a");
              a.href = url;
              a.download = downloadInvoiceNumber;
              a.click();
              dispatch(resetStoreState(INVOICE_PREVIEW_PATH_RESET));
              setdownloadInvoiceNumber("");
            });
          }
        );
      }, 100);
    }
  }, [invoicePath, downloadInvoiceNumber, dispatch]);

  const onClickInvoice = (id) => {
    dispatch(showInvoicePreview(id));
  };

  const onCheck = (checkedList) => {
    const allowedCharaterDescriptionLength = 255;

    if (checkedList.toString().length > allowedCharaterDescriptionLength) {
      const billIdLength = checkedList[0].toString().length + 1;
      const allowedBillsCount = Math.floor(
        allowedCharaterDescriptionLength / billIdLength
      );

      const arrySelectedBills = [...checkedList];
      const arrySliced = arrySelectedBills.splice(0, allowedBillsCount);

      let payBillOrders = [];
      let totalAmount = 0.0;
      payBillOrders = orderList(arrySliced);
      totalAmount = getTotalAmount(payBillOrders);

      setState({
        ...state,
        checkedList: arrySliced,
        payBillOrders,
        totalAmount: totalAmount,
      });

      showAlert.error(
        `You have reached the maximum number (${allowedBillsCount}) of transactions you can select per batch, please complete payment and repeat process.`
      );
    } else {
      let payBillOrders = [];
      let totalAmount = 0.0;
      payBillOrders = orderList(checkedList);
      totalAmount = getTotalAmount(payBillOrders);
      setState({
        ...state,
        checkedList,
        payBillOrders,
        totalAmount: totalAmount,
      });
    }
    // setTimeout(()=>{console.log(state.gridViewstate.checkedList )}, 100);
  };

  const orderList = (list) => {
    let payBillOrders = [];
    list.map((id, i) => {
      const order = orders.find((order) => order.id === id);
      payBillOrders.push(order);
      return "";
    });
    return payBillOrders;
  };

  const viewToggleTrue = () => {
    setState({ ...state, gridView: true });
  };
  const viewToggleFalse = () => {
    setState({ ...state, gridView: false });
  };

  const getInvoiceList = () => {
    let customer_receipt_lines_attributes = [];
    state.payBillOrders.map((order, index) => {
      return customer_receipt_lines_attributes.push({
        trans_bk: order.trans_bk,
        voucher_no: order.voucher_no,
        trans_date: order.trans_date,
        voucher_date: order.voucher_date,
        due_date: order.due_date,
        apply_flag: order.apply_flag,
        original_amt: order.original_amt,
        apply_amt: order.apply_amt,
        balance_amt: order.balance_amt,
        disctaken_amt: order.disctaken_amt,
        ref_no: order.ref_no,
      });
    });
    return customer_receipt_lines_attributes;
  };

  const getTotalAmount = (orders) => {
    let amount = 0.0;
    amount = orders.reduce(function (acct, order) {
      return acct + parseFloat(order.apply_amt);
    }, 0.0);
    return amount.toFixed(2);
  };

  useEffect(() => {
    // console.log(state.checkedList.toString());
  });

  return (
    <Spin
      spinning={isFetchingData}
      size={"large"}
      className="fullscreen-contentarea"
    >
      <div className="common-container pb-4">
        <Row>
          <Col xs="12" sm="12" md="8" lg="8" xl="9" className="pb-4">
            <Row className="pt-3">
              <Col xs="12" className='px-sm-0'>
                <div className="page-view-panel">
                  <MediaQuery minDeviceWidth={992}>
                    <span className="page-name">Pending Bills</span>
                  </MediaQuery>
                  <MediaQuery maxDeviceWidth={991}>
                    <Link to="/dashboard" className="common-back-btn">
                      <SvgIcon name="caretLeft" viewbox="0 0 451.846 451.847" />
                      <span>Pending Bills</span>
                    </Link>
                  </MediaQuery>
                  <div className="view-switch">
                    <button
                      type="button"
                      className={state.gridView ? "active" : ""}
                      onClick={viewToggleTrue}
                    >
                      <SvgIcon name="grid-view" viewbox="0 0 12 12" />
                    </button>
                    <button
                      type="button"
                      className={!state.gridView ? "active" : ""}
                      onClick={viewToggleFalse}
                    >
                      <SvgIcon
                        name="list-view"
                        width="20px"
                        viewbox="0 0 15 12"
                      />
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="12" md="12" lg="12" xl="12" className='px-sm-0'>
                {/* <Checkbox.Group className="pt-3 mw-100" value={state.gridViewstate.checkedList} onChange={state.gridViewonCheck}> */}

                {state.gridView ? (
                  <Checkbox.Group
                    className="pt-3 mw-100 w-100"
                    value={state.checkedList}
                    onChange={onCheck}
                  >
                    <Row className="gutter-sm">
                      <React.Fragment>
                        {orders.map((bill, i) => {
                          return (
                            <React.Fragment key={i}>
                              <BillBlock
                                id={"bill-" + i}
                                checkBoxValue={bill.id}
                                paidAmount={bill.paid_amt}
                                balanceAmount={bill.apply_amt}
                                orderNumber={bill.ref_trans_no}
                                trackingNumber={bill.tracking_no}
                                invoiceNumber={bill.trans_no}
                                invoiceDate={bill.voucher_date}
                                invoiceAmount={bill.original_amt}
                                customerPO={bill.ref_no}
                                shippingDate={bill.ship_date}
                                shipping={bill.shipping_code}
                                invoiceId={bill.id}
                                invoiceClick={onClickInvoice}
                              // invoiceClick={showModal}
                              />
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    </Row>
                  </Checkbox.Group>
                ) : (
                  <div className="table-wrapper mt-3 pending-bills-table">
                    <Table
                      // tableLayout="fixed"
                      className="common-table"
                      //loading={isFetchingData}
                      dataSource={orders}
                      pagination={false}
                      scroll={{ x: 100, y: "calc(100vh - 279px)" }}
                      rowKey="id"
                      rowSelection={{
                        // type: "checkbox",
                        // columnWidth: "40px",
                        // fixed: true,
                        //checkedList,
                        selectedRowKeys: state.checkedList,
                        onChange: onCheck,
                      }}
                    >
                      <Column
                        title="Invoice #"
                        dataIndex="trans_no"
                        key="trans_no"
                        width="70px"
                        render={(trans_no, record) => (
                          <Button
                            type="link"
                            onClick={() => onClickInvoice(record.id)}
                          >
                            <b>
                              <u>{trans_no}</u>
                            </b>
                          </Button>
                        )}
                      />
                      <Column
                        title="Customer PO #"
                        dataIndex="ref_no"
                        key="ref_no"
                        width="115px"
                      />
                      <Column
                        title="Invoice Date"
                        dataIndex="voucher_date"
                        key="voucher_date"
                        width="90px"
                        render={(voucher_date) => (
                          <b>{dataFormatter(voucher_date)}</b>
                        )}
                      />
                      <Column
                        title="Order #"
                        dataIndex="ref_trans_no"
                        key="ref_trans_no"
                        width="80px"
                      />
                      <Column
                        title="Invoice Amount"
                        dataIndex="original_amt"
                        key="original_amt"
                        width="95px"
                        render={(original_amt) => <b>${original_amt}</b>}
                      />
                      <Column
                        title="Amount Paid"
                        dataIndex="paid_amt"
                        key="paid_amt"
                        width="90px"
                        render={(paid_amt) => <b>${paid_amt}</b>}
                      />
                      <Column
                        title="Balance Amount"
                        dataIndex="apply_amt"
                        key="apply_amt"
                        width="95px"
                        render={(apply_amt) => <b>${apply_amt}</b>}
                      />

                      <Column
                        title="Due Date"
                        dataIndex="due_date"
                        key="due_date"
                        width="90px"
                        render={(due_date) => <b>{dataFormatter(due_date)}</b>}
                      />

                      <Column
                        title="Tracking #"
                        dataIndex="tracking_no"
                        key="tracking_no"
                        width="90px"
                      />

                      <Column
                        title=""
                        dataIndex="trans_no"
                        key="trans_no+1"
                        width="30px"
                        className="px-0"
                        render={(trans_no, record) => (
                          <Button
                            className="acknowledgementdownload-btn"
                            onClick={() => {
                              setdownloadInvoiceNumber(record.trans_no);
                              onClickInvoice(record.id);
                            }}
                          >
                            <div className="download-icon">
                              <SvgIcon name="download" viewbox="0 0 512 512" />
                            </div>
                          </Button>
                        )}
                      />

                      {/* <Column
                        title="Shipping Date"
                        dataIndex="ship_date"
                        key="ship_date"
                        width="100px"
                      />
                      <Column
                        title="Shipping #"
                        dataIndex="shipping_code"
                        key="shipping_code"
                        width="100px"
                      /> */}
                    </Table>
                  </div>
                )}
                {/* </Checkbox.Group> */}
              </Col>
            </Row>
          </Col>
          <Col
            xs="12"
            sm="12"
            md="4"
            lg="4"
            xl="3"
            className="total-block-wrapper"
          >
            <div className="total-block">
              <MediaQuery minDeviceWidth={768}>
                <div className="bill-totals-main">
                  <table>
                    <tbody>
                      {state.payBillOrders &&
                        state.payBillOrders.map((bill, index) => {
                          // sum += parseFloat(bill.balance_amt.replace("$", "")).toFixed(2);
                          return (
                            <tr key={index}>
                              <td className="block-label">
                                #{bill.ref_trans_no}
                              </td>
                              <td className="block-value">${bill.apply_amt}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </MediaQuery>
              <div className="total">
                <div className="block-label">Total</div>
                <div className="block-value">${state.totalAmount}</div>
              </div>

              <PaymentInfoModalComponent
                showIcon={false}
                buttonText={"Make Payment"}
                terms={"CC"}
                buttonType={"primary"}
                amount={state.totalAmount}
                transactionType={
                  paymentTransactionType.AUTH_CAPTURE_TRANSACTION
                }
                arryBills={getInvoiceList()}
                billIds={state.checkedList}
                makeDisable={state.payBillOrders.length > 0 ? false : true}
              />
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        destroyOnClose={true}
        centered={true}
        style={{ width: "100%", maxWidth: "1020px" }}
        bodyStyle={{ padding: "1rem" }}
        title="Invoice"
        closeIcon={
          <SvgIcon
            name="close"
            fill="#dc0100"
            width="13px"
            viewbox="0 0 298.667 298.667"
          />
        }
        className="full-screen-xs-modal with-footer"
        visible={
          isObjectNotEmpty(invoicePath) && isObjectEmpty(downloadInvoiceNumber)
        }
        onOk={() => {
          dispatch(resetStoreState(INVOICE_PREVIEW_PATH_RESET));
        }}
        onCancel={() => {
          dispatch(resetStoreState(INVOICE_PREVIEW_PATH_RESET));
        }}
        width="100%"
      >
        {isObjectNotEmpty(invoicePath) &&
          showFileViewer(baseServerAdress.REPORTS + invoicePath.invoice_path)}
      </Modal>
    </Spin>
  );
};
export default PayBillsListComponent;
