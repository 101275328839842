import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../../components/common";
import { Row, Col } from "../../../components/common/grid";
import { AutoComplete, Checkbox, Input, Table, Select, Spin } from "antd";
import Slider from "react-slick";
import "../../../assets/scss/plugins/slick-slider/slick.scss";
import "./role-and-permissions.scss";
import { componentPaths } from "../../../constants/path-constants";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSupervisorList,
  fetchAddRemoveSupervisorUsers,
  resetStoreState,
  createLoadingSelector,
  deleteSupervisorUser,
  addSupervisorMember,
} from "../../../redux/actions";
import {
  ADD_SUPERVISOR_MEMBER,
  ADD_SUPERVISOR_MEMBER_RESET,
  DELETE_SUPERVISOR_USER,
  DELETE_SUPERVISOR_USER_RESET,
  SUPERVISOR_LIST,
  ADD_REMOVE_SUPERVISOR_USERS,
} from "../../../redux/actions/type";
import { isObjectNotEmpty } from "../../../common/common-methods";
import { userSortKey } from "../../../constants";

const { Column } = Table;
const { Option } = Select;

const ManageSupervisor = () => {
  const settings = {
    dots: false,
    infinite: false,
    loop: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,
    nextArrow: <SvgIcon name="caretRight" viewbox="0 0 451.846 451.847" />,
    prevArrow: <SvgIcon name="caretLeft" viewbox="0 0 451.846 451.847" />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [selectedSupervisorObject, setSelectedSupervisorObject] = useState({});

  const [searchText, setSearchText] = useState("");
  const [sortKey, setSortKey] = useState(userSortKey.NAME);

  const dispatch = useDispatch();
  const loadingSelector = createLoadingSelector([
    SUPERVISOR_LIST,
    ADD_REMOVE_SUPERVISOR_USERS,
    ADD_SUPERVISOR_MEMBER,
    DELETE_SUPERVISOR_USER,
  ]);

  const isFetchingData = useSelector((state) => loadingSelector(state));
  const supervisorList = useSelector((state) => state.supervisorList);
  const addSupervisorMemberSuccessMessage = useSelector(
    (state) => state.addSupervisorMember
  );
  const deleteSupervisorUserSuccessMessage = useSelector(
    (state) => state.deleteSupervisorUser
  );

  const addRemoveSupervisorUsers = useSelector(
    (state) => state.addRemoveSupervisorUsers
  );

  const getUsersList = useCallback(
    (supervisor) => {
      setSelectedSupervisorObject(supervisor);
      dispatch(fetchAddRemoveSupervisorUsers(supervisor.id));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(fetchSupervisorList());
  }, [dispatch]);

  useEffect(() => {
    console.log(supervisorList);
    if (isObjectNotEmpty(supervisorList)) {
      setSelectedSupervisorObject(supervisorList[0]);
      //dispatch(fetchAddRemoveSupervisorUsers());

      getUsersList(supervisorList[0]);
    } else {
      //dispatch(resetStoreState(ADD_REMOVE_SUPERVISOR_USERS_RESET));
    }
  }, [supervisorList, getUsersList]);

  useEffect(() => {
    if (
      isObjectNotEmpty(addSupervisorMemberSuccessMessage) ||
      isObjectNotEmpty(deleteSupervisorUserSuccessMessage)
    ) {
      dispatch(resetStoreState(ADD_SUPERVISOR_MEMBER_RESET));
      dispatch(resetStoreState(DELETE_SUPERVISOR_USER_RESET));
      getUsersList(selectedSupervisorObject);
    }
  });

  let filteredUserList = isObjectNotEmpty(searchText)
    ? addRemoveSupervisorUsers
        .sort((a, b) =>
          a[sortKey].toString().localeCompare(b[sortKey].toString())
        )
        .filter(
          (user) =>
            user.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
            user.last_name.toLowerCase().includes(searchText.toLowerCase()) ||
            user.login.toLowerCase().includes(searchText.toLowerCase())
          //    ||
          //   user.user_cd.toLowerCase().includes(searchText.toLowerCase())
        )
    : addRemoveSupervisorUsers
        .sort((a, b) =>
          a[sortKey].toString().localeCompare(b[sortKey].toString())
        )
        .filter(() => true);

  return (
    <>
      <Spin spinning={isFetchingData} size="large">
        <div className="common-container rolepermission-section">
          <Row>
            <Col>
              <ul className="rap-tabs">
                <li>
                  <Link to={componentPaths.CREATE_USER}>
                    <SvgIcon name="create-user" viewbox="0 0 40 36" />
                    Create User
                  </Link>
                </li>
                <li>
                  <Link to={componentPaths.CHANGE_USER_ROLE}>
                    <SvgIcon name="change-user" viewbox="0 0 43 34" />
                    Change Role
                  </Link>
                </li>
                <li>
                  <Link
                    to={componentPaths.MANAGE_SUPERVISIOR}
                    className="selected"
                  >
                    <SvgIcon name="supervisor-icon" viewbox="0 0 25.765 36" />
                    Manage Supervisor
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="admin-role-top">
                <div className="adminrole-cards">
                  <Slider {...settings} className="userlisder">
                    {supervisorList.map((supervisor) => (
                      // please add selected class for blue border
                      <div
                        key={supervisor.id}
                        className={`user-card ${
                          selectedSupervisorObject.id === supervisor.id
                            ? "selected"
                            : ""
                        }`}
                        onClick={() => {
                          getUsersList(supervisor);
                        }}
                      >
                        <div className="role-switch">
                          {/* <Switch size="small" /> */}
                        </div>
                        <div className="usercard-inner">
                          <div className="user-image">
                            <SvgIcon
                              className="default-avatar"
                              name="user-icon"
                              viewbox="0 0 54 54"
                            />
                          </div>
                          <div className="mt-1">
                            <h4>
                              {supervisor.first_name +
                                " " +
                                supervisor.last_name}
                            </h4>
                            <small>Supervisor</small>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="role-search-bar mt-2">
                <div className="search-rep">
                  <AutoComplete
                    dropdownMatchSelectWidth={"100%"}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Input.Search
                      value={searchText}
                      onChange={(event) => {
                        setSearchText(event.target.value);
                      }}
                      placeholder="Search for user"
                    />
                  </AutoComplete>
                </div>
                <div className="dropdown-sort">
                  Sort by
                  <Select
                    value={sortKey}
                    bordered={false}
                    dropdownMatchSelectWidth={true}
                    dropdownClassName="sortDropdownof-adminrole"
                    onChange={(value) => {
                      console.log(value);
                      setSortKey(value);
                    }}
                  >
                    <Option value={userSortKey.NAME}>Name</Option>
                    <Option value={userSortKey.SUPERVISIOR}>Supervisors</Option>
                    <Option value={userSortKey.ASSIGN}>Assign</Option>
                  </Select>
                </div>
              </div>

              <div>
                <div className="table-wrapper admin-role-wrapper">
                  <Table
                    tableLayout="fixed"
                    className="common-table"
                    dataSource={filteredUserList}
                    pagination={false}
                    scroll={{ y: "calc(100vh - 480px)" }}
                  >
                    <Column
                      title="User's Name"
                      dataIndex="username"
                      key="username"
                      width="120px"
                      render={(username, order) => {
                        return order.first_name + " " + order.last_name;
                      }}
                    />
                    <Column
                      title="Login ID"
                      dataIndex="login"
                      key="login"
                      width="125px"
                    />
                    <Column
                      title="No. of Supervisors"
                      dataIndex="supervisor_count"
                      key="supervisor_count"
                      width="100px"
                      align="center"
                    />
                    <Column
                      title="Assign"
                      dataIndex="supervisor_user"
                      key="supervisor_user"
                      width="50px"
                      align="center"
                      render={(supervisor_user, user) => (
                        <Checkbox
                          checked={supervisor_user}
                          onChange={(event) => {
                            user.supervisor_user = event.target.checked;
                            setSelectedSupervisorObject({
                              ...selectedSupervisorObject,
                            });

                            if (event.target.checked) {
                              let requestObject = {
                                user_members: [user],
                              };
                              dispatch(addSupervisorMember(requestObject));
                            } else {
                              dispatch(
                                deleteSupervisorUser(user.user_member_id)
                              );
                            }
                          }}
                        />
                      )}
                    />
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};

export default ManageSupervisor;
