import React from "react";
import { SvgIcon } from "../../../../components/common";
import { Button, Modal, Form, Input, Select, DatePicker, Spin } from "antd";
import { Col, Row } from "../../../../components/common/grid";
import moment from "moment";
import _ from "lodash";
import MultiaddressSelectModalComponent from "./multiaddress-select-modal";

import {
  resetStoreState,
  saveShippingDetail,
  updateShippingDetail,
  createLoadingSelector,
  fetchCountries,
  fetchStates,
  fetchOrderShippingDetail,
  getInhandDate,
} from "../../../../redux/actions";

import {
  UPDATE_SHIPPING_DETAIL,
  SAVE_SHIPPING_DETAIL,
  FETCH_COUNTRIES,
  FETCH_STATES,
  STATE_LIST_RESET,
  SHIPPING_DETAIL_RESET,
  GET_INHAND_DATE,
  GET_INHAND_DATE_RESET,
  GET_SHIPPING_METHODS,
  GET_SHIPPING_METHODS_RESET,
} from "../../../../redux/actions/type";
import { connect } from "react-redux";

//Style
import "./multiaddress-modal.scss";
import { DATE_FORMAT } from "../../../../constants";
import {
  isObjectNotEmpty,
  isObjectEmpty,
  isValidationRequiredForShipProvider,
  isloggedInUserViewOnly,
} from "../../../../common/common-methods";
import { showAlert } from "../../../../common/alert-messages";

const { Option } = Select;

class AddEditAddressModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autoValidationTrigger: false,
      validatedAddress: {
        ship_address1: this.props.shipping.ship_address1,
        ship_address2: this.props.shipping.ship_address2,
        ship_city: this.props.shipping.ship_city,
        ship_state: this.props.shipping.ship_state,
        ship_country: this.props.shipping.ship_country,
        ship_zip: this.props.shipping.ship_zip,
      },
      shipping: { ...this.props.shipping },
      requiredShippingDate: this.props.shipping.ship_date,
      isInitialStateListRequired: this.props.shipping ? true : false,
      residentialIndicator: this.getResidentialTypeTitle(this.props.shipping),
      isValidationRequired: isValidationRequiredForShipProvider(
        this.props.shipping.shipping_code
      ),
    };
  }

  formRef = React.createRef();

  getResidentialTypeTitle = (shipping) => {
    return shipping.residential_indicator === "Y"
      ? "Residential"
      : "Commercial";
  };

  componentDidMount() {
    this.props.fetchCountries();
  }

  onChangeDateCombobox = (value, dateString) => {
    let shipping = this.state.shipping;
    shipping["ship_date"] = dateString;
    let detail = this.getDetailForInhandDate();
    this.props.getInhandDate(detail);
    this.setState({ shipping });
    this.formRef.current.setFieldsValue({
      state: null,
    });
  };

  getDetailForInhandDate = () => {
    const shipping = this.state.shipping;
    return {
      id: shipping.id,
      trans_no: this.props.orderNo,
      ship_city: shipping.ship_city,
      ship_state: shipping.ship_state,
      ship_zip: shipping.ship_zip,
      ship_country: shipping.ship_country,
      estimated_ship_date: isObjectNotEmpty(shipping.ship_date)
        ? shipping.ship_date
        : shipping.estimated_ship_date,
      shipping_code: shipping.shipping_code,
      ship_method: shipping.ship_method,
      residential_indicator: shipping.residential_indicator,
      insurance_flag: shipping.insurance_flag,
      saturday_delivery_flag: shipping.saturday_delivery_flag,
      ship_qty: shipping.ship_qty,
    };
  };
  onChangeCombobox = (event, option) => {
    let shipping = this.state.shipping;
    shipping[option.name] = option.value;
    this.setState({ shipping });
    this.formRef.current.setFieldsValue({
      state: null,
    });
  };

  onChangeComboboxForStates = (event, option) => {
    let shipping = this.state.shipping;
    if (option.name === "ship_country") {
      this.props.resetStoreState(STATE_LIST_RESET);
      shipping["ship_state"] = null;
      shipping["ship_city"] = null;
      shipping["ship_zip"] = null;
      this.props.fetchStates(option.key);
      this.formRef.current.setFieldsValue({
        ship_state: null,
        ship_city: null,
        ship_zip: null,
      });
    }
    if (option.name === "ship_state") {
      shipping["ship_zip"] = null;
      shipping["ship_city"] = null;
      this.formRef.current.setFieldsValue({
        ship_city: null,
        ship_zip: null,
      });
    }
    shipping[option.name] = option.value;
    this.setState({ shipping });
  };

  onChange = (event) => {
    let shipping = this.state.shipping;
    let name = event.target.getAttribute("name"); //field name
    let value = event.target.value; //field value
    if (name === "ship_city") {
      shipping["ship_zip"] = null;
      this.formRef.current.setFieldsValue({
        // ship_state: null,
        ship_zip: null,
      });
    }
    shipping[name] = value;
    this.setState({ shipping });
  };

  onFinish = (values) => {
    if (this.props.shipping.id) {
      if (this.state.isValidationRequired && !this.isCurrentAdressValidated()) {
        this.setState({
          autoValidationTrigger: !this.state.autoValidationTrigger,
        });
      } else {
        let shipping = this.state.shipping;
        shipping.internal_ship_date = isObjectEmpty(shipping.ship_date)
          ? shipping.estimated_ship_date
          : shipping.ship_date;
        shipping.internal_inhand_date = isObjectEmpty(shipping.inhand_date)
          ? shipping.estimated_arrival_date
          : shipping.inhand_date;
        this.setState({ shipping });
        this.props.updateShippingDetail(this.state.shipping);
      }
    }
  };

  disabledDate = (current) => {
    var now = moment();
    return now > current;
  };

  componentDidUpdate() {
    if (
      isObjectNotEmpty(this.props.countryList) &&
      this.state.isInitialStateListRequired
    ) {
      const filteredArry = this.props.countryList.filter(
        (country) => country.code === this.state.shipping.ship_country
      );
      if (filteredArry.length > 0) {
        const country = filteredArry[0];
        this.props.fetchStates(country.id);
      }

      this.setState({ isInitialStateListRequired: false });
    }

    if (isObjectNotEmpty(this.props.successMessage)) {
      this.props.resetStoreState(SHIPPING_DETAIL_RESET);
      showAlert.success(this.props.successMessage);
      this.props.closeEditComponent();
      this.props.fetchOrderShippingDetail(this.state.shipping.sales_order_id);
      this.props.resetStoreState(UPDATE_SHIPPING_DETAIL);
    }

    if (isObjectNotEmpty(this.props.inhandDate)) {
      let shipping = this.state.shipping;
      if (shipping.ship_date !== "") {
        shipping["inhand_date"] = this.props.inhandDate.est_arrival_date;
        this.formRef.current.setFieldsValue({
          inhand_date: moment(
            this.props.inhandDate.est_arrival_date,
            DATE_FORMAT
          ),
        });
      } else {
        shipping[
          "estimated_arrival_date"
        ] = this.props.inhandDate.est_arrival_date;
        shipping["inhand_date"] = "";
        this.formRef.current.setFieldsValue({
          estimated_arrival_date: moment(
            this.props.inhandDate.est_arrival_date,
            DATE_FORMAT
          ),
          inhand_date: moment("", DATE_FORMAT),
        });
      }
      this.setState({ shipping });
      this.props.resetStoreState(GET_INHAND_DATE_RESET);
    }

    if (
      isObjectNotEmpty(this.props.shippingMethods) &&
      this.props.shippingMethods.shipping_methods.length > 0
    ) {
      const selectedMethod = this.props.shippingMethods.shipping_methods.find(
        (method) => method.service_name === this.state.shipping.ship_method
      );
      let shipping = this.state.shipping;
      shipping.ship_amt = selectedMethod.ship_amt; //price.replace("$", "");
      shipping.inhand_date = selectedMethod.delivery_date;
      this.setState({ shipping });
      this.formRef.current.setFieldsValue({
        inhand_date: moment(selectedMethod.delivery_date, DATE_FORMAT),
        ship_amt: selectedMethod.ship_amt, //price.replace("$", ""),
      });
      this.props.resetStoreState(GET_SHIPPING_METHODS_RESET);
    }
  }

  validateHandler = (value) => {
    let ship_address1 = "";
    let ship_address2 = "";
    if (value.address1 instanceof Array) {
      ship_address1 = value.address1[0];
      // ship_address2 = value.address1[1];
    } else {
      ship_address1 = value.address1;
      //ship_address2 = "";
    }

    ship_address2 = this.state.shipping.ship_address2;

    let updatedShipping = this.state.shipping;
    updatedShipping.ship_address1 = ship_address1;
    updatedShipping.ship_address2 = ship_address2;
    updatedShipping.ship_city = value.city;
    updatedShipping.ship_state = value.state;
    updatedShipping.ship_zip = value.zip_code;
    updatedShipping.ship_country = value.country;
    updatedShipping.residential_indicator = value.residential_indicator;

    let validatedShipping = this.state.validatedAddress;
    validatedShipping.ship_address1 = ship_address1;
    validatedShipping.ship_address2 = ship_address2;
    validatedShipping.ship_city = value.city;
    validatedShipping.ship_state = value.state;
    validatedShipping.ship_zip = value.zip_code;
    validatedShipping.ship_country = value.country;
    validatedShipping.residential_indicator = value.residential_indicator;

    this.setState({
      shipping: { ...updatedShipping },
      validatedAddress: { ...validatedShipping },
      residentialIndicator: this.getResidentialTypeTitle(value),
    });
    this.formRef.current.setFieldsValue({
      ship_address1: ship_address1,
      ship_address2: validatedShipping.ship_address2,
      ship_city: value.city,
      ship_state: value.state,
      ship_zip: value.zip_code,
      ship_country: value.country,
    });
  };

  isCurrentAdressValidated = () => {
    let updatedShipping = this.state.shipping;

    let validatedShipping = this.state.validatedAddress;

    return _.isMatch(
      { ...updatedShipping, ship_address2: "" },
      { ...validatedShipping, ship_address2: "" }
    );
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          destroyOnClose={true}
          centered={true}
          bodyStyle={{ padding: "1rem" }}
          closeIcon={
            <SvgIcon
              name="close"
              fill="#dc0100"
              width="13px"
              viewbox="0 0 298.667 298.667"
            />
          }
          className="full-screen-xs-modal footer-less add-new-address-modal"
          closable={true}
          onCancel={this.props.closeEditComponent}
          title="Edit Shipping Address"
          visible={true}
          footer={null}
          width="100%"
        >
          <Spin spinning={this.props.isFetchingData} size="large">
            <Form
              ref={this.formRef}
              name="normal_login"
              className="login-form"
              initialValues={{
                ...this.state.shipping,

                ship_date: this.state.shipping.ship_date
                  ? moment(this.state.shipping.ship_date, DATE_FORMAT)
                  : "",

                // ship_date: moment(
                //   this.state.shipping.ship_date ?
                //     this.state.shipping.ship_date : "", DATE_FORMAT
                // ),   date picker issueaa

                inhand_date: moment(
                  this.state.shipping.inhand_date
                    ? this.state.shipping.inhand_date
                    : "",
                  DATE_FORMAT
                ),

                estimated_ship_date: moment(
                  this.state.shipping.estimated_ship_date
                    ? this.state.shipping.estimated_ship_date
                    : "",
                  DATE_FORMAT
                ),

                estimated_arrival_date: moment(
                  this.state.shipping.estimated_arrival_date
                    ? this.state.shipping.estimated_arrival_date
                    : "",
                  DATE_FORMAT
                ),
              }}
              onFinish={this.onFinish}
            >
              <Row className="gutter-sm">
                <Col xs="12">
                  <label htmlFor="ship_name" className="w-100 fs-12">
                    Full Name
                  </label>
                  <Form.Item
                    id="ship_name"
                    name="ship_name"
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Please enter full name!",
                      },
                    ]}
                  >
                    <Input
                      name="ship_name"
                      value={this.state.shipping.ship_name}
                      onChange={this.onChange.bind(this)}
                      size="large"
                      prefix={
                        <SvgIcon
                          name="menu-alt"
                          viewbox="0 0 16 7"
                          width="15px"
                        />
                      }
                      disabled={!this.props.isEditable}
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
                <Col xs="12">
                  <label htmlFor="attention" className="w-100 fs-12">
                    Attention
                  </label>
                  <Form.Item
                    id="attention"
                    name="attention"
                    className="mb-3"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter attention!",
                    //   },
                    // ]}
                  >
                    <Input
                      name="attention"
                      value={this.state.shipping.attention}
                      onChange={this.onChange.bind(this)}
                      size="large"
                      prefix={
                        <SvgIcon
                          name="menu-alt"
                          viewbox="0 0 16 7"
                          width="15px"
                        />
                      }
                      disabled={!this.props.isEditable}
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="gutter-sm ship-up-custom">
                <Col xs="9">
                  <label htmlFor="ship_address1" className="w-100 fs-12">
                    Address
                  </label>
                  <Form.Item
                    id="ship_address1"
                    name="ship_address1"
                    hasFeedback={this.state.isValidationRequired}
                    validateStatus={
                      this.state.isValidationRequired &&
                      !this.isCurrentAdressValidated()
                        ? "error"
                        : "success"
                    }
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your address!",
                      },
                    ]}
                  >
                    <Input
                      name="ship_address1"
                      value={this.state.shipping.ship_address1}
                      onChange={this.onChange.bind(this)}
                      size="large"
                      prefix={
                        <SvgIcon name="pin" viewbox="0 0 10 15" width="12px" />
                      }
                      disabled={!this.props.isEditable}
                      placeholder=""
                    />
                  </Form.Item>
                  <Form.Item
                    id="ship_address2"
                    name="ship_address2"
                    className="mb-3"
                    rules={[
                      {
                        required: false,
                        message: "Please enter your address!",
                      },
                    ]}
                  >
                    <Input
                      name="ship_address2"
                      value={this.state.shipping.ship_address2}
                      onChange={this.onChange.bind(this)}
                      size="large"
                      prefix={
                        <SvgIcon name="pin" viewbox="0 0 10 15" width="12px" />
                      }
                      disabled={!this.props.isEditable}
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
                <Col xs="3">
                  <label className="w-100 fs-12">Address Type</label>
                  <Form.Item id="residential_indicator" className="mb-3">
                    <Input
                      value={this.state.residentialIndicator}
                      size="large"
                      disabled="true"
                    />
                  </Form.Item>
                </Col>
                <Col xs="12" sm="6" md="4" lg="4">
                  <label htmlFor="ship_country" className="w-100 fs-12">
                    Country
                  </label>
                  <div className="custom-select-with-icon">
                    <SvgIcon name="globe" viewbox="0 0 59 59" width="15px" />
                    <Form.Item
                      name="ship_country"
                      id="ship_country"
                      className="mb-3"
                      hasFeedback={this.state.isValidationRequired}
                      validateStatus={
                        this.state.isValidationRequired &&
                        !this.isCurrentAdressValidated()
                          ? "error"
                          : "success"
                      }
                      rules={[
                        {
                          required: false,
                          message: "Please select your country!",
                        },
                      ]}
                    >
                      <Select
                        value={this.state.shipping.ship_country}
                        onChange={this.onChangeComboboxForStates.bind(this)}
                        suffixIcon={
                          <SvgIcon name="caret-alt" viewbox="0 0 12 7" />
                        }
                        size="large"
                        disabled={!this.props.isEditable}
                      >
                        {this.props.countryList.map((country) => (
                          <Option
                            key={country.id}
                            name="ship_country"
                            value={country.code}
                          >
                            {country.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col xs="12" sm="6" md="4" lg="4">
                  <label htmlFor="ship_state" className="w-100 fs-12">
                    State
                  </label>
                  <div className="custom-select-with-icon">
                    <SvgIcon name="bank" viewbox="0 0 15 17" width="15px" />
                    <Form.Item
                      id="ship_state"
                      name="ship_state"
                      className="mb-3"
                      hasFeedback={this.state.isValidationRequired}
                      validateStatus={
                        this.state.isValidationRequired &&
                        !this.isCurrentAdressValidated()
                          ? "error"
                          : "success"
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please select your state!",
                        },
                      ]}
                    >
                      <Select
                        value={this.state.shipping.ship_state ? "" : ""}
                        onChange={this.onChangeComboboxForStates.bind(this)}
                        suffixIcon={
                          <SvgIcon name="caret-alt" viewbox="0 0 12 7" />
                        }
                        size="large"
                        disabled={!this.props.isEditable}
                      >
                        {" "}
                        {this.props.stateList.map((state) => (
                          <Option
                            key={state.id}
                            name="ship_state"
                            value={state.code}
                          >
                            {state.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col xs="12" sm="12" md="4" lg="4">
                  <label htmlFor="ship_city" className="w-100 fs-12">
                    City
                  </label>
                  <Form.Item
                    id="ship_city"
                    name="ship_city"
                    className="mb-3"
                    validateStatus={
                      this.state.isValidationRequired &&
                      !this.isCurrentAdressValidated()
                        ? "error"
                        : "success"
                    }
                    hasFeedback={this.state.isValidationRequired}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your city!",
                      },
                    ]}
                  >
                    <Input
                      name="ship_city"
                      value={this.state.shipping.ship_city}
                      onChange={this.onChange.bind(this)}
                      size="large"
                      prefix={
                        <SvgIcon name="pin" viewbox="0 0 10 15" width="12px" />
                      }
                      placeholder=""
                      disabled={!this.props.isEditable}
                    />
                  </Form.Item>
                </Col>
                <Col xs="8" sm="6" md="6" lg="6">
                  <label htmlFor="ship_zip" className="w-100 fs-12">
                    Zip Code
                  </label>
                  <Form.Item
                    id="ship_zip"
                    name="ship_zip"
                    className="mb-3"
                    validateStatus={
                      this.state.isValidationRequired &&
                      !this.isCurrentAdressValidated()
                        ? "error"
                        : "success"
                    }
                    hasFeedback={this.state.isValidationRequired}
                    rules={[
                      {
                        required: true,
                        message: "Please select your zip code!",
                      },
                    ]}
                  >
                    <Input
                      name="ship_zip"
                      value={this.state.shipping.ship_zip}
                      onChange={this.onChange.bind(this)}
                      size="large"
                      prefix={
                        <SvgIcon name="pin" viewbox="0 0 10 15" width="12px" />
                      }
                      placeholder=""
                      disabled={!this.props.isEditable}
                    />
                  </Form.Item>
                </Col>
                <Col xs="4" sm="6" md="6" lg="6">
                  {this.props.isEditable && this.state.isValidationRequired && (
                    <MultiaddressSelectModalComponent
                      shippingDetail={this.state.shipping}
                      trans_no={this.props.orderNo}
                      validateHandler={this.validateHandler}
                      autoValidationTrigger={this.state.autoValidationTrigger}
                    />
                  )}
                </Col>
                <Col xs="12" sm="12" md="6" lg="6">
                  <label htmlFor="ship_date" className="w-100 fs-12">
                    Req. Ship Date
                  </label>
                  <div className="custom-date-picker mb-3">
                    <Form.Item
                      id="ship_date"
                      name="ship_date"
                      className="custom-label mb-0"
                      rules={[
                        {
                          required: false,
                          message: "Please select required ship date!",
                        },
                      ]}
                    >
                      <DatePicker
                        disabledDate={this.disabledDate}
                        format={DATE_FORMAT}
                        name="ship_date"
                        value={
                          this.state.shipping.ship_date
                            ? moment(this.state.shipping.ship_date, DATE_FORMAT)
                            : ""
                        }
                        onChange={this.onChangeDateCombobox.bind(this)}
                        placeholder="Enter Ext Ref Date"
                        suffixIcon={
                          <SvgIcon
                            name="calendar"
                            width="16px"
                            viewbox="0 0 14.652 14.726"
                          />
                        }
                        disabled={
                          !this.props.isEditable ||
                          !this.state.isValidationRequired
                        }
                      />
                    </Form.Item>
                    {/* apply disable condtion on this icons as well */}
                    {!this.state.isValidationRequired && (
                      <SvgIcon
                        name="lock"
                        className="disabled-icon"
                        width="13px"
                        viewbox="0 0 12.4 16.4"
                      />
                    )}
                  </div>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6">
                  <label htmlFor="inhand_date" className="w-100 fs-12">
                    Req. InHand Date
                  </label>
                  <div className="custom-date-picker mb-3">
                    <Form.Item
                      id="inhand_date"
                      name="inhand_date"
                      className="custom-label mb-0"
                      rules={[
                        {
                          required: false,
                          message: "Please select required in hand date!",
                        },
                      ]}
                    >
                      <DatePicker
                        name="inhand_date"
                        format={DATE_FORMAT}
                        value={
                          this.state.shipping.inhand_date
                            ? moment(
                                this.state.shipping.inhand_date,
                                DATE_FORMAT
                              )
                            : moment("07-01-2020", DATE_FORMAT)
                        }
                        onChange={this.onChangeDateCombobox.bind(this)}
                        placeholder="Enter Ext Ref Date"
                        suffixIcon={
                          <SvgIcon
                            name="calendar"
                            width="16px"
                            viewbox="0 0 14.652 14.726"
                          />
                        }
                        disabled={true}
                      />
                    </Form.Item>
                    {/* apply disable condtion on this icons as well */}
                    {true && (
                      <SvgIcon
                        name="lock"
                        className="disabled-icon"
                        width="13px"
                        viewbox="0 0 12.4 16.4"
                      />
                    )}
                  </div>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6">
                  <label htmlFor="estimated_ship_date" className="w-100 fs-12">
                    Est. Ship Date
                  </label>
                  <div className="custom-date-picker mb-3">
                    <Form.Item
                      id="estimated_ship_date"
                      name="estimated_ship_date"
                      className="custom-label mb-0"
                      rules={[
                        {
                          required: false,
                          message: "Please select estimated ship date!",
                        },
                      ]}
                    >
                      <DatePicker
                        name="estimated_ship_date"
                        format={DATE_FORMAT}
                        value={
                          this.state.shipping.estimated_ship_date
                            ? moment(
                                this.state.shipping.estimated_ship_date,
                                DATE_FORMAT
                              )
                            : moment("", DATE_FORMAT)
                        }
                        onChange={this.onChangeDateCombobox.bind(this)}
                        placeholder="Enter Ext Ref Date"
                        suffixIcon={
                          <SvgIcon
                            name="calendar"
                            width="16px"
                            viewbox="0 0 14.652 14.726"
                          />
                        }
                        disabled={true}
                      />
                    </Form.Item>
                    {/* apply disable condtion on this icons as well */}
                    {true && (
                      <SvgIcon
                        name="lock"
                        className="disabled-icon"
                        width="13px"
                        viewbox="0 0 12.4 16.4"
                      />
                    )}
                  </div>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6">
                  <label
                    htmlFor="estimated_arrival_date"
                    className="w-100 fs-12"
                  >
                    Est. InHand Date
                  </label>
                  <div className="custom-date-picker mb-3">
                    <Form.Item
                      id="estimated_arrival_date"
                      name="estimated_arrival_date"
                      className="custom-label mb-0"
                      rules={[
                        {
                          required: false,
                          message: "Please select estimated inhand date!",
                        },
                      ]}
                    >
                      <DatePicker
                        name="estimated_arrival_date"
                        format={DATE_FORMAT}
                        value={
                          this.state.shipping.estimated_arrival_date
                            ? moment(
                                this.state.shipping.estimated_arrival_date,
                                DATE_FORMAT
                              )
                            : moment("", DATE_FORMAT)
                        }
                        onChange={this.onChangeDateCombobox.bind(this)}
                        placeholder="Enter Ext Ref Date"
                        suffixIcon={
                          <SvgIcon
                            name="calendar"
                            width="16px"
                            viewbox="0 0 14.652 14.726"
                          />
                        }
                        disabled={true}
                      />
                    </Form.Item>
                    {/* apply disable condtion on this icons as well */}
                    {true && (
                      <SvgIcon
                        name="lock"
                        className="disabled-icon"
                        width="13px"
                        viewbox="0 0 12.4 16.4"
                      />
                    )}
                  </div>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6">
                  <label htmlFor="shipping_code" className="w-100 fs-12">
                    Shipping #
                  </label>
                  <div className="custom-status-input mb-3">
                    <Form.Item
                      id="shipping_code"
                      className="mb-0"
                      rules={[
                        {
                          required: true,
                          message: "Please select your shipping number!",
                        },
                      ]}
                    >
                      <Input
                        name="shipping_code"
                        value={this.state.shipping.shipping_code}
                        onChange={this.onChangeCombobox.bind(this)}
                        size="large"
                        placeholder=""
                        disabled={true}
                      />
                    </Form.Item>
                    {/* apply disable condtion on this icons as well */}
                    {true && (
                      <SvgIcon
                        name="lock"
                        className="disabled-icon"
                        width="13px"
                        viewbox="0 0 12.4 16.4"
                      />
                    )}
                  </div>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6">
                  <label htmlFor="ship_method" className="w-100 fs-12">
                    Shipping Method
                  </label>
                  <div className="custom-status-input mb-3">
                    <Form.Item
                      id="ship_method"
                      className="mb-0"
                      rules={[
                        {
                          required: true,
                          message: "Please select your shipping number!",
                        },
                      ]}
                    >
                      <Input
                        name="ship_method"
                        value={this.state.shipping.ship_method}
                        onChange={this.onChangeCombobox.bind(this)}
                        size="large"
                        placeholder=""
                        disabled={true}
                      />
                    </Form.Item>
                    {/* apply disable condtion on this icons as well */}
                    {true && (
                      <SvgIcon
                        name="lock"
                        className="disabled-icon"
                        width="13px"
                        viewbox="0 0 12.4 16.4"
                      />
                    )}
                  </div>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6">
                  <label htmlFor="ship_amt" className="w-100 fs-12">
                    Shipping Amount
                  </label>
                  <div className="custom-status-input mb-3">
                    <Form.Item
                      id="ship_amt"
                      className="mb-0"
                      rules={[
                        {
                          required: true,
                          message: "Please select your shipping number!",
                        },
                      ]}
                    >
                      <Input
                        name="ship_amt"
                        value={this.state.shipping.ship_amt}
                        onChange={this.onChangeCombobox.bind(this)}
                        size="large"
                        placeholder=""
                        disabled={true}
                      />
                    </Form.Item>
                    {/* apply disable condtion on this icons as well */}
                    {true && (
                      <SvgIcon
                        name="lock"
                        className="disabled-icon"
                        width="13px"
                        viewbox="0 0 12.4 16.4"
                      />
                    )}
                  </div>
                </Col>
              </Row>

              <div className="d-flex justify-content-end mt-3">
                <Button
                  onClick={this.props.closeEditComponent}
                  className="mr-3"
                  size="large"
                >
                  <b className="fs-13">CANCEL</b>
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  disabled={
                    isloggedInUserViewOnly() || !this.props.isEditable
                    //  ||
                    // (this.state.isValidationRequired
                    //   ? !this.isCurrentAdressValidated()
                    //   : false)
                  }
                >
                  <b className="fs-13">SAVE</b>
                </Button>
              </div>
            </Form>
          </Spin>{" "}
        </Modal>
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector([
  UPDATE_SHIPPING_DETAIL,
  SAVE_SHIPPING_DETAIL,
  FETCH_COUNTRIES,
  FETCH_STATES,
  GET_INHAND_DATE,
  GET_SHIPPING_METHODS,
]);

const mapStateToProps = (state) => ({
  successMessage: state.addUpdateShipping,
  countryList: state.countries,
  stateList: state.states,
  inhandDate: state.inhandDate,
  shippingMethods: state.shippingMethods,
  isFetchingData: loadingSelector(state),
});

export default connect(mapStateToProps, {
  saveShippingDetail,
  updateShippingDetail,
  resetStoreState,
  fetchOrderShippingDetail,
  fetchCountries,
  fetchStates,
  getInhandDate,
})(AddEditAddressModalComponent);
