export const orderStatusConstants = {
  ORDER_STATUS_COMPLETED: "ORDER COMPLETED",
  //ARTWORK_STATUS_COMPLETED: "COMPLETE",
  //PAYMENT_STATUS_COMPLETED: "CREDIT APPROVED",
  SHIPPING_STATUS_COMPLETED: "ORDER SHIPPED",
  NOT_APPLICABLE: "NOT APPLICABLE",
};

export const proofStatusConstants = {
  //Done
  PROOF_APPROVED: "PROOF APPROVED",
  ARTWORK_RECEIVED: "ARTWORK RECEIVED",
  PROOF_IN_PROGRESS: "PROOF IN PROGRESS", //'ARTWORK ANSWERED','PROOF IN PROGRESS','ARTWORK-QUERIED','READY FOR INTERNAL PROOFING'
  COMPLETE: "COMPLETE", //'REJECTEDFROMIMPOSITION','REJECTED FROM FILM','ARTWORK QC'

  //Pending
  PENDING_APPROVAL: "PENDING APPROVAL",
  WAITING_FOR_ARTWORK: "WAITING FOR ARTWORK",
  PROOF_REJECTED: "PROOF REJECTED",
};

export const acknowledgementStatusConstants = {
  NOT_SENT: "NOT SENT",
  SENT: "SENT",
};

export const paymentStatusConstants = {
  //Done

  TERMS: "NET (7,15,30,45,60,90)",
  CREDIT_APPROVED: "CREDIT APPROVED",
  CC_AUTHORIZED: "CC AUTHORIZED", //"CC AUTHORIZED AND CAPTURED", "PAYMENT CAPTURED",

  //Pending
  CC_RELEASED: "CC RELEASED",
  CC_AUTH_FAIL: "CC AUTH FAIL", //"PAYMENT AUTHORIZATION FAILED","PAYMENT CAPTURED FAILED", "PAYMENT AUTHORIZATION AND CAPTURED FAILED",
  PAY_ISSUE: "NEED CC INFO", //"NEED AUTHORIZATION",
  OVER_CREDIT_LIMIT: "OVER CREDIT LIMIT",
  PAYMENT_RELEASE_FAILED: "PAYMENT RELEASE FAILED",
};

export const orderStatusFilters = [
  "IMPOSITION COMPLETE",
  "ORDER COMPLETED",
  "PARTIALLY SHIPPED",
  "ORDER-QUERIED",
  "SHIPPED",
  "DIGITIZTION QC DONE",
  "ORDER REASSIGNED FOR ENTRY",
  "PARTIAL SHIPPED",
  "ORDER RECEIVED",
  "PREPRODUCTION APPROVED",
  "INVOICE SENT",
  "NEW ORDER",
  "ORDER ANSWERED",
  "ORDER PICKED",
  "IN PRODUCTION",
  "OUTSOURCE TO VENDOR",
];

export const artworkStatusFilters = [
  "PENDING APPROVAL",
  "ARTWORK RECEIVED",
  "PROOF APPROVED",
  "PROOF IN PROGRESS",
  "NOT APPLICABLE",
  "WAITING FOR ARTWORK",
  "REJECTED FROM IMPOSITION",
  "PROOF REJECTED",
  "COMPLETE",
  "PROOF APROVED",
];

export const paymentStatusFilters = [
  "CC AUTH FAIL",
  "OVER CREDIT LIMIT",
  "CREDIT NOT AUTHORIZED",
  "NEED CC INFO",
  "PAYMENT RELEASE FAILED",
  "CREDIT APPROVED",
  "CC AUTHORIZED",
  "NOT APPLICABLE",
  "CC RELEASED",
  "PAY_ISSUE",
];

export const shippingStatusFilters = [
  "NEED SHIP INFO",
  "ORDER SHIPPED",
  "PARTIAL SHIPPED",
  "NOT REQUIRED",
  "SHIP INFO RECEIVED",
  "ORDER SHIPPED TO VENDOR",
  "NOT APPLICABLE",
  "PARTIAL SHIP INFO RECEIVED",
];
