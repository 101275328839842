import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Form,
  Input,
  Checkbox,
  Modal,
  Select,
  Spin,
  AutoComplete,
} from "antd";
import { Link, useLocation } from "react-router-dom";
import MediaQuery from "react-responsive";
import { connect, useSelector, useDispatch } from "react-redux";
import { Row, Col } from "../../../components/common/grid";
import { SvgIcon } from "../../../components/common";
import debounce from "lodash/debounce";

import { showAlert } from "../../../common/alert-messages";
import {
  isObjectEmpty,
  isObjectNotEmpty,
  loggedInUserDetail,
  setScrollIn,
} from "../../../common/common-methods";

import {
  fetchItemPackageProfile,
  fetchShippingEstimate,
  fetchEndiciaPackageType,
  sendShipEstimatesEmail,
  fetchCountries,
  fetchStates,
  resetStoreState,
  createLoadingSelector,
  inventoryItemSearch,
  fetchStateByZip,
} from "../../../redux/actions";
import {
  ITEM_PACKAGE_PROFILE,
  ITEM_PACKAGE_PROFILE_RESET,
  SHIPPING_RATES,
  SHIPPING_ESTIMATE_RESET,
  ENDICIA_PACKAGE_TYPE,
  SEND_SHIP_ESTIMATE_EMAIL,
  SEND_SHIP_ESTIMATE_EMAIL_RESET,
  STATE_LIST_RESET,
  FETCH_COUNTRIES,
  FETCH_STATES,
  INVENTORY_ITEM_SEARCH_RESET,
  INVENTORY_ITEM_SEARCH,
  FETCH_STATE_BY_ZIP,
  FETCH_STATE_BY_ZIP_RESET,
} from "../../../redux/actions/type";
// Style
import "./ship-ups-estimate-list.scss";
import { SUGGESTION_API_DEBOUNCE_TIME } from "../../../constants";
import _ from "lodash";

import ValidateAddressPopup from "./validate-address-popup/validate-address-popup";

// const ship_methods = ["UPS"];
//const endicia_packages = ["Parcel", "FlatRateEnvelope"]
const ResultBlock = ({ onSendToCustomClick, shippingRates }) => {
  return (
    <React.Fragment>
      <Row>
        <Col md="5">
          {isObjectNotEmpty(shippingRates) && (
            <h3>
              <b>Delivery Date if Shipped Today</b>
            </h3>
          )}
        </Col>
        <Col md="7">
          {isObjectNotEmpty(shippingRates) && (
            <div className="aware-info">
              Please be aware that TEKWELD automatically includes shipping
              insurance for all orders unless otherwise specified in your
              purchase order.
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col lg='7'>
          <Row className="gutter-sm ship-up-custom">
            {shippingRates &&
              shippingRates.map((estimate) => {
                return (
                  <Col key={estimate.price} xs="12" sm="12" md="6" lg="6" xl="6">
                    <div className="border-box p-3 mb-20">
                      <Row>
                        <Col xs="12">
                          <div className="block-label">{estimate.service_name}</div>
                          <div className="block-value">{estimate.price}</div>
                          <div>
                            Delivery Date <b>{estimate.delivery_date}</b>
                          </div>

                          <div className="saturday-delivery-tag">
                            {weekDays[estimate.delivery_day]} delivery
                          </div>

                          <div>
                            Business Transit Days{" "}
                            <b>{estimate.business_transit_days}</b>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                );
              })}
          </Row>
          <Row>
            <Col xs="12" className="pt-3 mb-4">
              <Button className="alternate-btn" onClick={onSendToCustomClick}>
                SEND TO CUSTOMER
              </Button>
            </Col>
          </Row>
        </Col>
        <Col lg='5'>
          {isObjectNotEmpty(shippingRates) && (
            <div className="estimate-disclaimer">
              <h3>Disclaimer</h3>
              <ul>
                <li>
                  Shipping quotes are estimates and subject to fluctuations in UPS rates, which may lead to significant variances in actual charges.
                </li>
                <li>
                  Quotes assume a valid shipping address and specified product quantity; invalid addresses or discrepancies may incur additional charges and invalidate the quote.
                </li>
                <li>
                  All quotes are in USD and independent of the FOB selection.
                </li>
                <li>
                  External factors like freight availability may cause shipping delays, and Tekweld cannot guarantee delivery times.
                </li>
                <li>
                  Tekweld passes on UPS contract discounts to customers, but if you use your own shipping account, we can't guarantee quote accuracy.
                </li>
                <li>
                  A flat fee of $8.00 applies to 3rd party/collect shipments, with an additional $8.00 charge per drop ship location.
                </li>
                <li>
                  Extra surcharges apply for residential, school, military, convention center, and other limited-access deliveries; these are not included in the quote.
                </li>
                <li>
                  Different shipping methods may require distinct packaging.
                </li>
              </ul>
              <p>
                Please review these terms carefully, as they may impact the accuracy and timeliness of your shipping costs.
              </p>
            </div>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};
const { TextArea } = Input;
const { Option } = Select;

const searchResult = (searchResultArry) => {
  return searchResultArry.map((item, index) => {
    return {
      key: item.id,
      value: item.web_code,
      label: (
        <>
          <span>{item.web_code} </span>
          <span>{ } </span>
        </>
      ),
    };
  });
};

const Complete = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState(
    isObjectNotEmpty(location.state) ? location.state.item_code : ""
  );
  const { onSearchItemSelect } = props;

  const loadingSelectorSuggestion = createLoadingSelector([
    INVENTORY_ITEM_SEARCH,
  ]);

  const isFetchingDataSuggestion = useSelector((state) =>
    loadingSelectorSuggestion(state)
  );

  const onSelect = useCallback(
    (value) => {
      setSearchTerm(value);
      onSearchItemSelect(value);
      dispatch(resetStoreState(INVENTORY_ITEM_SEARCH_RESET));
      dispatch(resetStoreState(SHIPPING_ESTIMATE_RESET));
      dispatch(resetStoreState(ITEM_PACKAGE_PROFILE_RESET));
      dispatch(fetchItemPackageProfile(value));
      // itemPackage = "";
    },
    [dispatch, onSearchItemSelect]
  );

  useEffect(() => {
    if (isObjectNotEmpty(location.state)) {
      //onSearchItemSelect(searchTerm);
      onSelect(searchTerm);
    }
  }, [location.state, searchTerm, onSelect]);

  const options = useSelector((state) =>
    searchResult(state.inventoryItemSearchResult)
  );

  useEffect(() => {
    let matchedRow = _.find(options, function (option) {
      return option.value.toLowerCase() === searchTerm.toLowerCase();
    });
    if (isObjectNotEmpty(matchedRow)) {
      onSelect(searchTerm);
    }
  });

  const delayedQuery = useCallback(
    debounce(
      (value) =>
        dispatch(
          inventoryItemSearch({
            query: value,
          })
        ),
      SUGGESTION_API_DEBOUNCE_TIME
    ),
    []
  );

  const handleSearch = (value) => {
    setSearchTerm(value);
    dispatch(resetStoreState(INVENTORY_ITEM_SEARCH_RESET));
    dispatch(resetStoreState(ITEM_PACKAGE_PROFILE_RESET));
    props.onSearchItemSelect(value);
    if (!isObjectEmpty(value) && value.length > 2) {
      delayedQuery(value);
    }
  };

  return (
    <AutoComplete
      dropdownMatchSelectWidth={"100%"}
      value={searchTerm}
      size="large"
      options={options}
      onSelect={onSelect}
      onSearch={handleSearch}
      placeholder="Input first 3 characters"
      notFoundContent={isFetchingDataSuggestion ? <Spin /> : ""}
    ></AutoComplete>
  );
};

const weekDays = {
  MON: "Monday",
  TUE: "Tuesday",
  WED: "Wednesday",
  THU: "Thursday",
  FRI: "Friday",
  SAT: "Saturday",
  SUN: "Sunday",
};

class ShipUpsEstimateListComponent extends React.Component {
  state = {
    visible: false,
    callSubmitAutomatically: false,
    sendToCustomModal: false,
    packageProfileList: [],
    endiciaPackageTypeList: [],
    shippingEstimate: {
      residential_address_indicator: false,
      saturday_delivery_indicator: false,
      insurance_indicator: loggedInUserDetail().customer_info
        .insurance_indicator,
      shipping_provider: "UPS",
      postal_code: "",
      address1: "",
      city: "",
    },
    showEndiciaPackageType: false,
  };

  onSelectValidAddress = (selectedAddress) => {
    let shippingEstimate = this.state.shippingEstimate;

    shippingEstimate.postal_code = selectedAddress.zip_code;
    shippingEstimate.state = selectedAddress.state;
    shippingEstimate.city = selectedAddress.city;

    if (Array.isArray(selectedAddress.address1)) {
      shippingEstimate.ship_address1 = selectedAddress.address1.join(", ");
    } else {
      shippingEstimate.ship_address1 = selectedAddress.address1;
    }

    shippingEstimate.residential_address_indicator =
      selectedAddress.residential_indicator === "Y";

    this.setState({ shippingEstimate });
  };

  formRef = React.createRef();

  modalToggle = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };
  onFinish = (values) => {
    this.props.resetStoreState(SHIPPING_ESTIMATE_RESET);
    this.props.fetchShippingEstimate(this.state.shippingEstimate);
    this.setState({
      visible: !this.state.visible,
    });
  };
  onSendtoCustomerFormFinish = (values) => {
    this.props.sendShipEstimatesEmail(
      this.state.shippingEstimate,
      this.props.shippingMethodEstimates,
      values
    );
  };
  onSendToCustomClick = () => {
    if (!isObjectEmpty(this.props.shippingMethodEstimates)) {
      this.setState({
        sendToCustomModal: !this.state.sendToCustomModal,
      });
    } else {
      showAlert.error("No estimate for Email");
    }
  };
  handleChange = (value) => { };
  componentDidMount = () => {
    setScrollIn();
    if (isObjectEmpty(this.props.countryList) && !this.props.isFetchingData) {
      this.props.fetchCountries();
    }
  };

  componentDidUpdate = () => {
    if (!isObjectEmpty(this.props.sendEstimateEmailMessage)) {
      showAlert.success(this.props.sendEstimateEmailMessage);
      this.props.resetStoreState(SEND_SHIP_ESTIMATE_EMAIL_RESET);
      let estimate = this.state.shippingEstimate;
      estimate.recipient_email = "";
      estimate.comments = "";
      this.setState({
        sendToCustomModal: false,
        visible: false,
        shippingEstimate: { ...estimate },
      });
    }

    if (isObjectNotEmpty(this.props.stateByZip)) {
      this.props.resetStoreState(FETCH_STATE_BY_ZIP_RESET);
      let shippingEstimate = this.state.shippingEstimate;
      shippingEstimate["state"] = this.props.stateByZip.state_code;
      this.setState({ shippingEstimate });

      this.formRef.current.setFieldsValue({
        state: this.props.stateByZip.state_code,
      });
      if (this.state.callSubmitAutomatically) {
        this.formRef.current.submit();
        this.setState({ callSubmitAutomatically: false });
      }
    }

    if (
      isObjectNotEmpty(this.props.packageProfile) &&
      isObjectEmpty(this.state.shippingEstimate.package_profile_code)
    ) {
      let shippingEstimate = this.state.shippingEstimate;

      shippingEstimate.package_profile_code = this.props.packageProfile[0].package_profile_code;

      this.formRef.current.setFieldsValue({
        package_profile_code: shippingEstimate.package_profile_code,
      });

      this.setState({ shippingEstimate });
    }

    if (
      isObjectNotEmpty(this.props.countryList) &&
      isObjectEmpty(this.state.shippingEstimate.country)
    ) {
      let shippingEstimate = this.state.shippingEstimate;

      shippingEstimate.country = this.props.countryList[0].code;

      this.formRef.current.setFieldsValue({
        country: this.props.countryList[0].name,
      });

      this.setState({ shippingEstimate });
      this.props.fetchStates(this.props.countryList[0].id);
    }

    if (
      isObjectEmpty(this.props.packageProfile) &&
      isObjectNotEmpty(this.state.shippingEstimate.package_profile_code)
    ) {
      let shippingEstimate = this.state.shippingEstimate;

      shippingEstimate.package_profile_code = "";

      this.setState({ shippingEstimate });
    }
  };

  componentWillUnmount() {
    this.props.resetStoreState(SHIPPING_ESTIMATE_RESET);
  }

  onInputChange = (e) => {
    let itemPackage = this.state.shippingEstimate.package_profile_code;

    let shippingEstimate = this.state.shippingEstimate;
    let name = e.target.getAttribute("name");
    let value = e.target.value;
    shippingEstimate[name] = value;
    shippingEstimate["package_profile_code"] = itemPackage;
    if (name === "postal_code") {
      shippingEstimate["state"] = null;
      this.formRef.current.setFieldsValue({
        state: null,
      });
    }

    this.setState({ shippingEstimate });
  };

  onEmailInputChange = (e) => {
    let shippingEstimate = this.state.shippingEstimate;
    let name = e.target.getAttribute("name");
    let value = e.target.value;
    shippingEstimate[name] = value;

    this.setState({ shippingEstimate });
  };

  onChangeCombobox = (event, option) => {
    let showEndiciaPackage;
    let endiciaPackage;
    if (option.name === "shipping_provider") {
      if (option.value === "ENDICIA") {
        showEndiciaPackage = true;
        this.props.fetchEndiciaPackageType();
      } else {
        showEndiciaPackage = false;
        endiciaPackage = "";
        this.formRef.current.setFieldsValue({
          endicia_package_type: null,
        });
      }
    } else {
      showEndiciaPackage = this.state.showEndiciaPackageType;
      endiciaPackage = this.state.shippingEstimate.endicia_package_type;
    }
    let shippingEstimate = this.state.shippingEstimate;

    if (option.name === "country") {
      this.props.resetStoreState(STATE_LIST_RESET);
      shippingEstimate["state"] = null;
      shippingEstimate["postal_code"] = "";
      this.props.fetchStates(option.key);
      this.formRef.current.setFieldsValue({
        state: null,
        postal_code: "",
      });
    }

    shippingEstimate["endicia_package_type"] = endiciaPackage;
    shippingEstimate[option.name] = option.value;
    this.setState({
      shippingEstimate: shippingEstimate,
      showEndiciaPackageType: showEndiciaPackage,
    });
  };

  onChangeCheckBox = (e) => {
    let shippingEstimate = this.state.shippingEstimate;
    let name = e.target.name;
    let value = e.target.checked;
    shippingEstimate[name] = value;
    this.setState({ shippingEstimate });
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.packageProfile &&
      state.packageProfileList !== props.packageProfile
    ) {
      return {
        packageProfileList: { ...props.packageProfile },
      };
    }
    if (
      props.endiciaPackageType &&
      state.endiciaPackageTypeList !== props.endiciaPackageType
    ) {
      return {
        endiciaPackageTypeList: { ...props.endiciaPackageType },
      };
    }
    return null;
  }

  onSearchItemSelect = (value) => {
    let shippingEstimate = this.state.shippingEstimate;
    shippingEstimate["item_code"] = value;
    this.setState({ shippingEstimate });
    this.formRef.current.setFieldsValue({
      package_profile_code: "",
    });
  };

  render() {
    const { visible, sendToCustomModal } = this.state;
    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        <React.Fragment>
          <div className="common-container">
            <MediaQuery maxDeviceWidth={991}>
              <Link to="/dashboard" className="common-back-btn mt-3">
                <SvgIcon name="caretLeft" viewbox="0 0 451.846 451.847" />
                <span>Ship UPS Estimate</span>
              </Link>
            </MediaQuery>
            <Row className="pt-3 pt-lg-4 gutter-sm">
              <Col
                xs="12"
                sm="6"
                md="4"
                lg="4"
                xl="3"
                className="calculation-wrapper-colum"
              >
                <div className="calculation-wrapper">
                  <Form
                    ref={this.formRef}
                    layout="vertical"
                    name="calculation"
                    onFinish={this.onFinish}
                  >
                    <label htmlFor="item" className="w-100 d-block">
                      Item #
                    </label>
                    <Form.Item
                      id="item"
                      className="mb-3"
                      name="item"
                      rules={[
                        {
                          required: false,
                          message: "Please enter item number!",
                        },
                      ]}
                    >
                      <Complete onSearchItemSelect={this.onSearchItemSelect} />
                    </Form.Item>
                    <label htmlFor="quantity" className="w-100 d-block">
                      Quantity
                    </label>
                    <Form.Item
                      id="quantity"
                      name="quantity"
                      className="mb-3"
                      rules={[
                        {
                          required: true,
                          message: "Please enter quantity!",
                        },
                      ]}
                    >
                      <Input
                        id="quantity"
                        type={"number"}
                        name="qty"
                        placeholder=""
                        size="large"
                        value={this.state.shippingEstimate.qty}
                        onChange={this.onInputChange}
                      />
                    </Form.Item>
                    <label htmlFor="package" className="w-100 d-block">
                      Package
                    </label>
                    <Form.Item
                      className="mb-3"
                      id="package"
                      name="package_profile_code"
                      rules={[
                        {
                          required: true,
                          message: "Please enter package detail!",
                        },
                      ]}
                    >
                      <Select
                        value={this.state.shippingEstimate.package_profile_code}
                        onChange={this.onChangeCombobox}
                        suffixIcon={
                          <SvgIcon name="caret-alt" viewbox="0 0 12 7" />
                        }
                        size="large"
                      >
                        {this.props.packageProfile.map((pack, index) => (
                          <Option
                            key={index}
                            name="package_profile_code"
                            value={pack.package_profile_code}
                          >
                            {pack.package_profile_code}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {/* <label htmlFor="ship_method" className="w-100 d-block">
                      Ship Provider
                    </label>
                    <Form.Item
                      id="ship_method"
                      className="mb-3"
                      name="shipping_provider"
                      rules={[
                        {
                          required: true,
                          message: "Please select your ship provider!",
                        },
                      ]}
                    >
                      <Select
                        value={this.state.shippingEstimate.ship_method}
                        onChange={this.onChangeCombobox}
                        suffixIcon={
                          <SvgIcon name="caret-alt" viewbox="0 0 12 7" />
                        }
                        size="large"
                      >
                        {ship_methods.map((method, index) => (
                          <Option
                            key={index}
                            name="shipping_provider"
                            value={method}
                          >
                            {method}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item> */}

                    {this.state.showEndiciaPackageType && (
                      <label
                        htmlFor="endicia_packagee_type"
                        className="w-100 d-block"
                      >
                        Endicia Package Type
                      </label>
                    )}
                    {this.state.showEndiciaPackageType && (
                      <Form.Item
                        id="endicia_package_type"
                        name="endicia_package_type"
                        className="mb-3"
                        rules={[
                          {
                            required: true,
                            message: "Please select package type!",
                          },
                        ]}
                      >
                        <Select
                          value={
                            this.state.shippingEstimate.endicia_package_type
                          }
                          onChange={this.onChangeCombobox}
                          suffixIcon={
                            <SvgIcon name="caret-alt" viewbox="0 0 12 7" />
                          }
                          size="large"
                        //disabled={!this.state.showEndiciaPackageType}
                        >
                          {this.props.endiciaPackageType.map(
                            (endicia_package_type, index) => (
                              <Option
                                key={index}
                                name="endicia_package_type"
                                value={endicia_package_type["value"]}
                              >
                                {endicia_package_type["value"]}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    )}
                    <Row>
                      <Col xs="12">
                        <label htmlFor="country" className="w-100 d-block">
                          Country
                        </label>
                        <Form.Item
                          className="mb-3"
                          id="country"
                          name="country"
                          rules={[
                            {
                              required: true,
                              message: "Please enter country!",
                            },
                          ]}
                        >
                          {/* <Input
                            id="country"
                            name="country"
                            value={this.state.shippingEstimate.country}
                            onChange={this.onInputChange}
                            placeholder=""
                            size="large"
                          /> */}
                          <Select
                            name="country"
                            value={this.state.shippingEstimate.country}
                            onChange={this.onChangeCombobox}
                            suffixIcon={
                              <SvgIcon name="caret-alt" viewbox="0 0 12 7" />
                            }
                            size="large"
                          >
                            {this.props.countryList.map((country) => (
                              <Option
                                key={country.id}
                                name="country"
                                value={country.code}
                              >
                                {country.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <label htmlFor="postal_code" className="w-100 d-block">
                          Zip
                        </label>
                        <Form.Item
                          id="postal_code"
                          name="postal_code"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter zip!",
                            },
                          ]}
                        >
                          <Input
                            id="postal_code"
                            name="postal_code"
                            value={this.state.shippingEstimate.postal_code}
                            onChange={this.onInputChange}
                            onBlur={(event) => {
                              if (
                                this.state.shippingEstimate.postal_code.length >
                                0
                              ) {
                                this.props.fetchStateByZip(
                                  this.state.shippingEstimate.postal_code
                                );
                              }

                              if (
                                event.relatedTarget &&
                                event.relatedTarget.type === "submit"
                              ) {
                                this.setState({
                                  callSubmitAutomatically: true,
                                });
                              }
                            }}
                            placeholder=""
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <label
                          htmlFor="ship_address1"
                          className="w-100 d-block"
                        >
                          Address Line
                        </label>
                        <Form.Item id="ship_address1" className="mb-3">
                          <Input
                            id="ship_address1"
                            name="ship_address1"
                            value={this.state.shippingEstimate.ship_address1}
                            onChange={this.onInputChange}
                            placeholder=""
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <label htmlFor="city" className="w-100 d-block">
                          City
                        </label>
                        <Form.Item id="city" className="mb-3">
                          <Input
                            id="city"
                            name="city"
                            value={this.state.shippingEstimate.city}
                            onChange={this.onInputChange}
                            placeholder=""
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <label htmlFor="state" className="w-100 d-block">
                          State
                        </label>
                        <Form.Item
                          id="state"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter state!",
                            },
                          ]}
                        >
                          <Select
                            defaultValue=""
                            value={this.state.shippingEstimate.state}
                            onChange={this.onChangeCombobox}
                            suffixIcon={
                              <SvgIcon name="caret-alt" viewbox="0 0 12 7" />
                            }
                            size="large"
                          >
                            {this.props.stateList.map((state) => (
                              <Option
                                key={state.id}
                                name="state"
                                value={state.code}
                              >
                                {state.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <div className="text-right mt-n3">
                          <ValidateAddressPopup
                            onSelectValidAddress={this.onSelectValidAddress}
                            shippingEstimate={this.state.shippingEstimate}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Form.Item
                      name="residential_address"
                      className="ml-0 mb-3"
                    // valuePropName="checked"
                    >
                      <Checkbox
                        name="residential_address_indicator"
                        checked={
                          this.state.shippingEstimate
                            .residential_address_indicator
                        }
                        onChange={this.onChangeCheckBox}
                      >
                        Is Residential Address?
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="saturday_delivery"
                      className="ml-0 mb-3"
                    //valuePropName="checked"
                    >
                      <Checkbox
                        name="saturday_delivery_indicator"
                        checked={
                          this.state.shippingEstimate
                            .saturday_delivery_indicator
                        }
                        onChange={this.onChangeCheckBox}
                      >
                        Saturday Delivery?
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="ship_insurance"
                      className="ml-0 mb-3"
                    // valuePropName="checked"
                    >
                      <Checkbox
                        name="insurance_indicator"
                        checked={
                          this.state.shippingEstimate.insurance_indicator
                        }
                        onChange={this.onChangeCheckBox}
                      >
                        Ship Insurance
                      </Checkbox>
                    </Form.Item>
                    <Button type="primary" htmlType="submit" size="large" block>
                      Calculate
                    </Button>
                  </Form>
                </div>
              </Col>

              <Col xs="12" sm="6" md="8" lg="8" xl="9">
                <MediaQuery minDeviceWidth={576}>
                  <ResultBlock
                    onSendToCustomClick={this.onSendToCustomClick}
                    shippingRates={this.props.shippingMethodEstimates}
                  />
                </MediaQuery>
                {/* Result will be visible inside modal popup only for mobile devices. */}
                <MediaQuery maxDeviceWidth={575}>
                  <Modal
                    destroyOnClose={true}
                    centered={true}
                    bodyStyle={{ padding: "1rem" }}
                    closeIcon={
                      <SvgIcon
                        name="close"
                        fill="#dc0100"
                        width="13px"
                        viewbox="0 0 298.667 298.667"
                      />
                    }
                    className="full-screen-xs-modal footer-less"
                    closable={true}
                    onCancel={this.modalToggle}
                    title="Result"
                    visible={visible}
                    footer={null}
                  >
                    <ResultBlock
                      onSendToCustomClick={this.onSendToCustomClick}
                      shippingRates={this.props.shippingMethodEstimates}
                    />
                  </Modal>
                </MediaQuery>

                {/* Send to customer modal */}
                <Modal
                  destroyOnClose={true}
                  centered={true}
                  bodyStyle={{ padding: "1rem" }}
                  closeIcon={
                    <SvgIcon
                      name="close"
                      fill="#dc0100"
                      width="13px"
                      viewbox="0 0 298.667 298.667"
                    />
                  }
                  className="full-screen-xs-modal footer-less"
                  closable={true}
                  onCancel={this.onSendToCustomClick}
                  width={380}
                  title="Send to Customer"
                  visible={sendToCustomModal}
                  footer={null}
                >
                  <Spin spinning={this.props.isFetchingData} size="large">
                    <Form
                      layout="vertical"
                      name="sendToCustom"
                      onFinish={this.onSendtoCustomerFormFinish}
                    >
                      <label htmlFor="emails" className="w-100 d-block">
                        Enter Email(s)
                      </label>
                      <Form.Item
                        className="mb-3"
                        name="emails"
                        rules={[
                          {
                            required: true,
                            message: "Please enter email address!",
                          },
                        ]}
                      >
                        <Input
                          id="emails"
                          name="recipient_email"
                          value={this.state.shippingEstimate.recipient_email}
                          onChange={this.onEmailInputChange}
                          placeholder=""
                          size="large"
                        />
                        {/* <Select suffixIcon={<SvgIcon name="caret-alt" viewbox="0 0 12 7" />} mode="tags" id="emails" onChange={this.handleChange}>
                        <Option key={1} >Vijay Jain</Option>
                        <Option key={2}>Abhishek Chhajlani</Option>
                        <Option key={3}>Sajid Hussain</Option>
                        <Option key={4}>Asif Ansari</Option>
                      </Select> */}
                      </Form.Item>
                      <label htmlFor="comments" className="w-100 d-block">
                        Comment
                      </label>
                      <Form.Item
                        className="mb-3"
                        name="comments"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your comments!",
                          },
                        ]}
                      >
                        <TextArea
                          id="comments"
                          rows={4}
                          placeholder="Type here…"
                          name="comments"
                          value={this.state.shippingEstimate.comments}
                          onChange={this.onEmailInputChange}
                        />
                      </Form.Item>
                      <Button type="primary" htmlType="submit" block>
                        SEND
                      </Button>
                    </Form>
                  </Spin>{" "}
                </Modal>
              </Col>
            </Row>
          </div>
        </React.Fragment>
      </Spin>
    );
  }
}

const loadingSelector = createLoadingSelector([
  ITEM_PACKAGE_PROFILE,
  SHIPPING_RATES,
  FETCH_COUNTRIES,
  FETCH_STATES,
  ENDICIA_PACKAGE_TYPE,
  SEND_SHIP_ESTIMATE_EMAIL,
  //INVENTORY_ITEM_SEARCH,
  FETCH_STATE_BY_ZIP,
]);

const mapStateToProps = (state) => ({
  packageProfile: state.itemPackageProfile,
  shippingMethodEstimates: state.shippingEstimate,
  endiciaPackageType: state.endiciaPackageType,
  sendEstimateEmailMessage: state.sendShipEstimateEmailMessage,
  inventoryItems: state.inventoryItemSearch,
  countryList: state.countries,
  stateList: state.states,
  isFetchingData: loadingSelector(state),
  stateByZip: state.stateByZip,
});

export default connect(mapStateToProps, {
  fetchItemPackageProfile,
  fetchShippingEstimate,
  fetchEndiciaPackageType,
  sendShipEstimatesEmail,
  fetchCountries,
  fetchStates,
  resetStoreState,
  inventoryItemSearch,
  fetchStateByZip,
})(ShipUpsEstimateListComponent);
