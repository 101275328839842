import React, { Component } from "react";
import SvgIcon from "../svg-icon/svg-icon";
import PropTypes from "prop-types";

class ImageLoader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      src: props.src,
      errored: false,
    };
  }

  onError = (e) => {
    if (!this.state.errored) {
      this.setState({
        src: this.props.fallbackSrc,
        errored: true,
      });
    }
    e.target.parentNode.classList.add("image-less");
  };

  componentDidUpdate(nextProps) {
    const { src } = this.props;
    if (nextProps.src !== src) {
      this.setState({ errored: false, src: src });
    }
  }

  render() {
    const { src, errored } = this.state;
    const { src: _1, fallbackSrc: _2, ...props } = this.props;

    return (
      <React.Fragment>
        {!errored && (
          <img
            // style={{ visibility: errored ? 'hidden' : 'visible' }}
            src={src}
            onError={(e) => {
              this.onError(e);
            }}
            {...props}
            alt={props.alt}
          />
        )}
        {errored && (
          <div className="no-img">
            <SvgIcon name="picture" viewbox="0 0 28 28" />
            <span>No Image Available</span>
          </div>
        )}
      </React.Fragment>
    );
  }
}

ImageLoader.propTypes = {
  src: PropTypes.string,
  fallbackSrc: PropTypes.string,
};

export default ImageLoader;
