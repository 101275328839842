import { combineReducers } from "redux";

import * as Type from "../actions/type";
import { isObjectNotEmpty } from "../../common/common-methods";
import { showAlert } from "../../common/alert-messages";

// api/errorReducer.js
const errorReducer = (state = {}, action) => {
  const { type, json } = action;
  if (type === Type.ERROR_MESSAGES_RESET_ALL) {
    return {};
  }

  const matches = /(.*)_(REQUEST|FAILURE)/.exec(type);
  // not a *_REQUEST / *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store errorMessage
    // e.g. stores errorMessage when receiving GET_TODOS_FAILURE
    //      else clear errorMessage when receiving GET_TODOS_REQUEST
    [requestName.split("_")[1]]:
      requestState === Type.STATUS_TYPE_FAILURE ? json : "",
  };
};

// api/loadingReducer.js
const loadingReducer = (state = {}, action) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  //console.log("matches : " + matches);
  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    [requestName.split("_")[1]]: requestState === Type.STATUS_TYPE_REQUEST,
  };
};

const globalSearchResultReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(Type.GLOBAL_SEARCH, Type.STATUS_TYPE_SUCCESS):
      return action.json.search_results;

    case Type.GLOBAL_SEARCH_RESET:
      return [];
    default:
      return state;
  }
};

const inventoryItemSearchResultReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.INVENTORY_ITEM_SEARCH,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.catalog_items;

    case Type.INVENTORY_ITEM_SEARCH_RESET:
      return [];
    default:
      return state;
  }
};

const estimateDetailsReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.ESTIMATE_DETAILS,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.path;

    case Type.ESTIMATE_DETAILS_RESET:
      return "";
    default:
      return state;
  }
};

const acceptRejectStitchesMessageAReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.ACCEPT_REJECT_STITCHES_APPROVAL,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data.message;

    case Type.ACCEPT_REJECT_STITCH_APPROVAL_RESET:
      return "";
    default:
      return state;
  }
};

const acceptRejectPreProductionMessageReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.ACCEPT_PREPRODUCTION_APPROVAL,
      Type.STATUS_TYPE_SUCCESS
    ):
    case Type.getActionStatusType(
      Type.REJECT_PREPRODUCTION_APPROVAL,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data.message;

    case Type.ACCEPT_REJECT_PREPRODUCTION_APPROVAL_RESET:
      return "";
    default:
      return state;
  }
};

const ticketConversationsReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.TICKET_CONVERSATIONS,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data;

    case Type.TICKET_CONVERSATIONS_RESET:
      return [];
    default:
      return state;
  }
};

const preProductionApprovalsReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.PRE_PRODUCTION_APPROVALS,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.pre_production_approval;
    default:
      return state;
  }
};

const contactListReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(Type.CONTACTS_LIST, Type.STATUS_TYPE_SUCCESS):
      return action.json.data["customer_contacts"];
    default:
      return state;
  }
};

const contactDetailReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.CONTACT_DETAIL,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data.message;

    case Type.CONTACT_DETAIL_RESET:
      return "";
    default:
      return state;
  }
};

const activateUserReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(Type.ACTIVATE_USER, Type.STATUS_TYPE_SUCCESS):
      return action.json.data.message;

    case Type.ACTIVATE_USER_RESET:
      return "";
    default:
      return state;
  }
};

const estimateOrdersReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.ESTIMATE_ORDERS,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.estimates;

    default:
      return state;
  }
};

const dashboardReducer = (state = {}, action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.DASHBOARD_DETAILS,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.dashboard;
    default:
      return state;
  }
};

const historyOrdersReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.HISTORY_ORDERS,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.orders;
    default:
      return state;
  }
};

const missingArtOrdersReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.MISSING_ART_ORDERS,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.artworks;

    default:
      return state;
  }
};

const stitchApprovalsReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.STITCH_APPROVALS,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.stich_approvals;

    default:
      return state;
  }
};

const payBillsReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(Type.PAY_BILLS, Type.STATUS_TYPE_SUCCESS):
      return action.json.pay_bills;

    default:
      return state;
  }
};

const eProofOrdersReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.E_PROOF_ORDERS,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.eproof;

    default:
      return state;
  }
};

const eProofOrderDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.E_PROOF_ORDER_DETAILS,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data["sales_orders"];

    case Type.E_PROOF_ORDER_DETAILS_RESET:
      return [];
    default:
      return state;
  }
};

const ordersReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(Type.OPEN_ORDERS, Type.STATUS_TYPE_SUCCESS):
      return action.json.orders;

    default:
      return state;
  }
};

const orderDetailReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(Type.ORDER_DETAIL, Type.STATUS_TYPE_SUCCESS):
      return action.json.data["sales_orders"];
    case Type.ORDER_DETAIL_RESET:
      return [];
    default:
      return state;
  }
};
const subOrderDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.SUB_ORDER_DETAIL,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data.sub_orders;
    case Type.SUB_ORDER_DETAIL_RESET:
      return "";
    default:
      return state;
  }
};

const loginBySaltResponseReducer = (state = {}, action) => {
  switch (action.type) {
    case Type.getActionStatusType(Type.LOGIN_BY_SALT, Type.STATUS_TYPE_SUCCESS):
      return action.json.data;

    case Type.getActionStatusType(
      Type.LOGIN_BY_SALT_RESET,
      Type.STATUS_TYPE_SUCCESS
    ):
      return {};
    default:
      return state;
  }
};

const loginReducer = (state = {}, action) => {
  switch (action.type) {
    case Type.getActionStatusType(Type.LOGIN, Type.STATUS_TYPE_SUCCESS):
      return action.json.data.users;

    case Type.getActionStatusType(
      Type.LOGIN_WITH_SOCIAL_CONNECT,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data.users;

    case Type.getActionStatusType(Type.LOGIN_BY_SALT, Type.STATUS_TYPE_SUCCESS):
      return action.json.data.users;

    default:
      return state;
  }
};

const connectSocialAccountReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.CONNECT_SOCIAL_ACCOUNT,
      Type.STATUS_TYPE_SUCCESS
    ):
      showAlert.success(action.json.data.message);
      return "";

    default:
      return state;
  }
};

const signUpReducer = (state = {}, action) => {
  switch (action.type) {
    case Type.getActionStatusType(Type.SIGN_UP, Type.STATUS_TYPE_SUCCESS):
      return action.json.data["message"];

    case Type.REGISTRATION_DETAIL_RESET:
      return "";
    default:
      return state;
  }
};

const forgotPasswordReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.FORGOT_PASSWORD,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data["message"];

    case Type.FORGOT_PASSWORD_RESET:
      return "";
    default:
      return state;
  }
};

const logOutReducer = (state = {}, action) => {
  switch (action.type) {
    case Type.getActionStatusType(Type.LOGOUT, Type.STATUS_TYPE_SUCCESS):
      // state = undefined;
      return action.json.data["message"];
    default:
      return state;
  }
};

const changePasswordReducer = (state = {}, action) => {
  const { type, json } = action;
  switch (type) {
    case Type.getActionStatusType(
      Type.CHANGE_PASSWORD,
      Type.STATUS_TYPE_SUCCESS
    ):
      //return { ...state, json };
      return json.data["message"];
    case Type.CHANGE_PASSWORD_RESET:
      return "";

    default:
      return {};
  }
};

const orderShippingDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.ORDER_SHIPPING_DETAIL,
      Type.STATUS_TYPE_SUCCESS
    ):
      return {
        shippingList: action.json.data.sales_order_shippings, /////for handling no shipping case
      };
    case Type.ORDER_SHIPPING_DETAIL_RESET:
      return {};
    default:
      return state;
  }
};

const addUpdateShippingReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.SAVE_SHIPPING_DETAIL,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data.message;

    case Type.getActionStatusType(
      Type.UPDATE_SHIPPING_DETAIL,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data.message;

    case Type.SHIPPING_DETAIL_RESET:
      return "";
    default:
      return state;
  }
};

const orderItemOptionDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.ORDER_ITEM_OPTION_DETAIL,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data["item_option_details"];
    case Type.ORDER_ITEM_OPTION_DETAIL_RESET:
      return "";
    default:
      return state;
  }
};

const updateOrderEmailsReducer = (state = "", action) => {
  const { type, json } = action;
  switch (type) {
    case Type.getActionStatusType(
      Type.UPDATE_ORDER_EMAILS,
      Type.STATUS_TYPE_SUCCESS
    ):
      return json.data.message;

    case Type.EMAIL_UPDATE_MESSAGE_RESET:
      return "";

    default:
      return state;
  }
};

const userProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case Type.getActionStatusType(Type.USER_PROFILE, Type.STATUS_TYPE_SUCCESS):
      return action.json.data.customers[0];
    case Type.USER_DETAIL_RESET:
      return {};
    default:
      return state;
  }
};

const updateUserProfileReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.UPDATE_USER_PROFILE,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data.message;

    case Type.USER_UPDATE_MESSAGE_RESET:
      return "";
    default:
      return state;
  }
};

const checkInventoryReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.CHECK_INVENTORY,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json["inventory_items"];
    case Type.CHECK_INVENTORY_RESET:
      return [];
    default:
      return state;
  }
};

const itemPackageProfileReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.ITEM_PACKAGE_PROFILE,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.packages;
    case Type.ITEM_PACKAGE_PROFILE_RESET:
      return [];
    default:
      return state;
  }
};

const endiciaPackageTypeReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.ENDICIA_PACKAGE_TYPE,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.endicia_package_types;
    case Type.ENDICIA_PACKAGE_TYPE_RESET:
      return [];
    default:
      return state;
  }
};

const shippingEstimateReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.SHIPPING_RATES,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.shipping_methods;

    case Type.SHIPPING_ESTIMATE_RESET:
      return [];
    default:
      return state;
  }
};
const jobTitleReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.FETCH_JOB_TITLE,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.job_titles.map((object) => object.value);

    default:
      return state;
  }
};

const countryReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.FETCH_COUNTRIES,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data.countries;
    default:
      return state;
  }
};

const stateReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(Type.FETCH_STATES, Type.STATUS_TYPE_SUCCESS):
      return action.json.data.states;
    case Type.STATE_LIST_RESET:
      return [];
    default:
      return state;
  }
};

const showInvoicePreviewReducer = (state = {}, action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.INVOICE_PREVIEW,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data;
    case Type.INVOICE_PREVIEW_PATH_RESET:
      return {};
    default:
      return state;
  }
};

const createTicketReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(Type.CREATE_TICKET, Type.STATUS_TYPE_SUCCESS):
      return action.json.message;
    case Type.CREATE_TICKET_RESET:
      return "";
    default:
      return state;
  }
};

const fetchAllTicketsReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.FETCH_ALL_TICKETS,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data;
    default:
      return state;
  }
};

const ticketTypesReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.GET_TICKET_TYPES,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data.types;
    default:
      return state;
  }
};

const eproofEmailMessageReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.SEND_EPROOF_EMAIL,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.message;

    case Type.SEND_EPROOF_EMAIL_RESET:
      return "";
    default:
      return state;
  }
};

const sendShipEstimateEmailReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.SEND_SHIP_ESTIMATE_EMAIL,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.message;
    case Type.SEND_SHIP_ESTIMATE_EMAIL_RESET:
      return "";
    default:
      return state;
  }
};

const reSendOrderAcknowledgment = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.RE_SEND_ORDER_ACKNOWLEDGMENT,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.info;

    case Type.RE_SEND_ORDER_ACKNOWLEDGMENT_RESET:
      return "";
    default:
      return state;
  }
};
const acceptRejectEproofMessageReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.ACCEPT_REJECT_EPROOF,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.message;

    case Type.getActionStatusType(
      Type.REJECT_EPROOF_WITH_ART,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.message;

    case Type.ACCEPT_REJECT_EPROOF_RESET:
      return "";

    default:
      return state;
  }
};

const downloadStatementReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.DOWNLOAD_STATEMENT,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.path;
    case Type.DOWNLOAD_STATEMENT_RESET:
      return "";
    default:
      return state;
  }
};

const orderAcknowledgementFileNameReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.GET_ORDER_ACKNOWLEDGEMENT_FILE_NAME,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.acknowledgement_file_path;
    case Type.GET_ORDER_ACKNOWLEDGEMENT_FILE_NAME_RESET:
      return "";
    default:
      return state;
  }
};

const downloadTrackingInfoReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.DOWNLOAD_TRACKING_INFO,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.path;
    case Type.DOWNLOAD_TRACKING_INFO_RESET:
      return "";
    default:
      return state;
  }
};

const uploadMissingArtworkReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.UPLOAD_MISSING_ART,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.info;
    case Type.UPLOAD_MISSING_ART_RESET:
      return "";
    default:
      return state;
  }
};

const getInhandDateReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.GET_INHAND_DATE,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data;
    case Type.GET_INHAND_DATE_RESET:
      return "";
    default:
      return state;
  }
};

const getShpippingMethodsReducer = (state = {}, action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.GET_SHIPPING_METHODS,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data;
    case Type.GET_SHIPPING_METHODS_RESET:
      return {};
    default:
      return state;
  }
};

const validateAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.VALIDATE_ADDRESS,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data;
    case Type.VALIDATE_ADDRESS_RESET:
      return {};
    default:
      return state;
  }
};

const stateByZipReducer = (state = {}, action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.FETCH_STATE_BY_ZIP,
      Type.STATUS_TYPE_SUCCESS
    ):
      if (isObjectNotEmpty(action.json.postal_info)) {
        return action.json.postal_info;
      } else {
        return {};
      }

    case Type.FETCH_STATE_BY_ZIP_RESET:
      return {};
    default:
      return state;
  }
};

const getCutomerProfileReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.GET_CUSTOMER_PAYMENT_PROFILE,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.profile.paymentProfiles
        ? action.json.profile.paymentProfiles
        : [];
    case Type.GET_CUSTOMER_PAYMENT_PROFILE_RESET:
      return [];
    default:
      return state;
  }
};

const customerPaymentProfileCredentialsReducer = (state = {}, action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.GET_CUSTOMER_PROFILE_CREDENTIALS,
      Type.STATUS_TYPE_SUCCESS
    ):
      const merchantCredentials = action.json.credentials;
      const customerProfile = {
        customerProfileId: action.json.customer_profile.customer_profile_id,
      };
      return {
        merchantAuthentication: {
          name: merchantCredentials.api_login_id,
          transactionKey: merchantCredentials.api_transaction_key,
        },
        ...action.json.customer_profile,
        ...customerProfile,
      };
    case Type.GET_CUSTOMER_PROFILE_CREDENTIALS_RESET:
      return {};
    default:
      return state;
  }
};

const saveOrAuthorizePaymentReducer = (state = {}, action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.SAVE_CAPTURED_TRANSACTION,
      Type.STATUS_TYPE_SUCCESS
    ):
      return { ...action.json };

    case Type.SAVE_CAPTURED_TRANSACTION_RESET:
      return {};
    default:
      return state;
  }
};

const customerPaymentReceiptReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.CUSTOMER_PAYMENT_RECEIPT,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data.path;

    case Type.CUSTOMER_PAYMENT_RECEIPT_RESET:
      return "";
    default:
      return state;
  }
};

const updatedeletedPaymentProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.UPDATE_DELETED_PAYMENT_PROFILE,
      Type.STATUS_TYPE_SUCCESS
    ):
      return { ...action.json };

    case Type.UPDATE_DELETED_PAYMENT_PROFILE_RESET:
      return {};
    default:
      return state;
  }
};

const textNotificationDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.GET_TEXT_NOTIFICATION_DETAIL,
      Type.STATUS_TYPE_SUCCESS
    ):
      return { ...action.json.data.twilio_integrations };

    case Type.TEXT_NOTIFICATION_DETAIL_RESET:
      return {};
    default:
      return state;
  }
};

const textNotificationDetailSuccessMessageReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.ADD_EDIT_TEXT_NOTIFICATION_DETAIL,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data.message;

    case Type.ADD_EDIT_TEXT_NOTIFICATION_DETAIL_RESET:
      return "";
    default:
      return state;
  }
};

const showLoginConnectPopupReducer = (state = false, action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.SHOW_LOGIN_CONNECT_POPUP,
      Type.STATUS_TYPE_SUCCESS
    ):
      return true;

    case Type.SHOW_LOGIN_CONNECT_POPUP_RESET:
      return false;
    default:
      return state;
  }
};
const supervisorListReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.SUPERVISOR_LIST,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data.users;

    case Type.SUPERVISOR_LIST_RESET:
      return [];
    default:
      return state;
  }
};
const updateSupervisorStatusReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.UPDATE_SUPERVISOR_STATUS,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data.message;

    case Type.UPDATE_SUPERVISOR_STATUS_RESET:
      return "";
    default:
      return state;
  }
};
const supervisorMemberListReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.SUPERVISOR_MEMBER_LIST,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data.user_members;

    case Type.SUPERVISOR_MEMBER_LIST_RESET:
      return [];
    default:
      return state;
  }
};
const supervisorPopupListReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.SUPERVISOR_POPUP_LIST,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data.users;

    case Type.SUPERVISOR_POPUP_LIST_RESET:
      return [];
    default:
      return state;
  }
};
const addSupervisorMemberReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.ADD_SUPERVISOR_MEMBER,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data.message;

    case Type.ADD_SUPERVISOR_MEMBER_RESET:
      return "";
    default:
      return state;
  }
};
const deleteSupervisorUserReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.DELETE_SUPERVISOR_USER,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data.message;

    case Type.DELETE_SUPERVISOR_USER_RESET:
      return "";
    default:
      return state;
  }
};

const allUsersListReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(Type.GET_ALL_USERS, Type.STATUS_TYPE_SUCCESS):
      return action.json.data.users;

    case Type.ALL_USER_LIST_RESET:
      return [];
    default:
      return state;
  }
};

const createUserSuccessMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case Type.getActionStatusType(Type.CREATE_USER, Type.STATUS_TYPE_SUCCESS):
      return action.json.data;

    case Type.CREATE_USER_RESET:
      return {};
    default:
      return state;
  }
};

const addRemoveSupervisorUsersReducer = (state = [], action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.ADD_REMOVE_SUPERVISOR_USERS,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data.users;

    case Type.ADD_REMOVE_SUPERVISOR_USERS_RESET:
      return [];
    default:
      return state;
  }
};

const createResetPasswordMessageReducer = (state = "", action) => {
  switch (action.type) {
    case Type.getActionStatusType(
      Type.CREATE_RESET_PASSWORD,
      Type.STATUS_TYPE_SUCCESS
    ):
      return action.json.data.message;

    case Type.CREATE_RESET_PASSWORD_RESET:
      return "";
    default:
      return state;
  }
};

const openUploadResaleCertificateByDefaultReducer = (state = "", action) => {
  switch (action.type) {
    case Type.OPEN_UPDLOAD_CERTIFICATE_BY_DEFAULT:
      return true;

    case Type.getResetAction(Type.OPEN_UPDLOAD_CERTIFICATE_BY_DEFAULT):
      return false;
    default:
      return state;
  }
};

// Combine all reducers.
const appReducer = combineReducers({
  globalSearchResult: globalSearchResultReducer,
  acceptRejectPreProductionMessage: acceptRejectPreProductionMessageReducer,
  estimateDetails: estimateDetailsReducer,
  acceptRejectStitchesMessage: acceptRejectStitchesMessageAReducer,
  ticketConversations: ticketConversationsReducer,
  estimates: estimateOrdersReducer,
  orders: ordersReducer,
  loginDetails: loginReducer,
  signUpDetails: signUpReducer,
  forgotPassword: forgotPasswordReducer,
  logOutMessage: logOutReducer,
  historyOrders: historyOrdersReducer,
  dashboard: dashboardReducer,
  loadingApis: loadingReducer,
  errorMessages: errorReducer,
  missingArtOrders: missingArtOrdersReducer,
  eProofOrders: eProofOrdersReducer,
  eProofOrderDetails: eProofOrderDetailsReducer,
  payBills: payBillsReducer,
  orderDetail: orderDetailReducer,
  subOrderDetail: subOrderDetailReducer,
  changePassword: changePasswordReducer,
  stitchApprovals: stitchApprovalsReducer,
  updateOrderEmails: updateOrderEmailsReducer,
  orderShippingDetail: orderShippingDetailReducer,
  addUpdateShipping: addUpdateShippingReducer,
  orderItemOptionDetail: orderItemOptionDetailReducer,
  contactList: contactListReducer,
  contactDetail: contactDetailReducer,
  preProductionApprovals: preProductionApprovalsReducer,
  userProfile: userProfileReducer,
  updateUserProfile: updateUserProfileReducer,
  checkInventory: checkInventoryReducer,
  itemPackageProfile: itemPackageProfileReducer,
  shippingEstimate: shippingEstimateReducer,
  activateUser: activateUserReducer,
  countries: countryReducer,
  states: stateReducer,
  showInvoicePreview: showInvoicePreviewReducer,
  endiciaPackageType: endiciaPackageTypeReducer,
  createTicketDetails: createTicketReducer,
  allTickets: fetchAllTicketsReducer,
  eproofEmailMessage: eproofEmailMessageReducer,
  sendShipEstimateEmailMessage: sendShipEstimateEmailReducer,
  acceptRejectEproofMessage: acceptRejectEproofMessageReducer,
  uploadMissingArtworkMessage: uploadMissingArtworkReducer,
  downloadStatementMessage: downloadStatementReducer,
  inhandDate: getInhandDateReducer,
  shippingMethods: getShpippingMethodsReducer,
  validateAddress: validateAddressReducer,
  customerPaymentProfiles: getCutomerProfileReducer,
  customerPaymentProfileCredentials: customerPaymentProfileCredentialsReducer,
  paymentOrAuthorisationResponse: saveOrAuthorizePaymentReducer,
  customerPaymentReceipt: customerPaymentReceiptReducer,
  updatedeletedPaymentProfile: updatedeletedPaymentProfileReducer,
  inventoryItemSearchResult: inventoryItemSearchResultReducer,
  stateByZip: stateByZipReducer,
  jobTitles: jobTitleReducer,
  reSendOrderAcknowledgmentMessage: reSendOrderAcknowledgment,
  textNotificationDetails: textNotificationDetailReducer,
  textNotificationDetailSuccessMessage: textNotificationDetailSuccessMessageReducer,
  showLoginConnectPopup: showLoginConnectPopupReducer,
  connectSocialAccount: connectSocialAccountReducer,
  downloadTrackingInfo: downloadTrackingInfoReducer,
  loginBySaltResponse: loginBySaltResponseReducer,
  ticketTypes: ticketTypesReducer,
  supervisorList: supervisorListReducer,
  updateSupervisorStatus: updateSupervisorStatusReducer,
  supervisorMemberList: supervisorMemberListReducer,
  supervisorPopupList: supervisorPopupListReducer,
  addSupervisorMember: addSupervisorMemberReducer,
  deleteSupervisorUser: deleteSupervisorUserReducer,
  allUsersList: allUsersListReducer,
  createUserSuccessMessage: createUserSuccessMessageReducer,
  addRemoveSupervisorUsers: addRemoveSupervisorUsersReducer,
  createResetPasswordMessage: createResetPasswordMessageReducer,
  orderAcknowledgementFileName: orderAcknowledgementFileNameReducer,
  openUploadResaleCertificateByDefault: openUploadResaleCertificateByDefaultReducer,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.

  if (action.type === Type.RESET_ALL_STATES) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
