import React, { useState, useEffect } from "react";
import { SvgIcon } from "../../../components/common";
import { Button, Form, Input, Spin, Select } from "antd";
//Style
import "./support-desk.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  CREATE_TICKET,
  FETCH_ALL_TICKETS,
  CREATE_TICKET_RESET,
  TICKET_CONVERSATIONS_RESET,
  TICKET_CONVERSATIONS,
  GET_TICKET_TYPES,
} from "../../../redux/actions/type";
import {
  createLoadingSelector,
  createTicket,
  fetchAllTickets,
  resetStoreState,
  fetchTicketConversations,
  getTicketTypes,
} from "../../../redux/actions";
import {
  isObjectEmpty,
  getDateSeperatedByT,
} from "../../../common/common-methods";
import { showAlert } from "../../../common/alert-messages";

const { TextArea } = Input;
const { Option } = Select;

const ticketStatus = { 2: "OPEN", 3: "PENDING", 4: "RESOLVED", 5: "CLOSED" };

const SupportDesk = (props) => {
  const loadingSelector = createLoadingSelector([
    CREATE_TICKET,
    FETCH_ALL_TICKETS,
    TICKET_CONVERSATIONS,
    GET_TICKET_TYPES,
  ]);

  const initialQuery = {
    status: 2,
    type: "",
    priority: 1,
    description: "",
    subject: "",
    customers_po: props.customersPO ? props.customersPO : "",
  };

  const initialFormValues = {
    visible: false,
    previousQuery: false,
    formVisible: false,
    formVisible1: false,
    viewQuery: false,
    previousHaveQuestion: false,
    query: { ...initialQuery },
    selectedFilter: 1,
    onHaveQuestion: false,
  };

  const ticketTypes = useSelector((state) => state.ticketTypes);
  const ticketConversations = useSelector((state) => state.ticketConversations);
  const allTickets = useSelector((state) => state.allTickets);
  const ticketData = useSelector((state) => state.createTicketDetails);
  const isFetchingData = useSelector((state) => loadingSelector(state));

  const dispatch = useDispatch();
  const [state, setState] = useState({ ...initialFormValues });

  useEffect(() => {});

  useEffect(() => {
    if (!isObjectEmpty(ticketData)) {
      showAlert.success("Ticket generated successfully.");
      dispatch(resetStoreState(CREATE_TICKET_RESET));
      setState({
        ...initialFormValues,
        visible: true,
      });
    }
  }, [ticketData, dispatch, initialFormValues]);

  const bubbleToggle = () => {
    setState({
      ...initialFormValues,
      visible: !state.visible,
    });
  };

  const onNewQuestionClick = (e) => {
    dispatch(getTicketTypes());
    setState({
      ...state,
      formVisible: true,
    });
  };
  const onOldQuestionClick = (e) => {
    dispatch(fetchAllTickets());
    setState({
      ...state,
      previousQuery: true,
    });
  };
  const onHaveQuestionClick = (e) => {
    setState({
      ...state,
      onHaveQuestion: true,
      previousQuery: false,
    });
  };
  const onGoBack = () => {
    setState({
      ...state,

      formVisible: false,
      previousQuery: false,
      onHaveQuestion: false,
    });
  };

  const onFormSubmit = (values) => {
    dispatch(createTicket(state.query));
  };

  const onViewQueryClick = (ticket) => {
    setState({
      ...state,
      viewQuery: true,
      selectedTicket: ticket,
    });
    dispatch(fetchTicketConversations(ticket.id));
  };

  const onGoBackFromQuery = () => {
    setState({
      ...state,

      viewQuery: false,
    });
    resetStoreState(TICKET_CONVERSATIONS_RESET);
  };

  const onChange = (event) => {
    let query = state.query;
    let name = event.target.getAttribute("name"); //field name
    let value = event.target.value; //field value

    query[name] = value;
    setState({
      ...state,

      query,
    });
  };

  const onChangeTicketType = (value) => {
    let query = state.query;
    query["type"] = value;
    setState({
      ...state,

      query,
    });
  };

  const filterClicked = (type) => {
    setState({
      ...state,
      selectedFilter: type,
    });
  };
  return (
    <React.Fragment>
      <Button className="support-btn" onClick={bubbleToggle} shape="circle">
        <SvgIcon name="support" viewbox="0 0 34.909 34.909" /> Resolution Center
      </Button>
      {state.visible && (
        <React.Fragment>
          <div className="help-desk-drop"></div>
          <div className="help-desk-wrapper">
            {isFetchingData && (
              <Spin
                spinning={isFetchingData}
                size="large"
                className="support-spinner"
              ></Spin>
            )}
            <div className="help-desk-head">
              <span>Welcome to Tekweld Support</span>
              <span className="cursor-pointer" onClick={bubbleToggle}>
                <SvgIcon
                  name="close"
                  fill="#fff"
                  width="13px"
                  viewbox="0 0 298.667 298.667"
                />
              </span>
            </div>
            <div className="help-desk-body">
              {!state.previousQuery && !state.onHaveQuestion && (
                <React.Fragment>
                  {!state.formVisible && !state.onHaveQuestion ? (
                    <div className="selector">
                      <div className="d-flex w-100 fs-16">
                        How can we help you today?
                      </div>
                      <div
                        className="d-flex flex-row align-items-center w-100 cursor-pointer mt-3 selector-block"
                        onClick={onHaveQuestionClick}
                      >
                        <SvgIcon
                          name="question-icon"
                          width="32px"
                          viewbox="0 0 106 106"
                        />
                        <span className="ml-3">
                          <b>I have questions about an order…</b>
                          <br />
                          <span className="fs-12">
                            Use this to reach our Customer Service Team
                          </span>
                        </span>
                      </div>
                      <div
                        className="d-flex flex-row align-items-center w-100 cursor-pointer mt-3 selector-block"
                        onClick={onNewQuestionClick}
                      >
                        <SvgIcon
                          name="view-path"
                          width="32px"
                          viewbox="0 0 35.6 35.6"
                        />
                        <span className="ml-3">
                          <b>I have issues with an order...</b>
                          <br />
                          <span className="fs-12">
                            Use this to reach our support desk
                          </span>
                        </span>
                      </div>
                      <div
                        className="d-flex flex-row align-items-center w-100 cursor-pointer mt-3 selector-block"
                        onClick={onOldQuestionClick}
                      >
                        <SvgIcon
                          name="ticket"
                          width="32px"
                          viewbox="0 0 36.8 36.8"
                        />
                        <span className="ml-3">
                          <b>I reached out previously…</b>
                          <br />
                          <span className="fs-12">
                            Use this to check status of open tickets
                          </span>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="form-wrp">
                      <div className="d-flex w-100 align-items-center mb-3">
                        <span
                          className="text-primary d-flex cursor-pointer align-items-center fs-12 back"
                          onClick={onGoBack}
                        >
                          <SvgIcon
                            name="caretLeft"
                            fill="#0f7bc4"
                            width="11px"
                            className="mr-1"
                            viewbox="0 0 451.846 451.847"
                          />{" "}
                          <span>Back</span>
                        </span>
                        <span className="que">
                          I have issues with an order...
                        </span>
                      </div>
                      <Form
                        name="support"
                        layout="vertical"
                        className="query-form"
                        onFinish={onFormSubmit}
                        initialValues={state.query}
                      >
                        <div className="d-flex flex-column w-100">
                          <label htmlFor="order" className="w-100 fs-12">
                            Customer PO #
                          </label>
                          <Form.Item
                            id="customers_po"
                            name="customers_po"
                            className="mb-2"
                            rules={[
                              {
                                required: true,
                                message: "Please enter Customer PO!",
                              },
                            ]}
                          >
                            <Input
                              name="customers_po"
                              placeholder=""
                              value={state.query.customers_po}
                              onChange={onChange}
                            />
                          </Form.Item>
                        </div>
                        <div className="d-flex flex-column w-100">
                          <label htmlFor="issueType" className="w-100 fs-12">
                            Issue Type
                          </label>
                          <Form.Item
                            id="type"
                            name="type"
                            className="mb-2"
                            rules={[
                              {
                                required: true,
                                message: "Please select issue types!",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              allowClear
                              onChange={onChangeTicketType}
                            >
                              {ticketTypes.map((ticketType) => (
                                <Option
                                  key={ticketType.id}
                                  value={ticketType.value}
                                >
                                  {ticketType.value}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="d-flex flex-column w-100">
                          <label htmlFor="subject" className="w-100 fs-12">
                            Subject
                          </label>
                          <Form.Item
                            id="subject"
                            name="subject"
                            className="mb-2"
                            rules={[
                              {
                                required: true,
                                message: "Please enter subject!",
                              },
                            ]}
                          >
                            <Input
                              name="subject"
                              value={state.query.subject}
                              placeholder=""
                              onChange={onChange}
                            />
                          </Form.Item>
                        </div>
                        <div className="d-flex flex-column w-100">
                          <label htmlFor="query" className="w-100 fs-12">
                            Issue
                          </label>
                          <Form.Item
                            id="description"
                            name="description"
                            className="mb-3"
                            rules={[
                              {
                                required: true,
                                message: "Please enter issue!",
                              },
                            ]}
                          >
                            <TextArea
                              name="description"
                              value={state.query.description}
                              placeholder="Please type in your issue here"
                              rows={3}
                              onChange={onChange}
                            />
                          </Form.Item>
                        </div>
                        <Form.Item className="mb-0">
                          <Button type="primary" htmlType="submit" block>
                            Submit Issue
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  )}
                </React.Fragment>
              )}

              {state.onHaveQuestion && (
                <React.Fragment>
                  <div className="previous-query-wrap">
                    <div className="d-flex w-100 align-items-center mb-3">
                      <span
                        className="text-primary d-flex cursor-pointer align-items-center fs-12 back"
                        onClick={onGoBack}
                      >
                        <SvgIcon
                          name="caretLeft"
                          fill="#0f7bc4"
                          width="11px"
                          className="mr-1"
                          viewbox="0 0 451.846 451.847"
                        />{" "}
                        <span>Back</span>
                      </span>
                      <span className="que">
                        <b>I have questions about an order...</b>
                      </span>
                    </div>
                    <span className="w-100">
                      <span>
                        Please reach out to our Customer Service team.
                      </span>
                      <br />
                      <br />
                      <a
                        className="text-underline"
                        href="mailto:CSR@tekweld.com"
                      >
                        CSR@tekweld.com
                      </a>
                    </span>
                  </div>
                </React.Fragment>
              )}

              {state.previousQuery && (
                <React.Fragment>
                  {!state.viewQuery ? (
                    <div className="previous-query-wrap">
                      <div className="d-flex w-100 align-items-center mb-3">
                        <span
                          className="text-primary d-flex cursor-pointer align-items-center fs-12 back"
                          onClick={onGoBack}
                        >
                          <SvgIcon
                            name="caretLeft"
                            fill="#0f7bc4"
                            width="11px"
                            className="mr-1"
                            viewbox="0 0 451.846 451.847"
                          />{" "}
                          <span>Back</span>
                        </span>
                        <span className="que">I reached out previously…</span>
                      </div>
                      <div className="query-filter">
                        <ul>
                          <li
                            key={1}
                            onClick={() => {
                              filterClicked(1);
                            }}
                            className={
                              state.selectedFilter === 1 ? "active" : ""
                            }
                          >
                            All
                          </li>
                          <li
                            key={2}
                            onClick={() => {
                              filterClicked(2);
                            }}
                            className={`${
                              state.selectedFilter === 2 ? "active" : ""
                            }
                                    `}
                          >
                            Opened
                          </li>
                          <li
                            key={3}
                            onClick={() => {
                              filterClicked(3);
                            }}
                            className={
                              state.selectedFilter === 3 ? "active" : ""
                            }
                          >
                            Pending
                          </li>
                          <li
                            key={4}
                            onClick={() => {
                              filterClicked(4);
                            }}
                            className={
                              state.selectedFilter === 4 ? "active" : ""
                            }
                          >
                            Resolved
                          </li>
                          <li
                            key={5}
                            onClick={() => {
                              filterClicked(5);
                            }}
                            className={
                              state.selectedFilter === 5 ? "active" : ""
                            }
                          >
                            Closed
                          </li>
                        </ul>
                      </div>
                      <div className="query-list slim-scrollbar">
                        <ul>
                          {allTickets
                            .filter(
                              (ticket) =>
                                ticket.status === state.selectedFilter ||
                                state.selectedFilter === 1
                            )
                            .map((ticket, i) => {
                              return (
                                <li
                                  key={i}
                                  onClick={() => onViewQueryClick(ticket)}
                                >
                                  <span className="subject">
                                    {ticket.subject}
                                  </span>
                                  <span className="query-details">
                                    <span className="fs-13 mr-2">
                                      Order # {ticket.custom_fields.cf_order_id}
                                    </span>
                                    <span className="fs-12 ml-auto mr-1">
                                      {getDateSeperatedByT(ticket.created_at)}
                                    </span>
                                    <span
                                      className={
                                        "query-status-type " +
                                        ticketStatus[
                                          ticket.status
                                        ].toLowerCase()
                                      }
                                    >
                                      {ticketStatus[ticket.status]}
                                    </span>
                                  </span>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <React.Fragment>
                      <div className="d-flex w-100 align-items-center mb-3">
                        <span
                          className="text-primary d-flex cursor-pointer align-items-center fs-12 back"
                          onClick={onGoBackFromQuery}
                        >
                          <SvgIcon
                            name="caretLeft"
                            fill="#0f7bc4"
                            width="11px"
                            className="mr-1"
                            viewbox="0 0 451.846 451.847"
                          />{" "}
                          <span>Back</span>
                        </span>
                      </div>
                      <div className="query-main">
                        <div className="subject fs-16 d-flex w-100">
                          {state.selectedTicket.subject}
                        </div>
                        <div className="query-details">
                          <div className="fs-13 mr-2">
                            Order #{" "}
                            {state.selectedTicket.custom_fields.cf_order_id}
                          </div>
                          <div className="fs-12 ml-auto mr-1">
                            {getDateSeperatedByT(
                              state.selectedTicket.created_at
                            )}
                          </div>
                          <div
                            className={
                              "query-status-type " +
                              ticketStatus[
                                state.selectedTicket.status
                              ].toLowerCase()
                            }
                          >
                            {ticketStatus[state.selectedTicket.status]}
                          </div>
                        </div>
                        <div className="question">
                          {state.selectedTicket.description_text}
                        </div>

                        {ticketConversations.map((ticket, index) => (
                          <div
                            key={index}
                            className={
                              ticket.incoming ? "solution" : "question"
                            }
                          >
                            {ticket.body_text}
                          </div>
                        ))}
                      </div>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default SupportDesk;
