import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import { Row, Col } from "../../../../components/common/grid";
import { ImageLoader, SvgIcon } from "../../../../components/common";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import EProofDetailsComponent from "../e-proof-detail/eProof-details";

import {
  setScrollIn
} from "../../../../common/common-methods";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchEproofOrders,
  createLoadingSelector,
} from "../../../../redux/actions";

import { E_PROOF_ORDERS } from "../../../../redux/actions/type";
import { baseServerAdress } from "../../../../constants/server-address-constants";

// Style
import "./e-proof-list.scss";
import { Spin } from "antd";
import {
  paymentStatusConstants,
  proofStatusConstants,
} from "../../../../constants/order-status-constants";

const { Column } = Table;

const EproofBlock = ({
  orderId,
  img,
  artworkStatus,
  orderNumber,
  paymentStatus,
  customerPO,
  orderDate,
  contactPerson,
  logoName,
  detailViewPopupToggle,
}) => {
  return (
    <Col xs="12" md="6" lg="6" xl="4">
      <div className="border-box mb-20">
        <div className="stitch-block">
          <div className="stitch-img border-box">
            {img ? (
              <ImageLoader src={img} alt={"e-proof"} title={"e-proof"} />
            ) : (
              <div className="no-img">
                <SvgIcon name="picture" viewbox="0 0 28 28" />
                <span>No Image Available</span>
              </div>
            )}
          </div>
          <div className="product-info">
            <div className="block-label">Artwork Status</div>
            <div
              className={
                "block-value " +
                (artworkStatus === "Complete" ? "text-green" : "text-orange")
              }
              style={{ textTransform: "capitalize" }}
            >
              {artworkStatus.toLowerCase()}
            </div>
            <div className="block-label">Payment Status</div>
            <div
              className={
                "block-value " +
                (paymentStatus === "Credit Approved"
                  ? "text-green"
                  : paymentStatus === "Pending"
                    ? "text-orange"
                    : "")
              }
              style={{ textTransform: "capitalize" }}
            >
              {paymentStatus.toLowerCase()}
            </div>
          </div>
          <div className="block-controls">
            <Button
              type="primary"
              className="view-eproof-btn"
              onClick={() => detailViewPopupToggle(orderId)}
            >
              View
            </Button>
          </div>
        </div>
        <div className="stitch-block-info">
          <Row>
            <Col xs="6">
              <div className="block-label">Order #</div>
              <div className="block-value">{orderNumber}</div>
            </Col>
            <Col xs="6">
              <div className="block-label">Customer PO #</div>
              <div className="block-value">{customerPO}</div>
            </Col>
            <Col xs="6">
              <div className="block-label">Order Date</div>
              <div className="block-value">{orderDate}</div>
            </Col>
            <Col xs="6">
              <div className="block-label">Logo #</div>
              <div className="block-value">{logoName}</div>
            </Col>
            <Col xs="12">
              <div className="block-label">Contact</div>
              <div className="block-value">{contactPerson}</div>
            </Col>
          </Row>
        </div>
      </div>
    </Col>
  );
};

const EProofListComponent = () => {
  const loadingSelector = createLoadingSelector([E_PROOF_ORDERS]);

  const orders = useSelector((state) => state.eProofOrders);
  const isFetchingData = useSelector((state) => loadingSelector(state));
  const dispatch = useDispatch();

  useEffect(() => {
    setScrollIn();
    dispatch(fetchEproofOrders(0));
  }, [dispatch]);


  const [state, setState] = useState({
    gridView: true,
    popupVisible: false,
    selectedOrderId: "",
  });

  const viewToggleTrue = () => {
    setState({
      ...state,
      gridView: true,
    });
  };
  const viewToggleFalse = () => {
    setState({
      ...state,
      gridView: false,
    });
  };

  const eproofdetailViewPopupToggle = (orderId) => {
    setState({
      ...state,
      popupVisible: !state.popupVisible,
      selectedOrderId: orderId ? orderId : "",
    });
  };

  return (
    <Spin spinning={isFetchingData} size="large">
      {state.popupVisible && (
        <EProofDetailsComponent
          closePopup={() => { setState({ ...state, popupVisible: false, selectedOrderId: "" }) }}
          orderId={state.selectedOrderId}
        />
      )}
      <div className="common-container">
        <Row className="pt-3">
          <Col xs="12">
            <div className="page-view-panel">
              <MediaQuery minDeviceWidth={992}>
                <span className="page-name">e-Proof</span>
              </MediaQuery>
              <MediaQuery maxDeviceWidth={991}>
                <Link to="/dashboard" className="common-back-btn">
                  <SvgIcon name="caretLeft" viewbox="0 0 451.846 451.847" />
                  <span>e-Proof</span>
                </Link>
              </MediaQuery>
              <div className="view-switch">
                <button
                  type="button"
                  className={state.gridView ? "active" : ""}
                  onClick={viewToggleTrue}
                >
                  <SvgIcon name="grid-view" viewbox="0 0 12 12" />
                </button>
                <button
                  type="button"
                  className={!state.gridView ? "active" : ""}
                  onClick={viewToggleFalse}
                >
                  <SvgIcon name="list-view" width="20px" viewbox="0 0 15 12" />
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="pt-3 pt-lg-4 gutter-sm">
          {state.gridView ? (
            <React.Fragment>
              {orders.map((eproof, i) => {
                return (
                  <EproofBlock
                    key={i}
                    orderId={eproof.id}
                    img={baseServerAdress.IMAGE + eproof.image_url}
                    artworkStatus={eproof.artwork_status}
                    paymentStatus={eproof.payment_status}
                    orderNumber={eproof.trans_no}
                    customerPO={eproof.ext_ref_no}
                    orderDate={eproof.trans_date}
                    contactPerson={eproof.customer_contact}
                    logoName={eproof.logo_name}
                    detailViewPopupToggle={eproofdetailViewPopupToggle}
                  />
                );
              })}
            </React.Fragment>
          ) : (
            <Col xs="12">
              <div className="table-wrapper">
                <Table
                  tableLayout="fixed"
                  className="common-table"
                  loading={isFetchingData}
                  dataSource={orders}
                  pagination={false}
                  scroll={{ x: 768, y: "calc(100vh - 285px)" }}
                >
                  <Column
                    title="Order #"
                    dataIndex="trans_no"
                    key="trans_no"
                    render={(trans_no) => <b>{trans_no}</b>}
                    width="80px"
                  />
                  <Column
                    title="Order Date"
                    dataIndex="trans_date"
                    key="trans_date"
                    width="100px"
                  />
                  <Column
                    title="Customer PO #"
                    dataIndex="ext_ref_no"
                    key="ext_ref_no"
                    width="125px"
                  />
                  <Column
                    title="Logo #"
                    dataIndex="logo_name"
                    key="logo_name"
                    width="125px"
                  />
                  <Column
                    title="Contact"
                    dataIndex="customer_contact"
                    key="customer_contact"
                    width="100px"
                  />
                  <Column
                    title="Artwork Status"
                    dataIndex="artwork_status"
                    key="artwork_status"
                    width="150px"
                    render={(artwork_status) => {
                      return (
                        <span
                          className={
                            artwork_status.toUpperCase() ===
                              proofStatusConstants.COMPLETE
                              ? "text-green"
                              : "text-orange"
                          }
                        >
                          {artwork_status}
                        </span>
                      );
                    }}
                  />
                  <Column
                    title="Payment Status"
                    dataIndex="payment_status"
                    key="payment_status"
                    width="150px"
                    render={(payment_status) => {
                      return (
                        <span
                          className={
                            payment_status.toUpperCase() ===
                              paymentStatusConstants.CREDIT_APPROVED
                              ? "text-green"
                              : "text-orange"
                          }
                        >
                          {payment_status}
                        </span>
                      );
                    }}
                  />
                  <Column
                    title={false}
                    dataIndex="id"
                    className="text-right"
                    key="id"
                    width="65px"
                    render={(id) => (
                      <Button
                        type="primary"
                        className="view-eproof-btn"
                        onClick={() => eproofdetailViewPopupToggle(id)}
                      >
                        View
                      </Button>
                    )}
                  />
                </Table>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </Spin>
  );
};
export default EProofListComponent;
