import React from "react";
import { Upload, Button, Table, Spin } from "antd";
import MediaQuery from "react-responsive";
import { SvgIcon } from "../../../../components/common";

import { connect } from "react-redux";

import { Link } from "react-router-dom";
import { Row, Col } from "../../../../components/common/grid";

import {
  fetchMissingArtOrders,
  uploadMissingArtwork,
  resetStoreState,
  createLoadingSelector,
} from "../../../../redux/actions";

// Style
import "./missing-art-list.scss";
import {
  MISSING_ART_ORDERS,
  UPLOAD_MISSING_ART,
  UPLOAD_MISSING_ART_RESET,
} from "../../../../redux/actions/type";
import {
  showAlert,
  showFileNameError,
} from "../../../../common/alert-messages";
import {
  isFileNameContainSpecialCharacter,
  isloggedInUserViewOnly,
  isObjectEmpty,
  isStringContainSpecialCharacter,
  setScrollIn,
} from "../../../../common/common-methods";

const { Column } = Table;
// //let salesId;
// const props = {
//   name: 'file',
//   action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
//   headers: {
//     authorization: 'authorization-text',
//   },
//   onChange(info) {
//     if (info.file.status !== 'uploading') {
//       console.log(info.file, info.fileList);
//     }
//     if (info.file.status === 'done') {
//       showAlert.success(`${info.file.name} file uploaded successfully`);
//     } else if (info.file.status === 'error') {
//       showAlert.error(`${info.file.name} file upload failed.`);
//     }
//   },
// };

class MissingArtListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gridView: true,
      salesOrderId: "",
    };
  }
  componentDidMount = () => {
    setScrollIn();
    this.props.fetchMissingArtOrders(1);
  };

  componentDidUpdate() {
    if (!isObjectEmpty(this.props.uploadSuccessMessage)) {
      showAlert.success(this.props.uploadSuccessMessage);
      this.props.resetStoreState(UPLOAD_MISSING_ART_RESET);
      this.props.fetchMissingArtOrders(1);
    }
  }

  viewToggleTrue = () => {
    this.setState({
      gridView: true,
    });
  };
  viewToggleFalse = () => {
    this.setState({
      gridView: false,
    });
  };

  handleUpload = (file, orderId) => {
    if (isFileNameContainSpecialCharacter(file.name)) {
      showFileNameError();
    } else {
      this.props.uploadMissingArtwork(orderId, file);
    }
    return false;
  };

  render() {
    let { gridView } = this.state;

    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        <div className="common-container">
          <Row className="pt-3">
            <Col xs="12">
              <div className="page-view-panel">
                <MediaQuery minDeviceWidth={992}>
                  <span className="page-name">Missing Arts</span>
                </MediaQuery>
                <MediaQuery maxDeviceWidth={991}>
                  <Link to="/dashboard" className="common-back-btn">
                    <SvgIcon name="caretLeft" viewbox="0 0 451.846 451.847" />
                    <span>Missing Arts</span>
                  </Link>
                </MediaQuery>
                <div className="view-switch">
                  <button
                    type="button"
                    className={gridView ? "active" : ""}
                    onClick={this.viewToggleTrue}
                  >
                    <SvgIcon name="grid-view" viewbox="0 0 12 12" />
                  </button>
                  <button
                    type="button"
                    className={!gridView ? "active" : ""}
                    onClick={this.viewToggleFalse}
                  >
                    <SvgIcon
                      name="list-view"
                      width="20px"
                      viewbox="0 0 15 12"
                    />
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="pt-3 pt-lg-4 gutter-sm">
            {gridView ? (
              <React.Fragment>
                {this.props.orders.map((order, i) => {
                  return (
                    <Col xs="12" sm="6" md="4" lg="4" xl="3">
                      <div className="border-box p-3 mb-20">
                        <Row>
                          <Col xs="6">
                            <div className="block-label">Order #</div>
                            <div className="block-value">{order.trans_no}</div>
                          </Col>
                          <Col xs="6">
                            <div className="block-label">Order Date</div>
                            <div className="block-value">
                              {order.trans_date.split("T")[0]}
                            </div>
                          </Col>
                          <Col xs="6">
                            <div className="block-label">Customer PO #</div>
                            <div className="block-value">
                              {order.ext_ref_no}
                            </div>
                          </Col>
                          <Col xs="6">
                            <div className="block-label">Item #</div>
                            <div className="block-value">
                              {order.catalog_item_code}
                            </div>
                          </Col>
                          <Col xs="12">
                            <Upload
                              beforeUpload={(file) => {
                                this.handleUpload(file, order.id);
                                return false;
                              }}
                              progress={{ strokeWidth: 0, showInfo: false }}
                              showUploadList={false}
                              maxCount={1}
                            >
                              <Button
                                id={order.id}
                                type="primary"
                                block
                                disabled={isloggedInUserViewOnly()}
                              >
                                Upload Artwork
                              </Button>
                            </Upload>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  );
                })}
              </React.Fragment>
            ) : (
              <Col xs="12">
                <div className="table-wrapper">
                  <Table
                    tableLayout="fixed"
                    className="common-table"
                    loading={this.props.isFetchingData}
                    dataSource={this.props.orders}
                    pagination={false}
                    scroll={{ x: 768, y: "calc(100vh - 285px)" }}
                  >
                    <Column
                      title="Order #"
                      dataIndex="trans_no"
                      key="trans_no"
                      render={(trans_no) => <b>{trans_no}</b>}
                      width="80px"
                    />
                    <Column
                      title="Order Date"
                      dataIndex="trans_date"
                      key="trans_date"
                      width="100px"
                    />
                    <Column
                      title="Customer PO #"
                      dataIndex="ext_ref_no"
                      key="ext_ref_no"
                      width="125px"
                    />
                    <Column
                      title="Item #"
                      dataIndex="catalog_item_code"
                      key="catalog_item_code"
                      width="100px"
                    />
                    <Column
                      title={false}
                      dataIndex="id"
                      className="text-right"
                      key="id"
                      width="65px"
                      render={(id) => (
                        <Upload
                          beforeUpload={(file) => {
                            this.handleUpload(file, id);
                          }}
                        >
                          <Button
                            disabled={isloggedInUserViewOnly()}
                            type="primary"
                            id={id}
                            block
                            maxCount={1}
                          >
                            Upload Artwork
                          </Button>
                        </Upload>
                      )}
                    />
                  </Table>
                </div>
              </Col>
            )}
          </Row>
        </div>
      </Spin>
    );
  }
}

const loadingSelector = createLoadingSelector([
  MISSING_ART_ORDERS,
  UPLOAD_MISSING_ART,
]);

const mapStateToProps = (state) => ({
  orders: state.missingArtOrders,
  isFetchingData: loadingSelector(state),

  uploadSuccessMessage: state.uploadMissingArtworkMessage,
});

export default connect(mapStateToProps, {
  fetchMissingArtOrders,
  uploadMissingArtwork,
  resetStoreState,
})(MissingArtListComponent);
