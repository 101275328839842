import React from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Tabs,
  Select,
  Spin,
  message,
  Upload,
} from "antd";
import { Row, Col } from "../common/grid";
import { SvgIcon } from "../common";
import { UploadOutlined } from "@ant-design/icons";

import {
  USER_PROFILE,
  UPDATE_USER_PROFILE,
  FETCH_COUNTRIES,
  FETCH_STATES,
  USER_UPDATE_MESSAGE_RESET,
  USER_DETAIL_RESET,
  STATE_LIST_RESET,
  getResetAction,
  OPEN_UPDLOAD_CERTIFICATE_BY_DEFAULT,
} from "../../redux/actions/type";
import {
  fetchUserProfile,
  updateUserProfile,
  fetchCountries,
  fetchStates,
  resetStoreState,
  createLoadingSelector,
} from "../../redux/actions";

import { connect } from "react-redux";
import { isObjectEmpty, isObjectNotEmpty } from "../../common/common-methods";
// Style
import "./profile.scss";
import { baseServerAdress } from "../../constants/server-address-constants";

const { TabPane } = Tabs;

const { Option } = Select;

class EditProfileModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      profile: {},
      isInitialStateListRequired: false,
      activeTab: isObjectEmpty(this.props.defaultTabKey)
        ? "1"
        : this.props.defaultTabKey,
      hasGeneralTabError: false,
      hasEmailsTabError: false,
      hasDefaultTabError: false,
      hasIndustryInfoTabError: false,
    };
    this.onChangeCombobox = this.onChangeCombobox.bind(this);
  }
  formRef = React.createRef();

  componentDidMount() {
    this.props.fetchCountries();
    if (this.props.openUploadResaleCertificateByDefault) {
      this.onEditProfileClick();
    }
  }

  componentDidUpdate() {
    // console.log(this.props);

    if (
      !isObjectEmpty(this.props.countryList) &&
      this.state.isInitialStateListRequired &&
      this.state.visible &&
      !isObjectEmpty(this.state.profile)
    ) {
      const filteredArry = this.props.countryList.filter(
        (country) => country.code === this.state.profile.country
      );

      if (filteredArry.length > 0) {
        const country = filteredArry[0];

        this.props.fetchStates(country.id);
      }
      this.setState({ isInitialStateListRequired: false });
    }
    if (!isObjectEmpty(this.props.updateUserProfilemessage)) {
      message.success(this.props.updateUserProfilemessage);
      this.props.resetStoreState(USER_UPDATE_MESSAGE_RESET);
      this.props.resetStoreState(USER_DETAIL_RESET);
      this.setState({
        visible: false,
        isInitialStateListRequired: true,
      });
    }
  }

  onEditProfileFormSubmit = (values) => {
    let requestObject = { ...this.state.profile };

    if (!(this.state.profile.resale_certificate instanceof File)) {
      delete requestObject.resale_certificate;
    }

    this.props.updateUserProfile(this.state.profile.id, requestObject);
  };
  onFormValidationFailed = ({ values, errorFields }) => {
    for (var i = 0; i < errorFields.length; i++) {
      // console.log(document.getElementsByName(errorFields[i].name)[0].getAttribute("tabname"))
      // console.log(document.getElementsByName(errorFields[i].name)[0])
      if (
        document
          .getElementsByName(errorFields[i].name)[0]
          .getAttribute("tabname") === "general"
      ) {
        this.setState({ hasGeneralTabError: true });
      } else {
        this.setState({ hasGeneralTabError: false });
      }
      if (
        document
          .getElementsByName(errorFields[i].name)[0]
          .getAttribute("tabname") === "default-contact-details"
      ) {
        this.setState({ hasDefaultTabError: true });
      } else {
        this.setState({ hasDefaultTabError: false });
      }
      if (
        document
          .getElementsByName(errorFields[i].name)[0]
          .getAttribute("tabname") === "emails"
      ) {
        this.setState({ hasEmailsTabError: true });
      } else {
        this.setState({ hasEmailsTabError: false });
      }
      if (
        document
          .getElementsByName(errorFields[i].name)[0]
          .getAttribute("tabname") === "industry-info"
      ) {
        this.setState({ hasIndustryInfoTabError: true });
      } else {
        this.setState({ hasIndustryInfoTabError: false });
      }
    }
  };
  onFormFieldsChange = (changedValues) => {
    // if (changedValues.length > 0) {
    //   if (changedValues[0].errors.length > 0) {
    //     if (document.getElementsByName(changedValues[0].name[0])[0].getAttribute("tabname") === 'general') {
    //       this.setState({ hasGeneralTabError: true });
    //     }
    //     if (document.getElementsByName(changedValues[0].name[0])[0].getAttribute("tabname") === 'default-contact-details') {
    //       this.setState({ hasDefaultTabError: true });
    //     }
    //     if (document.getElementsByName(changedValues[0].name[0])[0].getAttribute("tabname") === 'emails') {
    //       this.setState({ hasEmailsTabError: true });
    //     }
    //     if (document.getElementsByName(changedValues[0].name[0])[0].getAttribute("tabname") === 'industry-info') {
    //       this.setState({ hasIndustryInfoTabError: true });
    //     }
    //   } else {
    //     if ((this.formRef.current.getFieldsError()).length > 0) {
    //       for (var i = 0; i < (this.formRef.current.getFieldsError()).length; i++) {
    //         if ((this.formRef.current.getFieldsError()[i].errors).length > 0) {
    //           console.log(this.formRef.current.getFieldsError()[i].errors)
    //         } else {
    //           this.setState({
    //             hasGeneralTabError: false,
    //             hasEmailsTabError: false,
    //             hasDefaultTabError: false,
    //             hasIndustryInfoTabError: false,
    //           });
    //         }
    //       }
    //     }
    //   }
    // }
  };
  onEditProfileClick = () => {
    const { visible } = { ...this.state };
    this.setState({
      profile: {},
      visible: !visible,
      isInitialStateListRequired: visible === false ? true : false,
      hasGeneralTabError: false,
      hasEmailsTabError: false,
      hasDefaultTabError: false,
      hasIndustryInfoTabError: false,
    });
    if (!visible) {
      this.props.fetchUserProfile();
    } else {
      if (this.props.openUploadResaleCertificateByDefault) {
        this.props.resetStoreState(
          getResetAction(OPEN_UPDLOAD_CERTIFICATE_BY_DEFAULT)
        );
      }
    }
  };
  callback = (key) => {
    this.formRef.current.validateFields();
    this.setState({
      activeTab: key,
    });
  };
  static getDerivedStateFromProps(props, state) {
    if (isObjectEmpty(state.profile)) {
      return {
        profile: { ...props.userProfile },
      };
    }
    return null;
  }

  onChange = (event) => {
    let newProfile = this.state.profile;
    let name = event.target.getAttribute("name"); //field name
    let value = event.target.value; //field value
    newProfile[name] = value;
    this.setState({ profile: { ...newProfile } });
  };

  onChangeContact = (event) => {
    let profile = this.state.profile;
    let default_contact = profile.default_contact;
    if (isObjectEmpty(default_contact)) {
      default_contact = {};
    }
    let name = event.target.getAttribute("name"); //field name
    let value = event.target.value; //field value
    default_contact[name] = value;
    profile.default_contact = default_contact;
    this.setState({ profile });
  };

  onChangeCombobox = (event, option) => {
    let profile = this.state.profile;
    if (option.name === "country") {
      this.props.resetStoreState(STATE_LIST_RESET);
      profile["state"] = null;
      this.props.fetchStates(option.key);
      this.formRef.current.setFieldsValue({
        state: null,
      });
    }
    profile[option.name] = option.value;
    this.setState({ profile });
  };

  uploadProps = () => {
    return {
      onRemove: (file) => {
        let profile = this.state.profile;
        delete profile.resale_certificate;
        profile.resale_certificate = profile.resale_certificate_old;
        this.setState({ profile });
      },
      beforeUpload: (file) => {
        let profile = this.state.profile;
        profile.resale_certificate_old = profile.resale_certificate;
        profile.resale_certificate = file;
        this.setState({ profile });

        return false;
      },
      maxCount: 1,
      fileList:
        this.state.profile.resale_certificate instanceof File
          ? [this.state.profile.resale_certificate]
          : null,
    };
  };

  render() {
    const {
      visible,
      hasGeneralTabError,
      hasEmailsTabError,
      hasDefaultTabError,
      hasIndustryInfoTabError,
    } = this.state;

    let certificateFileName = this.state.profile.resale_certificate;

    if (this.state.profile.resale_certificate instanceof File) {
      certificateFileName = this.state.profile.resale_certificate_old;
    }

    return (
      <React.Fragment>
        <span className="d-flex" onClick={this.onEditProfileClick}>
          Edit Profile
        </span>
        <Modal
          destroyOnClose={true}
          centered={true}
          bodyStyle={{
            padding: "1rem",
            minHeight: this.props.isFetchingData ? "400px" : "auto",
          }}
          closeIcon={
            <SvgIcon
              name="close"
              fill="#dc0100"
              width="13px"
              viewbox="0 0 298.667 298.667"
            />
          }
          className="full-screen-xs-modal footer-less edit-profile-modal"
          closable={true}
          onCancel={this.onEditProfileClick}
          width={"100%"}
          style={{ maxWidth: 700 }}
          title="Edit Profile"
          visible={visible}
          footer={null}
        >
          <Spin spinning={this.props.isFetchingData} size="large">
            {isObjectNotEmpty(this.state.profile) && (
              <Form
                ref={this.formRef}
                initialValues={{
                  ...this.state.profile,
                }}
                layout="vertical"
                //name="sendToCustom"
                onFinishFailed={this.onFormValidationFailed}
                onFinish={this.onEditProfileFormSubmit}
                onFieldsChange={this.onFormFieldsChange}
                className="h-100"
              >
                <Tabs
                  animated={false}
                  defaultActiveKey="1"
                  className="custom-tab aditprofile-tabs"
                  onChange={this.callback}
                  activeKey={this.state.activeTab}
                >
                  <TabPane
                    forceRender={true}
                    tab={
                      <span
                        className={
                          "d-flex align-items-center " +
                          (hasGeneralTabError ? "text-danger" : "")
                        }
                      >
                        General
                        {hasGeneralTabError && (
                          <SvgIcon name="caution" viewbox="0 0 512 512" />
                        )}
                      </span>
                    }
                    key="1"
                  >
                    <Row className="gutter-sm ship-up-custom">
                      <Col xs="12">
                        <label htmlFor="fname" className="w-100 fs-12">
                          Name
                        </label>
                        <Form.Item
                          id="name"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your  name!",
                            },
                          ]}
                        >
                          <Input
                            name="name"
                            value={this.state.profile.name}
                            size="large"
                            tabname="general"
                            prefix={
                              <SvgIcon
                                name="menu-alt"
                                viewbox="0 0 13 11"
                                width="15px"
                              />
                            }
                            placeholder=""
                            onChange={this.onChange.bind(this)}
                          />
                        </Form.Item>
                      </Col>
                      {/* <Col xs="12" sm="12" md="6" lg="6">
                        <label htmlFor="lname" className="w-100 fs-12">
                          Last Name
                        </label>
                        <Form.Item
                          id="lname"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your last name!",
                            },
                          ]}
                        >
                          <Input
                            name="last_name"
                            value={this.state.profile.last_name}
                            size="large"
                            tabname="general"
                            prefix={
                              <SvgIcon
                                name="menu-alt"
                                viewbox="0 0 16 7"
                                width="15px"
                              />
                            }
                            placeholder=""
                            onChange={this.onChange.bind(this)}
                          />
                        </Form.Item>
                      </Col> */}
                      <Col xs="12">
                        <label htmlFor="address1" className="w-100 fs-12">
                          Address
                        </label>
                        <Form.Item
                          name="address1"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your address!",
                            },
                          ]}
                        >
                          <Input
                            name="address1"
                            value={this.state.profile.address1}
                            onChange={this.onChange.bind(this)}
                            size="large"
                            prefix={
                              <SvgIcon
                                name="pin"
                                viewbox="0 0 10 15"
                                width="12px"
                              />
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          id="address2"
                          className="mb-3"
                          rules={[
                            {
                              required: false,
                              message: "Please enter your address!",
                            },
                          ]}
                        >
                          <Input
                            name="address2"
                            value={this.state.profile.address2}
                            onChange={this.onChange.bind(this)}
                            size="large"
                            prefix={
                              <SvgIcon
                                name="pin"
                                viewbox="0 0 10 15"
                                width="12px"
                              />
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12" sm="12" md="6" lg="6">
                        <label htmlFor="country" className="w-100 fs-12">
                          Country
                        </label>
                        <div className="custom-select-with-icon">
                          <SvgIcon
                            name="globe"
                            viewbox="0 0 59 59"
                            width="15px"
                          />
                          <Form.Item
                            id="country"
                            name="country"
                            className="mb-3"
                            rules={[
                              {
                                required: true,
                                message: "Please select your country!",
                              },
                            ]}
                          >
                            <Select
                              tabname="general"
                              value={this.state.profile.country}
                              onChange={this.onChangeCombobox}
                              suffixIcon={
                                <SvgIcon name="caret-alt" viewbox="0 0 12 7" />
                              }
                              size="large"
                            >
                              {this.props.countryList.map((country) => (
                                <Option
                                  key={country.id}
                                  name="country"
                                  value={country.code}
                                >
                                  {country.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs="12" sm="12" md="6" lg="6">
                        <label htmlFor="state" className="w-100 fs-12">
                          State
                        </label>
                        <div className="custom-select-with-icon">
                          <SvgIcon
                            name="bank"
                            viewbox="0 0 15 17"
                            width="15px"
                          />
                          <Form.Item
                            id="state"
                            name="state"
                            className="mb-3"
                            rules={[
                              {
                                required: true,
                                message: "Please select your state!",
                              },
                            ]}
                          >
                            <Select
                              tabname="general"
                              value={this.state.profile.state}
                              onChange={this.onChangeCombobox.bind(this)}
                              suffixIcon={
                                <SvgIcon name="caret-alt" viewbox="0 0 12 7" />
                              }
                              size="large"
                            >
                              {this.props.stateList.map((state) => (
                                <Option
                                  key={state.code}
                                  name="state"
                                  value={state.code}
                                >
                                  {state.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs="12" sm="12" md="6" lg="6">
                        <label htmlFor="city" className="w-100 fs-12">
                          City
                        </label>
                        <Form.Item
                          id="city"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please select your city!",
                            },
                          ]}
                        >
                          <Input
                            name="city"
                            value={this.state.profile.city}
                            onChange={this.onChange.bind(this)}
                            size="large"
                            placeholder="Enter City"
                            tabname="general"
                            prefix={
                              <SvgIcon
                                name="city"
                                viewbox="0 0 59 59"
                                width="15px"
                              />
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12" sm="12" md="6" lg="6">
                        <label htmlFor="zip_code" className="w-100 fs-12">
                          Zip Code
                        </label>
                        <Form.Item
                          id="zip_code"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please select your zip code!",
                            },
                          ]}
                        >
                          <Input
                            name="zip"
                            value={this.state.profile.zip}
                            onChange={this.onChange.bind(this)}
                            size="large"
                            tabname="general"
                            prefix={
                              <SvgIcon
                                name="pin"
                                viewbox="0 0 10 15"
                                width="12px"
                              />
                            }
                            placeholder=""
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12" sm="12" md="6" lg="6">
                        <label htmlFor="phone" className="w-100 fs-12">
                          Phone Number
                        </label>
                        <Form.Item
                          id="phone"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please select your phone number!",
                            },
                          ]}
                        >
                          <Input
                            name="phone1"
                            value={this.state.profile.phone1}
                            onChange={this.onChange.bind(this)}
                            size="large"
                            tabname="general"
                            prefix={
                              <SvgIcon
                                name="phone"
                                viewbox="0 0 13 13"
                                width="15px"
                              />
                            }
                            placeholder=""
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12" sm="12" md="6" lg="6">
                        <label htmlFor="fax_number" className="w-100 fs-12">
                          Fax Number
                        </label>
                        <Form.Item
                          id="fax_number"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please select your fax number!",
                            },
                          ]}
                        >
                          <Input
                            name="fax"
                            value={this.state.profile.fax1}
                            onChange={this.onChange.bind(this)}
                            size="large"
                            tabname="general"
                            prefix={
                              <SvgIcon
                                name="fax"
                                viewbox="0 0 59 59"
                                width="15px"
                              />
                            }
                            placeholder=""
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    forceRender={true}
                    tab={
                      <span
                        className={
                          "d-flex align-items-center " +
                          (hasEmailsTabError ? "text-danger" : "")
                        }
                      >
                        Emails
                        {hasEmailsTabError && (
                          <SvgIcon name="caution" viewbox="0 0 512 512" />
                        )}
                      </span>
                    }
                    key="2"
                  >
                    <Row className="gutter-sm ship-up-custom">
                      <Col xs="12">
                        <label
                          htmlFor="correspondence_email"
                          className="w-100 fs-12"
                        >
                          Correspondence
                        </label>
                        <Form.Item
                          id="correspondence_email"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter email address!",
                            },
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                          ]}
                        >
                          <Input
                            name="corr_dept_email"
                            value={this.state.profile.corr_dept_email}
                            onChange={this.onChange.bind(this)}
                            size="large"
                            tabname="emails"
                            prefix={
                              <SvgIcon
                                name="mail"
                                viewbox="0 0 13 11"
                                width="15px"
                              />
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <label
                          htmlFor="accounting_email"
                          className="w-100 fs-12"
                        >
                          Accounting
                        </label>
                        <Form.Item
                          id="accounting_email"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter email address!",
                            },
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                          ]}
                        >
                          <Input
                            name="account_dept_email"
                            value={this.state.profile.account_dept_email}
                            onChange={this.onChange.bind(this)}
                            size="large"
                            tabname="emails"
                            prefix={
                              <SvgIcon
                                name="mail"
                                viewbox="0 0 13 11"
                                width="15px"
                              />
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <label htmlFor="shipping_email" className="w-100 fs-12">
                          Shipping
                        </label>
                        <Form.Item
                          id="shipping_email"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter email address!",
                            },
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                          ]}
                        >
                          <Input
                            name="shipping_dept_email"
                            value={this.state.profile.shipping_dept_email}
                            onChange={this.onChange.bind(this)}
                            size="large"
                            tabname="emails"
                            prefix={
                              <SvgIcon
                                name="mail"
                                viewbox="0 0 13 11"
                                width="15px"
                              />
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <label htmlFor="artwork_email" className="w-100 fs-12">
                          Artwork
                        </label>
                        <Form.Item
                          id="artwork_email"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter email address!",
                            },
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                          ]}
                        >
                          <Input
                            name="artwork_dept_email"
                            value={this.state.profile.artwork_dept_email}
                            onChange={this.onChange.bind(this)}
                            size="large"
                            tabname="emails"
                            prefix={
                              <SvgIcon
                                name="mail"
                                viewbox="0 0 13 11"
                                width="15px"
                              />
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <label
                          htmlFor="purchase_dept_email"
                          className="w-100 fs-12"
                        >
                          Purchase
                        </label>
                        <Form.Item
                          id="purchase_dept_email"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter email address!",
                            },
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                          ]}
                        >
                          <Input
                            name="purchase_dept_email"
                            value={this.state.profile.purchase_dept_email}
                            onChange={this.onChange.bind(this)}
                            size="large"
                            tabname="emails"
                            prefix={
                              <SvgIcon
                                name="mail"
                                viewbox="0 0 13 11"
                                width="15px"
                              />
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    forceRender={true}
                    tab={
                      <span
                        className={
                          "d-flex align-items-center " +
                          (hasDefaultTabError ? "text-danger" : "")
                        }
                      >
                        Default Contact Details
                        {hasDefaultTabError && (
                          <SvgIcon name="caution" viewbox="0 0 512 512" />
                        )}
                      </span>
                    }
                    key="3"
                  >
                    <Row className="gutter-sm ship-up-custom">
                      <Col xs="12" xl="6">
                        <label htmlFor="first_name" className="w-100 fs-12">
                          First Name
                        </label>
                        <Form.Item
                          id="first_name"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your first name!",
                            },
                          ]}
                        >
                          <Input
                            name="first_name"
                            size="large"
                            tabname="default-contact-details"
                            value={
                              isObjectEmpty(this.state.profile.default_contact)
                                ? ""
                                : this.state.profile.default_contact.first_name
                            }
                            onChange={this.onChangeContact.bind(this)}
                            prefix={
                              <SvgIcon
                                name="menu-alt"
                                viewbox="0 0 16 7"
                                width="15px"
                              />
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12" xl="6">
                        <label htmlFor="last_name" className="w-100 fs-12">
                          Last Name
                        </label>
                        <Form.Item
                          id="last_name"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your first name!",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            name="last_name"
                            tabname="default-contact-details"
                            value={
                              isObjectEmpty(this.state.profile.default_contact)
                                ? ""
                                : this.state.profile.default_contact.last_name
                            }
                            onChange={this.onChangeContact.bind(this)}
                            prefix={
                              <SvgIcon
                                name="menu-alt"
                                viewbox="0 0 16 7"
                                width="15px"
                              />
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <label htmlFor="user_email" className="w-100 fs-12">
                          Email
                        </label>
                        <Form.Item
                          id="user_email"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter email address!",
                            },
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                          ]}
                        >
                          <Input
                            name="email"
                            size="large"
                            tabname="default-contact-details"
                            value={
                              isObjectEmpty(this.state.profile.default_contact)
                                ? ""
                                : this.state.profile.default_contact.email
                            }
                            onChange={this.onChangeContact.bind(this)}
                            prefix={
                              <SvgIcon
                                name="mail"
                                viewbox="0 0 13 11"
                                width="15px"
                              />
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <label
                          htmlFor="bussiness_phone"
                          className="w-100 fs-12"
                        >
                          Phone Number
                        </label>
                        <Form.Item
                          id="business_phone"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please select your phone number!",
                            },
                          ]}
                        >
                          <Input
                            name="business_phone"
                            size="large"
                            tabname="default-contact-details"
                            value={
                              isObjectEmpty(this.state.profile.default_contact)
                                ? ""
                                : this.state.profile.default_contact
                                    .business_phone
                            }
                            onChange={this.onChangeContact.bind(this)}
                            prefix={
                              <SvgIcon
                                name="phone"
                                viewbox="0 0 13 13"
                                width="15px"
                              />
                            }
                            placeholder=""
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    forceRender={true}
                    tab={
                      <span
                        className={
                          "d-flex align-items-center " +
                          (hasIndustryInfoTabError ? "text-danger" : "")
                        }
                      >
                        Industry Info
                        {hasIndustryInfoTabError && (
                          <SvgIcon name="caution" viewbox="0 0 512 512" />
                        )}
                      </span>
                    }
                    key="4"
                  >
                    <Row className="gutter-sm ship-up-custom">
                      <Col xs="12" xl="6">
                        <label
                          htmlFor="asi"
                          className="w-100 fs-12 custom-icon-lg"
                        >
                          ASI
                        </label>
                        <Form.Item
                          id="asi"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your ASI info!",
                            },
                          ]}
                        >
                          <Input
                            name="asi_number"
                            value={this.state.profile.asi_number}
                            onChange={this.onChange.bind(this)}
                            size="large"
                            tabname="industry-info"
                            disabled={true}
                            //prefix={<SvgIcon name="asi" viewbox="0 0 101.31 54.83" width="30px" />}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12" xl="6">
                        <label
                          htmlFor="ppai"
                          className="w-100 fs-12 custom-icon-lg"
                        >
                          PPAI
                        </label>
                        <Form.Item
                          id="ppai"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your PPI info!",
                            },
                          ]}
                        >
                          <Input
                            name="ppai"
                            value={this.state.profile.ppai}
                            onChange={this.onChange.bind(this)}
                            size="large"
                            tabname="industry-info"
                            disabled={true}
                            // prefix={<SvgIcon name="ppai" viewbox="0 0 248.03 98.64" width="36px" />}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12" xl="6">
                        <label
                          htmlFor="sage"
                          className="w-100 fs-12 custom-icon-lg"
                        >
                          SAGE
                        </label>
                        <Form.Item
                          id="sage"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your SAGE info!",
                            },
                          ]}
                        >
                          <Input
                            name="sage"
                            value={this.state.profile.sage}
                            onChange={this.onChange.bind(this)}
                            size="large"
                            tabname="industry-info"
                            disabled={true}
                            // prefix={<SvgIcon name="sage" className="sage-icon" viewbox="109.659 49.049 205.37 83.228" width="41px" />}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12" xl="6">
                        <label
                          htmlFor="upic"
                          className="w-100 fs-12 custom-icon-lg"
                        >
                          UPIC
                        </label>
                        <Form.Item
                          id="upic"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your UPIC info!",
                            },
                          ]}
                        >
                          <Input
                            name="upic"
                            value={this.state.profile.upic}
                            onChange={this.onChange.bind(this)}
                            size="large"
                            tabname="industry-info"
                            disabled={true}
                            //prefix={<SvgIcon name="upic" viewbox="0 0 431.55 283.67" width="35px" />}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <label htmlFor="affiliation" className="w-100 fs-12">
                          Affiliation
                        </label>
                        <Form.Item
                          id="affiliation"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your affiliation!",
                            },
                          ]}
                        >
                          <Input
                            name="affiliation"
                            value={this.state.profile.affiliation}
                            onChange={this.onChange.bind(this)}
                            size="large"
                            tabname="industry-info"
                            disabled={true}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <label htmlFor="organization" className="w-100 fs-12">
                          Organization
                        </label>
                        <Form.Item
                          id="organization"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your organization!",
                            },
                          ]}
                        >
                          <Input
                            name="organisation"
                            value={this.state.profile.organisation}
                            onChange={this.onChange.bind(this)}
                            size="large"
                            tabname="industry-info"
                            disabled={true}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <label htmlFor="other" className="w-100 fs-12">
                          Other
                        </label>
                        <Form.Item id="other" className="mb-3">
                          <Input
                            name="other"
                            value={this.state.profile.other}
                            onChange={this.onChange.bind(this)}
                            size="large"
                            tabname="industry-info"
                            disabled={true}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    forceRender={true}
                    tab={
                      <span
                        className={
                          "d-flex align-items-center " +
                          (hasDefaultTabError ? "text-danger" : "")
                        }
                      >
                        Documents
                        {hasDefaultTabError && (
                          <SvgIcon name="caution" viewbox="0 0 512 512" />
                        )}
                      </span>
                    }
                    key="5"
                  >
                    <Row className="gutter-sm ship-up-custom">
                      <Col xs="12" md="5">
                        <label htmlFor="first_name" className="w-100 fs-12">
                          Upload Resale Certificate
                        </label>
                        <Form.Item
                          id="first_name"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your first name!",
                            },
                          ]}
                        >
                          <Upload {...this.uploadProps()}>
                            <Button icon={<UploadOutlined />}>
                              Select File
                            </Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="6">
                        <label htmlFor="last_name" className="w-100 fs-12">
                          Resale Certificate
                        </label>
                        <Form.Item
                          id="last_name"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your first name!",
                            },
                          ]}
                        >
                          <Button
                            name="last_name"
                            tabname="default-contact-details"
                            title="View"
                            onClick={() => {
                              let baseUrl = this.state.profile
                                .resale_certificate_url;

                              if (isObjectEmpty(baseUrl)) {
                                baseUrl =
                                  baseServerAdress.RESALE_CERTIFICATE +
                                  certificateFileName;
                              }

                              window.open(baseUrl + "?" + Date(), "_blank");
                            }}
                            disabled={isObjectEmpty(certificateFileName)}
                          >
                            View
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
                <div className="d-flex justify-content-end pt-3 mt-auto">
                  <Button
                    onClick={this.onEditProfileClick}
                    className="mr-3"
                    size="large"
                  >
                    <b className="fs-13">CANCEL</b>
                  </Button>
                  <Button type="primary" htmlType="submit" size="large">
                    <b className="fs-13">SAVE</b>
                  </Button>
                </div>
              </Form>
            )}{" "}
          </Spin>
        </Modal>
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector([
  USER_PROFILE,
  UPDATE_USER_PROFILE,
  FETCH_COUNTRIES,
  FETCH_STATES,
]);

const mapStateToProps = (state) => ({
  userProfile: state.userProfile,
  updateUserProfilemessage: state.updateUserProfile,
  countryList: state.countries,
  stateList: state.states,
  isFetchingData: loadingSelector(state),
  openUploadResaleCertificateByDefault:
    state.openUploadResaleCertificateByDefault,
});

export default connect(mapStateToProps, {
  fetchUserProfile,
  updateUserProfile,
  fetchCountries,
  fetchStates,
  resetStoreState,
})(EditProfileModalComponent);
