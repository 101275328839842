import React from "react";
import { Modal, Spin } from "antd";
import { SvgIcon } from "../common";

import { fetchContactList, createLoadingSelector } from "../../redux/actions";
import { CONTACTS_LIST } from "../../redux/actions/type";
import { connect } from "react-redux";

// Style
import "./contacts.scss";
import AddEditListItemComponent from "./add-edit-listing-item";

class ManageContactsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      manageContactModal: false,
    };
  }

  onManageContactClick = (e) => {
    if (!this.state.manageContactModal) {
      this.props.fetchContactList();
    }
    this.setState({
      manageContactModal: !this.state.manageContactModal,
    });
  };

  renderList() {
    return this.props.contactList.map((contact, index) => {
      return <AddEditListItemComponent key={index} contact={contact} />;
    });
  }
  render() {
    let { manageContactModal } = this.state;
    return (
      <React.Fragment>
        <span className="d-flex" onClick={this.onManageContactClick}>
          Manage Contacts
        </span>
        <Modal
          destroyOnClose={true}
          centered={true}
          style={{ width: "100%", maxWidth: "1020px" }}
          bodyStyle={{ padding: "1rem" }}
          closeIcon={
            <SvgIcon
              name="close"
              fill="#dc0100"
              width="13px"
              viewbox="0 0 298.667 298.667"
            />
          }
          className="full-screen-xs-modal footer-less manage-contact-modal"
          closable={true}
          onCancel={this.onManageContactClick}
          title="Manage Contacts"
          visible={manageContactModal}
          width="auto"
          footer={null}
          loading={this.props.isFetchingData}
        >
          <Spin spinning={this.props.isFetchingData} size="large">
            <div className="contact-grid">
              <AddEditListItemComponent showAddButtonView={true} />

              {this.props.contactList && this.renderList()}
            </div>
          </Spin>
        </Modal>
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector([CONTACTS_LIST]);

const mapStateToProps = (state) => ({
  contactList: state.contactList,
  isFetchingData: loadingSelector(state),
});

export default connect(mapStateToProps, {
  fetchContactList,
})(ManageContactsComponent);
