import React from "react";
import { SvgIcon } from "../../../../components/common";
import { Button } from "antd";

//Style
import "./multiaddress-modal.scss";
import AddEditAddressModalComponent from "./add-edit-address-modal";
import { showAlert } from "../../../../common/alert-messages";
import {
  isObjectNotEmpty,
  shippingPackageTracking,
} from "../../../../common/common-methods";

class MultiaddressListItemsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
    };

    this.closeAddEditComponent = this.closeEditComponent.bind(this);
  }

  editButtonClicked = () => {
    if (this.props.shipping.shipping_flag === "Y") {
      showAlert.error("Shipping Address Can't Edit After Shipping");
    } else {
      this.setState({
        isEditing: true,
      });
    }
  };

  closeEditComponent() {
    this.setState({
      isEditing: false,
    });
  }

  render() {
    let { shipping, orderId } = this.props;
    return (
      <React.Fragment>
        {this.state.isEditing && (
          <AddEditAddressModalComponent
            closeEditComponent={this.closeEditComponent.bind(this)}
            shipping={
              this.state.isEditing ? shipping : { sales_order_id: orderId }
            }
            orderNo={this.props.orderNo}
            isEditable={this.props.isEditable}
          />
        )}

        <div className="address-block active">
          <div className="address-block-inner">
            <div className="address-head">
              <div className="address">
                {shipping.ship_name}
                <br />
                {shipping.ship_address1},{shipping.ship_address2},
                {shipping.ship_city},{shipping.ship_state},
                {shipping.ship_country},{shipping.ship_zip}
              </div>
              <Button
                shape="circle"
                className="edit-address ant-btn-icon-only"
                id={this.props.shippingId}
                onClick={this.editButtonClicked.bind(this)}
              >
                <SvgIcon name="pen" viewbox="0 0 16 16" />
              </Button>
            </div>
            <div className="address-content">
              <ul>
                <li>
                  <span className="label">Address type</span>
                  <span className="value">Commercial</span>
                </li>
                <li>
                  <span className="label">Quantity</span>
                  <span className="value">{shipping.ship_qty}</span>
                </li>
                <li>
                  <span className="label">Ship Date</span>
                  <span className="value">
                    {isObjectNotEmpty(shipping.internal_ship_date)
                      ? shipping.internal_ship_date
                      : shipping.estimated_ship_date}
                  </span>
                </li>
                <li>
                  <span className="label">Arrival Date</span>
                  <span className="value">
                    {isObjectNotEmpty(shipping.internal_inhand_date)
                      ? shipping.internal_inhand_date
                      : shipping.estimated_arrival_date}
                  </span>
                </li>
                <li>
                  <span className="label">Ship #</span>
                  <span className="value">{shipping.shipping_code}</span>
                </li>
                <li>
                  <span className="label">Ship Amount</span>
                  <span className="value">{shipping.display_ship_amt}</span>
                </li>
                <li>
                  <span className="label">Tracking #</span>
                  <Button
                    type="link"
                    onClick={() =>
                      shippingPackageTracking(
                        shipping.shipping_code,
                        shipping.tracking_number
                      )
                    }
                  >
                    <b>
                      <u>{shipping.tracking_number}</u>
                    </b>
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MultiaddressListItemsComponent;
