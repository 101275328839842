import React, { useState, useEffect } from "react";
import { Button, Table, Spin } from "antd";
import { Row, Col } from "../../../components/common/grid";
import { SvgIcon } from "../../../components/common";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchEstimateOrders,
  createLoadingSelector,
  fetchEstimateDetails,
  resetStoreState,
} from "../../../redux/actions";
import {
  ESTIMATE_ORDERS,
  ESTIMATE_DETAILS,
  ESTIMATE_DETAILS_RESET,
} from "../../../redux/actions/type";

// Style
import "./estimates-list.scss";
import { isObjectEmpty, setScrollIn } from "../../../common/common-methods";
import { baseServerAdress } from "../../../constants/server-address-constants";

const EstimatesListComponent = () => {
  const { Column } = Table;
  const loadingSelector = createLoadingSelector([
    ESTIMATE_ORDERS,
    ESTIMATE_DETAILS,
  ]);
  const estimateDetailsPath = useSelector((state) => state.estimateDetails);
  const estimateList = useSelector((state) => state.estimates);
  const isFetchingData = useSelector((state) => loadingSelector(state));
  const dispatch = useDispatch();

  const [state, setState] = useState({
    gridView: true,
  });
  useEffect(() => {
    setScrollIn();
    dispatch(fetchEstimateOrders());
  }, [dispatch]);

  useEffect(() => {
    if (!isObjectEmpty(estimateDetailsPath)) {
      dispatch(resetStoreState(ESTIMATE_DETAILS_RESET));
      window.open(baseServerAdress.REPORTS + estimateDetailsPath, "_blank");
    }
  });

  const viewToggleTrue = () => {
    setState({
      ...state,
      gridView: true,
    });
  };
  const viewToggleFalse = () => {
    setState({
      ...state,
      gridView: false,
    });
  };

  return (
    <Spin spinning={isFetchingData} size="large">
      <div className="common-container pb-4">
        <Row className="pt-3">
          <Col xs="12">
            <div className="page-view-panel">
              <MediaQuery minDeviceWidth={992}>
                <span className="page-name">Estimates</span>
              </MediaQuery>
              <MediaQuery maxDeviceWidth={991}>
                <Link to="/dashboard" className="common-back-btn">
                  <SvgIcon name="caretLeft" viewbox="0 0 451.846 451.847" />
                  <span>Estimates</span>
                </Link>
              </MediaQuery>
              <div className="view-switch">
                <button
                  type="button"
                  className={state.gridView ? "active" : ""}
                  onClick={viewToggleTrue}
                >
                  <SvgIcon name="grid-view" viewbox="0 0 12 12" />
                </button>
                <button
                  type="button"
                  className={!state.gridView ? "active" : ""}
                  onClick={viewToggleFalse}
                >
                  <SvgIcon name="list-view" width="20px" viewbox="0 0 15 12" />
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="pt-3 pt-lg-3">
          {state.gridView ? (
            <React.Fragment>
              {estimateList.map((estimate, i) => {
                return (
                  <Col
                    key={i}
                    xs="12"
                    sm="6"
                    md="4"
                    lg="4"
                    xl="3"
                    className="mb-20"
                  >
                    <div className="border-box p-3 h-100">
                      <Row className="h-100">
                        <Col xs="6">
                          <div className="block-label">Estimate #</div>
                          <div className="block-value">{estimate.trans_no}</div>
                        </Col>
                        <Col xs="6">
                          <div className="block-label">Estimate Date</div>
                          <div className="block-value">
                            {estimate.trans_date.split("T")[0]}
                          </div>
                        </Col>
                        <Col xs="6">
                          <div className="block-label">Inside Rep #</div>
                          <div
                            className="block-value"
                            style={{ textTransform: "capitalize" }}
                          >
                            {estimate.salesperson_code &&
                              estimate.salesperson_code.toLowerCase()}
                          </div>
                        </Col>
                        <Col xs="6">
                          <div className="block-label">Outside Rep #</div>
                          <div
                            className="block-value"
                            style={{ textTransform: "capitalize" }}
                          >
                            {estimate.externalsalesperson_code &&
                              estimate.externalsalesperson_code.toLowerCase()}
                          </div>
                        </Col>
                        <Col xs="6">
                          <div className="block-label">Contact</div>
                          <div
                            className="block-value"
                            style={{ textTransform: "capitalize" }}
                          >
                            {estimate.customer_contact &&
                              estimate.customer_contact.toLowerCase()}
                          </div>
                        </Col>
                        <Col xs="6">
                          <div className="block-label">Expiry Date</div>
                          <div className="block-value">
                            {estimate.expiry_date.split("T")[0]}
                          </div>
                        </Col>
                        <Col xs="12">
                          <div className="block-label">Items #</div>
                          <div className="block-value">
                            {estimate.catalog_item_code &&
                              estimate.catalog_item_code}
                          </div>
                        </Col>

                        <Col xs="12" className="mt-auto">
                          <Button
                            type="primary"
                            block
                            onClick={() => {
                              dispatch(fetchEstimateDetails(estimate.id));
                            }}
                          >
                            View Detail
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                );
              })}
            </React.Fragment>
          ) : (
            <Col xs="12">
              <div className="table-wrapper">
                <Table
                  tableLayout="fixed"
                  className="common-table"
                  dataSource={estimateList}
                  pagination={false}
                  scroll={{ x: 768, y: "calc(100vh - 285px)" }}
                >
                  <Column
                    title="Estimate #"
                    dataIndex="trans_no"
                    key="trans_no"
                    render={(estimate_number) => <b>{estimate_number}</b>}
                    width="80px"
                  />
                  <Column
                    title="Estimate Date"
                    dataIndex="trans_date"
                    key="trans_date"
                    width="100px"
                  />

                  <Column
                    title="Inside Rep #"
                    dataIndex="salesperson_code"
                    key="salesperson_code"
                    width="100px"
                    render={(salesperson_code) => {
                      return (
                        <div style={{ textTransform: "capitalize" }}>
                          {salesperson_code && salesperson_code.toLowerCase()}
                        </div>
                      );
                    }}
                  />
                  <Column
                    title="Outside Rep #"
                    dataIndex="externalsalesperson_code"
                    key="externalsalesperson_code"
                    width="100px"
                    render={(externalsalesperson_code) => {
                      return (
                        <div style={{ textTransform: "capitalize" }}>
                          {externalsalesperson_code &&
                            externalsalesperson_code.toLowerCase()}
                        </div>
                      );
                    }}
                  />
                  <Column
                    title="Contact"
                    dataIndex="customer_contact"
                    key="customer_contact"
                    width="100px"
                    render={(customer_contact) => {
                      return (
                        <div style={{ textTransform: "capitalize" }}>
                          {customer_contact && customer_contact.toLowerCase()}
                        </div>
                      );
                    }}
                  />
                  <Column
                    title="Expiry Date"
                    dataIndex="expiry_date"
                    key="expiry_date"
                    width="100px"
                  />

                  <Column
                    title="Item #"
                    dataIndex="catalog_item_code"
                    key="catalog_item_code"
                    width="200px"
                  />
                  <Column
                    title={false}
                    dataIndex="id"
                    className="text-right"
                    key="id"
                    width="65px"
                    render={(id) => (
                      <Button
                        type="primary"
                        onClick={() => {
                          dispatch(fetchEstimateDetails(id));
                        }}
                      >
                        View Detail
                      </Button>
                    )}
                  />
                </Table>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </Spin>
  );
};
export default EstimatesListComponent;
