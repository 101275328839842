import React from "react";
import { SvgIcon } from "../../../../components/common";
import { Modal, Button } from "antd";
//Style
import "./multiaddress-modal.scss";
import MultiaddressListItemsComponent from "./multiaddress-list-items";

import {
  fetchOrderShippingDetail,
  createLoadingSelector,

  resetStoreState,
} from "../../../../redux/actions";

import {
  ORDER_SHIPPING_DETAIL,
  ORDER_SHIPPING_DETAIL_RESET,
} from "../../../../redux/actions/type";
import { connect } from "react-redux";
import { isObjectEmpty, isObjectNotEmpty } from "../../../../common/common-methods";
import { showAlert } from "../../../../common/alert-messages";

class MultiaddressModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,

    };
  }
  modalToggle = () => {
    if (!this.state.visible) {
      this.props.fetchOrderShippingDetail(this.props.orderId);
    } else {
      this.props.resetStoreState(ORDER_SHIPPING_DETAIL_RESET);
      this.setState({
        visible: false,
      });
    }

  };

  componentDidUpdate = () => {
    if (this.props.isShippingFetched) {
      if (isObjectEmpty(this.props.shippingDetails)) {
        showAlert.error("No shipping found!");
        this.props.resetStoreState(ORDER_SHIPPING_DETAIL_RESET);
      }
      else {
        if (!this.state.visible) {
          this.setState({
            visible: true,
          });
        }
      }
    }
  }
  renderList() {
    return this.props.shippingDetails.map((shipping, index) => {
      return (
        <MultiaddressListItemsComponent
          key={index}
          shipping={shipping}
          orderNo={this.props.orderNo}
          isEditable={this.props.isEditable}
        />
      );
    });
  }
  render() {
    const { visible } = this.state;
    return (
      <React.Fragment>
        <Button
          className="colored-btn pink"
          onClick={this.modalToggle}
        //loading={this.props.isFetchingData}
        >
          <SvgIcon name="map" width="22px" viewbox="0 0 22 26" /> Shipping
          Address(es)
        </Button>
        <Modal
          destroyOnClose={true}
          centered={true}
          style={{ maxWidth: 1020 }}
          bodyStyle={{ padding: "1rem" }}
          width={"100%"}
          closeIcon={
            <SvgIcon
              name="close"
              fill="#dc0100"
              width="13px"
              viewbox="0 0 298.667 298.667"
            />
          }
          className="full-screen-xs-modal footer-less"
          closable={true}
          onCancel={this.modalToggle}
          title="Edit Shipping Addresses"
          visible={visible}
          footer={null}
        >
          <div className="address-grid">
            {this.props.shippingDetails && this.renderList()}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector([ORDER_SHIPPING_DETAIL]);


const mapStateToProps = (state) => ({
  shippingDetails: isObjectNotEmpty(state.orderShippingDetail) ? state.orderShippingDetail.shippingList : [],
  isShippingFetched: isObjectNotEmpty(state.orderShippingDetail),
  isFetchingData: loadingSelector(state),

});

export default connect(mapStateToProps, {
  fetchOrderShippingDetail,
  resetStoreState,
})(MultiaddressModalComponent);
