import React from "react";
import { SvgIcon } from "../common";

import "./contacts.scss";
import AddEditContactComponent from "./add-edit-contact";
import { Button } from "antd";

class AddEditListItemComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditingContact: false,
      isAddingNewContact: false,
    };

    this.closeAddEditComponent = this.closeAddEditComponent.bind(this);
  }

  editButtonClicked = () => {
    this.setState({
      isEditingContact: true,
    });
  };

  addButtonClicked = () => {
    this.setState({
      isAddingNewContact: true,
    });
  };

  closeAddEditComponent() {
    this.setState({
      isEditingContact: false,
      isAddingNewContact: false,
    });
  }

  render() {
    let { contact } = this.props;

    return (
      <React.Fragment>
        {(this.state.isEditingContact || this.state.isAddingNewContact) && (
          <AddEditContactComponent
            closeAddEditComponent={this.closeAddEditComponent}
            contact={this.state.isEditingContact ? contact : {}}
          />
        )}
        {this.props.showAddButtonView ? (
          <div className="contact-block" onClick={this.addButtonClicked}>
            <div className="contact-block-inner add-contact">
              +<span>ADD CONTACT</span>
            </div>
          </div>
        ) : (
          <div className="contact-block">
            <div className="contact-block-inner">
              <div className="contact-head">
                <div className="contact">
                  <b>
                    {contact.first_name} {` `}
                    {contact.last_name}
                  </b>
                  <br />
                  {contact.job_title} <br />
                </div>
                <Button
                  shape="circle"
                  className="edit-contact ant-btn-icon-only"
                  id={this.props.contactId}
                  onClick={this.editButtonClicked.bind(this)}
                >
                  <SvgIcon name="pen" viewbox="0 0 16 16" />
                </Button>
              </div>
              <div className="contact-content">
                <ul>
                  <li>
                    <span className="icon-wrap">
                      <SvgIcon name="mail" width="13px" viewbox="0 0 13 11" />
                    </span>
                    <span className="value">{contact.email}</span>
                  </li>
                  <li>
                    <span className="icon-wrap">
                      <SvgIcon name="pin" width="10px" viewbox="0 0 10 15" />
                    </span>
                    <span className="value">
                      {contact.city} - {contact.state}, {contact.zip} (
                      {contact.country})
                    </span>
                  </li>
                  <li>
                    <span className="icon-wrap">
                      <SvgIcon name="phone" width="12px" viewbox="0 0 13 13" />
                    </span>
                    <span className="value">
                      {contact.business_phone}, {contact.home_phone}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default AddEditListItemComponent;
