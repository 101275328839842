import React, { useState, useEffect, useCallback } from "react";
import { Button, Input, Table, Spin, AutoComplete, Alert } from "antd";
import { Row, Col } from "../../../components/common/grid";
import { SvgIcon, ImageLoader } from "../../../components/common";
import { Link, useLocation } from "react-router-dom";
import MediaQuery from "react-responsive";
import history from "../../../common/history";
import debounce from "lodash/debounce";

import {
  checkInventory,
  resetStoreState,
  createLoadingSelector,
  inventoryItemSearch,
} from "../../../redux/actions";
import {
  CHECK_INVENTORY,
  CHECK_INVENTORY_RESET,
  INVENTORY_ITEM_SEARCH,
  INVENTORY_ITEM_SEARCH_RESET,
} from "../../../redux/actions/type";
import { useSelector, useDispatch } from "react-redux";
import { baseServerAdress } from "../../../constants/server-address-constants";
import {
  isFlagTrueY,
  isObjectEmpty,
  isObjectNotEmpty,
  setScrollIn,
} from "../../../common/common-methods";

// Style
import "./check-inventory-list.scss";
import { componentPaths } from "../../../constants/path-constants";
import { SUGGESTION_API_DEBOUNCE_TIME } from "../../../constants";

const { Column } = Table;

const searchResult = (searchResultArry) => {
  return searchResultArry.map((item, index) => {
    return {
      key: item.id,
      value: item.web_code,
      label: (
        <>
          <span>{item.web_code} </span>
          <span>{} </span>
        </>
      ),
    };
  });
};
const InventoryBlock = ({
  img,
  item,
  availableQty,
  arrivalQty,
  info,
  onViewEstimateClick,
  catalog_item_code,
  itemObject,
}) => {
  let isMadeToOrder = itemObject.madetoorder_flag === "Y";
  let isCallForUpdatedInventory = isFlagTrueY(
    itemObject.updated_inventory_flag
  );

  return (
    <Col xs="12" md="6" lg="6" xl="4">
      <div className="border-box mb-20 h-100">
        <div className="inventory-block">
          <div className="inventory-img border-box">
            {img ? (
              <ImageLoader src={img} alt={"e-proof"} title={"e-proof"} />
            ) : (
              <div className="no-img">
                <SvgIcon name="picture" viewbox="0 0 28 28" />
                <span>No Image Available</span>
              </div>
            )}
          </div>
          <div className="product-info-wrap">
            <div className="product-info mt-2 mt-sm-0">
              <div className="d-flex flex-column pr-2">
                <div className="block-label">Item #</div>
                <div className="block-value">{item}</div>
              </div>
              <div className="d-flex flex-column">
                {!isMadeToOrder && !isCallForUpdatedInventory && (
                  <>
                    <div className="block-label text-right">Available Qty</div>
                    <div className="block-value d-flex justify-content-end ">
                      <Input defaultValue={availableQty} readOnly />
                    </div>
                  </>
                )}
                {isMadeToOrder && (
                  <div className="right-info-text block-value d-flex justify-content-end text-danger fs-16">
                    Made To Order
                  </div>
                )}
                {isCallForUpdatedInventory && (
                  <div className="right-info-text block-value d-flex justify-content-end text-danger fs-16">
                    Call For Updated <br /> Inventory
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex align-items-center block-controls w-100 mt-auto">
              {/* {catalog_item_code&& <div className="d-flex mr-auto mb-2">For {catalog_item_code}</div>} */}
              <Button
                type="primary"
                title="SHIP ESTIMATE"
                size="middle"
                onClick={onViewEstimateClick}
              >
                VIEW SHIP ESTIMATE
              </Button>
            </div>
            {isObjectNotEmpty(info) && !isMadeToOrder && (
              <div className="text-success mt-2 mb-n2 info w-100">{info}</div>
            )}
          </div>
        </div>
      </div>
    </Col>
  );
};
const CheckInventoryListComponent = () => {
  const loadingSelector = createLoadingSelector([CHECK_INVENTORY]);
  const loadingSelectorSuggestion = createLoadingSelector([
    INVENTORY_ITEM_SEARCH,
  ]);
  const inventoryStatus = useSelector((state) => state.checkInventory);
  const isFetchingData = useSelector((state) => loadingSelector(state));
  const isFetchingDataSuggestion = useSelector((state) =>
    loadingSelectorSuggestion(state)
  );

  const dispatch = useDispatch();
  const location = useLocation();

  const options = useSelector((state) =>
    searchResult(state.inventoryItemSearchResult)
  );

  const [state, setState] = useState({
    term: isObjectNotEmpty(location.state) ? location.state.term : "",
    gridView: true,
  });
  const onSelect = useCallback(
    (value) => {
      setState({ ...state, term: value });
      dispatch(resetStoreState(INVENTORY_ITEM_SEARCH_RESET));
      dispatch(resetStoreState(CHECK_INVENTORY_RESET));

      dispatch(checkInventory(value));
    },
    [dispatch, state]
  );
  useEffect(() => {
    setScrollIn();
    if (isObjectNotEmpty(location.state)) {
      if (state.term !== "") {
        onSelect(location.state.term);
        history.replace({ pathname: componentPaths.CHECK_INVENTORY, term: "" });
      }
    }
    return () => {
      dispatch(resetStoreState(INVENTORY_ITEM_SEARCH_RESET));
    };
  }, [dispatch, location.state, onSelect, state.term]);
  const viewToggleTrue = () => {
    setState({ ...state, gridView: true });
  };

  useEffect(() => {
    console.log(inventoryStatus);
  }, [inventoryStatus]);

  const viewToggleFalse = () => {
    setState({ ...state, gridView: false });
  };

  const delayedQuery = useCallback(
    debounce(
      (value) =>
        dispatch(
          inventoryItemSearch({
            query: value,
            search_type: "inventory",
          })
        ),
      SUGGESTION_API_DEBOUNCE_TIME
    ),
    []
  );

  const handleSearch = (value) => {
    dispatch(resetStoreState(INVENTORY_ITEM_SEARCH_RESET));
    setState({ ...state, term: value });
    if (isObjectNotEmpty(value) && value.length > 2) {
      delayedQuery(value);
    }
  };

  const handleMatchedSearch = (value, event) => {
    setState({ ...state, term: value });
    if (isObjectNotEmpty(value)) {
      onSelect(value);
    }
  };

  const onViewEstimateClick = (record) => {
    history.push({
      pathname: componentPaths.SHIP_UPS_ESTIMATE,
      state: { item_code: record.catalog_item_code },
    });
  };

  return (
    <Spin spinning={isFetchingData} size="large">
      <div className="common-container">
        <Row className="pt-3">
          <Col xs="12">
            <div className="page-view-panel">
              <MediaQuery minDeviceWidth={992}>
                <span className="page-name">Check Inventory</span>
              </MediaQuery>
              <MediaQuery maxDeviceWidth={991}>
                <Link to="/dashboard" className="common-back-btn">
                  <SvgIcon name="caretLeft" viewbox="0 0 451.846 451.847" />
                  <span>Check Inventory</span>
                </Link>
              </MediaQuery>
              <Alert
                message={
                  <div className="alert-inner">
                    <div className="text-row">
                      <span></span> Please note Available Quantity Inventory is
                      a live feed and is subject to change without notice{" "}
                    </div>
                    <div className="text-row">
                      <span></span> Stock arrival dates are estimated and not
                      guaranteed for arrival
                    </div>
                  </div>
                }
                type="warning"
                className="notice-alert-chechinventory web-alert"
              />
              <div className="view-switch">
                <button
                  type="button"
                  className={state.gridView ? "active" : ""}
                  onClick={viewToggleTrue}
                >
                  <SvgIcon name="grid-view" viewbox="0 0 12 12" />
                </button>
                <button
                  type="button"
                  className={!state.gridView ? "active" : ""}
                  onClick={viewToggleFalse}
                >
                  <SvgIcon name="list-view" width="20px" viewbox="0 0 15 12" />
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Alert
          message={
            <div className="alert-inner">
              <div className="text-row">
                <span></span> Please note Available Quantity Inventory is a live
                feed and is subject to change without notice{" "}
              </div>
              <div className="text-row">
                <span></span> Stock arrival dates are estimated and not
                guaranteed for arrival
              </div>
            </div>
          }
          type="warning"
          className="notice-alert-chechinventory mobile-alert"
        />
        {/* <form onSubmit={onSubmit}> */}
        <div className="bluebox">
          <label>Enter item # and check</label>

          <AutoComplete
            dropdownMatchSelectWidth={"300px"}
            style={{
              width: "100%",
            }}
            value={state.term}
            options={options}
            onSelect={onSelect}
            onSearch={handleSearch}
            notFoundContent={isFetchingDataSuggestion ? <Spin /> : ""}
          >
            <Input.Search
              placeholder="Input first 3 characters"
              onSearch={handleMatchedSearch}
            />
          </AutoComplete>
        </div>
        {/* </form> */}
        <Row className="pt-3 pt-lg-4 gutter-sm mt-2">
          {state.gridView ? (
            <React.Fragment>
              {inventoryStatus.map((item, i) => {
                if (!isObjectEmpty(item.store_code)) {
                  return (
                    <InventoryBlock
                      key={i}
                      img={baseServerAdress.IMAGE + item.item_image_url}
                      info={
                        item.arriving_qty > 0 &&
                        isObjectNotEmpty(item.estimated_arrival_date)
                          ? item.arriving_qty +
                            " items will be added by " +
                            item.estimated_arrival_date
                          : ""
                      }
                      item={item.store_code}
                      availableQty={parseInt(item.available_qty) || 0}
                      arrivalQty={parseInt(item.arriving_qty) || 0}
                      onViewEstimateClick={() => onViewEstimateClick(item)}
                      catalog_item_code={item.catalog_item_code}
                      itemObject={item}
                    />
                  );
                } else {
                  return <div></div>;
                }
              })}
            </React.Fragment>
          ) : (
            <Col xs="12">
              <div className="table-wrapper pb-4">
                <Table
                  tableLayout="fixed"
                  className="common-table"
                  loading={isFetchingData}
                  dataSource={inventoryStatus}
                  pagination={false}
                  scroll={{ x: 768, y: "calc(100vh - 285px)" }}
                  rowKey="store_code"
                >
                  <Column
                    title="Item #"
                    dataIndex="store_code"
                    key="store_code"
                    render={(store_code) => <b>{store_code}</b>}
                    width="180px"
                    sorter={(a, b) => a.store_code.localeCompare(b.store_code)}
                  />
                  <Column
                    title="Available Qty"
                    dataIndex="available_qty"
                    key="available_qty"
                    render={(available_qty) => parseInt(available_qty)}
                    width="120px"
                    sorter={(a, b) => a.available_qty - b.available_qty}
                  />
                  <Column
                    title="Info"
                    dataIndex="arriving_qty"
                    key="arriving_qty"
                    render={(arriving_qty, record) =>
                      arriving_qty > 0 &&
                      isObjectNotEmpty(record.estimated_arrival_date) && (
                        <span>
                          {record.arriving_qty} items will be added by{" "}
                          {record.estimated_arrival_date}
                        </span>
                      )
                    }
                    width="125px"
                  />

                  <Column
                    title={false}
                    dataIndex="action"
                    className="text-right"
                    key="action"
                    width="200px"
                    render={(catalog_item_code, record) => (
                      <div className="align-items-center d-flex justify-content-end">
                        {/* {record.catalog_item_code&&<span className="mr-3"> For {record.catalog_item_code}</span> } */}

                        <Button
                          type="primary"
                          title="View Ship Estimates"
                          onClick={() => onViewEstimateClick(record)}
                        >
                          VIEW SHIP ESTIMATE
                        </Button>
                      </div>
                    )}
                  />
                </Table>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </Spin>
  );
};
export default CheckInventoryListComponent;
