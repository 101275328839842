import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { SvgIcon, ImageLoader } from "../../../../components/common";
import { Button, Modal, Input, Form } from "antd";
import MediaQuery from "react-responsive";
import { Row, Col } from "../../../../components/common/grid";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPreProductionApprovals,
  createLoadingSelector,
  acceptPreProduction,
  resetStoreState,
  rejectPreProduction,
} from "../../../../redux/actions";

import {
  PRE_PRODUCTION_APPROVALS,
  ACCEPT_REJECT_PREPRODUCTION_APPROVAL_RESET,
  ACCEPT_PREPRODUCTION_APPROVAL,
  REJECT_PREPRODUCTION_APPROVAL,
} from "../../../../redux/actions/type";
import { Spin } from "antd";

// Style
import "./pre-production-approval-list.scss";
import { baseServerAdress } from "../../../../constants/server-address-constants";
import {
  isloggedInUserViewOnly,
  isObjectEmpty,
  isObjectNotEmpty,
  setScrollIn,
} from "../../../../common/common-methods";
import { showAlert } from "../../../../common/alert-messages";

const { confirm } = Modal;
const { TextArea } = Input;

const ProductBlock = ({
  imgPath,
  orderNumber,
  customerPO,
  orderDate,
  orderStatus,
  shipDate,
  onImagePreviewClick,
  shippingStatus,
  onApproveClick,
  onRejectClick,
}) => {
  return (
    <Col
      xs="12"
      sm="6"
      md="6"
      lg="4"
      xl="3"
      className="preproduction-aproval-product"
    >
      <div className="border-box mb-20">
        <div className="pre-production-block">
          <div
            className={
              "pre-production-img border-box" +
              (imgPath ? "" : " p-0 image-less")
            }
          >
            {imgPath ? (
              <React.Fragment>
                <ImageLoader
                  src={imgPath}
                  alt={orderNumber}
                  title={orderNumber}
                  onClick={onImagePreviewClick}
                />
                <SvgIcon
                  name="zoom-in"
                  fill="#fff"
                  width="23px"
                  viewbox="0 0 16 15.2"
                />
              </React.Fragment>
            ) : (
              <div className="no-img">
                <SvgIcon name="picture" viewbox="0 0 28 28" />
                <span>No Image Available</span>
              </div>
            )}
          </div>
        </div>
        <div className="pre-production-block-info">
          <Row>
            <Col xs="6">
              <div className="block-label">Order #</div>
              <div className="block-value">{orderNumber}</div>
            </Col>
            <Col xs="6">
              <div className="block-label">Order Date</div>
              <div className="block-value">{orderDate}</div>
            </Col>
            <Col xs="6">
              <div className="block-label">Customer PO #</div>
              <div className="block-value">{customerPO}</div>
            </Col>
            <Col xs="6">
              <div className="block-label">Order Status</div>
              <div
                className="block-value"
                style={{ textTransform: "capitalize" }}
              >
                {orderStatus.toLowerCase()}
              </div>
            </Col>
            <Col xs="6">
              <div className="block-label">Ship Date</div>
              <div className="block-value">{shipDate}</div>
            </Col>
            <Col xs="6">
              <div className="block-label">Shipping Status</div>
              <div
                className="block-value"
                style={{ textTransform: "capitalize" }}
              >
                {shippingStatus.toLowerCase()}
              </div>
            </Col>
          </Row>
          <div className="block-controls">
            <Row>
              <Col xs="6" className="pr-2">
                <Button
                  onClick={onRejectClick}
                  title="Reject"
                  block
                  danger
                  disabled={isloggedInUserViewOnly()}
                >
                  <b>Reject</b>
                </Button>
              </Col>
              <Col xs="6" className="pl-2">
                <Button
                  className="btn-success"
                  title="Approve"
                  onClick={onApproveClick}
                  block
                  disabled={isloggedInUserViewOnly()}
                >
                  <b>Approve</b>
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Col>
  );
};

const PreProductionListComponent = () => {
  const loadingSelector = createLoadingSelector([
    PRE_PRODUCTION_APPROVALS,
    ACCEPT_PREPRODUCTION_APPROVAL,
    REJECT_PREPRODUCTION_APPROVAL,
  ]);

  const acceptRejectSuccessMessage = useSelector(
    (state) => state.acceptRejectPreProductionMessage
  );
  const orders = useSelector((state) => state.preProductionApprovals);
  const isFetchingData = useSelector((state) => loadingSelector(state));

  const dispatch = useDispatch();

  const [state, setState] = useState({
    imagePreview: false,
    previewImageSrc: null,
    preproduction_reject_reason: "",
  });

  useEffect(() => {
    setScrollIn();
    dispatch(fetchPreProductionApprovals());
  }, [dispatch]);
  useEffect(() => {
    if (!isObjectEmpty(acceptRejectSuccessMessage)) {
      showAlert.success(acceptRejectSuccessMessage);
      resetStoreState(ACCEPT_REJECT_PREPRODUCTION_APPROVAL_RESET);
      dispatch(fetchPreProductionApprovals());
    }
  }, [acceptRejectSuccessMessage, dispatch]);

  const onImagePreviewClick = (image) => {
    setState({
      previewImageSrc: image,
      imagePreview: true,
    });
  };
  const onImagePreviewClose = () => {
    setState({
      previewImageSrc: null,
      imagePreview: false,
    });
  };
  const onApproveClick = (order) => {
    const details = {
      order_id: order.order_id,
      trans_no: order.trans_no,
      id: order.id,
      item_code: order.catalog_item_code,
      qty: order.item_qty,
    };
    dispatch(acceptPreProduction(details));
  };
  const formRef = React.createRef();
  const textAreaInput = React.createRef();
  const onRejectClick = (order) => {
    const details = {
      order_id: order.order_id,
      trans_no: order.trans_no,
      id: order.id,
      item_code: order.catalog_item_code,
      qty: order.item_qty,
      order_status: order.order_status,
      stage_code: "AI-PICK",
      customer_preproduction_reject_reason: state.preproduction_reject_reason,
    };

    confirm({
      destroyOnClose: true,
      className: "custom-confirm",
      okText: "Yes",
      cancelText: "No",
      title: "Are you sure, you want to reject these items?",
      content: (
        <Form ref={formRef} name="reject_form">
          <Form.Item
            name="reason"
            className="mt-3 mb-2"
            rules={[{ required: true, message: "Please input your reason!" }]}
          >
            <TextArea
              ref={textAreaInput}
              rows={3}
              // value={this.state.preproduction_reject_reason}
              onChange={(e) =>
                setState({ preproduction_reject_reason: e.target.value })
              }
            />
          </Form.Item>
        </Form>
      ),
      icon: (
        <span className="anticon anticon-exclamation-circle">
          <SvgIcon
            width="24px"
            fill="#ff0050"
            name="cancel"
            viewbox="0 0 489 489"
          />
        </span>
      ),
      okButtonProps: {
        disabled: false,
      },
      onOk: (close) => {
        formRef.current.submit();
        if (
          isObjectNotEmpty(textAreaInput.current.resizableTextArea.props.value)
        ) {
          dispatch(rejectPreProduction(details));
          Modal.destroyAll();
        }
      },
      onCancel() {},
    });
  };

  return (
    <Spin spinning={isFetchingData} size="large">
      <React.Fragment>
        <div className="common-container">
          <Row className="pt-3">
            <Col xs="12">
              <div className="page-view-panel d-flex flex-column">
                <MediaQuery maxDeviceWidth={991}>
                  <Link
                    to="/dashboard"
                    className="common-back-btn mt-3 mr-auto"
                  >
                    <SvgIcon name="caretLeft" viewbox="0 0 451.846 451.847" />
                    <span>Pre-Production Approval</span>
                  </Link>
                </MediaQuery>
                <MediaQuery minDeviceWidth={992}>
                  <span className="page-name mr-auto">
                    Pre-Production Approval
                  </span>
                </MediaQuery>
                <Row className="pt-3 pt-lg-4 gutter-sm preproduction-aproval-row">
                  {orders.map((order, i) => {
                    return (
                      <ProductBlock
                        key={i}
                        imgPath={
                          baseServerAdress.IMAGE +
                          order.product_picture_file_path
                        }
                        productCode={order.catalog_item_code}
                        orderNumber={order.trans_no}
                        orderDate={order.trans_date}
                        customerPO={order.ext_ref_no}
                        shippingStatus={order.shipping_status}
                        shipDate={order.ship_date}
                        orderStatus={order.order_status}
                        onApproveClick={() => {
                          onApproveClick(order);
                        }}
                        onRejectClick={() => onRejectClick(order)}
                        onImagePreviewClick={() => {
                          onImagePreviewClick(
                            baseServerAdress.IMAGE +
                              order.product_picture_file_path
                          );
                        }}
                      />
                    );
                  })}
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        {/* View order image modal start */}
        <Modal
          destroyOnClose={true}
          centered={true}
          bodyStyle={{ padding: "1rem" }}
          closeIcon={
            <SvgIcon
              name="close"
              fill="#dc0100"
              width="13px"
              viewbox="0 0 298.667 298.667"
            />
          }
          className="full-screen-xs-modal footer-less"
          closable={true}
          onCancel={onImagePreviewClose}
          width={"auto"}
          title="Product Image"
          visible={state.imagePreview}
          footer={null}
        >
          <div className="view-order-content">
            <img src={state.previewImageSrc} alt="Product" title="Product" />
          </div>
        </Modal>
        {/* View order image modal end */}
      </React.Fragment>
    </Spin>
  );
};

export default PreProductionListComponent;
