/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Result, Spin } from "antd";
import { SvgIcon } from "../../../components/common/index";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotPassword,
  createErrorMessageSelector,
  createLoadingSelector,
  resetStoreState,
} from "../../../redux/actions";

import {
  FORGOT_PASSWORD_RESET,
  FORGOT_PASSWORD,
  ERROR_MESSAGES_RESET_ALL,
} from "../../../redux/actions/type";

// Style
import "./forgot-password.scss";
import { isObjectEmpty } from "../../../common/common-methods";

const ForgotPasswordComponent = ({ showByDefault }) => {
  const loadingSelector = createLoadingSelector([FORGOT_PASSWORD]);
  const errorMessageSelector = createErrorMessageSelector([FORGOT_PASSWORD]);
  const dispatch = useDispatch();

  const successMessage = useSelector((state) => state.forgotPassword);
  const errorMessage = useSelector((state) => errorMessageSelector(state));
  const isFetchingData = useSelector((state) => loadingSelector(state));

  useEffect(() => {
    console.log(errorMessage);
    setLoading(isFetchingData);
    setResult(!isObjectEmpty(successMessage) || !isObjectEmpty(errorMessage));
  }, [errorMessage, isFetchingData, successMessage]);

  const [visible, setVisible] = useState(showByDefault);
  const [result, setResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const onCreate = (values) => {
    dispatch(forgotPassword(values));
  };

  return (
    <React.Fragment>
      <span
        className="btn-link fs-13"
        onClick={() => {
          setVisible(true);
        }}
      >
        <b>Forgot Password</b>
      </span>

      <Modal
        destroyOnClose={true}
        centered={true}
        bodyStyle={{ padding: "1rem" }}
        closeIcon={
          <SvgIcon
            name="close"
            fill="#dc0100"
            width="13px"
            viewbox="0 0 298.667 298.667"
          />
        }
        closable={true}
        visible={visible}
        title="Forgot Password"
        onCancel={() => {
          form.resetFields();
          setVisible(false);
          dispatch(resetStoreState(FORGOT_PASSWORD_RESET));
          dispatch(resetStoreState(ERROR_MESSAGES_RESET_ALL));
        }}
        footer={[
          <Button
            style={{ display: result ? "inline-flex" : "none" }}
            key="ok"
            type="primary"
            onClick={() => {
              setVisible(false);
              setResult(false);
              dispatch(resetStoreState(FORGOT_PASSWORD_RESET));
              dispatch(resetStoreState(ERROR_MESSAGES_RESET_ALL));
            }}
          >
            Ok
          </Button>,
          <Button
            style={{ display: !result ? "inline-flex" : "none" }}
            key="cancel"
            onClick={() => {
              setVisible(false);
              form.resetFields();
            }}
          >
            Cancel
          </Button>,
          <Button
            style={{ display: !result ? "inline-flex" : "none" }}
            key="submit"
            type="primary"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  onCreate(values);
                })
                .catch((info) => {
                  console.log("Validate Failed:", info);
                });
            }}
          >
            Submit
          </Button>,
        ]}
      >
        {!result ? (
          <Spin spinning={loading} size="large">
            <Form
              form={form}
              layout="vertical"
              name="forgot_password"
              initialValues={{ modifier: "public" }}
            >
              <Form.Item
                name="login"
                label="User ID \ Email ID"
                className="custom-label mb-0"
                rules={[
                  {
                    required: true,
                    message: "Please enter your User ID or Email ID!",
                  },
                ]}
              >
                <Input type="email" />
              </Form.Item>
            </Form>
          </Spin>
        ) : (
          <Result
            className="p-0 text-dark"
            status={errorMessage.length ? "error" : "success"}
            subTitle={errorMessage.length ? errorMessage : successMessage}
          />
        )}
      </Modal>
    </React.Fragment>
  );
};
export default ForgotPasswordComponent;
