export const shipProiderConstants = {
  SHIPPING_PROVIDER_CUSTOMER_PICKUP: "CUSTOMER_PICKUP",
  SHIPPING_PROVIDER_TRUCKING: "TRUCKING",
  SHIPPING_PROVIDER_OCEAN: "OCEAN",
  SHIPPING_PROVIDER_MANUAL: "MANUAL",

  SHIPPING_PROVIDER_UPS: "UPS",
  SHIPPING_PROVIDER_USPS: "USPS",
  SHIPPING_PROVIDER_FEDEX: "FEDEX",
  SHIPPING_PROVIDER_ENDICIA: "ENDICIA",
};
