import {
  getBaseUrl,
  getImageBaseUrl,
  getSchemaName,
  isLiveMode,
} from "../common/common-methods";

export const baseServerAdress = isLiveMode()
  ? {
      IMAGE: `https://${getImageBaseUrl()}/`,
      API: `https://${getBaseUrl()}/api/v1`,
      REPORTS: `https://${getBaseUrl()}/`,
      EPROOF: `https://${getImageBaseUrl()}/proofs/${getSchemaName()}/`,
      MANUAL_EPROOF: `https://${getImageBaseUrl()}/attachments/${getSchemaName()}/`,
      TRACKING_REPORT: `https://${getImageBaseUrl()}/reports/export/${getSchemaName()}/`,
      RESALE_CERTIFICATE: `https://${getBaseUrl()}/resale_certificates/${getSchemaName()}/`,
    }
  : {
      IMAGE: `http://${getImageBaseUrl()}/`,
      API: `http://${getBaseUrl()}/api/v1`,
      REPORTS: `http://${getBaseUrl()}/`,
      EPROOF: `http://${getImageBaseUrl()}/proofs/${getSchemaName()}/`,
      MANUAL_EPROOF: `http://${getImageBaseUrl()}/attachments/${getSchemaName()}/`,
      TRACKING_REPORT: `https://${getImageBaseUrl()}/reports/export/${getSchemaName()}/`,
      RESALE_CERTIFICATE: `http://${getBaseUrl()}/resale_certificates/${getSchemaName()}/`,
    };
