import React from "react";
import { Link } from "react-router-dom";

//Footer Style
import "./Footer.scss";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer-inner">
          <div className="footer-bottom">
            <span>&copy; 2021 Tekweld. All rights reserved.</span>
            <ul>
              <li>
                <Link to="/">PRIVACY POLICY</Link>
              </li>
              <li>
                <Link to="/">TERMS OF USE</Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
