import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../../components/common";
import { Row, Col } from "../../../components/common/grid";
import {
  AutoComplete,
  Input,
  Table,
  Select,
  Radio,
  Spin,
  Checkbox,
} from "antd";
import "./role-and-permissions.scss";
import {
  createLoadingSelector,
  updateSupervisorStatus,
  fetchAllUsersList,
  updateUserRole,
} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_ALL_USERS,
  UPDATE_SUPERVISOR_STATUS,
  UPDATE_USER_ROLE,
} from "../../../redux/actions/type";
import { isObjectNotEmpty } from "../../../common/common-methods";
import { componentPaths } from "../../../constants/path-constants";
import { userActiveStatus, userSortKey } from "../../../constants";

const { Column } = Table;
const { Option } = Select;

const ChangeRole = () => {
  const [, setValue] = React.useState(1);

  const [sortKey, setSortKey] = React.useState(userSortKey.NAME);
  const [searchText, setSearchText] = React.useState("");

  const onChange = (e, user) => {
    user.customer_role = e.target.value;
    setValue(e.target.value);
    dispatch(
      updateUserRole({ user_id: user.id, customer_role: e.target.value })
    );
  };

  const onChangeUserStatus = (e, user) => {
    user.trans_flag = e.target.checked
      ? userActiveStatus.ACTIVE
      : userActiveStatus.DEACTIVE;
    setValue(e.target.checked);

    let trans_flag = e.target.checked
      ? userActiveStatus.ACTIVE
      : userActiveStatus.DEACTIVE;
    let reqObj = { user_id: user.id, trans_flag: trans_flag };
    dispatch(updateSupervisorStatus(reqObj));
  };

  const loadingSelector = createLoadingSelector([
    GET_ALL_USERS,
    UPDATE_USER_ROLE,
    UPDATE_SUPERVISOR_STATUS,
  ]);
  const dispatch = useDispatch();

  const allUsersList = useSelector((state) => state.allUsersList);
  const isFetchingData = useSelector((state) => loadingSelector(state));

  let filteredUserList = isObjectNotEmpty(searchText)
    ? allUsersList
        .sort((a, b) => a[sortKey].localeCompare(b[sortKey]))
        .filter(
          (user) =>
            user.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
            user.last_name.toLowerCase().includes(searchText.toLowerCase()) ||
            user.login.toLowerCase().includes(searchText.toLowerCase())
          //    ||
          //   user.user_cd.toLowerCase().includes(searchText.toLowerCase())
        )
    : allUsersList
        .sort((a, b) => a[sortKey].localeCompare(b[sortKey]))
        .filter(() => true);

  useEffect(() => {
    dispatch(fetchAllUsersList());
  }, [dispatch]);

  return (
    <>
      <Spin spinning={isFetchingData} size="large">
        <div className="common-container rolepermission-section">
          <Row>
            <Col>
              <ul className="rap-tabs">
                <li>
                  <Link to={componentPaths.CREATE_USER}>
                    <SvgIcon name="create-user" viewbox="0 0 40 36" />
                    Create User
                  </Link>
                </li>
                <li>
                  <Link
                    to={componentPaths.CHANGE_USER_ROLE}
                    className="selected"
                  >
                    <SvgIcon name="change-user" viewbox="0 0 43 34" />
                    Change Role
                  </Link>
                </li>
                <li>
                  <Link to={componentPaths.MANAGE_SUPERVISIOR}>
                    <SvgIcon name="supervisor-icon" viewbox="0 0 25.765 36" />
                    Manage Supervisor
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="role-search-bar mt-2">
                <div className="search-rep">
                  <AutoComplete
                    dropdownMatchSelectWidth={"100%"}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Input.Search
                      value={searchText}
                      onChange={(event) => {
                        setSearchText(event.target.value);
                      }}
                      placeholder="Search for user"
                    />
                  </AutoComplete>
                </div>
                <div className="dropdown-sort">
                  Sort by
                  <Select
                    value={sortKey}
                    bordered={false}
                    dropdownMatchSelectWidth={true}
                    dropdownClassName="sortDropdownof-adminrole"
                    onChange={(value) => {
                      setSortKey(value);
                    }}
                  >
                    <Option value={userSortKey.NAME}>Name</Option>
                    <Option value={userSortKey.ROLE}>Role</Option>
                    <Option value={userSortKey.STATUS}>Status</Option>
                  </Select>
                </div>
              </div>

              <div>
                <div className="table-wrapper admin-role-wrapper">
                  <Table
                    tableLayout="fixed"
                    className="common-table"
                    dataSource={filteredUserList}
                    pagination={false}
                    scroll={{ y: "calc(100vh - 380px)" }}
                  >
                    <Column
                      title="User's Name"
                      dataIndex="username"
                      key="username"
                      width="120px"
                      render={(username, order) => {
                        return order.first_name + " " + order.last_name;
                      }}
                    />
                    <Column
                      title="Login ID"
                      dataIndex="login"
                      key="login"
                      width="145px"
                    />
                    <Column
                      title="Role"
                      dataIndex="id"
                      key="id"
                      width="220px"
                      align="center"
                      className="role-change-colum"
                      render={(id, user) => (
                        <Radio.Group
                          value={user.customer_role}
                          onChange={(e) => onChange(e, user)}
                        >
                          <Radio value={"A"}>Admin</Radio>
                          <Radio value={"B"}>Supervisor</Radio>
                          <Radio value={"C"}>User</Radio>
                        </Radio.Group>
                      )}
                    />
                    <Column
                      title="Active"
                      dataIndex="trans_flag"
                      key="trans_flag"
                      width="50px"
                      align="center"
                      render={(status, user) => (
                        <Checkbox
                          checked={status === userActiveStatus.ACTIVE}
                          onChange={(event) => {
                            onChangeUserStatus(event, user);
                          }}
                        />
                      )}
                    />
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};

export default ChangeRole;
