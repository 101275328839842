import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { defaultLayout, authLayout } from "./layouts";

// Route Views
import DashboardComponent from "./containers/homePage/dashboard/dashboard.js";
import EstimatesListComponent from "./containers/homePage/estimates-list/estimates-list.js";
import { LoginView } from "./containers/loginPage";
import LoginBySaltView from "./containers/loginPage/login-by-salt/login-by-salt";

import RegistrationView from "./containers/loginPage/registration/registration";
import OrderDetailsView from "./containers/homePage/order-details/order-details";
import OrderListView from "./containers/homePage/order-list/order-list.js";
import MissingArtListComponent from "./containers/homePage/missing-art/missing-art-list/missing-art-list";
import EProofListComponent from "./containers/homePage/e-proof/e-proof-list/e-proof-list";
import EProofDetailsComponent from "./containers/homePage/e-proof/e-proof-detail/eProof-details";
import PayBillsList from "./containers/homePage/pay-bills/pay-bills-list/pay-bills-list";
import { componentPaths } from "./constants/path-constants";
import StitchApprovalListComponent from "./containers/homePage/stitch-approval/stitch-approval-list/stitch-approval-list";
import preProductionApprovalList from "./containers/homePage/pre-production-approval/pre-production-approval-list/pre-production-approval-list";
import CheckInventoryListComponent from "./containers/homePage/check-inventory/check-inventory-list.js";
import ShipUpsEstimateListComponent from "./containers/homePage/ship-ups-estimate-list/ship-ups-estimate-list";
import UserActivationComponnet from "./containers/loginPage/user-activation/user-activation";
// import UserRoleAdminView from "./containers/homePage/user-role-admin/user-role-admin";
import UserRoleSupervisorsView from "./containers/homePage/user-role-supervisors/user-role-supervisors";
import CreateUser from "./containers/homePage/roles-and-permissions/create-user";
import ChangeRole from "./containers/homePage/roles-and-permissions/change-role";
import ManageSupervisor from "./containers/homePage/roles-and-permissions/manage-supervisor";
import CreatenewPasswordView from "./containers/loginPage/createnew-password";

export const publicRoutes = [
  {
    path: "/",
    exact: true,
    layout: defaultLayout,
    component: () => <Redirect to={componentPaths.login} />,
  },
  {
    path: componentPaths.login_with_forgot_password,
    exact: true,
    layout: authLayout,
    component: LoginView,
  },
  {
    path: componentPaths.USER_ACTIVATION,
    layout: authLayout,
    component: UserActivationComponnet,
  },
  {
    path: componentPaths.registration,
    layout: authLayout,
    component: RegistrationView,
  },
  {
    path: componentPaths.login,
    // exact: true,
    layout: authLayout,
    component: LoginView,
  },
  {
    path: componentPaths.LOGIN_BY_SALT,
    // exact: true,
    layout: authLayout,
    component: LoginBySaltView,
  },
  {
    path: componentPaths.CREATE_RESET_PASSWORD,
    layout: authLayout,
    component: CreatenewPasswordView,
  },
];

export const privateRoutes = [
  {
    path: componentPaths.PRE_PRODUCTION_APPROVALS,
    layout: defaultLayout,
    component: preProductionApprovalList,
  },
  {
    path: componentPaths.STITCH_APPROVALS,
    layout: defaultLayout,
    component: StitchApprovalListComponent,
  },
  {
    path: componentPaths.missingarts,
    layout: defaultLayout,
    component: MissingArtListComponent,
  },
  {
    path: componentPaths.eproofs,
    layout: defaultLayout,
    component: EProofListComponent,
  },
  {
    path: componentPaths.eproofDetail,
    layout: defaultLayout,
    component: EProofDetailsComponent,
  },
  {
    path: componentPaths.estimates,
    layout: defaultLayout,
    component: EstimatesListComponent,
  },
  {
    path: componentPaths.paybills,
    layout: defaultLayout,
    component: PayBillsList,
  },
  {
    path: componentPaths.dashboard,
    layout: defaultLayout,
    component: DashboardComponent,
  },
  {
    path: componentPaths.orderlist,
    layout: defaultLayout,
    component: OrderListView,
  },
  {
    path: componentPaths.orderdetails(),
    layout: defaultLayout,
    component: OrderDetailsView,
  },
  {
    path: componentPaths.CHECK_INVENTORY,
    layout: defaultLayout,
    component: CheckInventoryListComponent,
  },
  {
    path: componentPaths.SHIP_UPS_ESTIMATE,
    layout: defaultLayout,
    component: ShipUpsEstimateListComponent,
  },
  // {
  //   path: componentPaths.USER_ROLE_ADMIN,
  //   layout: defaultLayout,
  //   component: UserRoleAdminView,
  // },
  {
    path: componentPaths.USER_ROLE_SUPERVISORS,
    layout: defaultLayout,
    component: UserRoleSupervisorsView,
  },

  {
    path: componentPaths.CHANGE_USER_ROLE,
    layout: defaultLayout,
    component: ChangeRole,
  },
  {
    path: componentPaths.CREATE_USER,
    layout: defaultLayout,
    component: CreateUser,
  },
  {
    path: componentPaths.MANAGE_SUPERVISIOR,
    layout: defaultLayout,
    component: ManageSupervisor,
  },
];
