import React from 'react';

const AuthLayout = ({children}) => (
  <div className="authLayout">
    {children}
  </div>
);


export default AuthLayout;
