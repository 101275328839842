/* eslint-disable max-len */
import React from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, Spin, Tooltip, Modal } from "antd";
import { SvgIcon } from "../../../components/common";
import { componentPaths } from "../../../constants/path-constants";
import { socialConnectAcoounts } from "../../../constants/index";

import ForgotPasswordComponent from "../forgot-password/forgot-password";
import LoginFormComponent from "../loginForm/loginForm";
import RegistrationFormComponent from "../registrationForm/registrationForm";
// import REGISTRATION from "../registration/registration";

// Style
import "./login.scss";
import {
  loginAction,
  createLoadingSelector,
  resetLoginDataAndStore,
  resetStoreState,
  loginWithSocialConnect,
} from "../../../redux/actions/index";
import { connect } from "react-redux";
import {
  LOGIN,
  LOGIN_WITH_SOCIAL_CONNECT,
  SHOW_LOGIN_CONNECT_POPUP_RESET,
} from "../../../redux/actions/type";
import history from "../../../common/history";
import { isObjectNotEmpty } from "../../../common/common-methods";
import { NAVIGATION_REFERENCE } from "../../../constants";
import GoogleLogin from "react-google-login";
const queryString = require("query-string");

/**
 * The component with the Login Form
 */
class LoginView extends React.Component {
  constructor() {
    super();
    this.state = {
      isModalVisible: false,
      isShowingConnectRegistration: false,
      socialAccountDetails: { name: "", type: "", id: "" },
    };
  }
  /**
   * @param {*} values The values from the form
   */
  onFinish = (values) => {
    const searchQuery = queryString.parse(this.props.location.search);
    console.log(this.props.location.search);
    if (isObjectNotEmpty(searchQuery.redirecturl)) {
      this.props.setRedirectURL(searchQuery.redirecturl);
    }
    this.props.loginAction(values["email"], values["password"]);
  };

  /**
   * Default lifecycle method - componentDidUpdate
   * @param {*} nextProps The props to be passed from this component
   */

  /**
   * @return {*} The Login form
   */

  componentDidMount() {
    this.props.resetLoginDataAndStore();
  }

  componentWillUnmount() {}

  componentDidUpdate() {
    if (this.props.showLoginConnectPopup) {
      this.openSocialConnectPopup();

      this.props.resetStoreState(SHOW_LOGIN_CONNECT_POPUP_RESET);
    }

    if (isObjectNotEmpty(this.props.userDetails)) {
      var referer = componentPaths.dashboard;
      if (
        isObjectNotEmpty(this.props.location.state) &&
        isObjectNotEmpty(this.props.location.state[NAVIGATION_REFERENCE])
      ) {
        referer = this.props.location.state[NAVIGATION_REFERENCE];
      }
      history.replace(referer);
    }
  }

  openSocialConnectPopup = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  closeConnectPopup = () => {
    this.setState({
      isModalVisible: false,
      isShowingConnectRegistration: false,
      socialAccountDetails: { name: "", type: "", id: "" },
    });
  };

  toggleConnectLoginAndRegistration = () => {
    this.setState({
      isShowingConnectRegistration: !this.state.isShowingConnectRegistration,
    });
  };

  handleOk = () => {};

  handleCancel = () => {};

  responseGoogle = (response) => {
    if (isObjectNotEmpty(response) && isObjectNotEmpty(response.googleId)) {
      console.log(response.googleId);
      // call login by social connect API
      this.props.loginWithSocialConnect(
        socialConnectAcoounts.google.type,
        response.googleId
      );
      this.setState({
        socialAccountDetails: {
          ...socialConnectAcoounts.google,
          id: response.googleId,
        },
      });
    }
  };

  handleAmazonLogin = () => {
    const thisRef = this;

    let options = {};
    options.client_id =
      "amzn1.application-oa2-client.10d6e2b27d214a68a7719a93c409a756";
    options.scope = "profile";
    options.pkce = true;
    window.amazon.Login.authorize(options, function (response) {
      if (response.error) {
        console.log("oauth error " + response.error);
        return;
      }
      window.amazon.Login.retrieveToken(response.code, function (response) {
        if (response.error) {
          console.log("oauth error " + response.error);
          return;
        }
        window.amazon.Login.retrieveProfile(response.access_token, function (
          response
        ) {
          console.log("Your unique ID is " + response.profile.CustomerId);

          thisRef.props.loginWithSocialConnect(
            socialConnectAcoounts.amazon.type,
            response.profile.CustomerId
          );
          thisRef.setState({
            socialAccountDetails: {
              ...socialConnectAcoounts.amazon,
              id: response.profile.CustomerId,
            },
          });
        });
      });
    });
  };

  render() {
    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        <div className="login-wrapper">
          <div className="login-left">
            <div>
              <div className="center-content">
                <SvgIcon
                  name="logo"
                  className="login-logo"
                  fill="#ffffff"
                  viewbox="0 0 300.000000 50.000000"
                />
                <p className="mt-3 mb-0">Tekweld customer portal</p>
              </div>
            </div>
          </div>
          <div className="login-right">
            <div className="login-form-wrapper">
              <div className="login-form-inner">
                <Form
                  name="normal_login"
                  className="login-form"
                  onSubmit={this.handleSubmit}
                  initialValues={{ remember: false }}
                  onFinish={this.onFinish}
                >
                  <div>
                    <h1 className="form-title">Sign in</h1>
                  </div>
                  <Form.Item
                    name="email"
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your User ID or Email ID!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      prefix={
                        <SvgIcon name="mail" viewbox="0 0 13 11" width="15px" />
                      }
                      placeholder="User ID \ Email ID"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                    ]}
                  >
                    <Input.Password
                      size="large"
                      prefix={
                        <SvgIcon
                          name="download-alt"
                          viewbox="0 0 15 16"
                          width="15px"
                        />
                      }
                      placeholder="Password"
                    />
                  </Form.Item>
                  <div className="d-flex justify-content-between align-items-center">
                    <Form.Item
                      className="mb-0 d-flex align-items-start"
                      name="remember"
                      valuePropName="checked"
                    >
                      <Checkbox>Remember me</Checkbox>
                    </Form.Item>
                    <ForgotPasswordComponent
                      showByDefault={
                        isObjectNotEmpty(this.props.match.params.id) &&
                        this.props.match.params.id.toLowerCase() ===
                          "forgotpassword"
                      }
                    />
                  </div>
                  <Form.Item className="mb-1">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                      Log in
                    </Button>

                    <div className="text-center mt-3">
                      <p className="w-100 text-center">Or Login with</p>
                      {/* <Tooltip title="Login with Apple">
                        <Button
                          shape="circle"
                          size="large"
                          className="mr-4"
                          // onClick={() => this.openSocialConnectPopup("Apple")}
                          icon={
                            <SvgIcon
                              name="apple"
                              style={{ marginTop: "-2px" }}
                              viewbox="0 0 305 305"
                              width="25px"
                            />
                          }
                        />
                      </Tooltip> */}
                      <Tooltip title="Login with Google">
                        <GoogleLogin
                          clientId="649720307901-4hnhiaqhdde9d3ftighkkjae2rspvhiu.apps.googleusercontent.com"
                          render={(renderProps) => (
                            <Button
                              className="mr-4"
                              shape="circle"
                              size="large"
                              onClick={renderProps.onClick}
                              disabled={renderProps.disabled}
                              icon={
                                <SvgIcon
                                  name="google"
                                  viewbox="0 0 512 512"
                                  width="22px"
                                />
                              }
                            ></Button>
                          )}
                          buttonText="Login"
                          onSuccess={this.responseGoogle}
                          onFailure={this.responseGoogle}
                          cookiePolicy={"single_host_origin"}
                        />
                      </Tooltip>
                      <Tooltip title="Login with Amazon">
                        <Button
                          id="LoginWithAmazon"
                          shape="circle"
                          size="large"
                          title="Login with Amazon"
                          onClick={this.handleAmazonLogin}
                          icon={
                            <SvgIcon
                              name="amazon"
                              viewbox="0 0 100 100"
                              width="23px"
                            />
                          }
                        />
                      </Tooltip>
                    </div>
                    <div className="emailus-row">
                      Having trouble logging in? <br />
                      Email us at: 
                      <a href="mailto:PORTALSUPPORT@TEKWELD.COM">
                        PORTALSUPPORT@TEKWELD.COM
                      </a>
                    </div>
                    <div className="text-center mt-3">
                      Or{" "}
                      <Link to={componentPaths.registration}>
                        Register now!
                      </Link>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>

        <Modal
          destroyOnClose={true}
          centered={true}
          style={{ width: "100%", maxWidth: "500px" }}
          bodyStyle={{ padding: "1rem" }}
          closeIcon={
            <SvgIcon
              name="close"
              fill="#dc0100"
              width="13px"
              viewbox="0 0 298.667 298.667"
            />
          }
          className="full-screen-xs-modal footer-less"
          closable={true}
          title={`Linking your Tekweld and ${this.state.socialAccountDetails.name} accounts.`}
          width="500px"
          footer={null}
          loading={this.props.isFetchingData}
          visible={this.state.isModalVisible}
          onOk={this.handleOk}
          onCancel={this.closeConnectPopup}
        >
          <h3 style={{ padding: "0 10px" }} className="mb-4">
            Log in to your customer portal account or register for a new one.
          </h3>
          {this.state.isShowingConnectRegistration ? (
            <RegistrationFormComponent
              socialAccountDetails={this.state.socialAccountDetails}
              closeConnectPopup={this.closeConnectPopup.bind(this)}
              toggleConnectLoginAndRegistration={this.toggleConnectLoginAndRegistration.bind(
                this
              )}
            />
          ) : (
            <LoginFormComponent
              socialAccountDetails={this.state.socialAccountDetails}
              toggleConnectLoginAndRegistration={this.toggleConnectLoginAndRegistration.bind(
                this
              )}
            />
          )}
        </Modal>
      </Spin>
    );
  }
}

const loadingSelector = createLoadingSelector([
  LOGIN,
  LOGIN_WITH_SOCIAL_CONNECT,
]);

const mapStateToProps = (state) => ({
  userDetails: state.loginDetails,
  isFetchingData: loadingSelector(state),
  redirectURL: state.redirectURL,
  showLoginConnectPopup: state.showLoginConnectPopup,
});

export default connect(mapStateToProps, {
  loginAction,
  resetLoginDataAndStore,
  resetStoreState,
  loginWithSocialConnect,
})(LoginView);
