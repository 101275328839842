import React from "react";
import TextFileReader from "../containers/homePage/order-details/TextFileReader ";
import FileViewer from "react-file-viewer";

export const showFileViewer = (filePath) => {
  var fileExtension = filePath.substr(filePath.lastIndexOf(".") + 1);

  switch (fileExtension) {
    case "txt":
      return <TextFileReader txt={filePath} />;
    case "png":
    case "jpeg":
    case "jpg":
    case "gif":
    case "bmp": //image
      return (
        <FileViewer fileType={fileExtension} filePath={filePath} onError="" />
      );
    case "pdf":
    case "csv":
    case "xslx":
    case "docx": //file
      return (
        <FileViewer
          fileType={fileExtension}
          filePath={filePath}
          onError={onError}
        />
      );

    default:
      return (
        <FileViewer fileType={fileExtension} filePath={filePath} onError="" />
      );
  }
};

const onError = (e) => {
  alert(e);
};
