import { message, Modal } from "antd";
import confirm from "antd/lib/modal/confirm";

export const showAlert = {
  success: (string, duration = 5) => {
    message.success(string, duration);
  },
  error: (string, duration = 10) => {
    message.error(string, duration);
  },
  confirm: (string, title = "Success") => {
    confirm({
      title: title,
      content: string,
      cancelButtonProps: { hidden: true },
    });
  },
};

export const showFileNameError = () => {
  Modal.error({
    title: "Incorrect File Name!",
    content:
      "Filename cannot contain special characters i.e. [ ] ; | % & ~ @ ' , { } etc. \nPlease rename file before upload.",
  });
};
