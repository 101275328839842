import React from "react";
import { Button, Modal, Form, Input, Select, Spin, message } from "antd";
import { SvgIcon } from "../common";
import { Col, Row } from "../common/grid";

import {
  resetStoreState,
  saveContactDetail,
  updateContactDetail,
  fetchCountries,
  fetchStates,
  createLoadingSelector,
  fetchJobTitle,
  fetchContactList,
} from "../../redux/actions";

import {
  CONTACT_DETAIL,
  FETCH_COUNTRIES,
  FETCH_STATES,
  CONTACT_DETAIL_RESET,
  STATE_LIST_RESET,
  FETCH_JOB_TITLE,
} from "../../redux/actions/type";
import { connect } from "react-redux";
import { isObjectEmpty } from "../../common/common-methods";
// Style
import "./contacts.scss";

const { Option } = Select;
//let contact = {};

const daysList = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
];

const monthList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

class AddEditContactComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: { ...this.props.contact },
      isInitialStateListRequired: this.props.contact ? true : false,
    };
  }
  formRef = React.createRef();

  componentDidMount() {
    this.props.fetchCountries();
    this.props.fetchJobTitle();
  }

  onChangeCombobox = (event, option) => {
    let contact = this.state.contact;
    if (option.name === "country") {
      this.props.resetStoreState(STATE_LIST_RESET);
      contact["state"] = null;
      this.props.fetchStates(option.key);
      this.formRef.current.setFieldsValue({
        state: null,
      });
    }

    contact[option.name] = option.value;
    this.setState({ contact });
  };

  onChange = (event) => {
    let contact = this.state.contact;
    let name = event.target.getAttribute("name"); //field name
    let value = event.target.value; //field value

    contact[name] = value;
    this.setState({ contact });
  };

  onFinish = (values) => {
    if (this.props.contact.id) {
      this.props.updateContactDetail(this.state.contact);
    } else {
      this.props.saveContactDetail(this.state.contact);
    }
  };

  onSelectDobChange = (event, option) => {
    let contact = this.state.contact;
    contact[option.name] = option.value;
    this.setState({ contact });
  };

  componentDidUpdate() {
    if (
      !isObjectEmpty(this.props.countryList) &&
      this.state.isInitialStateListRequired
    ) {
      const filteredArry = this.props.countryList.filter(
        (country) => country.code === this.state.contact.country
      );
      if (filteredArry.length > 0) {
        const country = filteredArry[0];
        this.props.fetchStates(country.id);
      }

      this.setState({ isInitialStateListRequired: false });
    }

    if (this.props.successMessage.length > 0) {
      message.success(this.props.successMessage);
      this.props.closeAddEditComponent();
      this.props.fetchContactList();
      this.props.resetStoreState(CONTACT_DETAIL_RESET);
    }
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          destroyOnClose={true}
          centered={true}
          style={{ width: "100%", maxWidth: "768px" }}
          bodyStyle={{ padding: "1rem" }}
          closeIcon={
            <SvgIcon
              name="close"
              fill="#dc0100"
              width="13px"
              viewbox="0 0 298.667 298.667"
            />
          }
          className="full-screen-xs-modal footer-less"
          closable={true}
          onCancel={this.props.closeAddEditComponent}
          title="Contact Details"
          visible={true}
          width="100%"
          footer={null}
          loading={this.props.isFetchingData}
        >
          <Spin spinning={this.props.isFetchingData} size="large">
            <Form
              ref={this.formRef}
              name="normal_login"
              className="login-form"
              initialValues={{ ...this.state.contact, remember: false }}
              onFinish={this.onFinish}
            >
              <div className="form-heading">
                <b>General Details</b>
              </div>
              <Row className="gutter-sm">
                <Col xs="12" sm="6">
                  <label htmlFor="first_name" className="w-100 fs-12">
                    First Name
                  </label>
                  <Form.Item
                    id="first_name"
                    name="first_name"
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Please enter first name!",
                      },
                    ]}
                  >
                    <Input
                      name="first_name"
                      value={this.state.contact.first_name}
                      onChange={this.onChange.bind(this)}
                      size="large"
                      prefix={
                        <SvgIcon
                          name="menu-alt"
                          viewbox="0 0 16 7"
                          width="15px"
                        />
                      }
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
                <Col xs="12" sm="6">
                  <label htmlFor="last_name" className="w-100 fs-12">
                    Last Name
                  </label>
                  <Form.Item
                    id="last_name"
                    name="last_name"
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Please enter last name!",
                      },
                    ]}
                  >
                    <Input
                      name="last_name"
                      value={this.state.contact.last_name}
                      onChange={this.onChange.bind(this)}
                      size="large"
                      prefix={
                        <SvgIcon
                          name="menu-alt"
                          viewbox="0 0 16 7"
                          width="15px"
                        />
                      }
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
                <Col xs="12" sm="6">
                  <label htmlFor="job_title" className="w-100 fs-12">
                    Job Title
                  </label>
                  <div className="custom-select-with-icon">
                    <SvgIcon
                      name="brifcase"
                      viewbox="0 0 16 15.2"
                      width="15px"
                    />
                    <Form.Item
                      id="job_title"
                      name="job_title"
                      value={this.state.contact.job_title}
                      className="mb-3"
                      rules={[
                        {
                          required: false,
                          message: "Please select job title!",
                        },
                      ]}
                    >
                      <Select
                        value={this.state.contact.job_titles}
                        onChange={this.onChangeCombobox.bind(this)}
                        suffixIcon={
                          <SvgIcon name="caret-alt" viewbox="0 0 12 7" />
                        }
                        internalProps
                        size="large"
                      >
                        {this.props.jobTitleList.map((jobName, index) => (
                          <Option key={index} name="job_title" value={jobName}>
                            {jobName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                {/* <Col xs="12" sm="6">
                  <label htmlFor="department" className="w-100 fs-12">
                    Department
                  </label>
                  <Form.Item
                    id="department"
                    name="department"
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Please enter department!",
                      },
                    ]}
                  >
                    <Input
                      name="department"
                      value={this.state.contact.department}
                      onChange={this.onChange.bind(this)}
                      size="large"
                      prefix={
                        <SvgIcon
                          name="sitemap"
                          viewbox="0 0 17 15"
                          width="15px"
                        />
                      }
                      placeholder=""
                    />
                  </Form.Item>
                </Col> */}
                <Col xs="6">
                  <label htmlFor="email" className="w-100 fs-12">
                    Email Address
                  </label>
                  <Form.Item
                    id="email"
                    name="email"
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Please enter email address!",
                      },
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                    ]}
                  >
                    <Input
                      name="email"
                      value={this.state.contact.email}
                      onChange={this.onChange.bind(this)}
                      size="large"
                      prefix={
                        <SvgIcon name="mail" viewbox="0 0 13 11" width="15px" />
                      }
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
                <Col xs="6">
                  <label htmlFor="email" className="w-100 fs-12">
                    Birthday
                  </label>
                  <Row>
                    <Col xs="4" className="pr-0">
                      <div className="custom-select-with-icon">
                        <SvgIcon
                          name="calendar"
                          viewbox="0 0 14.652 14.726"
                          width="15px"
                        />
                        <Form.Item
                          id="dob_date"
                          name="dob_date"
                          className="mb-3"
                          // rules={[
                          //   {
                          //     required: false,
                          //     message: "Please select date",
                          //   },
                          // ]}
                        >
                          <Select
                            onChange={this.onSelectDobChange}
                            value={this.state.contact.dob_date}
                            suffixIcon={
                              <SvgIcon name="caret-alt" viewbox="0 0 12 7" />
                            }
                            placeholder="Day"
                            size="large"
                          >
                            {daysList.map((date, index) => (
                              <Option key={index} name="dob_date" value={date}>
                                {date}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col xs="8" className="pl-1">
                      <div className="custom-select-with-icon">
                        <SvgIcon
                          name="calendar-alt"
                          viewbox="0 0 12.67 14.022"
                          width="15px"
                        />
                        <Form.Item
                          id="dob_month"
                          name="dob_month"
                          value={this.state.contact.dob_month}
                          className="mb-3"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please select date",
                          //   },
                          // ]}
                        >
                          <Select
                            onChange={this.onSelectDobChange}
                            suffixIcon={
                              <SvgIcon name="caret-alt" viewbox="0 0 12 7" />
                            }
                            placeholder="Month"
                            size="large"
                          >
                            {monthList.map((month, index) => (
                              <Option
                                key={index}
                                name="dob_month"
                                value={index + 1}
                              >
                                {month}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="form-heading">
                <b>Address Details</b>
              </div>
              <Row className="gutter-sm ship-up-custom">
                <Col xs="12">
                  <label htmlFor="address_name" className="w-100 fs-12">
                    Address Name
                  </label>
                  <Form.Item
                    id="address_name"
                    name="address_name"
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your address Name!",
                      },
                    ]}
                  >
                    <Input
                      name="address_name"
                      value={this.state.contact.address_name}
                      onChange={this.onChange.bind(this)}
                      size="large"
                      prefix={
                        <SvgIcon
                          name="menu-alt"
                          viewbox="0 0 16 7"
                          width="15px"
                        />
                      }
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
                <Col xs="12">
                  <label htmlFor="address1" className="w-100 fs-12">
                    Address
                  </label>
                  <Form.Item
                    id="address1"
                    name="address1"
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your address!",
                      },
                    ]}
                  >
                    <Input
                      name="address1"
                      value={this.state.contact.address1}
                      onChange={this.onChange.bind(this)}
                      size="large"
                      prefix={
                        <SvgIcon name="pin" viewbox="0 0 10 15" width="12px" />
                      }
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6">
                  <label htmlFor="country" className="w-100 fs-12">
                    Country
                  </label>
                  <div className="custom-select-with-icon">
                    <SvgIcon name="globe" viewbox="0 0 59 59" width="15px" />
                    <Form.Item
                      id="country"
                      className="mb-3"
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: "Please select your country!",
                        },
                      ]}
                    >
                      <Select
                        value={this.state.contact.country}
                        onChange={this.onChangeCombobox.bind(this)}
                        suffixIcon={
                          <SvgIcon name="caret-alt" viewbox="0 0 12 7" />
                        }
                        size="large"
                      >
                        {this.props.countryList.map((country) => (
                          <Option
                            key={country.id}
                            name="country"
                            value={country.code}
                          >
                            {country.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6">
                  <label htmlFor="state" className="w-100 fs-12">
                    State
                  </label>
                  <div className="custom-select-with-icon">
                    <SvgIcon name="bank" viewbox="0 0 15 17" width="15px" />
                    <Form.Item
                      id="state"
                      name="state"
                      className="mb-3"
                      rules={[
                        {
                          required: true,
                          message: "Please select your state!",
                        },
                      ]}
                    >
                      <Select
                        value={this.state.contact.state}
                        onChange={this.onChangeCombobox.bind(this)}
                        suffixIcon={
                          <SvgIcon name="caret-alt" viewbox="0 0 12 7" />
                        }
                        size="large"
                      >
                        {this.props.stateList.map((state) => (
                          <Option
                            key={state.id}
                            name="state"
                            value={state.code}
                          >
                            {state.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6">
                  <label htmlFor="city" className="w-100 fs-12">
                    City
                  </label>
                  <Form.Item
                    id="city"
                    name="city"
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Please select your city!",
                      },
                    ]}
                  >
                    <Input
                      name="city"
                      value={this.state.contact.city}
                      onChange={this.onChange.bind(this)}
                      size="large"
                      placeholder="Enter City"
                      prefix={
                        <SvgIcon name="city" viewbox="0 0 59 59" width="15px" />
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6">
                  <label htmlFor="zip" className="w-100 fs-12">
                    Zip Code
                  </label>
                  <Form.Item
                    id="zip"
                    name="zip"
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Please select your zip code!",
                      },
                    ]}
                  >
                    <Input
                      name="zip"
                      value={this.state.contact.zip}
                      onChange={this.onChange.bind(this)}
                      size="large"
                      prefix={
                        <SvgIcon name="pin" viewbox="0 0 10 15" width="12px" />
                      }
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6">
                  <label htmlFor="bussiness_phone" className="w-100 fs-12">
                    Phone Number
                  </label>
                  <Form.Item
                    id="business_phone"
                    name="business_phone"
                    className="mb-3"
                    rules={[
                      {
                        required: false,
                        message: "Please select your phone number!",
                      },
                    ]}
                  >
                    <Input
                      name="business_phone"
                      value={this.state.contact.business_phone}
                      onChange={this.onChange.bind(this)}
                      size="large"
                      prefix={
                        <SvgIcon
                          name="phone"
                          viewbox="0 0 13 13"
                          width="15px"
                        />
                      }
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
                {/* <Col xs="12" sm="12" md="6" lg="6">
                  <label htmlFor="fax" className="w-100 fs-12">
                    Fax Number
                  </label>
                  <Form.Item
                    id="fax"
                    name="fax"
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Please select your fax number!",
                      },
                    ]}
                  >
                    <Input
                      name="fax"
                      value={this.state.contact.fax}
                      onChange={this.onChange.bind(this)}
                      size="large"
                      prefix={
                        <SvgIcon name="fax" viewbox="0 0 59 59" width="15px" />
                      }
                      placeholder=""
                    />
                  </Form.Item>
                </Col> */}
              </Row>
              <div className="d-flex justify-content-end mt-3">
                <Button
                  onClick={this.props.closeAddEditComponent}
                  className="mr-3"
                  size="large"
                >
                  <b className="fs-13">CANCEL</b>
                </Button>
                <Button type="primary" htmlType="submit" size="large">
                  <b className="fs-13">SAVE</b>
                </Button>
              </div>
            </Form>
          </Spin>
        </Modal>
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector([
  CONTACT_DETAIL,
  FETCH_COUNTRIES,
  FETCH_STATES,
  FETCH_JOB_TITLE,
]);

const mapStateToProps = (state) => ({
  successMessage: state.contactDetail,
  countryList: state.countries,
  stateList: state.states,
  jobTitleList: state.jobTitles,
  isFetchingData: loadingSelector(state),
});

export default connect(mapStateToProps, {
  saveContactDetail,
  updateContactDetail,
  resetStoreState,
  fetchContactList,
  fetchCountries,
  fetchStates,
  fetchJobTitle,
})(AddEditContactComponent);
