import confirm from "antd/lib/modal/confirm";
import {
  LOGIN_TOKEN_KEY,
  USER_DETAIL_KEY,
  requestContentType,
  CAPTURE_REQUEST_DETAILS_KEY,
  AUTHORISE_REQUEST_DETAILS_KEY,
  buildType,
} from "../constants/";
import { componentPaths } from "../constants/path-constants";
import { shipProiderConstants } from "../constants/ship-provider-constants";
import { showAlert } from "./alert-messages";
import { dispatchWithResponseAndType } from "../redux/actions/actions";
import { OPEN_UPDLOAD_CERTIFICATE_BY_DEFAULT } from "../redux/actions/type";

export const clearLoginData = () => {
  localStorage.removeItem(LOGIN_TOKEN_KEY);
  localStorage.removeItem(USER_DETAIL_KEY);
  clearCaptureRequestDetails();
  clearAuthoriseRequestDetails();
};

export const isOpenPath = (routerPath) => {
  switch (routerPath) {
    case componentPaths.registration:
    case componentPaths.USER_ACTIVATION:
      return true;

    default:
      return false;
  }
};

export const isUserLoggedIn = () => {
  if (localStorage.getItem(LOGIN_TOKEN_KEY)) return true;
  else return false;
};

export const setScrollIn = () => {
  document.getElementById("root").scrollTop = 0;
  document.getElementById("root").scrollLeft = 0;
  return;
};

export const getDateSeperatedByT = (dateString) => {
  if (dateString) {
    let arry = dateString.split("T");
    if (arry.length > 0) return arry[0];
  } else return "";
};

export const dataFormatter = (dateString) => {
  var d = dateString.slice(0, 10).split("-");
  return d[1] + "-" + d[2] + "-" + d[0];
};

export const saveLoginToken = (loginToken) => {
  localStorage.setItem(LOGIN_TOKEN_KEY, loginToken);
};

export const saveLoginTokenAndUserDetails = (data, dispatch) => {
  if (isObjectEmpty(data.users.customer_info.resale_certificate)) {
    confirm({
      title: "Alert!",
      content:
        " Resale Certificate is not available. Please upload it from  \n Edit-profile -> Documents-Tab -> Upload-Resale-Certificate .",
      // cancelButtonProps: { hidden: true },
      okText: "Upload Now!",
      onOk: () => {
        dispatch(
          dispatchWithResponseAndType(null, OPEN_UPDLOAD_CERTIFICATE_BY_DEFAULT)
        );
      },
    });
  }

  saveLoginToken(data.auth_token);
  localStorage.setItem(USER_DETAIL_KEY, JSON.stringify(data.users));
};

export const getLoginToken = () => {
  return localStorage.getItem(LOGIN_TOKEN_KEY);
};

export const loggedInUserDetail = () => {
  return JSON.parse(localStorage.getItem(USER_DETAIL_KEY));
};

export const isObjectNotEmpty = (obj) => {
  return !isObjectEmpty(obj);
};

export const isLiveMode = () => {
  if (process.env.REACT_APP_LIVE_URL === window.location.origin) {
    return true;
  } else {
    return false;
  }
};

export const isBuildTypeDemo = () => {
  return process.env.REACT_APP_BUILD_TYPE === buildType.DEMO;
};

export const saveCaptureRequestDetails = (requestObject) => {
  const paymentDetails = { requestObject: requestObject };

  localStorage.setItem(
    CAPTURE_REQUEST_DETAILS_KEY,
    JSON.stringify(paymentDetails)
  );
};

export const getCaptureRequestDetails = () => {
  if (isObjectEmpty(localStorage.getItem(CAPTURE_REQUEST_DETAILS_KEY))) {
    return null;
  } else {
    return JSON.parse(localStorage.getItem(CAPTURE_REQUEST_DETAILS_KEY));
  }
};

export const clearCaptureRequestDetails = () => {
  localStorage.removeItem(CAPTURE_REQUEST_DETAILS_KEY);
};

export const saveAuthoriseRequestDetails = (requestObject, orderId) => {
  const paymentDetails = { requestObject: requestObject, orderId: orderId };

  localStorage.setItem(
    AUTHORISE_REQUEST_DETAILS_KEY,
    JSON.stringify(paymentDetails)
  );
};

export const getAuthoriseRequestDetails = () => {
  if (isObjectEmpty(localStorage.getItem(AUTHORISE_REQUEST_DETAILS_KEY))) {
    return null;
  } else {
    return JSON.parse(localStorage.getItem(AUTHORISE_REQUEST_DETAILS_KEY));
  }
};

export const clearAuthoriseRequestDetails = () => {
  localStorage.removeItem(AUTHORISE_REQUEST_DETAILS_KEY);
};

export const getSchemaName = () => {
  let schemaName = "";
  if (isBuildTypeDemo()) {
    let url = window.location.origin.replace("https://", "");
    url = url.replace("http://", "");

    let urlsParts = url.split(".");
    if (isObjectNotEmpty(urlsParts)) {
      schemaName = urlsParts[0];
    }
  } else {
    schemaName = process.env.REACT_APP_SCHEMA_TEST;
  }

  return isLiveMode() ? process.env.REACT_APP_SCHEMA_LIVE : schemaName; //process.env.REACT_APP_SCHEMA_TEST;
};

export const getBaseUrl = () => {
  let url = "";
  if (isBuildTypeDemo()) {
    url = `${getSchemaName()}${process.env.REACT_APP_BASE_URL_TEST}`;
  } else {
    url = process.env.REACT_APP_BASE_URL_TEST;
  }

  return isLiveMode() ? process.env.REACT_APP_BASE_URL_LIVE : url;
};

export const getImageBaseUrl = () => {
  let url = "";
  if (isBuildTypeDemo()) {
    url = `${getSchemaName()}${process.env.REACT_APP_IMAGE_BASE_URL_TEST}`;
  } else {
    url = process.env.REACT_APP_IMAGE_BASE_URL_TEST;
  }

  return isLiveMode() ? process.env.REACT_APP_IMAGE_BASE_URL_LIVE : url;
};

export const isFlagTrueY = (flag) => {
  return flag === "Y";
};

export const isObjectEmpty = (obj) => {
  var hasOwnProperty = Object.prototype.hasOwnProperty;
  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== "object") return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (var key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
};

export const getObjectValue = (obj, property) => {
  return isObjectEmpty(obj) ? "" : obj[property];
};

///////////////////Request Header ////////////////////////
export const getJsonRequestHeader = () => {
  return {
    Accept: requestContentType.APPLICATION_JSON,
    "Content-Type": requestContentType.APPLICATION_JSON,
  };
};

export const getJsonRequestHeaderWithAuthentication = () => {
  return {
    ...getJsonRequestHeader(),
    Authorization: `Bearer  ${getLoginToken()}`,
  };
};

export const getRequestHeaderFormData = () => {
  return {
    Authorization: `Bearer  ${getLoginToken()}`,
  };
};

///////////////Shipping Methods
export const isValidationRequiredForShipProvider = (shipping_code) => {
  switch (shipping_code) {
    case shipProiderConstants.SHIPPING_PROVIDER_CUSTOMER_PICKUP:
    case shipProiderConstants.SHIPPING_PROVIDER_MANUAL:
    case shipProiderConstants.SHIPPING_PROVIDER_OCEAN:
    case shipProiderConstants.SHIPPING_PROVIDER_TRUCKING:
      return false;
    default:
      return true;
  }
};

export const isValueAnInteger = (value) => {
  if (value > 0) {
    return true;
  } else {
    return false;
  }
};

export const isloggedInUserViewOnly = () => {
  if (
    isObjectNotEmpty(loggedInUserDetail()) &&
    loggedInUserDetail().portal_view_only_flag === "Y"
  ) {
    return true;
  } else {
    return false;
  }
};

export const isloggedInUserRoleAdmin = () => {
  if (
    isObjectNotEmpty(loggedInUserDetail()) &&
    loggedInUserDetail().customer_role === "A"
  ) {
    return true;
  } else {
    return false;
  }
};

export const isloggedInUserRoleSupervisior = () => {
  if (
    isObjectNotEmpty(loggedInUserDetail()) &&
    loggedInUserDetail().customer_role === "B"
  ) {
    return true;
  } else {
    return false;
  }
};

export const isloggedInUserRoleSupervisiorOrAdmin = () => {
  if (
    isObjectNotEmpty(loggedInUserDetail()) &&
    (loggedInUserDetail().customer_role === "B" ||
      loggedInUserDetail().customer_role === "A")
  ) {
    return true;
  } else {
    return false;
  }
};

export const shippingPackageTracking = (shippingProvider, trackingNo) => {
  if (shippingProvider === "") {
    showAlert.error("Please Select Shipping Provider");
    return;
  }
  if (trackingNo === "") {
    showAlert.error("Tracking # Not Found");
    return;
  } else {
    var url = "";
    switch (shippingProvider) {
      case shipProiderConstants.SHIPPING_PROVIDER_UPS:
        url =
          "http://wwwapps.ups.com/WebTracking/OnlineTool?InquiryNumber1=" +
          trackingNo +
          "&TypeOfInquiryNumber=T&UPS_HTML_License=6C921F3E7B6B3698&UPS_HTML_Version=3.0&IATA=us&Lang=eng&submit=Track+package%28s%29";
        window.open(url);
        break;
      case shipProiderConstants.SHIPPING_PROVIDER_FEDEX:
        url =
          "https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=" +
          trackingNo +
          "&cntry_code=us";
        window.open(url);
        break;
      case shipProiderConstants.SHIPPING_PROVIDER_USPS:
        url =
          "https://tools.usps.com/go/TrackConfirmAction.action?tLabels=" +
          trackingNo;
        window.open(url);
        break;
      case shipProiderConstants.SHIPPING_PROVIDER_ENDICIA: //need to enter actual url for endicia
        url =
          "https://tools.usps.com/go/TrackConfirmAction.action?tLabels=" +
          trackingNo;
        window.open(url);
        break;
      default:
        showAlert.error("Please Select Valid Shipping Provider");
        break;
    }
  }
};

export const isStringContainSpecialCharacter = (string) => {
  var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  if (format.test(string)) {
    return true;
  } else {
    return false;
  }
};

export const isFileNameContainSpecialCharacter = (fileName) => {
  const arryItems = fileName.split(".");
  arryItems.pop();
  const name = arryItems.join(".");
  return isStringContainSpecialCharacter(name);
};
