import React from "react";
import { SvgIcon } from "../../../../components/common";
import MaskedInput from "antd-mask-input";
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  Radio,
  Alert,
  Checkbox,
  Popconfirm,
  Spin,
  Tooltip,
} from "antd";
import { Col, Row } from "../../../../components/common/grid";

import {
  chargeOrAuthorizeCustomerCreditCard,
  chargeOrAuthorizeCustomerProfile,
  fetchCountries,
  fetchStates,
  resetStoreState,
  createLoadingSelector,
  fetchOrderDetail,
  createCustomerAndPaymentProfile,
  createCutomerPaymentProfile,
  getCustomerProfileCredentials,
  getCustomerPaymentProfile,
  customerPaymentReceipt,
  fetchPayBills,
  deleteCustomerPaymentProfile,
  updateDeletedCustomerPaymentProfile,
  createErrorMessageSelector,
  saveAutorizeTransaction,
  saveCaptureTransaction,
} from "../../../../redux/actions";
import {
  clearAuthoriseRequestDetails,
  clearCaptureRequestDetails,
  getAuthoriseRequestDetails,
  getCaptureRequestDetails,
  isloggedInUserViewOnly,
  isObjectEmpty,
  isObjectNotEmpty,
} from "../../../../common/common-methods";
import {
  FETCH_COUNTRIES,
  FETCH_STATES,
  STATE_LIST_RESET,
  ERROR_MESSAGES_RESET_ALL,
  SAVE_CAPTURED_TRANSACTION_RESET,
  SAVE_CAPTURED_TRANSACTION,
  GET_CUSTOMER_PAYMENT_PROFILE,
  GET_CUSTOMER_PROFILE_CREDENTIALS,
  GET_CUSTOMER_PROFILE_CREDENTIALS_RESET,
  GET_CUSTOMER_PAYMENT_PROFILE_RESET,
  CUSTOMER_PAYMENT_RECEIPT,
  CUSTOMER_PAYMENT_RECEIPT_RESET,
  UPDATE_DELETED_PAYMENT_PROFILE,
  UPDATE_DELETED_PAYMENT_PROFILE_RESET,
} from "../../../../redux/actions/type";
import { connect } from "react-redux";

//Style
import "./payment-info-modal.scss";
import { showAlert } from "../../../../common/alert-messages";
import { baseServerAdress } from "../../../../constants/server-address-constants";
import {
  paymentTransactionType,
  cardType,
} from "../../../../constants/payment-constants";
import { PAYMENT_FAILURE_COUNT_KEY } from "../../../../constants";

const { Option } = Select;
const { confirm } = Modal;

const NEW_CARD = "new_card";

class NumericInput extends React.Component {
  onChange = (e) => {
    const { value } = e.target;
    const reg = new RegExp("^[0-9]+$");
    if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
      this.props.onChange(value);
    }
  };

  onBlur = () => {
    const { value, onBlur, onChange } = this.props;
    let valueTemp = value;
    if (value !== undefined && value !== "") {
      if (value.charAt(value.length - 1) === "." || value === "-") {
        valueTemp = value.slice(0, -1);
      }
      onChange(valueTemp.replace(/[^0-9.]/g, ""));
    }

    if (onBlur) {
      onBlur();
    }
  };

  render() {
    const { value } = this.props;
    const title = value ? (
      <span className="numeric-input-title">
        {value !== "-" ? value : null}
      </span>
    ) : (
      "Enter your CVV number"
    );

    return (
      <Tooltip
        trigger={["focus"]}
        title={title}
        placement="topLeft"
        overlayClassName="numeric-input"
      >
        <Input
          {...this.props}
          onChange={this.onChange}
          onBlur={this.onBlur}
          placeholder="CVV"
        />
      </Tooltip>
    );
  }
}

class PaymentInfoModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.onChangeCombobox = this.onChangeCombobox.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = {
      paymentInfoModal: false,
      paymentInfo: {},
      isInitialStateListRequired: false,
      selectedCardIndex: null,
      isdefaultCardSet: false,
      isSavingCard: true,
      isSavingCardAsDefault: false,
      cardType: cardType.VISA,
      isIntialStateSet: false,
      isPrintReceiptPopupOpen: false,
    };
  }
  formRef = React.createRef();

  isNewCardSelected() {
    return this.state.selectedCardIndex === NEW_CARD;
  }

  resetPaymentInfoModal = () => {
    this.setState({
      paymentInfoModal: false,
      paymentInfo: {},
      isInitialStateListRequired: false,
      isIntialStateSet: false,
      selectedCardIndex: null,
      isSavingCard: true,
      isSavingCardAsDefault: false,
      isdefaultCardSet: false,
      cardType: cardType.VISA,
    });
    this.props.resetStoreState(GET_CUSTOMER_PROFILE_CREDENTIALS_RESET);
    this.props.resetStoreState(GET_CUSTOMER_PAYMENT_PROFILE_RESET);
    this.props.resetStoreState(SAVE_CAPTURED_TRANSACTION_RESET);
    if (
      this.props.transactionType ===
      paymentTransactionType.AUTH_CAPTURE_TRANSACTION
    ) {
      this.props.fetchPayBills();
    } else {
      if (this.props.showPaymentPopupByDefault) {
        if (this.props.togglePaymentPopup) {
          this.props.togglePaymentPopup();
        }
      } else {
        this.props.fetchOrderDetail(this.props.orderId);
      }
    }
  };

  paymentInfoModalToggle = () => {
    if (this.props.terms !== "CC") {
      showAlert.error(`Payment Terms are ${this.props.terms}`);
      this.props.resetStoreState(ERROR_MESSAGES_RESET_ALL);
    } else if (this.props.payment_authorize_flag === "Y") {
      showAlert.error("Payment Already Authorized.");
      this.props.resetStoreState(ERROR_MESSAGES_RESET_ALL);
    } else if (this.props.payment_capture_flag === "Y") {
      showAlert.error("Payment Already Captured.");
      this.props.resetStoreState(ERROR_MESSAGES_RESET_ALL);
    } else {
      let paymentInfoObject = {};

      if (this.props.paymentInfo) {
        paymentInfoObject = {
          firstName: isObjectNotEmpty(
            this.props.paymentInfo.bill_name.split(" ")[0]
          )
            ? this.props.paymentInfo.bill_name.split(" ")[0]
            : "",
          lastName: isObjectNotEmpty(
            this.props.paymentInfo.bill_name.split(" ")[1]
          )
            ? this.props.paymentInfo.bill_name.split(" ")[1]
            : "",
          address: this.props.paymentInfo.bill_address1,
          country: this.props.paymentInfo.bill_country,
          state: this.props.paymentInfo.bill_state,
          city: this.props.paymentInfo.bill_city,
          zip: this.props.paymentInfo.bill_zip,
        };
      }
      if (!this.state.paymentInfoModal) {
        this.completeFailedSaveTransactionRequest(null, true);

        this.props.fetchCountries();
        this.props.getCustomerProfileCredentials();
        this.setState({
          paymentInfoModal: true,
          isInitialStateListRequired: true,
          isIntialStateSet: false,
          paymentInfo: paymentInfoObject,
        });
      } else {
        this.resetPaymentInfoModal();
      }
    }
  };
  onFinish = (values) => {
    if (this.state.selectedCardIndex === null) {
      showAlert.error("Please select a card for payment.");
      return;
    }

    const thisRef = this;
    let titleText = "";
    let contentText = "";
    if (
      thisRef.props.transactionType ===
      paymentTransactionType.AUTH_CAPTURE_TRANSACTION
    ) {
      titleText = "Payment";
      contentText = `$${this.props.amount} amount payment is about to be done. Are you sure?`;
    } else {
      titleText = "Authorization";
      contentText = `$${this.props.amount} amount authorization is about to be done. Are you sure?`;
    }
    confirm({
      title: titleText,
      content: contentText,
      onOk() {
        if (thisRef.isNewCardSelected()) {
          //when we are adding new card.
          if (thisRef.state.isSavingCard) {
            if (
              isObjectNotEmpty(
                thisRef.props.customerPaymentProfileCredentials
                  .customerProfileId
              )
            ) {
              // is customer profile exist

              //   create customer profile payment profile
              const requestObject = {
                ...thisRef.props.customerPaymentProfileCredentials,
                billInfo: thisRef.getBillingInfoForGatewayPayment(),
                cardDetails: thisRef.getCreditCardDetails(),
                transactionType: thisRef.props.transactionType,
                amount: thisRef.props.amount,
                cardCode: thisRef.state.paymentInfo.cvv,
              };
              thisRef.props.createCutomerPaymentProfile(
                requestObject,
                thisRef.props.orderId,
                thisRef.props.arryBills,
                thisRef.props.billIds,
                thisRef.getDetailsForSavingPaymentProfile()
              );
              return;
            } else {
              // const requestObject = {
              //   ...thisRef.props.customerPaymentProfileCredentials,
              //   billInfo: thisRef.getBillingInfoForGatewayPayment(),
              //   cardDetails: thisRef.getCreditCardDetails(),
              //   transactionType: thisRef.props.transactionType,
              //   amount: thisRef.props.amount,
              //   cardCode: thisRef.state.paymentInfo.cvv,
              // };
              // create customer and payment profile both.
              // thisRef.props.createCustomerAndPaymentProfile(
              //   requestObject,
              //   thisRef.props.orderId,
              //   thisRef.props.arryBills,
              //   thisRef.props.billIds,
              //   thisRef.getDetailsForSavingPaymentProfile()
              // );
              return;
            }
          } else {
            // charge creditcard
            const requestObject = {
              ...thisRef.props.customerPaymentProfileCredentials,
              billInfo: thisRef.getBillingInfoForGatewayPayment(),
              cardDetails: thisRef.getCreditCardDetails(),
              transactionType: thisRef.props.transactionType,
              amount: thisRef.props.amount,
            };

            thisRef.props.chargeOrAuthorizeCustomerCreditCard(
              requestObject,
              thisRef.props.orderId,
              thisRef.props.arryBills,
              thisRef.props.billIds,
              thisRef.getDetailsForSavingPaymentProfile()
            );
            return;
          }
        } else {
          //get profile details by
          let selectedProfileDetails =
            thisRef.props.customerPaymentProfiles[
              thisRef.state.selectedCardIndex
            ];

          const requestObject = {
            ...thisRef.props.customerPaymentProfileCredentials,
            paymentProfileId: selectedProfileDetails.customerPaymentProfileId,
            transactionType: thisRef.props.transactionType,
            amount: thisRef.props.amount,
            cardCode: thisRef.state.paymentInfo.cvv,
          };

          thisRef.props.chargeOrAuthorizeCustomerProfile(
            requestObject,
            thisRef.props.orderId,
            thisRef.props.arryBills,
            thisRef.props.billIds,
            {},
            selectedProfileDetails.payment.creditCard.expirationDate
          );
          return;
        }
      },
    });
  };

  getDetailsForSavingPaymentProfile = () => {
    let paymentInfo = this.state.paymentInfo;
    return {
      card_no: paymentInfo.cardNumber.split(" ").join("").replace("_", ""), // paymentInfo.cardNumber,
      card_type: this.state.cardType,
      expiration_month: paymentInfo.expirationMonth,
      expiration_year: paymentInfo.expirationYear,
      first_name: paymentInfo.firstName,
      last_name: paymentInfo.lastName,
      address: paymentInfo.address,
      city: paymentInfo.city,
      state: paymentInfo.state,
      zip: paymentInfo.zip,
      country: paymentInfo.country,
      default_card_flag: this.state.isSavingCardAsDefault ? "Y" : "N",
    };
  };
  getBillingInfoForGatewayPayment = () => {
    let paymentInfo = this.state.paymentInfo;
    return {
      firstName: paymentInfo.firstName,
      lastName: paymentInfo.lastName,
      address: paymentInfo.address,
      city: paymentInfo.city,
      state: paymentInfo.state,
      zip: paymentInfo.zip,
      country: paymentInfo.country,
    };
  };
  getCreditCardDetails = () => {
    let paymentInfo = this.state.paymentInfo;
    return {
      cardNumber: paymentInfo.cardNumber.split(" ").join("").replace("_", ""),
      expirationDate:
        paymentInfo.expirationYear + "-" + paymentInfo.expirationMonth,
      cardCode: paymentInfo.cvv,
    };
  };
  getReceiptDetails = (receipt) => {
    return {
      from_trans_no: receipt.trans_no,
      to_trans_no: receipt.trans_no,
      in_trans_no: receipt.trans_no,
      from_trans_date: receipt.trans_date,
      to_trans_date: receipt.trans_date,
    };
  };
  onCardTypeChange = (e) => {
    this.setState({
      cardType: e.target.value,
    });
  };

  deletePaymentProfile = (e) => {
    if (this.props.customerPaymentProfileCredentials) {
      const requestObject = {
        ...this.props.customerPaymentProfileCredentials,
        paymentProfileId: this.props.customerPaymentProfiles[
          this.state.selectedCardIndex
        ].customerPaymentProfileId,
      };
      this.props.deleteCustomerPaymentProfile(requestObject);
    }
  };

  componentDidMount() {
    if (this.props.showPaymentPopupByDefault) {
      this.paymentInfoModalToggle();
    }
  }

  completeFailedSaveTransactionRequest = (errorMessage, showPendingMessage) => {
    if (
      this.props.transactionType ===
      paymentTransactionType.AUTH_CAPTURE_TRANSACTION
    ) {
      const paymentDetails = getCaptureRequestDetails();

      if (isObjectNotEmpty(paymentDetails)) {
        if (
          paymentDetails.requestObject[PAYMENT_FAILURE_COUNT_KEY] === 2 &&
          isObjectNotEmpty(errorMessage)
        ) {
          showAlert.success(errorMessage);
        } else if (showPendingMessage) {
          showAlert.success(
            "Please wait we are working on your previous payment!"
          );
        }
        this.props.saveCaptureTransaction(paymentDetails.requestObject);
      }
    } else {
      const paymentDetails = getAuthoriseRequestDetails();

      if (isObjectNotEmpty(paymentDetails)) {
        if (
          paymentDetails.requestObject[PAYMENT_FAILURE_COUNT_KEY] === 2 &&
          isObjectNotEmpty(errorMessage)
        ) {
          showAlert.success(errorMessage);
        } else if (showPendingMessage) {
          showAlert.success(
            "Please wait we are working on your previous Authorization!"
          );
        }
        this.props.saveAutorizeTransaction(
          paymentDetails.requestObject,
          paymentDetails.orderId
        );
      }
    }
  };

  componentDidUpdate() {
    if (isObjectNotEmpty(this.props.errorMessageSaveTransaction)) {
      this.props.resetStoreState(ERROR_MESSAGES_RESET_ALL);

      if (this.state.paymentInfoModal) {
        this.completeFailedSaveTransactionRequest(
          this.props.errorMessageSaveTransaction
        );
      }
      //this.resetPaymentInfoModal();
    }

    if (
      isObjectNotEmpty(this.props.customerPaymentProfileCredentials) &&
      isObjectEmpty(
        this.props.customerPaymentProfileCredentials.customerProfileId
      )
    ) {
      this.resetPaymentInfoModal();
      showAlert.error(
        "Your account is not setup for the Credit Card processing. Please call Tekweld Accounts department."
      );
    }

    if (
      isObjectNotEmpty(this.props.countryList) &&
      this.state.isInitialStateListRequired &&
      this.state.paymentInfoModal &&
      isObjectNotEmpty(this.state.paymentInfo)
    ) {
      const filteredArry = this.props.countryList.filter(
        (country) => country.code === this.state.paymentInfo.country
      );

      if (filteredArry.length > 0) {
        const country = filteredArry[0];
        let paymentDtl = this.state.paymentInfo;
        paymentDtl["country"] = country.name;
        this.setState({
          isInitialStateListRequired: false,
          paymentInfo: paymentDtl,
        });
        isObjectNotEmpty(this.formRef.current) &&
          this.formRef.current.setFieldsValue({
            country: country.name,
          });
        this.props.fetchStates(country.id);
      }
    }

    if (
      !this.state.isIntialStateSet &&
      isObjectNotEmpty(this.props.stateList) &&
      isObjectNotEmpty(this.props.countryList) &&
      isObjectNotEmpty(this.props.paymentInfo)
    ) {
      const filteredState = this.props.stateList.filter(
        (state) => state.code === this.state.paymentInfo.state
      );
      if (filteredState.length > 0) {
        const state = filteredState[0];
        let paymentDtl = this.state.paymentInfo;
        paymentDtl["state"] = state.name;
        this.setState({
          paymentInfo: paymentDtl,
          isIntialStateSet: true,
        });
        isObjectNotEmpty(this.formRef.current) &&
          this.formRef.current.setFieldsValue({
            state: state.name,
          });
      }
    }
    if (isObjectNotEmpty(this.props.paymentOrAuthorisationResponse)) {
      if (this.props.paymentOrAuthorisationResponse.customer_receipt) {
        console.log(this.props.paymentOrAuthorisationResponse);
        if (!this.state.isPrintReceiptPopupOpen) {
          this.setState({ isPrintReceiptPopupOpen: true });
          confirm({
            title: "Payment",
            content: `${this.props.paymentOrAuthorisationResponse.info} Do you want to print a receipt ?`,
            onOk: () => {
              let detail = this.getReceiptDetails(
                this.props.paymentOrAuthorisationResponse.customer_receipt
              );
              this.resetPaymentInfoModal();
              this.props.customerPaymentReceipt(detail);
              this.setState({ isPrintReceiptPopupOpen: false });
            },
            onCancel: () => {
              this.resetPaymentInfoModal();
              this.setState({ isPrintReceiptPopupOpen: false });
            },
          });
        }
      } else {
        showAlert.success(this.props.paymentOrAuthorisationResponse.info);
        if (this.props.transactionSuccessCallBack) {
          this.props.transactionSuccessCallBack();
        }
        this.resetPaymentInfoModal();
      }
      clearCaptureRequestDetails();
      clearAuthoriseRequestDetails();
    }

    if (isObjectNotEmpty(this.props.customerPaymentReceiptPath)) {
      this.props.resetStoreState(CUSTOMER_PAYMENT_RECEIPT_RESET);
      window.open(
        baseServerAdress.REPORTS + this.props.customerPaymentReceiptPath,
        "_blank"
      );
    }

    if (
      isObjectNotEmpty(this.props.customerPaymentProfiles) &&
      isObjectNotEmpty(
        this.props.customerPaymentProfileCredentials.customer_payment_profile_id
      ) &&
      !this.state.isdefaultCardSet
    ) {
      for (
        let index = 0;
        index < this.props.customerPaymentProfiles.length;
        index++
      ) {
        const profile = this.props.customerPaymentProfiles[index];
        if (
          profile.customerPaymentProfileId ===
          this.props.customerPaymentProfileCredentials
            .customer_payment_profile_id
        ) {
          this.setState({ selectedCardIndex: index, isdefaultCardSet: true });
          break;
        }
      }
    }

    if (isObjectNotEmpty(this.props.deletedCustomerPaymentProfileMessage)) {
      showAlert.success(this.props.deletedCustomerPaymentProfileMessage.info);
      this.props.resetStoreState(UPDATE_DELETED_PAYMENT_PROFILE_RESET);
      this.props.resetStoreState(GET_CUSTOMER_PAYMENT_PROFILE_RESET);
      this.setState({ selectedCardIndex: null, isdefaultCardSet: false });
      this.props.getCustomerPaymentProfile(
        this.props.customerPaymentProfileCredentials
      );
    }
  }
  onChangeCombobox = (event, option) => {
    let paymentInfo = this.state.paymentInfo;
    if (option.name === "country") {
      this.props.resetStoreState(STATE_LIST_RESET);
      paymentInfo["state"] = "";
      this.props.fetchStates(option.key);
      this.formRef.current.setFieldsValue({
        state: null,
      });
      this.setState({ paymentInfo });
      this.props.fetchStates(option.key);
    }
    paymentInfo[option.name] = option.value;

    this.setState({ paymentInfo });
  };

  onChange = (event) => {
    let paymentInfo = this.state.paymentInfo;
    let name = event.target.getAttribute("name");
    let value = event.target.value;
    paymentInfo[name] = value;

    this.setState({ paymentInfo });
  };

  onChangeDateFields = (value, option) => {
    let paymentInfo = this.state.paymentInfo;
    let name = option.name;
    let key_value = option.value;
    paymentInfo[name] = key_value;

    this.setState({ paymentInfo });
  };
  onCVVChange = (e) => {
    let paymentInfo = this.state.paymentInfo;
    paymentInfo["cvv"] = e.toString();
    this.setState({ paymentInfo });
  };

  onPreviousCardChange = (e) => {
    let paymentDtl = this.state.paymentInfo;
    paymentDtl["cvv"] = "";
    this.setState({
      selectedCardIndex: e.target.value,
      paymentInfo: paymentDtl,
    });
    this.formRef.current.setFieldsValue({
      cvv: "",
    });
  };
  saveCard = (e) => {
    this.setState({ isSavingCard: !this.state.isSavingCard });
  };
  saveDefaultCard = () => {
    this.setState({ isSavingCardAsDefault: !this.state.isSavingCardAsDefault });
  };

  confirmCancel(e) {}

  getYearList = () => {
    let list = [];
    var currentDate = new Date();
    for (
      let index = currentDate.getFullYear();
      index < currentDate.getFullYear() + 21;
      index++
    ) {
      list.push(index.toString());
    }
    return list;
  };
  getMonthList = () => {
    return [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
  };
  render() {
    return (
      <React.Fragment>
        <Button
          type={this.props.buttonType}
          className="colored-btn green"
          onClick={this.paymentInfoModalToggle}
          disabled={this.props.makeDisable || isloggedInUserViewOnly()}
        >
          {this.props.showIcon && (
            <SvgIcon name="payment" width="28px" viewbox="0 0 28 27" />
          )}
          {this.props.buttonText}
        </Button>

        <Modal
          destroyOnClose={true}
          centered={true}
          bodyStyle={{ padding: "1rem" }}
          closeIcon={
            <SvgIcon
              name="close"
              fill="#dc0100"
              width="13px"
              viewbox="0 0 298.667 298.667"
            />
          }
          className="full-screen-xs-modal footer-less payment-info-modal"
          closable={true}
          onCancel={this.paymentInfoModalToggle}
          title="Payment Info"
          visible={
            this.state.paymentInfoModal || this.props.showPaymentPopupByDefault
          }
          footer={null}
          width="100%"
        >
          <Spin spinning={this.props.isFetchingData} size="large">
            <Form
              autoComplete={"off"}
              name="payment_info"
              className="payment-info-form"
              initialValues={{
                ...this.state.paymentInfo,
              }}
              ref={this.formRef}
              onFinish={this.onFinish}
            >
              <Row className="gutter-sm ship-up-custom">
                <Col xs="12" lg="6">
                  <div className="payment-stats mt-3">
                    <span className="head">Amount</span>
                    <span className="value">${this.props.amount}</span>
                  </div>
                  {this.props.transactionType ===
                    paymentTransactionType.AUTH_ONLY_TRANSACTION && (
                    <Alert
                      className="informative-alert my-4"
                      message="Your credit card will be authorized for 10% over your order amount, due to potential unfinalized changes to your order. TEKWELD will only charge your credit card for the exact amount of your order when it ships."
                      type="info"
                    />
                  )}

                  <div className="form-heading my-2">
                    <b>Card Information</b>
                  </div>

                  <Radio.Group
                    className="card-option w-100"
                    onChange={this.onPreviousCardChange}
                    value={this.state.selectedCardIndex}
                  >
                    {this.props.customerPaymentProfiles.map(
                      (profile, profileIndex) => {
                        return (
                          <Radio
                            key={profileIndex}
                            className="d-flex w-100 align-items-start mb-3"
                            value={profileIndex}
                          >
                            <span
                              className="fw-600 d-flex w-100"
                              style={{ lineHeight: 1 }}
                            >
                              {profile.payment.creditCard.cardNumber}{" "}
                              <span
                                className={`${profile.payment.creditCard.cardType} ${cardType.VISA}`}
                              ></span>
                              {this.state.selectedCardIndex ===
                                profileIndex && (
                                <Popconfirm
                                  title="Are you sure delete this card?"
                                  onConfirm={this.deletePaymentProfile}
                                  onCancel={this.confirmCancel}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <span className="close cursor-pointer ml-auto">
                                    <SvgIcon
                                      name="close"
                                      viewbox="0 0 298.667 298.667"
                                      width="12px"
                                    />
                                  </span>
                                </Popconfirm>
                              )}
                            </span>

                            <small className="d-flex w-100 mt-n1">
                              {profile.payment.creditCard.expirationDate}
                            </small>
                            {this.state.selectedCardIndex === profileIndex ? (
                              <Form.Item
                                id="cvv"
                                name="cvv"
                                className="mb-2"
                                validateTrigger={"onKeyUp"}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter cvv number!",
                                  },
                                  {
                                    pattern: /^\d+$/,
                                    message: "Please correct CVV number!",
                                  },
                                  {
                                    min: 3,
                                    message: "Please correct CVV number!",
                                  },
                                ]}
                              >
                                <NumericInput
                                  style={{ width: 60 }}
                                  onChange={this.onCVVChange}
                                  value={this.state.paymentInfo.cvv}
                                  maxLength={4}
                                  minLength={3}
                                />
                                {/* <Input
                                  name="cvv"
                                  value={this.state.paymentInfo.cvv}
                                  onChange={this.onChange}
                                  style={{ width: 60 }}
                                /> */}
                              </Form.Item>
                            ) : null}
                          </Radio>
                        );
                      }
                    )}
                    <Radio
                      className="d-flex w-100 align-items-center"
                      value={NEW_CARD}
                    >
                      Add Debit/Credit/ATM Card
                    </Radio>
                  </Radio.Group>
                  {this.isNewCardSelected() && (
                    <div>
                      <Radio.Group
                        className="card-type-group mb-2 mt-2"
                        onChange={this.onCardTypeChange}
                        value={this.state.cardType}
                      >
                        <Radio value={cardType.VISA}>
                          <span className={cardType.VISA}></span>
                        </Radio>
                        <Radio value={cardType.MASTERCARD}>
                          <span
                            className={`${cardType.MASTERCARD} ${cardType.VISA}`}
                          ></span>
                        </Radio>
                        <Radio value={cardType.AMERICAN_EXPRESS}>
                          <span
                            className={`${cardType.AMERICAN_EXPRESS} ${cardType.VISA}`}
                          ></span>
                        </Radio>
                        <Radio value={cardType.DISCOVER}>
                          <span
                            className={`${cardType.DISCOVER} ${cardType.VISA}`}
                          ></span>
                        </Radio>
                        <Radio value={cardType.JCB}>
                          <span
                            className={`${cardType.JCB} ${cardType.VISA}`}
                          ></span>
                        </Radio>
                        <Radio value={cardType.DINERS_CLUB}>
                          <span
                            className={`${cardType.DINERSCLUB} ${cardType.VISA}`}
                          ></span>
                        </Radio>
                      </Radio.Group>
                      <React.Fragment>
                        <Row>
                          <Col xs="12">
                            <label htmlFor="cardNumber" className="w-100 fs-12">
                              Card Number
                            </label>
                            <Form.Item
                              id="cardNumber"
                              name="cardNumber"
                              className="mb-2"
                              validateTrigger={"onKeyUp"}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter card number!",
                                },
                                // {
                                //   pattern: (/^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/) || (/^(?:4[0-9]{12}(?:[0-9]{3})?)$/) || (/^(?:5[1-5][0-9]{14})$/) || (/^(?:3[47][0-9]{13})$/),
                                //   message: 'Please enter vaild card number!',
                                // }
                                // {
                                //   len: 19,
                                //   message: 'Please enter vaild card number!',
                                // },
                              ]}
                            >
                              {/* <Input
                                name="cardNumber"
                                value={this.state.paymentInfo.cardNumber}
                                onChange={this.onChange}
                                size="large"
                                placeholder="Enter Card Number"
                              /> */}
                              <MaskedInput
                                placeholder="Enter Card Number"
                                value={this.state.paymentInfo.cardNumber}
                                mask="1111 1111 1111 1111"
                                size="large"
                                name="cardNumber"
                                onChange={this.onChange}
                              />
                            </Form.Item>

                            <label htmlFor="cvv" className="w-100 fs-12">
                              Security Code
                            </label>
                            <Form.Item
                              id="cvv"
                              name="cvv"
                              className="mb-2"
                              validateTrigger={"onKeyUp"}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter cvv number!",
                                },
                                {
                                  pattern: /^\d+$/,
                                  message: "Please correct CVV number!",
                                },
                                {
                                  min: 3,
                                  message: "Please correct CVV number!",
                                },
                              ]}
                            >
                              {/* <Input
                                name="cvv"
                                value={this.state.paymentInfo.cvv}
                                onChange={this.onChange}
                                size="large"
                                placeholder="Enter Security Code"
                              /> */}
                              <NumericInput
                                name="cvv"
                                onChange={this.onCVVChange}
                                value={this.state.paymentInfo.cvv}
                                maxLength={4}
                                minLength={3}
                                size="large"
                                placeholder="Enter Security Code"
                              />
                            </Form.Item>

                            <label
                              htmlFor="expirationMonth"
                              className="w-100 fs-12"
                            >
                              Expiration
                            </label>
                            <div className="d-flex">
                              <Form.Item
                                id="expirationMonth"
                                name="expirationMonth"
                                className="mb-2 mr-3"
                                rules={[
                                  {
                                    required: true,
                                    message: "Month required!",
                                    // message: "Please enter expiration month!",
                                  },
                                ]}
                              >
                                {/* <Input
                                    name="expirationMonth"
                                    value={this.state.paymentInfo.expirationMonth}
                                    onChange={this.onChange}
                                    size="large"
                                    placeholder="MM"
                                  /> */}
                                <Select
                                  size="large"
                                  style={{ width: 80 }}
                                  name="expirationMonth"
                                  onChange={this.onChangeDateFields}
                                  placeholder="MM"
                                >
                                  {this.getMonthList().map(
                                    (expiryMonth, index) => {
                                      return (
                                        <Option
                                          name="expirationMonth"
                                          value={expiryMonth}
                                          key={index}
                                        >
                                          {expiryMonth}
                                        </Option>
                                      );
                                    }
                                  )}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                id="expirationYear"
                                name="expirationYear"
                                className="mb-3"
                                rules={[
                                  {
                                    required: true,
                                    message: "Year required!",
                                    // message: "Please enter expiration month & year!",
                                  },
                                ]}
                              >
                                {/* <Input
                                    name="expirationYear"
                                    value={this.state.paymentInfo.expirationYear}
                                    onChange={this.onChange}
                                    size="large"
                                    placeholder="YYYY"
                                  /> */}
                                <Select
                                  size="large"
                                  style={{ width: 90 }}
                                  name="expirationYear"
                                  onChange={this.onChangeDateFields}
                                  placeholder="YYYY"
                                >
                                  {this.getYearList().map((year, index) => {
                                    return (
                                      <Option
                                        name="expirationYear"
                                        key={index}
                                        value={year}
                                      >
                                        {year}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs="12">
                            <b className="text-info mb-2 d-flex">
                              We save your card to process your orders faster.
                            </b>
                            {/* <Checkbox onChange={this.saveCard}>
                              Save this card for faster payments.
                            </Checkbox> */}
                          </Col>
                          {this.state.isSavingCard && (
                            <Col xs="12">
                              <Checkbox onChange={this.saveDefaultCard}>
                                Save this as default payment method.
                              </Checkbox>
                            </Col>
                          )}
                        </Row>
                      </React.Fragment>
                    </div>
                  )}
                </Col>
                {this.isNewCardSelected() && (
                  <Col xs="12" lg="6">
                    <div className="form-heading my-2">
                      <b>Billing Information</b>
                    </div>

                    <label htmlFor="firstName" className="w-100 fs-12">
                      First Name
                    </label>
                    <Form.Item
                      id="firstName"
                      name="firstName"
                      className="mb-3"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your First Name!",
                        },
                      ]}
                    >
                      <Input
                        name="firstName"
                        value={this.state.paymentInfo.firstName}
                        onChange={this.onChange}
                        size="large"
                        prefix={
                          <SvgIcon
                            name="menu-alt"
                            viewbox="0 0 16 7"
                            width="15px"
                          />
                        }
                      />
                    </Form.Item>

                    <label htmlFor="lastName" className="w-100 fs-12">
                      Last Name
                    </label>
                    <Form.Item
                      id="lastName"
                      name="lastName"
                      className="mb-3"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Last Name!",
                        },
                      ]}
                    >
                      <Input
                        name="lastName"
                        value={this.state.paymentInfo.lastName}
                        onChange={this.onChange}
                        size="large"
                        prefix={
                          <SvgIcon
                            name="menu-alt"
                            viewbox="0 0 16 7"
                            width="15px"
                          />
                        }
                      />
                    </Form.Item>

                    <label htmlFor="address" className="w-100 fs-12">
                      Address
                    </label>
                    <Form.Item
                      id="address"
                      name="address"
                      className="mb-3"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your address!",
                        },
                      ]}
                    >
                      <Input
                        name="address"
                        value={this.state.paymentInfo.address}
                        onChange={this.onChange}
                        size="large"
                        prefix={
                          <SvgIcon
                            name="pin"
                            viewbox="0 0 10 15"
                            width="12px"
                          />
                        }
                      />
                    </Form.Item>

                    <label htmlFor="country" className="w-100 fs-12">
                      {" "}
                      Country
                    </label>
                    <div className="custom-select-with-icon">
                      <SvgIcon name="globe" viewbox="0 0 59 59" width="15px" />
                      <Form.Item
                        id="country"
                        name="country"
                        className="mb-3"
                        rules={[
                          {
                            required: true,
                            message: "Please select your country!",
                          },
                        ]}
                      >
                        <Select
                          value={this.state.paymentInfo.country}
                          onChange={this.onChangeCombobox}
                          suffixIcon={
                            <SvgIcon name="caret-alt" viewbox="0 0 12 7" />
                          }
                          size="large"
                        >
                          {this.props.countryList.map((country) => (
                            <Option
                              key={country.id}
                              name="country"
                              value={country.name}
                            >
                              {country.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>

                    <label htmlFor="state" className="w-100 fs-12">
                      {" "}
                      State
                    </label>
                    <div className="custom-select-with-icon">
                      <SvgIcon name="bank" viewbox="0 0 15 17" width="15px" />
                      <Form.Item
                        id="state"
                        name="state"
                        className="mb-3"
                        rules={[
                          {
                            required: true,
                            message: "Please select your state!",
                          },
                        ]}
                      >
                        <Select
                          defaultValue=""
                          value={this.state.paymentInfo.state}
                          onChange={this.onChangeCombobox}
                          suffixIcon={
                            <SvgIcon name="caret-alt" viewbox="0 0 12 7" />
                          }
                          size="large"
                        >
                          {this.props.stateList.map((state) => (
                            <Option
                              key={state.id}
                              name="state"
                              value={state.name}
                            >
                              {state.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>

                    <label htmlFor="city" className="w-100 fs-12">
                      City
                    </label>
                    <Form.Item
                      id="city"
                      name="city"
                      className="mb-3"
                      rules={[
                        { required: true, message: "Please enter city!" },
                      ]}
                    >
                      <Input
                        name="city"
                        value={this.state.paymentInfo.city}
                        onChange={this.onChange}
                        size="large"
                        placeholder="Enter City"
                        prefix={
                          <SvgIcon
                            name="city"
                            viewbox="0 0 59 59"
                            width="15px"
                          />
                        }
                      />
                    </Form.Item>

                    <label htmlFor="zip" className="w-100 fs-12">
                      Zip Code
                    </label>
                    <Form.Item
                      id="zip"
                      name="zip"
                      className="mb-3"
                      rules={[
                        {
                          required: true,
                          message: "Please select your zip code!",
                        },
                      ]}
                    >
                      <Input
                        name="zip"
                        size="large"
                        value={this.state.paymentInfo.zip}
                        onChange={this.onChange}
                        prefix={
                          <SvgIcon
                            name="pin"
                            viewbox="0 0 10 15"
                            width="12px"
                          />
                        }
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <div className="d-flex justify-content-end mt-3">
                <Button
                  onClick={this.paymentInfoModalToggle}
                  className="mr-3"
                  size="large"
                >
                  <b className="fs-13">CANCEL</b>
                </Button>
                <Button type="primary" htmlType="submit" size="large">
                  <b className="fs-13">
                    {this.props.transactionType ===
                    paymentTransactionType.AUTH_CAPTURE_TRANSACTION
                      ? "MAKE PAYMENT"
                      : "AUTHORIZE PAYMENT"}
                  </b>
                </Button>
              </div>
            </Form>
          </Spin>
        </Modal>
      </React.Fragment>
    );
  }
}

const errorSelector = createErrorMessageSelector([SAVE_CAPTURED_TRANSACTION]);

const loadingSelector = createLoadingSelector([
  UPDATE_DELETED_PAYMENT_PROFILE,
  FETCH_COUNTRIES,
  FETCH_STATES,
  SAVE_CAPTURED_TRANSACTION,
  GET_CUSTOMER_PAYMENT_PROFILE,
  GET_CUSTOMER_PROFILE_CREDENTIALS,
  CUSTOMER_PAYMENT_RECEIPT,
  // GET_CUSTOMER_PAYMENT_PROFILE,
]);

const mapStateToProps = (state) => ({
  paymentOrAuthorisationResponse: state.paymentOrAuthorisationResponse,
  customerPaymentProfiles: state.customerPaymentProfiles,
  customerPaymentProfileCredentials: state.customerPaymentProfileCredentials,
  saveCutomerProfileSuccessMessage: state.saveCutomerProfileSuccessMessage,
  chargeCustomerProfileResponse: state.chargeCustomerProfileResponse,
  createCustomerAndPaymentProfileDetails:
    state.createCustomerAndPaymentProfileDetails,
  chargeCustomerCreditCardResponse: state.chargeCustomerCreditCard,
  authorizationMessage: state.authorizePaymentMessage,
  countryList: state.countries,
  stateList: state.states,
  customerPaymentReceiptPath: state.customerPaymentReceipt,
  isFetchingData: loadingSelector(state),
  deletedCustomerPaymentProfileMessage: state.updatedeletedPaymentProfile,
  errorMessageSaveTransaction: errorSelector(state),
});

export default connect(mapStateToProps, {
  chargeOrAuthorizeCustomerCreditCard,
  chargeOrAuthorizeCustomerProfile,
  fetchCountries,
  fetchStates,
  resetStoreState,
  fetchOrderDetail,
  getCustomerPaymentProfile,
  createCustomerAndPaymentProfile,
  createCutomerPaymentProfile,
  getCustomerProfileCredentials,
  customerPaymentReceipt,
  fetchPayBills,
  deleteCustomerPaymentProfile,
  updateDeletedCustomerPaymentProfile,
  saveAutorizeTransaction,
  saveCaptureTransaction,
})(PaymentInfoModalComponent);
