/* eslint-disable max-len */
import React from "react";
import { Form, Input, Button, Spin } from "antd";
import { SvgIcon } from "../../../components/common";

import {
  createLoadingSelector,
  loginAction,
} from "../../../redux/actions/index";

// Style
import "./loginForm.scss";
import { connect } from "react-redux";
import { LOGIN } from "../../../redux/actions/type";
import { isObjectNotEmpty } from "../../../common/common-methods";

class LoginFormComponent extends React.Component {
  onFinish = (values) => {
    console.log(values);

    if (
      isObjectNotEmpty(this.props.socialAccountDetails.type) &&
      isObjectNotEmpty(this.props.socialAccountDetails.id)
    ) {
      this.props.loginAction(
        values["email"],
        values["password"],
        this.props.socialAccountDetails.type,
        this.props.socialAccountDetails.id
      );
    } else {
      this.props.loginAction(values["email"], values["password"]);
    }
  };
  handleSubmit = (e) => {
    console.log(e);
  };

  render() {
    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        <div className="login-form-outer">
          <Form
            autoComplete={false}
            name="normal_login"
            className="login-form"
            onSubmit={this.handleSubmit}
            initialValues={{ remember: false }}
            onFinish={this.onFinish}
          >
            <Form.Item
              name="email"
              className="mb-3"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please enter your E-mail!",
                },
              ]}
            >
              <Input
                autoComplete="off"
                size="large"
                prefix={
                  <SvgIcon name="mail" viewbox="0 0 13 11" width="15px" />
                }
                placeholder="Email ID"
              />
            </Form.Item>
            <Form.Item
              name="password"
              className="mb-3"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input.Password
                size="large"
                autoComplete="off"
                prefix={
                  <SvgIcon
                    name="download-alt"
                    viewbox="0 0 15 16"
                    width="15px"
                  />
                }
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item className="mb-1">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Log in
              </Button>
              {/* <div className="text-center mt-3">
                Or{" "}
                <Button
                  type="link"
                  onClick={this.props.toggleConnectLoginAndRegistration}
                >
                  Register now!
                </Button>
              </div> */}
            </Form.Item>
          </Form>
        </div>
      </Spin>
    );
  }
}
const loadingSelector = createLoadingSelector([LOGIN]);

const mapStateToProps = (state) => ({
  isFetchingData: loadingSelector(state),
});

export default connect(mapStateToProps, {
  loginAction,
})(LoginFormComponent);
