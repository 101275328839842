import React from "react";
import { Spin } from "antd";
import { componentPaths } from "../../../constants/path-constants";

import {
  activateUser,
  resetStoreState,
  createLoadingSelector,
  createErrorMessageSelector,
} from "../../../redux/actions/index";
import { connect } from "react-redux";
import {
  ACTIVATE_USER,
  ACTIVATE_USER_RESET,
} from "../../../redux/actions/type";
import { setScrollIn } from "../../../common/common-methods";

class UserActivationComponnet extends React.Component {
  componentDidMount() {
    setScrollIn();
    console.log(this.props.match.params.id);
    this.props.activateUser(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.resetStoreState(ACTIVATE_USER_RESET);
  }

  componentDidUpdate() {
    if (this.props.successMessage.length > 0) {
      this.props.history.push(componentPaths.login);
    }
  }

  render() {
    // if (this.props.successMessage) {
    //   alert(this.props.successMessage);
    // }
    return (

      <React.Fragment>
        {this.props.successMessage ?
          <div className="user-activation-text">
            <p className="fs-16">{this.props.successMessage}</p>
          </div>
          :
          this.props.errorMessage ? <div className="user-activation-text">
            <p className="fs-16">{this.props.errorMessage}</p>
          </div> :
            <div
              hidden={!this.props.isFetchingData}
              className="user-activation-text"
            >
              <Spin
                spinning={this.props.isFetchingData}
                size="large"
                className="mx-auto"
              ></Spin>
              <p className="mt-4 mb-0">Please Wait </p>
              <p className="fs-16">
                User activation is under process, you will be redirected shortly.{" "}
              </p>
            </div>

        }
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector([ACTIVATE_USER]);
const errorMessageSelector = createErrorMessageSelector([ACTIVATE_USER]);

const mapStateToProps = (state) => ({
  successMessage: state.activateUser,
  isFetchingData: loadingSelector(state),
  errorMessage: errorMessageSelector(state),
});

export default connect(mapStateToProps, { activateUser, resetStoreState })(
  UserActivationComponnet
);
