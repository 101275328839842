import { isLiveMode } from "../common/common-methods";

//export const IS_LIVE_MODE = false;
export const paymentTransactionType = {
  AUTH_ONLY_TRANSACTION: "authOnlyTransaction",
  AUTH_CAPTURE_TRANSACTION: "authCaptureTransaction",
};

export const cardType = {
  VISA: "Visa",
  MASTERCARD: "MasterCard",
  AMERICAN_EXPRESS: "AmericanExpress",
  DISCOVER: "Discover",
  JCB: "JCB",
  DINERSCLUB: "DinersClub",
};

export const paymentValidationMode = {
  TEST_MODE: "testMode",
  LIVE_MODE: "liveMode",
};

export const PAYMENT_VALIDATION_MODE = isLiveMode()
  ? paymentValidationMode.LIVE_MODE
  : paymentValidationMode.TEST_MODE;

export const paymentGatewayEndPoint = {
  PAYMENT_GATEWAY_TEST: "https://apitest.authorize.net/xml/v1/request.api",
  PAYMENT_GATEWAY_LIVE: "https://api.authorize.net/xml/v1/request.api",
};

export const PAYMENT_GATEWAY_ENDPOINT = isLiveMode()
  ? paymentGatewayEndPoint.PAYMENT_GATEWAY_LIVE
  : paymentGatewayEndPoint.PAYMENT_GATEWAY_TEST;
