import React, { useState, useEffect } from "react";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { Link, NavLink } from "react-router-dom";
import { Menu, Modal, Spin } from "antd";
import GlobalSearch from "./GlobalSearch/GlobalSearch";
import ManageContactsComponent from "../../contacts/manage-contacts";
import {
  ChangePasswordModalComponent,
  EditProfileModalComponent,
} from "../../profile";
import { SvgIcon } from "../../common";
import "./Navbar.scss";

import {
  isloggedInUserRoleAdmin,
  isloggedInUserRoleSupervisior,
  isloggedInUserRoleSupervisiorOrAdmin,
  isloggedInUserViewOnly,
  isObjectEmpty,
  isObjectNotEmpty,
  isValueAnInteger,
  loggedInUserDetail,
} from "../../../common/common-methods";
import history from "../../../common/history";

import { componentPaths } from "../../../constants/path-constants";
import {
  createLoadingSelector,
  logoutAction,
  downloadStatement,
  resetStoreState,
  getDashboardDetails,
} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  LOGOUT,
  DOWNLOAD_STATEMENT,
  DOWNLOAD_STATEMENT_RESET,
} from "../../../redux/actions/type";
import { showAlert } from "../../../common/alert-messages";
import { baseServerAdress } from "../../../constants/server-address-constants";

const { SubMenu } = Menu;
const { confirm } = Modal;

const UpperMenu = ({ className, onClick, mode, theme }) => {
  const loadingSelector = createLoadingSelector([LOGOUT, DOWNLOAD_STATEMENT]);
  const dispatch = useDispatch();

  const successMessage = useSelector((state) => state.logOutMessage);
  const isFetchingData = useSelector((state) => loadingSelector(state));
  const statementPath = useSelector((state) => state.downloadStatementMessage);
  const openUploadResaleCertificateByDefault = useSelector(
    (state) => state.openUploadResaleCertificateByDefault
  );

  useEffect(() => {
    if (!isObjectEmpty(successMessage)) {
      showAlert.success(successMessage);
      history.push(componentPaths.login);
    }
    if (!isObjectEmpty(statementPath)) {
      dispatch(resetStoreState(DOWNLOAD_STATEMENT_RESET));
      setTimeout(() => {
        fetch(baseServerAdress.REPORTS + statementPath).then((response) => {
          response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = "statement";
            a.click();
          });
        });
      }, 100);
    }
  });

  function showConfirm() {
    confirm({
      title: "Sign out from your profile.",
      content: "Are you sure you want to sign out from your account?",
      onOk() {
        dispatch(logoutAction());
      },
    });
  }
  const userDetails = loggedInUserDetail();

  const [searchVisible, setVisiblity] = useState(false);
  const handleSearchToggle = () => {
    setVisiblity(!searchVisible);
  };
  const handleUserRole = () => {
    if (userDetails.customer_role === "A") {
      history.push(componentPaths.CHANGE_USER_ROLE);
    } else if (userDetails.customer_role === "B") {
      history.push(componentPaths.USER_ROLE_SUPERVISORS);
    }
  };

  const isTablet = useMediaQuery({ query: "(max-device-width: 1024px)" });

  return (
    <>
      {isFetchingData && (
        <Spin
          spinning={true}
          size="large"
          className="full-screen-spinner"
        ></Spin>
      )}
      {openUploadResaleCertificateByDefault && (
        <EditProfileModalComponent defaultTabKey={"5"} />
      )}
      <Menu
        selectable={false}
        getPopupContainer={(triggernode) => {
          return triggernode.parentNode;
        }}
        theme={theme}
        className={className}
        triggerSubMenuAction={"click"}
        onClick={onClick}
        mode={mode}
      >
        {!isTablet && !isloggedInUserViewOnly() && isloggedInUserRoleAdmin() && (
          <SubMenu
            key={"userrole-dropdown"}
            popupClassName="userrole-dropdown"
            placement="bottomRight"
            title={
              <div className="userrole-droptoggle">
                <SvgIcon
                  className="usericon"
                  name="user-alt"
                  viewbox="0 0 36 36"
                />{" "}
                User Role Permissions{" "}
                <div className="arrowicon">
                  <SvgIcon
                    name="caret-alt"
                    className="arrow"
                    viewbox="0 0 12 7"
                  />
                </div>
              </div>
            }
          >
            <Menu.Item key="user-option-11">
              <Link to={componentPaths.CREATE_USER}>Create User</Link>
            </Menu.Item>
            <Menu.Item key="user-option-22">
              <Link to={componentPaths.CHANGE_USER_ROLE}>Change Role</Link>
            </Menu.Item>
            <Menu.Item key="user-option-33">
              <Link to={componentPaths.MANAGE_SUPERVISIOR}>
                Manage Supervisor
              </Link>
            </Menu.Item>
          </SubMenu>
        )}
        {!isTablet &&
          !isloggedInUserViewOnly() &&
          isloggedInUserRoleSupervisior() && (
            <Menu.Item key="user-role" className="top-bar-btn">
              <span onClick={handleUserRole}>
                <SvgIcon name="user" viewbox="-42 0 512 512.001" width="15px" />
                <span>User Role Permissions</span>
              </span>
            </Menu.Item>
          )}
        {!isTablet && (
          <Menu.Item key="download-statement" className="top-bar-btn">
            <span onClick={() => dispatch(downloadStatement())}>
              <SvgIcon name="download-alt" viewbox="0 0 15 16" width="15px" />
              <span>Download Statement</span>
            </span>
          </Menu.Item>
        )}
        {!isTablet &&
          isloggedInUserRoleSupervisiorOrAdmin() &&
          !isloggedInUserViewOnly() && (
            <Menu.Item key="contacts" className="top-bar-btn">
              <span>
                <SvgIcon name="contact" viewbox="0 0 13 16" width="13px" />
                <ManageContactsComponent />
              </span>
            </Menu.Item>
          )}
        {isTablet && (
          <Menu.Item key="search" className="mobile-btn-options">
            <span onClick={handleSearchToggle}>
              <SvgIcon name="search" viewbox="0 0 16 16" width="20px" />
              <small>Search</small>
            </span>
            {searchVisible && (
              <React.Fragment>
                <div className="gloabal-search-mobile">
                  <GlobalSearch />
                </div>
                <span
                  onClick={handleSearchToggle}
                  className="search-backdrop"
                ></span>
              </React.Fragment>
            )}
          </Menu.Item>
        )}
        {isTablet && (
          <Menu.Item key="dashboard" className="mobile-btn-options">
            <NavLink to="/dashboard" activeClassName="active">
              <SvgIcon name="home" viewbox="0 0 247.8 247.8" width="20px" />
              <small>Dashboard</small>
            </NavLink>
          </Menu.Item>
        )}
        <SubMenu
          key={"user-dropdown"}
          popupClassName="user-dropdown"
          placement="bottomRight"
          className="user-option"
          title={
            <span>
              {/* <span className="user-avatar">
                <img
                  src={userAvatarDefault}
                  alt="User Name"
                  title="User Name"
                />
              </span> */}
              <MediaQuery minDeviceWidth={992}>
                <div className="d-flex flex-column">
                  <div className="w-100 d-flex align-items-center user-avatar-top-right">
                    Welcome
                    <b>
                      {isObjectNotEmpty(userDetails)
                        ? userDetails.first_name
                        : ""}
                    </b>
                    <SvgIcon
                      name="caret-alt"
                      className="arrow"
                      viewbox="0 0 12 7"
                    />
                  </div>
                  <small className="w-100 d-flex mt-1">
                    Customer Code :{" "}
                    {isObjectNotEmpty(userDetails)
                      ? userDetails.customer_info.code
                      : ""}
                  </small>
                </div>
              </MediaQuery>
              <MediaQuery maxDeviceWidth={991}>
                <SvgIcon name="user" viewbox="-42 0 512 512.001" width="20px" />
                <small>Account</small>
              </MediaQuery>
            </span>
          }
        >
          {!isloggedInUserViewOnly() &&
            (isloggedInUserRoleAdmin() || isloggedInUserRoleSupervisior()) && (
              <Menu.Item onClick={handleUserRole}>
                <span>User Role Permissions</span>
              </Menu.Item>
            )}
          {isloggedInUserRoleSupervisiorOrAdmin() && !isloggedInUserViewOnly() && (
            <Menu.Item key="user-option-1">
              <ManageContactsComponent />
            </Menu.Item>
          )}
          <Menu.Item
            onClick={() => dispatch(downloadStatement())}
            key="user-option-2"
          >
            <span>Download Statement</span>
          </Menu.Item>
          <Menu.Item key="user-option-3">
            <EditProfileModalComponent />
          </Menu.Item>
          <Menu.Item key="user-option-4">
            <ChangePasswordModalComponent />
          </Menu.Item>
          <Menu.Item onClick={showConfirm} key="user-option-5">
            <span>Sign Out</span>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </>
  );
};

const NavMenu = ({ className, onClick, mode, openEvent }) => {
  const dashboard = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isObjectEmpty(dashboard)) {
      dispatch(getDashboardDetails());
    }
  });

  return (
    <Menu
      triggerSubMenuAction={openEvent}
      selectable={false}
      className={className}
      onClick={onClick}
      mode={mode}
      theme="dark"
      getPopupContainer={(triggernode) => {
        return triggernode.parentNode;
      }}
    >
      <Menu.Item key="order">
        <NavLink to="/order-list">
          Orders
          {isValueAnInteger(dashboard.open_orders) && (
            <span className="badge"> {dashboard.open_orders}</span>
          )}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="e-proofs">
        <NavLink to="/eproofs">
          e-Proof
          {isValueAnInteger(dashboard.eproof) && (
            <span className="badge"> {dashboard.eproof}</span>
          )}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="estimates">
        <NavLink to="/estimates">
          Estimates
          {isValueAnInteger(dashboard.estimates) && (
            <span className="badge"> {dashboard.estimates}</span>
          )}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="pay-bills">
        <NavLink to="/pay-bills">
          Pay Bills
          {isValueAnInteger(dashboard.pay_my_bills) && (
            <span className="badge"> {dashboard.pay_my_bills}</span>
          )}
        </NavLink>
      </Menu.Item>

      <Menu.Item key="missing-arts">
        <NavLink to="/missingarts">
          Missing Arts
          {isValueAnInteger(dashboard.missing_arts) && (
            <span className="badge"> {dashboard.missing_arts}</span>
          )}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="pre-production-approval">
        <NavLink to={componentPaths.PRE_PRODUCTION_APPROVALS}>
          Pre-Production Approval
          {isValueAnInteger(dashboard.pre_production_approval) && (
            <span className="badge"> {dashboard.pre_production_approval}</span>
          )}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="stitch-approval">
        <NavLink to={componentPaths.STITCH_APPROVALS}>
          Stitch Approval
          {/* Apply condition on rendering of badge if value is less then 0 then badge should not render */}
          {isValueAnInteger(dashboard.stitch_approval) && (
            <span className="badge"> {dashboard.stitch_approval}</span>
          )}
        </NavLink>
      </Menu.Item>

      <Menu.Item key="check-inventory">
        <NavLink to={componentPaths.CHECK_INVENTORY}>Check Inventory</NavLink>
      </Menu.Item>
      <Menu.Item key="ship-ups-estimate">
        <NavLink to={componentPaths.SHIP_UPS_ESTIMATE}>Ship Estimate</NavLink>
      </Menu.Item>
    </Menu>
  );
};

class Navbar extends React.Component {
  render() {
    return (
      <header>
        {/* Topbar */}
        <div className="top-bar">
          <div className="logo-wrapper">
            <Link to="/dashboard" className="logo" title="">
              <SvgIcon name="logo" viewbox="0 0 300.000000 50.000000" />
              <small>Start Ordering Now</small>
            </Link>
          </div>
          {/* Topbar Search & Menu (Desktop) */}
          <div className="center-elements">
            <MediaQuery minDeviceWidth={992}>
              <GlobalSearch />
            </MediaQuery>
            <div className="upper-menu">
              <UpperMenu theme={"transparent"} mode="horizontal" />
            </div>
          </div>
        </div>

        {/* Main Navigation Menu (Desktop) */}
        <MediaQuery minDeviceWidth={992}>
          <NavMenu
            className="main-navbar"
            openEvent={"click"}
            mode="horizontal"
          />
        </MediaQuery>
      </header>
    );
  }
}

export default Navbar;
