import React from "react";
import { Button, Modal, Form, Input } from "antd";
import { SvgIcon } from "../common";

// Style
import "./profile.scss";
import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_RESET,
} from "../../redux/actions/type";

import { connect } from "react-redux";
import {
  changePassword,
  resetStoreState,
  createLoadingSelector,
} from "../../redux/actions";
import { showAlert } from "../../common/alert-messages";
import { isObjectEmpty } from "../../common/common-methods";

class ChangePasswordModalComponent extends React.Component {
  state = {
    visible: false,
  };
  onChangePasswordFormSubmit = (values) => {
    this.props.changePassword(values.current_password, values.new_password);
  };
  onChangePasswordClick = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  componentDidUpdate() {
    if (!isObjectEmpty(this.props.changePasswordMessage)) {
      showAlert.success(this.props.changePasswordMessage);
      this.props.resetStoreState(CHANGE_PASSWORD_RESET);
      this.setState({
        visible: !this.state.visible,
      });
    }
  }
  render() {
    const { visible } = this.state;
    return (
      <React.Fragment>
        <span className="d-flex" onClick={this.onChangePasswordClick}>
          Change Password
        </span>
        <Modal
          destroyOnClose={true}
          centered={true}
          bodyStyle={{ padding: "1rem" }}
          closeIcon={
            <SvgIcon
              name="close"
              fill="#dc0100"
              width="13px"
              viewbox="0 0 298.667 298.667"
            />
          }
          className="full-screen-xs-modal footer-less"
          closable={true}
          onCancel={this.onChangePasswordClick}
          width={380}
          title="Change Password"
          visible={visible}
          footer={null}
        >
          <Form
            layout="vertical"
            name="sendToCustom"
            onFinish={this.onChangePasswordFormSubmit}
          >
            <label htmlFor="current_password" className="w-100 fs-12">
              Current Password
            </label>
            <Form.Item
              id="current_password"
              name="current_password"
              rules={[
                {
                  required: true,
                  message: "Please enter your current password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                prefix={
                  <SvgIcon name="mail" viewbox="0 0 13 11" width="15px" />
                }
              />
            </Form.Item>
            <label htmlFor="new_password" className="w-100 fs-12">
              New Password
            </label>
            <Form.Item
              id="new_password"
              name="new_password"
              rules={[
                {
                  required: true,
                  message: "Please enter your new password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                prefix={
                  <SvgIcon name="mail" viewbox="0 0 13 11" width="15px" />
                }
              />
            </Form.Item>
            <label htmlFor="confirm_password" className="w-100 fs-12  ">
              Confirm Password
            </label>
            <Form.Item
              id="confirm_password"
              name="confirm_password"
              className="mb-3"
              dependencies={["new_password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The two passwords that you entered do not match!"
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={
                  <SvgIcon name="mail" viewbox="0 0 13 11" width="15px" />
                }
              />
            </Form.Item>
            <Button type="primary" htmlType="submit" className="mt-3" block>
              SAVE
            </Button>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector([CHANGE_PASSWORD]);

const mapStateToProps = (state) => ({
  changePasswordMessage: state.changePassword,
  isFetchingData: loadingSelector(state),
});

export default connect(mapStateToProps, { changePassword, resetStoreState })(
  ChangePasswordModalComponent
);
