import React, { useCallback, useEffect, useRef, useState } from "react";
import { Input } from "antd";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../../components/common";
import { useSelector, useDispatch } from "react-redux";
import { componentPaths } from "../../../constants/path-constants";
import { Spin, AutoComplete } from "antd";
import history from "../../../common/history";
import debounce from "lodash/debounce";

import {
  getDashboardDetails,
  createLoadingSelector,
  resetStoreState,
  inventoryItemSearch,
} from "../../../redux/actions";

// Style
import "./dashboard.scss";
import {
  DASHBOARD_DETAILS,
  INVENTORY_ITEM_SEARCH,
  INVENTORY_ITEM_SEARCH_RESET,
} from "../../../redux/actions/type";
import {
  isloggedInUserRoleAdmin,
  isloggedInUserRoleSupervisior,
  isloggedInUserViewOnly,
  isObjectEmpty,
  setScrollIn,
} from "../../../common/common-methods";
import { isValueAnInteger } from "../../../common/common-methods";
import { SUGGESTION_API_DEBOUNCE_TIME } from "../../../constants";

const searchResult = (searchResultArry) => {
  return searchResultArry.map((item, index) => {
    return {
      key: item.id,
      value: item.web_code,
      label: (
        <>
          <span>{item.web_code} </span>
          <span>{} </span>
        </>
      ),
    };
  });
};

const DashboardComponent = () => {
  const myRef = useRef();
  const loadingSelector = createLoadingSelector([DASHBOARD_DETAILS]);
  const loadingSelectorSuggestion = createLoadingSelector([
    INVENTORY_ITEM_SEARCH,
  ]);

  const delayedQuery = useCallback(
    debounce(
      (value) =>
        dispatch(
          inventoryItemSearch({
            query: value,
            search_type: "inventory",
          })
        ),
      SUGGESTION_API_DEBOUNCE_TIME
    ),
    []
  );

  const handleSearch = (value) => {
    dispatch(resetStoreState(INVENTORY_ITEM_SEARCH_RESET));
    setState({ ...state, term: value });
    if (!isObjectEmpty(value) && value.length > 2) {
      delayedQuery(value);
    }
  };

  const options = useSelector((state) =>
    searchResult(state.inventoryItemSearchResult)
  );
  const [state, setState] = useState({
    term: "",
  });

  const dashboard = useSelector((state) => state.dashboard);
  const isFetchingData = useSelector(
    (state) => !state.dashboard || loadingSelector(state)
  );
  const isFetchingDataSuggestion = useSelector((state) =>
    loadingSelectorSuggestion(state)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setScrollIn();
    //dispatch(getDashboardDetails());
  }, [dispatch]);

  if (isFetchingData) {
    return <Spin spinning={isFetchingData} size="large"></Spin>;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (state.term !== "") {
      history.push({
        pathname: componentPaths.CHECK_INVENTORY,
        state: { term: state.term },
      });
    }
  };
  const onSelect = (value) => {
    dispatch(resetStoreState(INVENTORY_ITEM_SEARCH_RESET));
    history.push({
      pathname: componentPaths.CHECK_INVENTORY,
      state: { term: value },
    });
  };

  const handleMatchedSearch = (value, e) => {
    if (value !== "") {
      onSelect(value);
    }
  };
  return (
    <Spin spinning={isFetchingData} size="large">
      <div className="common-container" ref={myRef}>
        <form onSubmit={onSubmit}>
          <div className="bluebox">
            <label>Check Inventory</label>
            {/* <Input placeholder="Enter item #" className="mt-1" value={state.term} onChange={(e) => setState({ ...state, term: e.target.value })} /> */}

            <AutoComplete
              dropdownMatchSelectWidth={"300px"}
              style={{
                width: "100%",
              }}
              options={options}
              onSelect={onSelect}
              onSearch={handleSearch}
              notFoundContent={isFetchingDataSuggestion ? <Spin /> : ""}
            >
              <Input.Search
                placeholder="Input first 3 characters"
                onSearch={handleMatchedSearch}
              />
            </AutoComplete>
          </div>
        </form>
        <div className="dashboard-menu">
          <ul>
            <li>
              <Link to={componentPaths.orderlist}>
                <SvgIcon name="openOrders" width="24px" viewbox="0 0 23 27" />
                <span>Orders</span>
                {isValueAnInteger(dashboard.open_orders) && (
                  <span className="badge"> {dashboard.open_orders}</span>
                )}
              </Link>
            </li>
            <li>
              <Link to={componentPaths.eproofs}>
                <SvgIcon name="e-proof" width="24px" viewbox="0 0 24 29" />
                <span>eProof</span>
                {isValueAnInteger(dashboard.eproof) && (
                  <span className="badge"> {dashboard.eproof}</span>
                )}
              </Link>
            </li>
            <li>
              <Link to={componentPaths.estimates}>
                <SvgIcon
                  name="calculator"
                  width="22px"
                  viewbox="0 0 350.4 475.1"
                />
                <span>Estimates</span>
                {isValueAnInteger(dashboard.estimates) && (
                  <span className="badge"> {dashboard.estimates}</span>
                )}
              </Link>
            </li>
            <li>
              <Link to={componentPaths.paybills}>
                <SvgIcon name="credit-card" width="30px" viewbox="0 0 29 21" />
                <span>Pay My Bills</span>
                {isValueAnInteger(dashboard.pay_my_bills) && (
                  <span className="badge"> {dashboard.pay_my_bills}</span>
                )}
              </Link>
            </li>
            <li>
              <Link to={componentPaths.missingarts}>
                <SvgIcon name="picture" width="24px" viewbox="0 0 28 28" />
                <span>Missing Arts</span>
                {isValueAnInteger(dashboard.missing_arts) && (
                  <span className="badge"> {dashboard.missing_arts}</span>
                )}
              </Link>
            </li>
            <li>
              <Link to={componentPaths.PRE_PRODUCTION_APPROVALS}>
                <SvgIcon name="factory" width="24px" viewbox="0 0 27 27" />
                <span>Pre-Production Approval</span>
                {isValueAnInteger(dashboard.pre_production_approval) && (
                  <span className="badge">
                    {" "}
                    {dashboard.pre_production_approval}
                  </span>
                )}
              </Link>
            </li>
            <li>
              <Link to={componentPaths.STITCH_APPROVALS}>
                <SvgIcon name="stitch" width="24px" viewbox="0 0 27 27" />
                <span>Stitch Approval</span>
                {isValueAnInteger(dashboard.stitch_approval) && (
                  <span className="badge"> {dashboard.stitch_approval}</span>
                )}
              </Link>
            </li>

            {!isloggedInUserViewOnly() &&
              (isloggedInUserRoleAdmin() ||
                isloggedInUserRoleSupervisior()) && (
                <li>
                  <Link
                    to={
                      isloggedInUserRoleAdmin()
                        ? componentPaths.CHANGE_USER_ROLE
                        : componentPaths.USER_ROLE_SUPERVISORS
                    }
                  >
                    <SvgIcon
                      name="user"
                      width="22px"
                      viewbox="-42 0 512 512.001"
                    />
                    <span>User Role Permissions</span>
                  </Link>
                </li>
              )}
          </ul>
          <Link to="/ship-estimate" className="alt-menu">
            <SvgIcon
              name="truck"
              width="26px"
              className="mr-3"
              viewbox="0 0 29 19"
            />
            <span>Shipping Estimates Calculator</span>
            <SvgIcon name="next-circle" width="24px" viewbox="0 0 512 512" />
          </Link>
        </div>
      </div>
    </Spin>
  );
};

export default DashboardComponent;

/*
 */
