/* eslint-disable max-len */
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import {useBootstrapPrefix} from './ThemeProvider';

const containerSizes = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
]);

const propTypes = {
  /**
   * @default 'container'
   */
  bsPrefix: PropTypes.string,

  /**
   * Allow the Container to fill all of its available horizontal space.
   * @type {(true|"sm"|"md"|"lg"|"xl")}
   */
  fluid: containerSizes,
  /**
   * You can use a custom element for this component
   */
  as: PropTypes.elementType,
};

const defaultProps = {
  fluid: false,
};

const Container = React.forwardRef(
    (
        {
          bsPrefix,
          fluid,
          // Need to define the default "as" during prop destructuring to be compatible with styled-components github.com/react-bootstrap/react-bootstrap/issues/3595
          as: Component = 'div',
          className,
          ...props
        },
        ref,
    ) => {
      const prefix = useBootstrapPrefix(bsPrefix, 'container');
      const suffix = typeof fluid === 'string' ? `-${fluid}` : '-fluid';
      return (
        <Component
          ref={ref}
          {...props}
          className={classNames(className, fluid ? `${prefix}${suffix}` : prefix)}
        />
      );
    },
);

Container.displayName = 'Container';
Container.propTypes = propTypes;
Container.defaultProps = defaultProps;

export default Container;
