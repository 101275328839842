export const componentPaths = {
  login: "/login",
  login_with_forgot_password: `/login/:id`,
  registration: "/registration",
  dashboard: "/dashboard",
  missingarts: "/missingarts",
  eproofs: "/eproofs",
  eproofDetail: "/eproof-detail",
  estimates: "/estimates",
  orderlist: "/order-list",
  orderdetails: (id) => `/order-details/${id ? id : ":id/:saltType?"}`,
  paybills: "/pay-bills",
  STITCH_APPROVALS: "/stitchapprovals",
  PRE_PRODUCTION_APPROVALS: "/preproductionapprovals",
  CHECK_INVENTORY: "/check-inventory",
  SHIP_UPS_ESTIMATE: "/ship-estimate",
  USER_ACTIVATION: "/user-activation/:id",
  LOGIN_BY_SALT: "/loginbysalt/:saltType/:saltValue",
  USER_ROLE_ADMIN: "/user-role-admin",
  USER_ROLE_SUPERVISORS: "/user-role-supervisors",
  CHANGE_USER_ROLE: "/change-role",
  CREATE_USER: "/create-user",
  MANAGE_SUPERVISIOR: "/manage-supervisor",
  CREATE_RESET_PASSWORD: "/create-reset-password/:id",
};
