import React from "react";
import { Button, Modal, Form, Input, Select, Spin } from "antd";
import { SvgIcon } from "../../../components/common";
import { Col, Row } from "../../../components/common/grid";
import {
  createLoadingSelector,
  fetchCountries,
  fetchStates,
  resetStoreState,
  saveContactDetail,
} from "../../../redux/actions";
import {
  CONTACT_DETAIL,
  CONTACT_DETAIL_RESET,
  FETCH_COUNTRIES,
  FETCH_STATES,
  STATE_LIST_RESET,
} from "../../../redux/actions/type";
import { connect } from "react-redux";
import { isObjectNotEmpty } from "../../../common/common-methods";
//import { showAlert } from "../../../common/alert-messages";

// Style

const { Option } = Select;

class AddressModal extends React.Component {
  formRef = React.createRef();

  handleCancel = () => {
    this.props.setShowAddressPopup(false);
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.showAddressPopup && this.props.showAddressPopup) {
      this.props.fetchCountries();
    }

    if (
      isObjectNotEmpty(this.props.successMessage) &&
      this.props.showAddressPopup
    ) {
      //this.formRef.reset();
      // showAlert.success(this.props.successMessage);
      this.handleCancel();
      this.props.resetStoreState(CONTACT_DETAIL_RESET);
      this.props.callCreateUser();
    }
  }

  onChangeCombobox = (event, option) => {
    let contact = this.props.userDetails;
    if (option.name === "country") {
      this.props.resetStoreState(STATE_LIST_RESET);
      contact["state"] = null;
      this.props.fetchStates(option.key);
      this.formRef.current.setFieldsValue({
        state: null,
      });
    }

    contact[option.name] = option.value;
    this.props.setUserDetails({ ...contact });
  };

  render() {
    const visible = this.props.showAddressPopup;

    return (
      <React.Fragment>
        <Modal
          destroyOnClose={true}
          centered={true}
          style={{ width: "100%", maxWidth: "768px" }}
          bodyStyle={{ padding: "1rem" }}
          onCancel={this.handleCancel}
          closeIcon={
            <SvgIcon
              name="close"
              fill="#dc0100"
              width="13px"
              viewbox="0 0 298.667 298.667"
            />
          }
          className="full-screen-xs-modal footer-less"
          closable={true}
          title="Address Details"
          visible={visible}
          width="100%"
          footer={null}
        >
          <Spin spinning={this.props.isFetchingData} size="large">
            <Form
              ref={this.formRef}
              name="normal_login"
              className="login-form"
              initialValues={{ remember: false }}
              onFinish={() => {
                this.props.saveContactDetail({
                  ...this.props.userDetails,
                  email: this.props.userDetails.login,
                  business_phone: this.props.userDetails.user_phone,
                });
              }}
            >
              <Row className="gutter-sm ship-up-custom">
                <Col xs="12">
                  <label htmlFor="address_name" className="w-100 fs-12">
                    Address Name
                  </label>
                  <Form.Item
                    id="address_name"
                    name="address_name"
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your address Name!",
                      },
                    ]}
                  >
                    <Input
                      name="address_name"
                      value={this.props.userDetails.address_name}
                      onChange={this.props.onChangeTextField}
                      size="large"
                      prefix={
                        <SvgIcon
                          name="menu-alt"
                          viewbox="0 0 16 7"
                          width="15px"
                        />
                      }
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
                <Col xs="12">
                  <label htmlFor="address1" className="w-100 fs-12">
                    Address
                  </label>
                  <Form.Item
                    id="address1"
                    name="address1"
                    value={this.props.userDetails.address1}
                    onChange={this.props.onChangeTextField}
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your address!",
                      },
                    ]}
                  >
                    <Input
                      name="address1"
                      size="large"
                      prefix={
                        <SvgIcon name="pin" viewbox="0 0 10 15" width="12px" />
                      }
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6">
                  <label htmlFor="country" className="w-100 fs-12">
                    Country
                  </label>
                  <div className="custom-select-with-icon">
                    <SvgIcon name="globe" viewbox="0 0 59 59" width="15px" />
                    <Form.Item
                      id="country"
                      className="mb-3"
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: "Please select your country!",
                        },
                      ]}
                    >
                      <Select
                        suffixIcon={
                          <SvgIcon name="caret-alt" viewbox="0 0 12 7" />
                        }
                        size="large"
                        value={this.props.userDetails.country}
                        onChange={this.onChangeCombobox}
                      >
                        {this.props.countryList.map((country) => (
                          <Option
                            key={country.id}
                            name="country"
                            value={country.code}
                          >
                            {country.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6">
                  <label htmlFor="state" className="w-100 fs-12">
                    State
                  </label>
                  <div className="custom-select-with-icon">
                    <SvgIcon name="bank" viewbox="0 0 15 17" width="15px" />
                    <Form.Item
                      id="state"
                      name="state"
                      className="mb-3"
                      rules={[
                        {
                          required: true,
                          message: "Please select your state!",
                        },
                      ]}
                    >
                      <Select
                        suffixIcon={
                          <SvgIcon name="caret-alt" viewbox="0 0 12 7" />
                        }
                        size="large"
                        value={this.props.userDetails.state}
                        onChange={this.onChangeCombobox}
                      >
                        {this.props.stateList.map((state) => (
                          <Option
                            key={state.id}
                            name="state"
                            value={state.code}
                          >
                            {state.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6">
                  <label htmlFor="city" className="w-100 fs-12">
                    City
                  </label>
                  <Form.Item
                    id="city"
                    name="city"
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Please select your city!",
                      },
                    ]}
                  >
                    <Input
                      name="city"
                      value={this.props.userDetails.city}
                      onChange={this.props.onChangeTextField}
                      size="large"
                      placeholder="Enter City"
                      prefix={
                        <SvgIcon name="city" viewbox="0 0 59 59" width="15px" />
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6">
                  <label htmlFor="zip" className="w-100 fs-12">
                    Zip Code
                  </label>
                  <Form.Item
                    id="zip"
                    name="zip"
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Please select your zip code!",
                      },
                    ]}
                  >
                    <Input
                      name="zip"
                      value={this.props.userDetails.zip}
                      onChange={this.props.onChangeTextField}
                      size="large"
                      prefix={
                        <SvgIcon name="pin" viewbox="0 0 10 15" width="12px" />
                      }
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
                {/* <Col xs="12" sm="12" md="6" lg="6">
                <label htmlFor="bussiness_phone" className="w-100 fs-12">
                  Phone Number
                </label>
                <Form.Item
                  id="business_phone"
                  name="business_phone"
                  className="mb-3"
                  rules={[
                    {
                      required: false,
                      message: "Please select your phone number!",
                    },
                  ]}
                >
                  <Input
                    name="business_phone"
                    size="large"
                    prefix={
                      <SvgIcon name="phone" viewbox="0 0 13 13" width="15px" />
                    }
                    placeholder=""
                  />
                </Form.Item>
              </Col> */}
              </Row>
              <div className="d-flex justify-content-end mt-3">
                <Button
                  onClick={this.handleCancel}
                  className="mr-3"
                  size="large"
                >
                  <b className="fs-13">CANCEL</b>
                </Button>
                <Button type="primary" htmlType="submit" size="large">
                  <b className="fs-13">SAVE</b>
                </Button>
              </div>
            </Form>
          </Spin>
        </Modal>
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector([
  CONTACT_DETAIL,
  FETCH_COUNTRIES,
  FETCH_STATES,
]);

const mapStateToProps = (state) => ({
  successMessage: state.contactDetail,
  countryList: state.countries,
  stateList: state.states,
  jobTitleList: state.jobTitles,
  isFetchingData: loadingSelector(state),
});

export default connect(mapStateToProps, {
  saveContactDetail,
  resetStoreState,
  fetchCountries,
  fetchStates,
})(AddressModal);
