import React from "react";
import {
  Affix,
  Button,
  Popover,
  Input,
  Form,
  Select,
  Modal,
  Spin,
  Switch,
} from "antd";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import { SvgIcon } from "../../../components/common";
import MultiaddressModalComponent from "./multiaddress-modal/multiaddress-modal";
import EProofDetailsComponent from "../e-proof/e-proof-detail/eProof-details";
import PaymentInfoModalComponent from "./payment-info-modal/payment-info-modal";
import { baseServerAdress } from "../../../constants/server-address-constants";
import { Col, Row } from "../../../components/common/grid";
// Order Page Style
import "./order-details.scss";

import {
  fetchOrderDetail,
  getTextNotificationDetails,
  addEditTextNotificationDetails,
  //fetchOrderItemOptionDetail,
  fetchSuborderDetail,
  updateOrderEmails,
  createLoadingSelector,
  showInvoicePreview,
  uploadMissingArtwork,
  reSendOrderAcknowlegment,
  resetStoreState,
  downloadTrackingInfo,
  getOrderAcknowledgementFileName,
} from "../../../redux/actions";

import {
  ORDER_DETAIL,
  ORDER_DETAIL_RESET,
  //ORDER_ITEM_OPTION_DETAIL,
  //ORDER_ITEM_OPTION_DETAIL_RESET,
  SUB_ORDER_DETAIL,
  SUB_ORDER_DETAIL_RESET,
  INVOICE_PREVIEW,
  INVOICE_PREVIEW_PATH_RESET,
  EMAIL_UPDATE_MESSAGE_RESET,
  UPLOAD_MISSING_ART,
  UPLOAD_MISSING_ART_RESET,
  RE_SEND_ORDER_ACKNOWLEDGMENT_RESET,
  RE_SEND_ORDER_ACKNOWLEDGMENT,
  GET_TEXT_NOTIFICATION_DETAIL,
  ADD_EDIT_TEXT_NOTIFICATION_DETAIL,
  TEXT_NOTIFICATION_DETAIL_RESET,
  ADD_EDIT_TEXT_NOTIFICATION_DETAIL_RESET,
  DOWNLOAD_TRACKING_INFO,
  DOWNLOAD_TRACKING_INFO_RESET,
  GET_ORDER_ACKNOWLEDGEMENT_FILE_NAME,
  GET_ORDER_ACKNOWLEDGEMENT_FILE_NAME_RESET,
} from "../../../redux/actions/type";
import { connect } from "react-redux";
import { showFileViewer } from "../../../common/common-views";
import {
  isFileNameContainSpecialCharacter,
  isloggedInUserViewOnly,
  isObjectEmpty,
  isObjectNotEmpty,
  loggedInUserDetail,
} from "../../../common/common-methods";
import { setScrollIn } from "../../../common/common-methods";
import { showAlert, showFileNameError } from "../../../common/alert-messages";
import SupportDesk from "../support-desk/support-desk";
import {
  paymentStatusConstants,
  acknowledgementStatusConstants,
  proofStatusConstants,
  orderStatusConstants,
} from "../../../constants/order-status-constants";
import { paymentTransactionType } from "../../../constants/payment-constants";
import { saltTypes } from "../../../constants";

// const { Option } = Select;

const ViewOptionButtons = ({
  onDownloadTrackingInfoClick,
  onViewAcknowledgementClick,
  onCusomerPOClick,
  onOrderImageClick,
  onInvoiceClick,
}) => {
  return (
    <React.Fragment>
      {/* <Button type="primary" onClick={onDownloadTrackingInfoClick}>
        <span>Download </span> Tracking Info
      </Button> */}

      <Button type="primary" onClick={onViewAcknowledgementClick}>
        <span>View </span> Acknowledgement
      </Button>
      <Button type="primary" onClick={onCusomerPOClick}>
        <span>View </span> Customer PO
      </Button>
      <Button type="primary" onClick={onOrderImageClick}>
        <span>View </span> Order Image
      </Button>
      <Button type="primary" onClick={onInvoiceClick}>
        <span>View </span> Invoice
      </Button>
    </React.Fragment>
  );
};

const statusName = {
  PAYMENT: "PAYMENT",
  ACKNOWLEGEMENT: "ACKNOWLEGEMENT",
  ARTANDPROOF: "ART/ PROOF",
};

export const notificationField = {
  ARTWORK_READY: "0",
  ORDER_SHIPPED: "1",
  ORDER_DELIVERED: "2",
};

class OrderDetailsView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      eprrofPopupVisible:
        isObjectNotEmpty(this.props.match.params.saltType) &&
          this.props.match.params.saltType === saltTypes.ARTWORK
          ? true
          : false,
      showPaymentPopupByDefault:
        isObjectNotEmpty(this.props.match.params.saltType) &&
          this.props.match.params.saltType === saltTypes.PAYMENT_AUTHORIZE
          ? true
          : false,
      viewVisible: false,
      textNotificationPopup: false,
      updateOrderEmailModalView: false,
      viewOrderImageModalView: false,
      viewCustomerPOModalView: false,
      viewInvoiceModalView: false,
      emails: {},
      activeOrder: this.props.match.params.id,
      showPreview: false,
      shouldScreenReload: false,
      drillOrderId: this.props.match.params.id,
      recipientEmails: "",
      sendToCustomerModal: false,
      textNotificationDetails: this.textNotificationDefaultDetails(),
    };
    this.onOrderButtonClick = this.onOrderButtonClick.bind(this);
  }

  closeDefaultPaymentPopup = () => {
    this.setState({ showPaymentPopupByDefault: false });
  };

  shouldShowDefaultPaymmentPopup = () => {
    if (
      this.state.showPaymentPopupByDefault &&
      isObjectNotEmpty(this.props.orderDetails) &&
      this.props.orderDetails.term_code === "CC" &&
      this.props.orderDetails.payment_authorize_flag !== "Y" &&
      this.props.orderDetails.payment_capture_flag !== "Y"
    ) {
      return true;
    } else {
      return false;
    }
  };

  textNotificationDefaultDetails = () => {
    return {
      id: null,
      sales_order_id: this.props.match.params.id,
      user_id: loggedInUserDetail().id,
      artwork_ready_for_eproof_enabled: "N",
      order_shipped_enabled: "N",
      order_delivered_enabled: "N",
      phone_numbers_attributes: [
        {
          id: null,
          twilio_integration_id: null,
          notification_type: "SENT TO CUSTOMER",
          phone_number: "",
          country_code: null,
        },
        {
          id: null,
          twilio_integration_id: null,
          notification_type: "SHIPPED",
          phone_number: "",
          country_code: null,
        },
        {
          id: null,
          twilio_integration_id: null,
          notification_type: "ORDER COMPLETED",
          phone_number: "",
          country_code: null,
        },
      ],
    };
  };

  onError(e) {
    alert(e);
  }

  onDownloadTrackingInfoClick = (e) => {
    console.log("onDownloadTrackingInfoClick");
    this.props.downloadTrackingInfo(this.props.orderDetails.id);
  };

  onCusomerPOClick = (e) => {
    this.setState({
      viewCustomerPOModalView: !this.state.viewCustomerPOModalView,
      viewVisible: false,
    });
  };
  onOrderImageClick = () => {
    if (isObjectNotEmpty(this.props.orderDetails.order_image_path)) {
      this.setState({
        viewOrderImageModalView: !this.state.viewOrderImageModalView,
        viewVisible: false,
      });
    } else {
      showAlert.error("Product image not available!");
    }
  };
  onInvoiceClick = (e) => {
    if (!this.state.viewInvoiceModalView) {
      if (
        this.props.orderDetails.sales_invoice_id === null ||
        this.props.orderDetails.sales_invoice_id === ""
      ) {
        showAlert.error("Invoice is not generated yet!!");
        return;
      } else {
        this.props.showInvoicePreview(this.props.orderDetails.sales_invoice_id);
      }
    } else {
      this.props.resetStoreState(INVOICE_PREVIEW_PATH_RESET);
    }
    this.setState({
      viewInvoiceModalView: !this.state.viewInvoiceModalView,
      viewVisible: false,
      showPreview: false,
    });
  };

  onViewAcknowledgementClick = (e) => {
    this.props.getOrderAcknowledgementFileName(this.props.match.params.id);
  };

  onCloseViewAcknowledgementClick = () => {
    this.props.resetStoreState(GET_ORDER_ACKNOWLEDGEMENT_FILE_NAME_RESET);
  };

  textNotificationPopupToggle = () => {
    if (this.state.textNotificationPopup === false) {
      this.props.getTextNotificationDetails(this.props.match.params.id);
    }

    this.setState({
      textNotificationPopup: !this.state.textNotificationPopup,
    });
  };
  onNotificationDetailSave = (values) => {
    this.props.addEditTextNotificationDetails(
      this.state.textNotificationDetails
    );
  };

  static getDerivedStateFromProps(props, state) {
    if (state.drillOrderId !== props.match.params.id) {
      return {
        drillOrderId: props.match.params.id,
        activeOrder: props.match.params.id,
        shouldScreenReload: "true",
      };
    }

    if (props.showInvoicePreviewPath.invoice_path && !state.showPreview) {
      return {
        showPreview: true,
      };
    }
    return null;
  }

  updateOrderEmailModal = () => {
    this.setState({
      updateOrderEmailModalView: !this.state.updateOrderEmailModalView,
      emails: !this.state.updateOrderEmailModalView
        ? { ...this.props.orderDetails.emails }
        : {},
    });
  };
  onSelectChanges = (value) => { };
  onFinish = (values) => { };
  onUpdateOrderEmail = () => {
    this.props.updateOrderEmails(this.props.orderDetails.id, this.state.emails);
    // this.updateOrderEmailModal();
  };

  onChange = (event) => {
    let emails = this.state.emails;
    let name = event.target.getAttribute("name"); //field name
    let value = event.target.value; //field value
    emails[name] = value;
    this.setState({ emails });
  };

  onNotificationFieldsChange = (event) => {
    let textNotificationDetails = this.state.textNotificationDetails;

    textNotificationDetails.phone_numbers_attributes[
      event.target.name
    ].phone_number = event.target.value; //field value

    var enableField = "Y";

    if (event.target.value.length === 0) {
      enableField = "N";
    }

    switch (event.target.name) {
      case notificationField.ARTWORK_READY:
        textNotificationDetails.artwork_ready_for_eproof_enabled = enableField;

        break;
      case notificationField.ORDER_SHIPPED:
        textNotificationDetails.order_shipped_enabled = enableField;
        break;

      default:
        textNotificationDetails.order_delivered_enabled = enableField;
        break;
    }

    this.setState({ textNotificationDetails });
  };

  onNotificationToggle = (checked, event) => {
    let textNotificationDetails = this.state.textNotificationDetails;
    let name = event.target.getAttribute("name"); //field name
    textNotificationDetails[name] = checked ? "Y" : "N";
    this.setState({ textNotificationDetails });
  };

  componentDidMount = () => {
    setScrollIn();
    this.props.fetchOrderDetail(this.props.match.params.id);
    this.props.fetchSuborderDetail(this.props.match.params.id);
    // this.props.fetchOrderItemOptionDetail(this.props.match.params.id);
  };

  componentDidUpdate() {
    if (isObjectNotEmpty(this.props.downloadTrackingInfoFileName)) {
      setTimeout(() => {
        fetch(
          baseServerAdress.REPORTS + this.props.downloadTrackingInfoFileName
        ).then((response) => {
          response.blob().then((blob) => {
            if (navigator.appVersion.toString().indexOf(".NET") > 0) {
              window.navigator.msSaveBlob(blob, `tracking-info.xls`);
            } else {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement("a");
              a.href = url;
              a.download = "tracking-info";
              a.click();
            }
          });
        });
      }, 100);

      this.props.resetStoreState(DOWNLOAD_TRACKING_INFO_RESET);
    }

    if (isObjectNotEmpty(this.props.orderEmailmessage)) {
      showAlert.success(this.props.orderEmailmessage);
      this.props.resetStoreState(EMAIL_UPDATE_MESSAGE_RESET);
      this.updateOrderEmailModal();
      this.props.fetchOrderDetail(this.props.match.params.id);
      // this.setState({
      //   updateOrderEmailModalView: false,
      //   email: this.props.orderDetails.emails,
      // });
    }
    if (this.state.shouldScreenReload) {
      this.props.fetchOrderDetail(this.props.match.params.id);
      this.props.fetchSuborderDetail(this.props.match.params.id);
      this.setState({
        shouldScreenReload: false,
      });
    }
    if (isObjectNotEmpty(this.props.uploadSuccessMessage)) {
      showAlert.success(this.props.uploadSuccessMessage);
      this.props.resetStoreState(UPLOAD_MISSING_ART_RESET);
      this.props.fetchOrderDetail(this.props.match.params.id);
    }
    if (isObjectNotEmpty(this.props.reSendAcknowledgmentMessage)) {
      showAlert.success(this.props.reSendAcknowledgmentMessage);
      this.props.resetStoreState(RE_SEND_ORDER_ACKNOWLEDGMENT_RESET);
      this.setState({
        recipientEmails: "",
        sendToCustomerModal: false,
      });
    }

    if (isObjectNotEmpty(this.props.textNotificationDetails)) {
      this.setState({
        textNotificationDetails: { ...this.props.textNotificationDetails },
      });
      this.props.resetStoreState(TEXT_NOTIFICATION_DETAIL_RESET);
    }

    if (isObjectNotEmpty(this.props.textNotificationSuccessMessage)) {
      showAlert.success(this.props.textNotificationSuccessMessage);
      this.props.resetStoreState(ADD_EDIT_TEXT_NOTIFICATION_DETAIL_RESET);
      this.setState({
        textNotificationDetails: this.textNotificationDefaultDetails(),
        textNotificationPopup: false,
      });
    }
  }

  componentWillUnmount() {
    this.props.resetStoreState(ORDER_DETAIL_RESET);
    //this.props.resetStoreState(ORDER_ITEM_OPTION_DETAIL_RESET);
    this.props.resetStoreState(SUB_ORDER_DETAIL_RESET);
  }

  renderItemOption() {
    return this.props.subOrderDetail.item_line.line_detail.map((item, i) => {
      return (
        <div className="form-stuff-row" key={i}>
          <label>{item.catalog_attribute_code}</label>

          <div className="form-elements">
            <Select
              id={item.id}
              suffixIcon={<SvgIcon name="caret-alt" viewbox="0 0 12 7" />}
              defaultValue={item.catalog_attribute_value_code}
              disabled={true}
            ></Select>
            <Input disabled={true} value={item.remarks} />
          </div>
        </div>
      );
    });
  }

  renderSubOrderList() {
    return this.props.orderDetails.sub_orders_list.map((order) => {
      return (
        <Button
          key={order.id}
          id={`${order.id}`}
          size="large"
          className={
            parseInt(this.state.activeOrder) === order.id ? "active" : ""
          }
          onClick={() => this.onOrderButtonClick(order.id)}
        >
          {order.trans_no}
        </Button>
      );
    });
  }

  onOrderButtonClick(id) {
    this.props.resetStoreState(SUB_ORDER_DETAIL_RESET);
    this.props.fetchSuborderDetail(id);
    this.setState({ activeOrder: id });
  }
  renderSetupChargeList() {
    return this.props.orderDetails.ship_order_lines.map((item) => {
      return (
        <tr>
          <td>{item.catalog_item_code}</td>
          <td className="text-right">{item.item_qty}</td>
          <td className="text-right">{item.item_price}</td>
          <td className="text-right">{item.item_amt}</td>
        </tr>
      );
    });
  }
  eproofdetailViewPopupToggle = () => {
    this.setState({
      eprrofPopupVisible: !this.state.eprrofPopupVisible,
    });
  };

  getTotalAuthorizedAmount = () => {
    let amount = 0.0;
    // let extraAuthorizedAmt = 0.00;
    // extraAuthorizedAmt = parseFloat((this.props.orderDetails.absolute_net_amt * 10 / 100).toFixed(2));
    // amount = parseFloat(this.props.orderDetails.absolute_net_amt);
    // amount = amount + extraAuthorizedAmt;
    amount = (
      parseFloat(this.props.orderDetails.absolute_net_amt) * 1.1
    ).toFixed(2); //10% extra authorizer
    return amount;
  };

  statusView = (header, text) => {
    const done = {
      classColor: "green",
      iconName: "done",
      viewBoxString: "0 0 375.147 375.147",
    };
    const pending = {
      classColor: "orange",
      iconName: "close",
      viewBoxString: "0 0 298.667 298.667",
    };
    const notApplicable = {
      classColor: "pink",
      iconName: "exclam",
      viewBoxString: "0 0 2.22 14.06",
    };

    const getTheme = () => {
      if (isObjectEmpty(text)) return pending;

      if (header === statusName.PAYMENT) {
        switch (text.toUpperCase()) {
          case orderStatusConstants.NOT_APPLICABLE:
            return notApplicable;
          case paymentStatusConstants.TERMS:
          case paymentStatusConstants.CREDIT_APPROVED:
          case paymentStatusConstants.CC_AUTHORIZED:
            return done;
          default:
            return pending;
        }
      } else if (header === statusName.ACKNOWLEGEMENT) {
        switch (text.toUpperCase()) {
          case orderStatusConstants.NOT_APPLICABLE:
            return notApplicable;
          case acknowledgementStatusConstants.SENT:
            return done;
          default:
            return pending;
        }
      } else if (header === statusName.ARTANDPROOF) {
        switch (text.toUpperCase()) {
          case orderStatusConstants.NOT_APPLICABLE:
            return notApplicable;
          case proofStatusConstants.PENDING_APPROVAL:
          case proofStatusConstants.WAITING_FOR_ARTWORK:
          case proofStatusConstants.PROOF_REJECTED:
            return pending;
          default:
            return done;
        }
      }
    };

    const finalIcon = getTheme();

    return (
      <li className={finalIcon.classColor}>
        <span className="icons">
          <SvgIcon
            name={finalIcon.iconName}
            viewbox={finalIcon.viewBoxString}
          />
        </span>
        <span className="list-info">
          <span className="value-label">{header}</span>
          <span className="d-flex flex-wrap align-items-center justify-content-between">
            <span className="value">{text && text.toLowerCase()}</span>
            {header === statusName.ACKNOWLEGEMENT &&
              text === acknowledgementStatusConstants.SENT && (
                <span className="value-option">
                  <span
                    className="cursor-pointer text-primary"
                    onClick={this.onResentButtonClick}
                  >
                    Re-send
                  </span>
                </span>
              )}
            {header === statusName.ARTANDPROOF &&
              text === proofStatusConstants.WAITING_FOR_ARTWORK && (
                <span className="value-option upload">
                  <label
                    className="cursor-pointer text-primary"
                    htmlFor="artworkUpload"
                  >
                    Upload Artwork
                  </label>
                  <input
                    disabled={isloggedInUserViewOnly()}
                    type="file"
                    className="d-invisible"
                    id="artworkUpload"
                    onChange={this.handleUploadArtwork}
                  />
                </span>
              )}
          </span>
        </span>
      </li>
    );
  };

  handleUploadArtwork = (e) => {
    if (e.target.files.length) {
      const uploadFileObj = e.target.files[0];

      if (isFileNameContainSpecialCharacter(uploadFileObj.name)) {
        showFileNameError();
        e.target.value = null;
      } else {
        this.props.uploadMissingArtwork(
          this.props.match.params.id,
          uploadFileObj
        );
      }

      return false;
    }
  };

  onRecipientEmailChanges = (e) => {
    let emails = e.target.value;
    this.setState({ recipientEmails: emails });
  };

  onResentAcknowlegmentClick = () => {
    this.props.reSendOrderAcknowlegment(
      this.props.match.params.id,
      this.state.recipientEmails
    );
  };
  onResentButtonClick = () => {
    this.setState({
      sendToCustomerModal: !this.state.sendToCustomerModal,
    });
  };

  render() {
    const {
      viewVisible,
      updateOrderEmailModalView,
      viewOrderImageModalView,
      viewCustomerPOModalView,
      textNotificationPopup,
      emails,
    } = this.state;

    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        {this.state.eprrofPopupVisible && (
          <EProofDetailsComponent
            closePopup={this.eproofdetailViewPopupToggle}
            orderId={this.state.activeOrder}
          />
        )}
        <div className="common-container order-details-wrapper">
          <MediaQuery maxDeviceWidth={991}>
            <Link to="/order-list" className="common-back-btn pl-3 mt-3">
              <SvgIcon name="caretLeft" viewbox="0 0 451.846 451.847" />
              <span>Order Detail</span>
            </Link>
          </MediaQuery>
          <div className="content-inner-main-wrap">
            <div className="detail-bar list-bar">
              <div className="bar-heading">
                <div className="h3">DETAILS</div>
              </div>
              <ul>
                <li>
                  <span className="value-label">Order</span>
                  <span className="value">
                    {this.props.orderDetails.order_number}
                  </span>
                </li>
                <li>
                  <span className="value-label">PO #</span>
                  <span className="value">
                    {this.props.orderDetails.po_number}
                  </span>
                </li>
                <li>
                  <span className="value-label">Order Date</span>
                  <span className="value">
                    {this.props.orderDetails.order_date}
                  </span>
                </li>
                <li>
                  <span className="value-label">Est. Shipping Date</span>
                  <span className="value">
                    {this.props.orderDetails.approx_shipping_date}
                  </span>
                </li>
                {isObjectNotEmpty(
                  this.props.orderDetails.approx_arrival_date
                ) && (
                    <li>
                      <span className="value-label">Approx. Arrival Date</span>
                      <span className="value">
                        {this.props.orderDetails.approx_arrival_date}
                      </span>
                    </li>
                  )}
                <li>
                  <span className="value-label">Customer #</span>
                  <span className="value">
                    {this.props.orderDetails.customer_number}
                  </span>
                </li>
                <li>
                  <span className="value-label">Sales Rep</span>
                  <span
                    className="value"
                    style={{ textTransform: "capitalize" }}
                  >
                    {this.props.orderDetails.sales_person &&
                      this.props.orderDetails.sales_person.toLowerCase()}
                  </span>
                </li>
                <li>
                  <span className="value-label">Payment Terms</span>
                  <span className="value">
                    {this.props.orderDetails.term_code}
                  </span>
                </li>
              </ul>
            </div>
            <div className="Status-and-item-bar-wrapper">
              <div className="status-bar list-bar">
                <div className="bar-heading">
                  <div className="h3">STATUS</div>
                </div>
                <ul>
                  {this.statusView(
                    statusName.PAYMENT,
                    this.props.orderDetails.portal_payment_status
                  )}
                  {this.statusView(
                    statusName.ACKNOWLEGEMENT,
                    this.props.orderDetails.portal_acknowledgment_status
                  )}
                  {this.statusView(
                    statusName.ARTANDPROOF,
                    this.props.orderDetails.portal_artwork_status
                  )}
                </ul>
              </div>
              <div className="item-bar list-bar">
                <div className="bar-heading">
                  <div className="h3">ITEM</div>
                </div>
                <ul>
                  <li>
                    <span className="value-label">Item</span>
                    <span className="value">
                      {this.props.orderDetails.item_line &&
                        this.props.orderDetails.item_line.product_name}
                    </span>
                  </li>
                  <li>
                    <span className="value-label">Description</span>
                    <span
                      className="value"
                      style={{ textTransform: "capitalize" }}
                    >
                      {this.props.orderDetails.item_line &&
                        this.props.orderDetails.item_line.product_description &&
                        this.props.orderDetails.item_line.product_description.toLowerCase()}
                    </span>
                  </li>
                  <li>
                    <span className="value-label">Order Quantity</span>
                    <span className="value">
                      {this.props.orderDetails.item_line &&
                        this.props.orderDetails.item_line.ordered}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="order-main-details">
              <div className="btn-control-bar">
                <Button
                  className="colored-btn orange"
                  onClick={this.textNotificationPopupToggle}
                >
                  <SvgIcon
                    width="27px"
                    name="text-notification"
                    viewbox="0 0 27 23"
                  />{" "}
                  Text Notification
                </Button>
                {/* Test notification modal start */}
                <Modal
                  destroyOnClose={true}
                  centered={true}
                  bodyStyle={{ padding: "1rem" }}
                  closeIcon={
                    <SvgIcon
                      name="close"
                      fill="#dc0100"
                      width="13px"
                      viewbox="0 0 298.667 298.667"
                    />
                  }
                  className="full-screen-xs-modal with-footer"
                  closable={true}
                  onCancel={this.textNotificationPopupToggle}
                  title="Get Notified via Text Message"
                  visible={textNotificationPopup}
                  footer={null}
                >
                  <Spin spinning={this.props.isFetchingData} size="large">
                    <Form
                      layout={"vertical"}
                      //name="customerEmail"
                      onFinish={this.onNotificationDetailSave}
                    >
                      <Row className="gutter-sm">
                        <Col xs="1" className="d-flex align-items-end">
                          <Form.Item
                            className="mb-3"
                            name="artwork_ready_for_eproof_enabled"
                          >
                            <Switch
                              name="artwork_ready_for_eproof_enabled"
                              size="small"
                              onClick={this.onNotificationToggle}
                              checked={
                                this.state.textNotificationDetails
                                  .artwork_ready_for_eproof_enabled === "Y"
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs="11">
                          <label htmlFor="artwork" className="w-100 fs-12">
                            Artwork Ready for Proofing
                          </label>
                          <Form.Item className="mb-2">
                            <Input
                              name={notificationField.ARTWORK_READY}
                              value={
                                this.state.textNotificationDetails
                                  .phone_numbers_attributes[0].phone_number
                              }
                              onChange={this.onNotificationFieldsChange.bind(
                                this
                              )}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="gutter-sm">
                        <Col xs="1" className="d-flex align-items-end">
                          <Form.Item
                            className="mb-3"
                            name="order_shipped_enabled"
                          >
                            <Switch
                              name="order_shipped_enabled"
                              size="small"
                              onClick={this.onNotificationToggle}
                              checked={
                                this.state.textNotificationDetails
                                  .order_shipped_enabled === "Y"
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs="11">
                          <label
                            htmlFor="order_shipped"
                            className="w-100 fs-12"
                          >
                            Order Shipped
                          </label>
                          <Form.Item className="mb-2">
                            <Input
                              name={notificationField.ORDER_SHIPPED}
                              value={
                                this.state.textNotificationDetails
                                  .phone_numbers_attributes[1].phone_number
                              }
                              onChange={this.onNotificationFieldsChange.bind(
                                this
                              )}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="gutter-sm">
                        <Col xs="1" className="d-flex align-items-end">
                          <Form.Item
                            className="mb-3"
                            name="order_delivered_enabled"
                          >
                            <Switch
                              name="order_delivered_enabled"
                              size="small"
                              onClick={this.onNotificationToggle}
                              checked={
                                this.state.textNotificationDetails
                                  .order_delivered_enabled === "Y"
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs="11">
                          <label
                            htmlFor="order_delivered"
                            className="w-100 fs-12"
                          >
                            Order Delivered
                          </label>
                          <Form.Item className="mb-2">
                            <Input
                              name={notificationField.ORDER_DELIVERED}
                              value={
                                this.state.textNotificationDetails
                                  .phone_numbers_attributes[2].phone_number
                              }
                              onChange={this.onNotificationFieldsChange.bind(
                                this
                              )}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs="12">
                          <div className="fs-10 text-muted pl-5">
                            Separate multiple numbers by commas
                          </div>
                          <div className="fs-10 text-muted mb-3 pl-5">
                            (ex:123456789, 1231231234)
                          </div>
                        </Col>
                        <Col xs="12">
                          <Button
                            block
                            key="submit"
                            type="primary"
                            htmlType="submit"
                          >
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Spin>
                </Modal>
                {/* Test notification modal end */}

                <Button
                  className="colored-btn blue"
                  onClick={this.updateOrderEmailModal}
                >
                  <SvgIcon name="msg" width="29px" viewbox="0 0 29.1 19.9" />{" "}
                  Email Settings
                </Button>
                {/* Update email address modal start */}
                <Modal
                  destroyOnClose={true}
                  centered={true}
                  bodyStyle={{ padding: "1rem" }}
                  closeIcon={
                    <SvgIcon
                      name="close"
                      fill="#dc0100"
                      width="13px"
                      viewbox="0 0 298.667 298.667"
                    />
                  }
                  className="full-screen-xs-modal with-footer"
                  closable={true}
                  onCancel={this.updateOrderEmailModal}
                  title="Update Order Email"
                  visible={updateOrderEmailModalView}
                  footer={[
                    <Button key="back" onClick={this.updateOrderEmailModal}>
                      Cancel
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      // htmlType="submit"
                      onClick={this.onUpdateOrderEmail}
                    >
                      Update
                    </Button>,
                  ]}
                >
                  <Form layout="vertical">
                    <Form.Item className="mb-2">
                      <label htmlFor="general_correspondence">
                        General Correspondence
                      </label>
                      <Input
                        id="general_correspondence"
                        name="corr_dept_email"
                        placeholder="General Correspondence"
                        value={emails.corr_dept_email}
                        onChange={this.onChange.bind(this)}
                      />
                    </Form.Item>
                    <Form.Item className="mb-2">
                      <label htmlFor="accounting">Accounting</label>
                      <Input
                        name="account_dept_email"
                        onChange={this.onChange.bind(this)}
                        id="accounting"
                        placeholder="Accounting"
                        value={emails.account_dept_email}
                      />
                    </Form.Item>
                    <Form.Item className="mb-2">
                      <label htmlFor="artwork_dept_email">Artwork</label>
                      <Input
                        name="artwork_dept_email"
                        onChange={this.onChange.bind(this)}
                        id="artwork"
                        placeholder="Artwork"
                        value={emails.artwork_dept_email}
                      />
                    </Form.Item>
                    <Form.Item className="mb-2">
                      <label htmlFor="tracking">Tracking</label>
                      <Input
                        name="shipping_dept_email"
                        onChange={this.onChange.bind(this)}
                        id="tracking"
                        placeholder="Tracking"
                        value={emails.shipping_dept_email}
                      />
                    </Form.Item>
                  </Form>
                </Modal>
                {/* Update email address modal end */}
                {this.shouldShowDefaultPaymmentPopup() && (
                  <PaymentInfoModalComponent
                    showIcon={true}
                    buttonText={"Payment Details"}
                    paymentInfo={this.props.orderDetails.payment_info}
                    amount={this.getTotalAuthorizedAmount()}
                    terms={this.props.orderDetails.term_code}
                    payment_authorize_flag={
                      this.props.orderDetails.payment_authorize_flag
                    }
                    payment_capture_flag={
                      this.props.orderDetails.payment_capture_flag
                    }
                    orderId={this.props.match.params.id}
                    transactionType={
                      paymentTransactionType.AUTH_ONLY_TRANSACTION
                    }
                    showPaymentPopupByDefault={true}
                    togglePaymentPopup={this.closeDefaultPaymentPopup.bind(
                      this
                    )}
                  />
                )}

                <PaymentInfoModalComponent
                  showIcon={true}
                  buttonText={"Payment Details"}
                  paymentInfo={this.props.orderDetails.payment_info}
                  //amount={this.props.orderDetails.absolute_net_amt}
                  amount={this.getTotalAuthorizedAmount()}
                  terms={this.props.orderDetails.term_code}
                  payment_authorize_flag={
                    this.props.orderDetails.payment_authorize_flag
                  }
                  payment_capture_flag={
                    this.props.orderDetails.payment_capture_flag
                  }
                  orderId={this.props.match.params.id}
                  transactionType={paymentTransactionType.AUTH_ONLY_TRANSACTION}
                />
                <MultiaddressModalComponent
                  orderId={this.props.orderDetails.id}
                  orderNo={this.props.orderDetails.order_number}
                  isEditable={
                    this.props.orderDetails.paper_proof_flag === "Y"
                      ? isObjectEmpty(
                        this.props.orderDetails.artworkapprovedbycust_flag
                      )
                        ? true
                        : false
                      : this.props.orderDetails.artworkreviewed_flag === "Y"
                        ? false
                        : true
                  }
                />
                <br />
                <br />
                {/* {this.props.orderDetails.payment_info} */}
                {/* <br /><br />
                {this.props.orderDetails.net_amt}
                <br /><br />
                {this.props.orderDetails.absolute_net_amt}
                <br /><br />
                {this.props.orderDetails.term_code}
                <br /><br />
                {
                  this.props.orderDetails.payment_authorize_flag
                }
                <br /><br />
                {
                  this.props.orderDetails.payment_capture_flag
                }<br /><br />
                {this.props.match.params.id} */}
              </div>
              {/* Update email address modal end */}
              <div className="sub-order-view-control">
                <div className="block-heading">Select Sub-Order</div>
                <div className="order-view-control">
                  {this.props.orderDetails.sub_orders_list &&
                    this.renderSubOrderList()}
                </div>
              </div>
              <div className="sub-order-info">
                <ul>
                  <li>
                    <span className="value-label">Ordered</span>
                    <span className="value">
                      {this.props.subOrderDetail.item_line &&
                        this.props.subOrderDetail.item_line.ordered}
                    </span>
                  </li>
                  <li>
                    <span className="value-label">Unit Price</span>
                    <span className="value">
                      {this.props.subOrderDetail.item_line &&
                        this.props.subOrderDetail.item_line.unit_price}
                    </span>
                  </li>
                  <li>
                    <span className="value-label">Ext. Price</span>
                    <span className="value">
                      {this.props.subOrderDetail.item_line &&
                        this.props.subOrderDetail.item_line.net_amt}
                    </span>
                  </li>
                </ul>
                <Button
                  type="primary"
                  className="view-eproof-btn"
                  onClick={this.eproofdetailViewPopupToggle}
                >
                  <SvgIcon
                    fill="#fff"
                    name="view-e-proff"
                    width="25px"
                    viewbox="0 0 25 23"
                  />
                  View eProof
                </Button>
              </div>
              <div className="form-stuff">
                {this.props.subOrderDetail.item_line ? (
                  this.renderItemOption()
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>

          <Affix
            offsetBottom={0}
            onChange={() => {
              this.setState({ viewVisible: false });
            }}
            className="bottom-navigation"
          >
            <div className="w-100 d-flex justify-content-md-end bottom-total-row">
              <MediaQuery maxDeviceWidth={767}>
                <React.Fragment>
                  <Popover
                    visible={viewVisible}
                    align={{ offset: [0, 2] }}
                    overlayClassName="view-option-wrapper"
                    getPopupContainer={(triggernode) => {
                      return triggernode.parentNode;
                    }}
                    placement="topLeft"
                    title={null}
                    content={
                      <React.Fragment>
                        <ViewOptionButtons
                          onViewAcknowledgementClick={
                            this.onViewAcknowledgementClick
                          }
                          onDownloadTrackingInfoClick={
                            this.onDownloadTrackingInfoClick
                          }
                          onCusomerPOClick={this.onCusomerPOClick}
                          onOrderImageClick={this.onOrderImageClick}
                          onInvoiceClick={this.onInvoiceClick}
                        />
                      </React.Fragment>
                    }
                    trigger="click"
                  >
                    <Button
                      onClick={() => {
                        this.setState({ viewVisible: !viewVisible });
                      }}
                      type="primary"
                    >
                      View{" "}
                      <SvgIcon
                        name="caret-alt"
                        className="icon-white"
                        viewbox="0 0 12 7"
                      />
                    </Button>
                  </Popover>
                  {viewVisible && (
                    <div
                      className="overlay"
                      onClick={() => {
                        this.setState({ viewVisible: false });
                      }}
                    ></div>
                  )}
                </React.Fragment>
              </MediaQuery>
              <MediaQuery minDeviceWidth={768}>
                <ViewOptionButtons
                  onViewAcknowledgementClick={this.onViewAcknowledgementClick}
                  onDownloadTrackingInfoClick={this.onDownloadTrackingInfoClick}
                  onCusomerPOClick={this.onCusomerPOClick}
                  onOrderImageClick={this.onOrderImageClick}
                  onInvoiceClick={this.onInvoiceClick}
                />
              </MediaQuery>
              <span className="total">
                <small>Setup Amount</small>
                <div>{this.props.orderDetails.setup_amt}</div>
              </span>
              <span className="total">
                <small>Other Amount</small>
                <div>{this.props.orderDetails.other_amt}</div>
              </span>{" "}
              <span className="total">
                <small>Ship Amount</small>
                <div>{this.props.orderDetails.ship_amt}</div>
              </span>
              <span className="total">
                <small>Total</small>
                <div>{this.props.orderDetails.net_amt}</div>
              </span>
            </div>
          </Affix>

          {/* view Acknowledgment modal */}
          <Modal
            destroyOnClose={true}
            centered={true}
            bodyStyle={{ padding: "1rem" }}
            closeIcon={
              <SvgIcon
                name="close"
                fill="#dc0100"
                width="13px"
                viewbox="0 0 298.667 298.667"
              />
            }
            className="full-screen-xs-modal with-footer"
            closable={true}
            onCancel={this.onCloseViewAcknowledgementClick}
            width={600}
            title="View Acknowledgement"
            visible={isObjectNotEmpty(this.props.orderAcknowledgementFileName)}
            footer={[
              <Button
                key="Download"
                type="primary"
                onClick={() => {
                  setTimeout(() => {
                    fetch(
                      baseServerAdress.REPORTS +
                      this.props.orderAcknowledgementFileName
                    ).then((response) => {
                      response.blob().then((blob) => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement("a");
                        a.href = url;
                        a.download = this.props.orderDetails.order_number;
                        a.click();
                      });
                    });
                  }, 100);
                }}
              >
                Download
              </Button>,
              <Button
                key="Ok"
                type="primary"
                onClick={this.onCloseViewAcknowledgementClick}
              >
                Ok
              </Button>,
            ]}
          >
            <div className="invoice-content">
              {isObjectNotEmpty(this.props.orderAcknowledgementFileName) &&
                showFileViewer(
                  baseServerAdress.REPORTS +
                  this.props.orderAcknowledgementFileName
                )}
            </div>
          </Modal>

          {/* View order image modal start */}
          <Modal
            destroyOnClose={true}
            centered={true}
            bodyStyle={{ padding: "1rem" }}
            closeIcon={
              <SvgIcon
                name="close"
                fill="#dc0100"
                width="13px"
                viewbox="0 0 298.667 298.667"
              />
            }
            className="full-screen-xs-modal with-footer"
            closable={true}
            onCancel={this.onOrderImageClick}
            width={600}
            title="Order Image"
            visible={viewOrderImageModalView}
            footer={[
              <Button key="Ok" type="primary" onClick={this.onOrderImageClick}>
                Ok
              </Button>,
            ]}
          >
            <div className="view-order-content">
              <img
                src={
                  baseServerAdress.IMAGE +
                  this.props.orderDetails.order_image_path
                }
                alt="Not Available!"
                title="Product Ordered"
              />
            </div>
          </Modal>
          {/* View order image modal end */}
          {/* view customer PO */}
          <Modal
            destroyOnClose={true}
            centered={true}
            bodyStyle={{ padding: "1rem" }}
            closeIcon={
              <SvgIcon
                name="close"
                fill="#dc0100"
                width="13px"
                viewbox="0 0 298.667 298.667"
              />
            }
            className="full-screen-xs-modal with-footer"
            closable={true}
            onCancel={this.onCusomerPOClick}
            width={600}
            title="PO Image"
            visible={viewCustomerPOModalView}
            footer={[
              <Button key="Ok" type="primary" onClick={this.onCusomerPOClick}>
                Ok
              </Button>,
            ]}
          >
            <div className="view-order-content">
              {this.state.viewCustomerPOModalView &&
                showFileViewer(
                  baseServerAdress.IMAGE +
                  this.props.orderDetails.customer_po_path
                )}
            </div>
          </Modal>

          {/* view Invoice modal */}
          <Modal
            destroyOnClose={true}
            centered={true}
            bodyStyle={{ padding: "1rem" }}
            closeIcon={
              <SvgIcon
                name="close"
                fill="#dc0100"
                width="13px"
                viewbox="0 0 298.667 298.667"
              />
            }
            className="full-screen-xs-modal with-footer"
            closable={true}
            onCancel={this.onInvoiceClick}
            width={600}
            title="Invoice"
            visible={this.state.viewInvoiceModalView}
            footer={[
              <Button
                key="Download"
                type="primary"
                onClick={() => {
                  setTimeout(() => {
                    fetch(
                      baseServerAdress.REPORTS +
                      this.props.showInvoicePreviewPath.invoice_path
                    ).then((response) => {
                      response.blob().then((blob) => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement("a");
                        a.href = url;
                        a.download = "Invoice";
                        a.click();
                      });
                    });
                  }, 100);
                }}
              >
                Download
              </Button>,
              <Button key="Ok" type="primary" onClick={this.onInvoiceClick}>
                Ok
              </Button>,
            ]}
          >
            <div className="invoice-content">
              {this.state.showPreview &&
                showFileViewer(
                  baseServerAdress.REPORTS +
                  this.props.showInvoicePreviewPath.invoice_path
                )}
            </div>
          </Modal>

          {/* Send to customer modal */}
          <Modal
            destroyOnClose={true}
            centered={true}
            bodyStyle={{ padding: "1rem" }}
            closeIcon={
              <SvgIcon
                name="close"
                fill="#dc0100"
                width="13px"
                viewbox="0 0 298.667 298.667"
              />
            }
            className="full-screen-xs-modal footer-less"
            closable={true}
            onCancel={this.onResentButtonClick}
            width={380}
            title="Send to Customer"
            visible={this.state.sendToCustomerModal}
            footer={null}
          >
            <Spin spinning={this.props.isFetchingData} size="large">
              <Form
                layout="vertical"
                name="sendToCustomer"
                onFinish={this.onResentAcknowlegmentClick}
              >
                <label htmlFor="emails" className="w-100 d-block">
                  Enter Email(s)
                </label>
                <Form.Item
                  className="mb-3"
                  name="emails"
                  rules={[
                    {
                      required: true,
                      message: "Please enter email address!",
                    },
                  ]}
                >
                  <Input
                    id="emails"
                    name="recipient_email"
                    value={this.state.recipientEmails}
                    onChange={this.onRecipientEmailChanges}
                    placeholder=""
                    size="large"
                  />
                </Form.Item>
                <Button type="primary" htmlType="submit" block>
                  SEND
                </Button>
              </Form>
            </Spin>{" "}
          </Modal>
        </div>
        {this.props.orderDetails.po_number && (
          <SupportDesk customersPO={this.props.orderDetails.po_number} />
        )}
      </Spin>
    );
  }
}

const loadingSelector = createLoadingSelector([
  ORDER_DETAIL,
  SUB_ORDER_DETAIL,
  INVOICE_PREVIEW,
  UPLOAD_MISSING_ART,
  RE_SEND_ORDER_ACKNOWLEDGMENT,
  GET_TEXT_NOTIFICATION_DETAIL,
  ADD_EDIT_TEXT_NOTIFICATION_DETAIL,
  DOWNLOAD_TRACKING_INFO,
  GET_ORDER_ACKNOWLEDGEMENT_FILE_NAME,
]);

const mapStateToProps = (state) => ({
  orderDetails: state.orderDetail,
  orderEmailmessage: state.updateOrderEmails,
  subOrderDetail: state.subOrderDetail,
  showInvoicePreviewPath: state.showInvoicePreview,
  uploadSuccessMessage: state.uploadMissingArtworkMessage,
  reSendAcknowledgmentMessage: state.reSendOrderAcknowledgmentMessage,
  isFetchingData: loadingSelector(state),
  textNotificationDetails: state.textNotificationDetails,
  textNotificationSuccessMessage: state.textNotificationDetailSuccessMessage,
  downloadTrackingInfoFileName: state.downloadTrackingInfo,
  orderAcknowledgementFileName: state.orderAcknowledgementFileName,
});

export default connect(mapStateToProps, {
  fetchOrderDetail,
  fetchSuborderDetail,
  updateOrderEmails,
  showInvoicePreview,
  uploadMissingArtwork,
  resetStoreState,
  reSendOrderAcknowlegment,
  getTextNotificationDetails,
  addEditTextNotificationDetails,
  downloadTrackingInfo,
  getOrderAcknowledgementFileName,
})(OrderDetailsView);
