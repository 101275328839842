import React from "react";
import { Button, Modal, Table, Input, Form } from "antd";
import { Row, Col } from "../../../../components/common/grid";
import { SvgIcon, ImageLoader } from "../../../../components/common";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";

import { connect } from "react-redux";
import {
  fetchStitchApprovals,
  createLoadingSelector,
  acceptRejectStitchesApproval,
  resetStoreState,
} from "../../../../redux/actions";

// Style
import "./stitch-approval-list.scss";
import { Spin } from "antd";

import {
  STITCH_APPROVALS,
  ACCEPT_REJECT_STITCHES_APPROVAL,
  ACCEPT_REJECT_STITCH_APPROVAL_RESET,
} from "../../../../redux/actions/type";
import { baseServerAdress } from "../../../../constants/server-address-constants";
import { showAlert } from "../../../../common/alert-messages";
import {
  isloggedInUserViewOnly,
  isObjectEmpty,
  setScrollIn,
} from "../../../../common/common-methods";

const { Column } = Table;

const { confirm } = Modal;

const { TextArea } = Input;

const StitchProductBlock = ({
  img,
  orderNumber,
  productCode,
  orderType,
  stitch,
  stitchIncluded,
  itemQuantity,
  shipDate,
  orderStatus,
  onApproveClick,
  onRejectClick,
}) => {
  return (
    <Col xs="12" md="6" lg="6" xl="4">
      <div className="border-box mb-20">
        <div className="stitch-block">
          <div className="stitch-img border-box">
            {img ? (
              <ImageLoader src={img} alt={productCode} title={productCode} />
            ) : (
              <div className="no-img">
                <SvgIcon name="picture" viewbox="0 0 28 28" />
                <span>No Image Available</span>
              </div>
            )}
            {productCode && (
              <span className="product-code" title={productCode}>
                {productCode}
              </span>
            )}
          </div>
          <div className="product-info">
            <div className="block-label">Order #</div>
            <div className="block-value">{orderNumber}</div>
            <div className="block-label">No. of Stitches</div>
            <div className="block-value">
              {stitch} <small>({stitchIncluded} stitches included)</small>
            </div>
          </div>
          <div className="block-controls">
            <Button
              className="btn-success"
              title="Approve"
              onClick={onApproveClick}
              block
              disabled={isloggedInUserViewOnly()}
            >
              <b>Approve</b>
            </Button>
            <Button
              onClick={onRejectClick}
              title="Reject"
              block
              danger
              disabled={isloggedInUserViewOnly()}
            >
              <b>Reject</b>
            </Button>
          </div>
        </div>
        <div className="stitch-block-info">
          <Row>
            <Col xs="6">
              <div className="block-label">Order Type</div>
              <div className="block-value">{orderType}</div>
            </Col>
            <Col xs="6">
              <div className="block-label">Item Quantity</div>
              <div className="block-value">{itemQuantity}</div>
            </Col>
            <Col xs="6">
              <div className="block-label">Ship Date</div>
              <div className="block-value">{shipDate}</div>
            </Col>
            <Col xs="6">
              <div className="block-label">Order Status</div>
              <div
                className="block-value"
                style={{ textTransform: "capitalize" }}
              >
                {orderStatus.toLowerCase()}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Col>
  );
};

class StitchApprovalListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gridView: true,
      customer_stitch_reject_reason: "",
    };
    this.getParamsForAcceptRejectApi = this.getParamsForAcceptRejectApi.bind(
      this
    );
  }
  getParamsForAcceptRejectApi = (orderDetails) => {
    return {
      trans_no: orderDetails.trans_no,
      id: orderDetails.id,
      item_code: orderDetails.catalog_item_code,
      qty: orderDetails.item_qty,
    };
  };

  onApproveClick = (orderDetails) => {
    const details = {
      ...this.getParamsForAcceptRejectApi(orderDetails),
      approval_flag: "Y",
    };

    this.props.acceptRejectStitchesApproval(details);
  };
  formRef = React.createRef();
  textAreaInput = React.createRef();
  onRejectClick = (orderDetails) => {
    const params = this.getParamsForAcceptRejectApi(orderDetails);
    const sendRejectRequest = this.props.acceptRejectStitchesApproval;
    confirm({
      destroyOnClose: true,
      className: "custom-confirm",
      okText: "Yes",
      cancelText: "No",
      title: "Are you sure, you want to reject these items?",
      content: (
        <Form
          ref={this.formRef}
          name="reject_form"
          onFinish={this.onReject}
          onFinishFailed={this.onRejectFailed}
        >
          <Form.Item
            name="reason"
            className="mt-3 mb-2"
            rules={[{ required: true, message: "Please input your reason!" }]}
          >
            <TextArea
              ref={this.textAreaInput}
              rows={3}
              value={this.state.customer_stitch_reject_reason}
              onChange={(e) =>
                this.setState({ customer_stitch_reject_reason: e.target.value })
              }
            />
          </Form.Item>
        </Form>
      ),
      icon: (
        <span className="anticon anticon-exclamation-circle">
          <SvgIcon
            width="24px"
            fill="#ff0050"
            name="cancel"
            viewbox="0 0 489 489"
          />
        </span>
      ),
      onOk: (close) => {
        this.formRef.current.submit();
        if (this.textAreaInput.current.props.value !== undefined) {
          const details = {
            ...params,
            approval_flag: "N",
            reject_reason: this.state.customer_stitch_reject_reason,
            revert_to: "Reestimation",
            artwork_file_name: orderDetails.artwork_file_path,
          };
          sendRejectRequest(details);
          Modal.destroyAll();
        }
      },
      onCancel() {},
    });
  };
  onReject = (values) => {
    console.log("Success:", values);
  };
  onRejectFailed = (values) => {
    console.log("Faild:", values);
  };
  componentDidMount = () => {
    setScrollIn();
    this.props.fetchStitchApprovals();
  };
  viewToggleTrue = () => {
    this.setState({
      gridView: true,
    });
  };
  viewToggleFalse = () => {
    this.setState({
      gridView: false,
    });
  };

  componentDidUpdate() {
    if (!isObjectEmpty(this.props.acceptRejectMessage)) {
      showAlert.success(this.props.acceptRejectMessage);
      this.props.resetStoreState(ACCEPT_REJECT_STITCH_APPROVAL_RESET);
      this.props.fetchStitchApprovals();
    }
  }

  render() {
    let { gridView } = this.state;
    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        <div className="common-container">
          <Row className="pt-3">
            <Col xs="12">
              <div className="page-view-panel">
                <MediaQuery minDeviceWidth={992}>
                  <span className="page-name">Stitch Approval</span>
                </MediaQuery>
                <MediaQuery maxDeviceWidth={991}>
                  <Link to="/dashboard" className="common-back-btn">
                    <SvgIcon name="caretLeft" viewbox="0 0 451.846 451.847" />
                    <span>Stitch Approval</span>
                  </Link>
                </MediaQuery>
                <div className="view-switch">
                  <button
                    type="button"
                    className={gridView ? "active" : ""}
                    onClick={this.viewToggleTrue}
                  >
                    <SvgIcon name="grid-view" viewbox="0 0 12 12" />
                  </button>
                  <button
                    type="button"
                    className={!gridView ? "active" : ""}
                    onClick={this.viewToggleFalse}
                  >
                    <SvgIcon
                      name="list-view"
                      width="20px"
                      viewbox="0 0 15 12"
                    />
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="pt-3 pt-lg-4 gutter-sm">
            {gridView ? (
              <React.Fragment>
                {this.props.orders.map((order, i) => {
                  return (
                    <StitchProductBlock
                      key={i}
                      img={baseServerAdress.IMAGE + order.artwork_file_path}
                      productCode={order.catalog_item_code}
                      orderNumber={order.trans_no}
                      stitch={order.stitch_count}
                      stitchIncluded={order.stitch_approval_count}
                      orderType={order.order_type}
                      itemQuantity={order.item_qty}
                      shipDate={order.ship_date}
                      orderStatus={order.order_status}
                      onApproveClick={() => this.onApproveClick(order)}
                      onRejectClick={() => this.onRejectClick(order)}
                    />
                  );
                })}
              </React.Fragment>
            ) : (
              <Col xs="12">
                <div className="table-wrapper">
                  <Table
                    tableLayout="fixed"
                    className="common-table"
                    loading={this.props.isFetchingData}
                    dataSource={this.props.orders}
                    pagination={false}
                    scroll={{ x: 768, y: "calc(100vh - 285px)" }}
                  >
                    <Column
                      title="Order #"
                      dataIndex="trans_no"
                      key="trans_no"
                      render={(trans_no) => <b>{trans_no}</b>}
                      width="120px"
                    />
                    <Column
                      title="No. of Stitches"
                      dataIndex="stitch_count"
                      key="stitch_count"
                      width="180px"
                    />
                    <Column
                      title="Order Type"
                      dataIndex="order_type"
                      key="order_type"
                      width="125px"
                    />
                    <Column
                      title="Item Quantity"
                      dataIndex="item_qty"
                      key="item_qty"
                      width="125px"
                    />
                    <Column
                      title="Ship Date"
                      dataIndex="ship_date"
                      key="ship_date"
                      width="125px"
                      render={(ship_date) => <span> {ship_date}</span>}
                    />
                    <Column
                      title="Order Status"
                      dataIndex="order_status"
                      key="order_status"
                      width="125px"
                      render={(order_status) => {
                        return (
                          <div style={{ textTransform: "capitalize" }}>
                            {order_status && order_status.toLowerCase()}
                          </div>
                        );
                      }}
                    />
                    <Column
                      title={false}
                      dataIndex="action"
                      className="text-right"
                      key="action"
                      width="205px"
                      render={(value, record, index) => (
                        <div className="d-flex justify-content-end">
                          <Button
                            className="btn-success mr-2"
                            title="Approve"
                            disabled={isloggedInUserViewOnly()}
                            onClick={() => this.onApproveClick(record)}
                          >
                            <b>Approve</b>
                          </Button>
                          <Button
                            onClick={() => this.onRejectClick(record)}
                            title="Reject"
                            danger
                            disabled={isloggedInUserViewOnly()}
                          >
                            <b>Reject</b>
                          </Button>
                        </div>
                      )}
                    />
                  </Table>
                </div>
              </Col>
            )}
          </Row>
        </div>
      </Spin>
    );
  }
}
const loadingSelector = createLoadingSelector([
  STITCH_APPROVALS,
  ACCEPT_REJECT_STITCHES_APPROVAL,
]);

const mapStateToProps = (state) => ({
  acceptRejectMessage: state.acceptRejectStitchesMessage,
  orders: state.stitchApprovals,
  isFetchingData: loadingSelector(state),
});

export default connect(mapStateToProps, {
  fetchStitchApprovals,
  acceptRejectStitchesApproval,
  resetStoreState,
})(StitchApprovalListComponent);
