import React, { Component } from "react";
import { Modal, Button, List, Spin } from "antd";
import { SvgIcon } from "../../../../components/common";
import "./validate-address-popup.scss";
import {
  createLoadingSelector,
  resetStoreState,
  validateAddress,
} from "../../../../redux/actions";
import {
  VALIDATE_ADDRESS,
  VALIDATE_ADDRESS_RESET,
} from "../../../../redux/actions/type";
import { connect } from "react-redux";
import { isObjectEmpty } from "../../../../common/common-methods";
import { showAlert } from "../../../../common/alert-messages";

class ValidateAddressPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectedAddressIndex: null,
    };
  }

  getDetailForValidateAddress() {
    let shipping = this.props.shippingEstimate;
    return {
      shipping_code: shipping.shipping_provider,
      ship_address1: shipping.ship_address1,
      // ship_address2: shipping.ship_address2,
      //ship_city: shipping.ship_city,
      ship_state: shipping.state,
      ship_zip: shipping.postal_code,
      ship_country: shipping.country,
    };
  }

  showModal = () => {
    let shipping = this.props.shippingEstimate;

    if (isObjectEmpty(shipping.postal_code)) {
      showAlert.error("Please enter valid zip code!");
    } else if (isObjectEmpty(shipping.ship_address1)) {
      showAlert.error("Please enter valid Address Line!");
    } else {
      this.setState({
        visible: true,
      });

      this.props.resetStoreState(VALIDATE_ADDRESS_RESET);
      const detail = this.getDetailForValidateAddress();
      this.props.validateAddress(detail);
    }
  };

  hideModal = () => {
    this.setState({
      visible: false,
      selectedAddressIndex: null,
    });
    this.props.resetStoreState(VALIDATE_ADDRESS_RESET);
  };

  render() {
    return (
      <React.Fragment>
        <Button
          type="primary"
          size="small"
          className="px-2"
          onClick={this.showModal}
        >
          Validate address
        </Button>
        <Modal
          centered
          title="Addresses"
          visible={this.state.visible}
          onOk={this.hideModal}
          onCancel={this.hideModal}
          footer={false}
          closeIcon={
            <SvgIcon
              name="close"
              fill="#dc0100"
              width="13px"
              viewbox="0 0 298.667 298.667"
            />
          }
          className="validationaddress-modal"
        >
          <Spin spinning={this.props.isFetchingData} size="large">
            <div className="validationaddress-lists">
              <List
                bordered
                dataSource={this.props.validateAddresses.suggested_addresses}
                renderItem={(item, index) => {
                  return (
                    <List.Item
                      // please add selected class for selection
                      className={
                        index === this.state.selectedAddressIndex
                          ? "selected"
                          : ""
                      }
                      actions={[<div className="select-radio"></div>]}
                      onClick={() => {
                        this.setState({ selectedAddressIndex: index });
                      }}
                    >
                      <List.Item.Meta
                        description={`${item.address1}, ${item.city}, ${item.state}, ${item.country}, ${item.zip_code}`}
                      />
                    </List.Item>
                  );
                }}
              />
            </div>
            <div className="validationaddress-footer">
              <Button
                size="large"
                onClick={() => {
                  this.setState({
                    visible: false,
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                size="large"
                type="primary"
                className="ml-3"
                onClick={() => {
                  this.props.onSelectValidAddress(
                    this.props.validateAddresses.suggested_addresses[
                      this.state.selectedAddressIndex
                    ]
                  );
                  this.hideModal();
                }}
                disabled={this.state.selectedAddressIndex === null}
              >
                Ok
              </Button>
            </div>
          </Spin>
        </Modal>
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector([VALIDATE_ADDRESS]);

const mapStateToProps = (state) => ({
  validateAddresses: state.validateAddress,
  isFetchingData: loadingSelector(state),
});

export default connect(mapStateToProps, {
  resetStoreState,
  validateAddress,
})(ValidateAddressPopup);
