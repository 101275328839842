import React, { useCallback, useEffect, useState } from "react";
import { Input, Select, AutoComplete, Spin } from "antd";
import { SvgIcon } from "../../../common";
import "./GlobalSearch.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  createLoadingSelector,
  globalSearch,
  resetStoreState,
} from "../../../../redux/actions";
import {
  GLOBAL_SEARCH,
  GLOBAL_SEARCH_RESET,
} from "../../../../redux/actions/type";
import { isObjectEmpty } from "../../../../common/common-methods";
import { componentPaths } from "../../../../constants/path-constants";
import history from "../../../../common/history";
import debounce from "lodash/debounce";
import { SUGGESTION_API_DEBOUNCE_TIME } from "../../../../constants";

const { Option } = Select;
const defaultSearchType = "ext_ref_no";
const orderNumberKey = "trans_no";
const logoName = "logo_name";
const trackingNo = "tracking_no";
const invoiceNo = "invoice_no";

const searchResult = (searchResultArry, searchType, query) => {
  return searchResultArry.map((item, index) => {
    return {
      key: item.id,
      value:
        searchType === defaultSearchType
          ? item[defaultSearchType]
          : searchType === orderNumberKey
          ? item[orderNumberKey]
          : searchType === logoName
          ? item[logoName]
          : item[invoiceNo],
      label: (
        <>
          <span>
            {searchType === defaultSearchType
              ? item[defaultSearchType]
              : searchType === orderNumberKey
              ? item[orderNumberKey]
              : searchType === logoName
              ? item[logoName]
              : item[invoiceNo]}{" "}
          </span>
          <span>{} </span>
        </>
      ),
    };
  });
};

const Complete = (props) => {
  const dispatch = useDispatch();
  const loadingSelector = createLoadingSelector([GLOBAL_SEARCH]);
  const isFetchingData = useSelector((state) => loadingSelector(state));

  const [searchText, setSearchText] = useState("");

  const options = useSelector((state) =>
    searchResult(state.globalSearchResult, props.searchType, searchText)
  );

  useEffect(() => {
    // console.log(props);
  });

  const delayedQuery = useCallback(
    debounce(
      (value, searchType) =>
        dispatch(
          globalSearch({
            search_type: searchType,
            search_value: value,
          })
        ),
      SUGGESTION_API_DEBOUNCE_TIME
    ),
    []
  );

  const handleSearch = (value) => {
    dispatch(resetStoreState(GLOBAL_SEARCH_RESET));

    setSearchText(value);
    if (!isObjectEmpty(value) && value.length > 2) {
      delayedQuery(value, props.searchType);
    }
  };

  // const handleMatchedSearch = (value, event) => {
  //   let matchedRow = _.find(options, function (option) {
  //     return option.value.toLowerCase() === value.toLowerCase();
  //   });
  //   if (isObjectNotEmpty(matchedRow)) {
  //     onSelect(value, matchedRow);
  //   }
  // };

  const onSelect = (value, option) => {
    dispatch(resetStoreState(GLOBAL_SEARCH_RESET));
    history.push(componentPaths.orderdetails(option.key));
  };

  return (
    <AutoComplete
      // autoFocus={true}
      getPopupContainer={(triggernode) => {
        return triggernode.parentNode;
      }}
      dropdownMatchSelectWidth={"100%"}
      options={options}
      onSelect={onSelect}
      onSearch={handleSearch}
      notFoundContent={isFetchingData ? <Spin /> : ""}
    >
      <Input.Search
        size="large"
        placeholder="Input at least 3 characters"
        // enterButton
        // onSearch={handleMatchedSearch}
      />
    </AutoComplete>
  );
};

const GlobalSearch = () => {
  const [searchType, setSearchType] = useState(defaultSearchType);

  return (
    <div className="global-search-wrapper">
      <Select
        onChange={(value) => {
          console.log(value);
          setSearchType(value);
        }}
        defaultValue={defaultSearchType}
        dropdownClassName="search-dropdown"
        suffixIcon={
          <SvgIcon name="caret-alt" className="arrow" viewbox="0 0 12 7" />
        }
      >
        <Option value={defaultSearchType}>Customer PO #</Option>
        <Option value={orderNumberKey}>Order #</Option>
        <Option value={logoName}>Logo </Option>
        <Option value={trackingNo}>Tracking #</Option>
        <Option value={invoiceNo}>Invoice #</Option>
      </Select>
      <Complete searchType={searchType} />
    </div>
  );
};
export default GlobalSearch;
