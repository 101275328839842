import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../../components/common";
import { Row, Col } from "../../../components/common/grid";
import { Button, Form, Input, Radio, Spin, Select, Checkbox } from "antd";
import "./role-and-permissions.scss";
import { componentPaths } from "../../../constants/path-constants";
import { useEffect } from "react";
import { userRole } from "../../../constants";
import {
  createLoadingSelector,
  createUser,
  resetStoreState,
  fetchJobTitle,
} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { isFlagTrueY, isObjectNotEmpty } from "../../../common/common-methods";
import {
  CREATE_USER,
  CREATE_USER_RESET,
  FETCH_JOB_TITLE,
} from "../../../redux/actions/type";
import AddressModal from "./address-modal";
import { showAlert } from "../../../common/alert-messages";

const { Option } = Select;

const daysList = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
];

const monthList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const CreateUser = () => {
  //   const initialStateValues = {
  //     createUserView: true,
  //     createUserSuccess: false,
  //   };

  const initialUserDetails = {
    customer_role: userRole.ADMIN,
    portal_view_only_flag: "N",
  };

  const dispatch = useDispatch();
  const createUserSuccessMessage = useSelector(
    (state) => state.createUserSuccessMessage
  );
  const jobTitleList = useSelector((state) => state.jobTitles);

  const loadingSelector = createLoadingSelector([CREATE_USER, FETCH_JOB_TITLE]);
  const isFetchingData = useSelector((state) => loadingSelector(state));

  const [userDetails, setUserDetails] = useState({ ...initialUserDetails });
  const [showCreateUserForm, setShowCreateUserForm] = useState(true);
  const [showAddressPopup, setShowAddressPopup] = useState(false);

  const backtoCreateUser = () => {
    setShowCreateUserForm(true);
    setUserDetails({ ...initialUserDetails });
  };
  const onChange = (event) => {
    setUserDetails({ ...userDetails, customer_role: event.target.value });
  };

  const onChangeTextField = (event) => {
    setUserDetails({ ...userDetails, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    if (isObjectNotEmpty(createUserSuccessMessage) && showCreateUserForm) {
      if (createUserSuccessMessage.create_contact) {
        setShowAddressPopup(true);
        showAlert.error(createUserSuccessMessage.message);
      } else {
        setShowCreateUserForm(false);
        dispatch(resetStoreState(CREATE_USER_RESET));
      }
    }
  }, [createUserSuccessMessage, showCreateUserForm, dispatch]);

  useEffect(() => {
    dispatch(fetchJobTitle());
  }, [dispatch]);

  const onChangeDropDown = (value, option) => {
    setUserDetails({ ...userDetails, [option.name]: option.value });
  };

  const callCreateUser = () => {
    dispatch(createUser(userDetails));
  };

  return (
    <>
      <Spin spinning={isFetchingData} size="large">
        <div className="common-container rolepermission-section">
          <Row>
            <Col>
              <ul className="rap-tabs">
                <li>
                  <Link to={componentPaths.CREATE_USER} className="selected">
                    <SvgIcon name="create-user" viewbox="0 0 40 36" />
                    Create User
                  </Link>
                </li>
                <li>
                  <Link to={componentPaths.CHANGE_USER_ROLE}>
                    <SvgIcon name="change-user" viewbox="0 0 43 34" />
                    Change Role
                  </Link>
                </li>
                <li>
                  <Link to={componentPaths.MANAGE_SUPERVISIOR}>
                    <SvgIcon name="supervisor-icon" viewbox="0 0 25.765 36" />
                    Manage Supervisor
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
          {showCreateUserForm && (
            <Row>
              <Col>
                <Form
                  name="create_user"
                  className="createuser-from"
                  initialValues={{ remember: true }}
                  onFinish={callCreateUser}
                >
                  <Row>
                    <Col sm={12}>
                      <label htmlFor="first_name" className="w-100">
                        First Name
                      </label>
                      <Form.Item
                        id="first_name"
                        name="first_name"
                        className="mb-3"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your first name!",
                          },
                        ]}
                      >
                        <Input
                          name="first_name"
                          prefix={
                            <SvgIcon
                              name="name-icon"
                              viewbox="0 0 16 7"
                              width="15px"
                            />
                          }
                          placeholder="First Name"
                          onChange={(event) => onChangeTextField(event)}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <label htmlFor="last_name" className="w-100">
                        Last Name
                      </label>
                      <Form.Item
                        id="last_name"
                        name="last_name"
                        className="mb-3"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your last name!",
                          },
                        ]}
                      >
                        <Input
                          name="last_name"
                          prefix={
                            <SvgIcon
                              name="name-icon"
                              viewbox="0 0 16 7"
                              width="15px"
                            />
                          }
                          placeholder="Last Name"
                          onChange={(event) => onChangeTextField(event)}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <label htmlFor="birthday" className="w-100">
                        Birthday
                      </label>
                      {/* <Form.Item
                        id="birthday"
                        name="birthday"
                        className="mb-3"
                        //   rules={[
                        //     {
                        //       //required: true,
                        //       message: "Please select Date",
                        //     },
                        //   ]}
                      >
                        <DatePicker
                          dropdownClassName="datepicker-popup"
                          placeholder="MM/DD"
                          format={dateFormat}
                          onChange={(value, dateString) => {
                            let dateArry = dateString.split("/");
                            const dateKeys = {
                              dob_month: dateArry[0],
                              dob_date: dateArry[1],
                            };

                            setUserDetails({ ...userDetails, ...dateKeys });
                          }}
                        />
                      </Form.Item> */}
                      <Row>
                        <Col xs="4" className="pr-0">
                          <div className="custom-select-with-icon">
                            <SvgIcon
                              name="calendar"
                              viewbox="0 0 14.652 14.726"
                              width="15px"
                              style={{ top: 8 }}
                            />
                            <Form.Item
                              id="dob_date"
                              name="dob_date"
                              className="mb-3"
                            >
                              <Select
                                suffixIcon={
                                  <SvgIcon
                                    name="caret-alt"
                                    viewbox="0 0 12 7"
                                  />
                                }
                                placeholder="Day"
                                onChange={onChangeDropDown}
                              >
                                {daysList.map((date, index) => (
                                  <Option
                                    key={index}
                                    name="dob_date"
                                    value={index + 1}
                                  >
                                    {date}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs="8" className="pl-1">
                          <div className="custom-select-with-icon">
                            <SvgIcon
                              name="calendar-alt"
                              viewbox="0 0 12.67 14.022"
                              width="15px"
                              style={{ top: 8 }}
                            />
                            <Form.Item
                              id="dob_month"
                              name="dob_month"
                              className="mb-3"
                            >
                              <Select
                                suffixIcon={
                                  <SvgIcon
                                    name="caret-alt"
                                    viewbox="0 0 12 7"
                                  />
                                }
                                placeholder="Month"
                                onChange={onChangeDropDown}
                              >
                                {monthList.map((month, index) => (
                                  <Option
                                    key={index}
                                    name="dob_month"
                                    value={index + 1}
                                  >
                                    {month}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={12}>
                      <label htmlFor="login" className="w-100">
                        Email
                      </label>
                      <Form.Item
                        id="login"
                        name="login"
                        className="mb-3"
                        rules={[
                          {
                            type: "email",
                            message: "The input is not valid E-mail!",
                          },
                          {
                            required: true,
                            message: "Please enter your E-mail!",
                          },
                        ]}
                      >
                        <Input
                          name="login"
                          prefix={
                            <SvgIcon
                              name="mail"
                              viewbox="0 0 13 11"
                              width="15px"
                            />
                          }
                          placeholder="Email ID"
                          onChange={(event) => onChangeTextField(event)}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <label htmlFor="user_phone" className="w-100">
                        Phone (optional)
                      </label>
                      <Form.Item
                        id="user_phone"
                        name="user_phone"
                        className="mb-3"
                      >
                        <Input
                          name="user_phone"
                          prefix={
                            <SvgIcon
                              name="phone"
                              viewbox="0 0 13 13"
                              width="15px"
                            />
                          }
                          placeholder="Phone"
                          onChange={(event) => onChangeTextField(event)}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      {/* <label htmlFor="job_title" className="w-100">
                        Position (optional)
                      </label>
                      <Form.Item
                        id="job_title"
                        name="job_title"
                        className="mb-3"
                      >
                        <Input
                          name="job_title"
                          prefix={
                            <SvgIcon
                              name="user-alt"
                              viewbox="0 0 36 36"
                              width="15px"
                            />
                          }
                          placeholder="Position"
                          onChange={(event) => onChangeTextField(event)}
                        />
                      </Form.Item> */}
                      <label htmlFor="job_title" className="w-100 fs-12">
                        Job Title
                      </label>
                      <div className="custom-select-with-icon">
                        <SvgIcon
                          name="brifcase"
                          viewbox="0 0 16 15.2"
                          width="15px"
                          style={{ top: 8 }}
                        />
                        <Form.Item
                          id="job_title"
                          name="job_title"
                          className="mb-3"
                          rules={[
                            {
                              required: false,
                              message: "Please select job title!",
                            },
                          ]}
                        >
                          <Select
                            suffixIcon={
                              <SvgIcon name="caret-alt" viewbox="0 0 12 7" />
                            }
                            onChange={onChangeDropDown}
                          >
                            {jobTitleList.map((jobTitle) => (
                              <Option
                                key={jobTitle}
                                name="job_title"
                                value={jobTitle}
                              >
                                {jobTitle}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col sm={12} className="mb-3 mt-1">
                      <Radio.Group
                        onChange={onChange}
                        value={userDetails.customer_role}
                      >
                        <Radio value={userRole.ADMIN}>Admin</Radio>
                        <Radio value={userRole.SUPERVISIOR}>Supervisor</Radio>
                        <Radio value={userRole.USER}>User</Radio>
                      </Radio.Group>
                      <Checkbox
                        value={isFlagTrueY(userDetails.portal_view_only_fla)}
                        onChange={(event) => {
                          setUserDetails({
                            ...userDetails,
                            portal_view_only_flag: event.target.checked
                              ? "Y"
                              : "N",
                          });
                        }}
                      >
                        View Only User
                      </Checkbox>
                      <AddressModal
                        showAddressPopup={showAddressPopup}
                        setShowAddressPopup={setShowAddressPopup}
                        onChangeTextField={onChangeTextField}
                        userDetails={userDetails}
                        setUserDetails={setUserDetails}
                        callCreateUser={callCreateUser}
                      />
                    </Col>
                    <Col sm={12}>
                      <label htmlFor="password" className="w-100">
                        Password
                      </label>
                      <Form.Item
                        id="password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ]}
                        hasFeedback
                      >
                        <Input.Password
                          prefix={
                            <SvgIcon
                              name="lock"
                              viewbox="0 0 12.4 16.4"
                              width="12px"
                            />
                          }
                          name="password"
                          onChange={(event) => onChangeTextField(event)}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <label htmlFor="confirm_password" className="w-100">
                        Confirm Password
                      </label>
                      <Form.Item
                        id="confirm_password"
                        name="confirm_password"
                        dependencies={["password"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                "The two passwords that you entered do not match!"
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          prefix={
                            <SvgIcon
                              name="lock"
                              viewbox="0 0 12.4 16.4"
                              width="12px"
                            />
                          }
                          name="confirm_password"
                          onChange={(event) => onChangeTextField(event)}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm="12">
                      <Button
                        type="primary"
                        className="text-uppercase"
                        block
                        // onClick={CreateUderToggle}
                        htmlType="submit"
                      >
                        Create User
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          )}
          {!showCreateUserForm && (
            <Row>
              <Col>
                <div className="success-section">
                  <SvgIcon name="check-icon" viewbox="0 0 65.234 65.234" />
                  <p>
                    User created successfully. User would be getting the email
                    for activation.
                    {/* "<b>Vijay Jain</b>" as a
                  supervisor */}
                  </p>
                  <Button
                    type="primary"
                    className="text-uppercase"
                    onClick={backtoCreateUser}
                  >
                    Create Another User
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </Spin>
    </>
  );
};

export default CreateUser;
