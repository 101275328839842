/* eslint-disable max-len */
/* eslint-disable camelcase */
import React from "react";
import { Table, Button } from "antd";
import { Collapse } from "react-collapse";
import MediaQuery from "react-responsive";
import { SvgIcon } from "../../../components/common";
import { connect } from "react-redux";
import {
  fetchHistoryOrders,
  ORDERS_PAGE_SIZE,
  createLoadingSelector,
} from "../../../redux/actions";
import { HISTORY_ORDERS } from "../../../redux/actions/type";
import {
  artworkStatusFilters,
  orderStatusConstants,
  orderStatusFilters,
  paymentStatusConstants,
  paymentStatusFilters,
  proofStatusConstants,
  shippingStatusFilters,
} from "../../../constants/order-status-constants";
import { componentPaths } from "../../../constants/path-constants";
import {
  shippingPackageTracking,
  isObjectNotEmpty,
} from "../../../common/common-methods";

const { Column } = Table;

/**
 * Component to display Order History
 * @param {*} index Index for the selected object
 * @param {*} e Event object
 */
class OrderHistoryComponent extends React.Component {
  state = {
    filteredInfo: null,
  };
  /**
   * Default Constructor
   * @param {*} props default props passed to the component
   */
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activeIndex: null,
      pagination: {},
      sortBy: "trans_date",
      sortOrder: "desc",
    };
    this.toggleClass = this.toggleClass.bind(this);
    this.selectRow = this.selectRow.bind(this);
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    let sortParams = {};
    let filtersParams = {};

    if (isObjectNotEmpty(sorter)) {
      sortParams = {
        sortBy: sorter.field,
        sortOrder: sorter.order === "ascend" ? "asc" : "desc",
      };
      this.setState({
        pagination: pager,
        ...sortParams,
      });
    } else {
      sortParams = {
        sortBy: this.state.sortBy,
        sortOrder: this.state.sortOrder,
      };
    }

    if (isObjectNotEmpty(filters)) {
      Object.keys(filters).map((key) => {
        //filtersParams
        if (isObjectNotEmpty(filters[key])) {
          const filterValue = filters[key]
            .map((filterName) => `''${filterName}''`)
            .join(",");
          filtersParams[key] = `'${filterValue}'`;
        }
        return key;
      });
    }

    const params = {
      sort_by: sortParams.sortBy,
      sort_order: sortParams.sortOrder,
      page: pager.current,
      ...filtersParams,
    };
    this.props.fetchHistoryOrders(params);
  };

  toggleClass = (index, e) => {
    this.setState({
      activeIndex: this.state.activeIndex === index ? null : index,
    });
  };

  /**
   * @param {*} index Selected object index
   * @return {*} Image
   */
  moreLess = (index) => {
    if (this.state.activeIndex === index) {
      return (
        <SvgIcon viewbox="0 0 451.847 451.847" className="arrow" name="caret" />
      );
    } else {
      return <SvgIcon viewbox="0 0 21 5" name="more" />;
    }
  };
  /**
   * Lifecycle function - componentDidMount
   */
  componentDidMount() {
    const params = {
      sort_by: this.state.sortBy,
      sort_order: this.state.sortOrder,
      page: 1,
    };
    this.props.fetchHistoryOrders(params);
  }

  selectRow = (record) => {
    this.props.history.push({
      pathname: componentPaths.orderdetails(record.id),
    });
  };
  /**
   * @return {*} Component to show Order History
   */

  OnTrackingNoClick = (event, record) => {
    event.stopPropagation();
    shippingPackageTracking(record.shipping_code, record.tracking_no);
  };
  render() {
    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    const { activeIndex } = this.state;
    const orders = Array.isArray(this.props.orders) ? this.props.orders : [];
    const pager = { ...this.state.pagination };
    if (orders.length > 0) {
      pager.total = parseInt(orders[0].page_count) * ORDERS_PAGE_SIZE;
      pager.pageSize = ORDERS_PAGE_SIZE;
    } else {
      pager.total = pager.current * ORDERS_PAGE_SIZE;
      pager.pageSize = ORDERS_PAGE_SIZE;
    }

    return (
      <React.Fragment>
        <MediaQuery minDeviceWidth={768}>
          <Table
            tableLayout="fixed"
            className="common-table"
            dataSource={orders}
            pagination={pager}
            onChange={this.handleTableChange}
            loading={this.props.isFetchingData}
            scroll={{ x: 100 }}
            rowKey="id"
            onRow={(record) => ({
              onClick: () => {
                this.selectRow(record);
              },
            })}
          >
            <Column
              title="Order Date"
              dataIndex="trans_date"
              key="trans_date"
              width="100px"
              fixed="left"
              sorter={(a, b) => new Date(a.trans_date) - new Date(b.trans_date)}
            />
            <Column
              title="Customer PO#"
              dataIndex="ext_ref_no"
              key="ext_ref_no"
              width="125px"
              fixed="left"
              sorter={(a, b) => a.ext_ref_no.length - b.ext_ref_no.length}
            />
            <Column
              title="Order#"
              dataIndex="trans_no"
              key="trans_no"
              render={(trans_no) => <b>{trans_no}</b>}
              width="80px"
              sorter={(a, b) => a.trans_no - b.trans_no}
            />
            <Column
              title="Order Total"
              dataIndex="net_amt"
              key="net_amt"
              className="text-center"
              render={(net_amt) => <b>{net_amt}</b>}
              width="100px"
              sorter={(a, b) => a.net_amt - b.net_amt}
            />
            <Column
              title="Order Quantity"
              className="text-center"
              dataIndex="ship_qty"
              key="ship_qty"
              width="135px"
              sorter={(a, b) => a.ship_qty - b.ship_qty}
            />
            <Column
              title="Logo #"
              dataIndex="logo_name"
              key="logo_name"
              width="125px"
              sorter={(a, b) => a.logo_name.localeCompare(b.logo_name)}
            />
            <Column
              title="Order Status"
              dataIndex="portal_order_status"
              key="portal_order_status"
              width="165px"
              render={(portal_order_status) => {
                return (
                  <span
                    className={
                      portal_order_status.toUpperCase() ===
                      orderStatusConstants.ORDER_STATUS_COMPLETED
                        ? "text-green"
                        : "text-orange"
                    }
                  >
                    {portal_order_status}
                  </span>
                );
              }}
              filters={orderStatusFilters.map((textKey) => {
                return { text: textKey, value: textKey };
              })}
              filteredValue={filteredInfo.portal_order_status || null}
              onFilter={(value, record) =>
                record.portal_order_status.includes(value)
              }
              sorter={(a, b) =>
                a.portal_order_status.localeCompare(b.portal_order_status)
              }
            />
            <Column
              title="Artwork Status"
              dataIndex="portal_artwork_status"
              key="portal_artwork_status"
              width="130px"
              render={(portal_artwork_status) => {
                return (
                  <span
                    className={
                      portal_artwork_status.toUpperCase() ===
                      proofStatusConstants.COMPLETE
                        ? "text-green"
                        : "text-orange"
                    }
                  >
                    {portal_artwork_status}
                  </span>
                );
              }}
              filters={artworkStatusFilters.map((textKey) => {
                return { text: textKey, value: textKey };
              })}
              filteredValue={filteredInfo.portal_artwork_status || null}
              onFilter={(value, record) =>
                record.portal_artwork_status.includes(value)
              }
              sorter={(a, b) =>
                a.portal_artwork_status.localeCompare(b.portal_artwork_status)
              }
            />
            <Column
              title="Payment Status"
              dataIndex="portal_payment_status"
              key="portal_payment_status"
              width="140px"
              render={(portal_payment_status) => {
                return (
                  <span
                    className={
                      isObjectNotEmpty(portal_payment_status) &&
                      portal_payment_status.toUpperCase() ===
                        paymentStatusConstants.CREDIT_APPROVED
                        ? "text-green"
                        : "text-orange"
                    }
                  >
                    {portal_payment_status}
                  </span>
                );
              }}
              filters={paymentStatusFilters.map((textKey) => {
                return { text: textKey, value: textKey };
              })}
              filteredValue={filteredInfo.portal_payment_status || null}
              onFilter={(value, record) =>
                record.portal_payment_status.includes(value)
              }
              sorter={(a, b) =>
                a.portal_payment_status.localeCompare(b.portal_payment_status)
              }
            />
            <Column
              title="Shipping Status"
              dataIndex="portal_shipping_status"
              key="portal_shipping_status"
              width="140px"
              render={(portal_shipping_status) => {
                return (
                  <span
                    className={
                      portal_shipping_status ===
                      orderStatusConstants.SHIPPING_STATUS_COMPLETED
                        ? "text-green"
                        : "text-orange"
                    }
                  >
                    {portal_shipping_status}
                  </span>
                );
              }}
              filters={shippingStatusFilters.map((textKey) => {
                return { text: textKey, value: textKey };
              })}
              onFilter={(value, record) =>
                record.portal_shipping_status.includes(value, 0)
              }
              filteredValue={filteredInfo.portal_shipping_status || null}
              sorter={(a, b) =>
                a.portal_shipping_status.localeCompare(b.portal_shipping_status)
              }
            />

            <Column
              title="Estimated Ship Date"
              className="text-center"
              dataIndex="estimated_ship_date"
              key="estimated_ship_date"
              width="150px"
              sorter={(a, b) =>
                new Date(a.estimated_ship_date) -
                new Date(b.estimated_ship_date)
              }
            />
            <Column
              title="Tracking #"
              className="text-center"
              dataIndex="tracking_no"
              key="tracking_no"
              width="140px"
              render={(tracking_no, record) => (
                <Button
                  type="link"
                  onClick={(event) => {
                    this.OnTrackingNoClick(event, record);
                  }}
                >
                  <b>
                    <u> {tracking_no}</u>
                  </b>
                </Button>
              )}
              sorter={(a, b) => a.tracking_no - b.tracking_no}
            />
          </Table>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={767}>
          <div className="mobile-table">
            <Table
              tableLayout="fixed"
              className="common-table"
              dataSource={orders}
              pagination={pager}
              loading={this.props.isFetchingData}
              onChange={this.handleTableChange}
              scrollToFirstRowOnChange={true}
              // scroll={{ x:100 }}
            >
              <Column
                title={null}
                dataIndex="key"
                key="key"
                width="130px"
                render={(text, record, index) => {
                  return (
                    <div className="cell-block">
                      <span
                        className="row-toggler"
                        onClick={this.toggleClass.bind(this, index)}
                      >
                        {this.moreLess(index)}
                      </span>
                      <ul
                        className={activeIndex !== index ? "cell-hidden" : ""}
                      >
                        <li>
                          <span className="cell-name">Customer PO#</span>
                          <span className="cell-value">{record.trans_no}</span>
                        </li>
                        <li>
                          <span className="cell-name">Order #</span>
                          <span className="cell-value">{record.trans_no}</span>
                        </li>
                        <li>
                          <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                              this.props.history.push({
                                pathname: componentPaths.orderdetails(
                                  record.id
                                ),
                              });
                            }}
                          >
                            VIEW
                          </Button>
                        </li>
                        <li>
                          <span className="cell-name">Artwork Status</span>
                          <span
                            className={
                              record.portal_artwork_status.toUpperCase() ===
                              proofStatusConstants.COMPLETE
                                ? "text-green"
                                : "text-orange"
                            }
                          >
                            {record.portal_artwork_status}
                          </span>
                        </li>
                        <li>
                          <span className="cell-name">Payment Status</span>
                          <span
                            className={
                              isObjectNotEmpty(record.portal_payment_status) &&
                              record.portal_payment_status.toUpperCase() ===
                                paymentStatusConstants.CREDIT_APPROVED
                                ? "text-green"
                                : "text-orange"
                            }
                          >
                            {record.portal_payment_status}
                          </span>
                        </li>
                        <li>
                          <span className="cell-name">Shipping Status</span>
                          <span
                            className={
                              record.portal_shipping_status.toUpperCase ===
                              orderStatusConstants.SHIPPING_STATUS_COMPLETED
                                ? "text-green"
                                : "text-orange"
                            }
                          >
                            {record.portal_shipping_status}
                          </span>
                        </li>
                      </ul>
                      <Collapse isOpened={activeIndex === index}>
                        <ul>
                          <li>
                            <span className="cell-name">Order Date</span>
                            <span className="cell-value">
                              {record.trans_date}
                            </span>
                          </li>
                          <li>
                            <span className="cell-name">Order Total</span>
                            <span className="cell-value">{record.net_amt}</span>
                          </li>
                          <li>
                            <span className="cell-name">Logo #</span>
                            <span className="cell-value">
                              {record.logo_name}
                            </span>
                          </li>
                          <li>
                            <span className="cell-name">Order Status</span>
                            <span
                              className={
                                record.portal_order_status.toUpperCase ===
                                orderStatusConstants.ORDER_STATUS_COMPLETED
                                  ? "text-green"
                                  : "text-orange"
                              }
                            >
                              {record.portal_order_status}
                            </span>
                          </li>
                          <li>
                            <span className="cell-name">Order Quantity</span>
                            <span className="cell-value">
                              {record.ship_qty}
                            </span>
                          </li>
                          <li>
                            <span className="cell-name">
                              Estimated Ship Date
                            </span>
                            <span className="cell-value">
                              {record.estimatedShipDate}
                            </span>
                          </li>
                          <li>
                            <span className="cell-name">Tracking #</span>
                            <span className="cell-value">
                              {record.tracking_no}
                            </span>
                          </li>
                        </ul>
                      </Collapse>
                    </div>
                  );
                }}
              />
            </Table>
          </div>
        </MediaQuery>
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector([HISTORY_ORDERS]);

const mapStateToProps = (state) => ({
  orders: state.historyOrders,
  isFetchingData: loadingSelector(state),
});

export default connect(mapStateToProps, { fetchHistoryOrders })(
  OrderHistoryComponent
);
