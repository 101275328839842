import React from "react";
import { ImageLoader, SvgIcon } from "../../../../components/common";
import { UploadOutlined } from "@ant-design/icons";
import {
  Modal,
  Button,
  Select,
  Popover,
  Form,
  Input,
  Radio,
  Table,
  Spin,
  Checkbox,
  Divider,
} from "antd";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
//Style
import "./eProof-details.scss";

import { connect } from "react-redux";
import {
  E_PROOF_ORDER_DETAILS_RESET,
  E_PROOF_ORDER_DETAILS,
  SEND_EPROOF_EMAIL,
  SEND_EPROOF_EMAIL_RESET,
  ACCEPT_REJECT_EPROOF,
  ACCEPT_REJECT_EPROOF_RESET,
  REJECT_EPROOF_WITH_ART,
} from "../../../../redux/actions/type";
import { baseServerAdress } from "../../../../constants/server-address-constants";
// import PDFViewer from "pdf-viewer-reactjs";

import {
  fetchEproofOrderDetails,
  createLoadingSelector,
  sendEproofEmail,
  resetStoreState,
  acceptRejectEproof,
  rejectEproofWithArt,
} from "../../../../redux/actions";
import {
  isObjectEmpty,
  getObjectValue,
  isObjectNotEmpty,
  setScrollIn,
  isloggedInUserViewOnly,
  isFileNameContainSpecialCharacter,
} from "../../../../common/common-methods";
import {
  showAlert,
  showFileNameError,
} from "../../../../common/alert-messages";
import PaymentInfoModalComponent from "../../order-details/payment-info-modal/payment-info-modal";
import { paymentTransactionType } from "../../../../constants/payment-constants";
import { showFileViewer } from "../../../../common/common-views";
import CustomPdfNavigation from "../../../../components/common/custom-pdf-navigation/custom-pdf-navigation";

import DistributedByImage from "../../../../assets/images/distributed-by.png";
import { Link } from "react-router-dom";
import { componentPaths } from "../../../../constants/path-constants";

const { TextArea } = Input;
const { Option } = Select;
const { Column } = Table;

class EProofDetailsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedArtwork: {},
      defaultZoom: 1,
      positionX: 0,
      positionY: 0,
      emailCustomerPopup: false,
      eProofAcceptRejectValue: "A",
      sendToCustomerEmailValue: "",
      emailCustomerConfirm: false,
      artworkData: [],
      emailForwardDetails: this.initializeEmailForwardDetails(),
      respondedFlag: false,
      selectedRowKeys: "",
      artworkRemarks: "",
      showPaymentPopup: false,
      distributed_by_text: "",
      distributed_by_flag: false,
      file: null,
      fileData: null,
    };
    this.selectRow = this.selectRow.bind(this);
    this.onChangerejectFile = this.onChangeUploaifFile.bind(this);
    this.resetFileRjectFile = this.resetFileUploadFile.bind(this);
  }
  formRef = React.createRef();

  onChangeUploaifFile(event) {
    if (event.target.files.length) {
      const uploadFileObj = event.target.files[0];

      if (isFileNameContainSpecialCharacter(uploadFileObj.name)) {
        showFileNameError();
        event.target.value = null;
      } else {
        this.setState({
          file: uploadFileObj.name,
          fileData: uploadFileObj,
        });
      }
    }
    // event.target.value = null;
  }

  resetFileUploadFile(event) {
    event.preventDefault();
    this.setState({ file: null, fileData: null });
  }

  initializeEmailForwardDetails() {
    return {
      recipient_email: "",
      remarks: "",
    };
  }

  onChange = (event) => {
    let emailForwardDetails = this.state.emailForwardDetails;
    let name = event.target.getAttribute("name"); //field name
    let value = event.target.value; //field value

    emailForwardDetails[name] = value;
    this.setState({ emailForwardDetails });
  };

  selectRow = (id) => {
    this.props.resetStoreState(E_PROOF_ORDER_DETAILS_RESET);

    this.setState({
      artworkData: [],
      selectedArtwork: {},
    });
    this.onSelectedRowKeysChange([parseInt(id)]);
    this.props.fetchEproofOrderDetails(id);
  };

  downloadProof = () => {
    if (Object.keys(this.state.selectedArtwork).length) {
      var eProofUrl = "";
      var extension = ".png";

      if (isObjectNotEmpty(this.state.selectedArtwork.proof_file_name)) {
        extension = ".pdf";
        eProofUrl =
          baseServerAdress.MANUAL_EPROOF +
          this.state.selectedArtwork.proof_file_name;
      } else if (this.props.orderDetails.virtual_artwork === "Y") {
        eProofUrl =
          baseServerAdress.IMAGE +
          this.state.selectedArtwork.artwork_image_path;
      } else {
        eProofUrl =
          baseServerAdress.EPROOF +
          "proof_" +
          this.props.orderDetails.order_number +
          "_" +
          this.state.selectedArtwork.artwork_version +
          ".png";
      }

      setTimeout(() => {
        fetch(eProofUrl).then((response) => {
          response.blob().then((blob) => {
            if (navigator.appVersion.toString().indexOf(".NET") > 0) {
              window.navigator.msSaveBlob(blob, `image${extension}`);
            } else {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement("a");
              a.href = url;
              a.download = "image";
              a.click();
            }
          });
        });
      }, 100);
    } else {
      alert("Please select an artwork version.");
    }
  };

  onSelectChanges = (event, option) => {
    this.setState({
      selectedArtwork: this.state.artworkData[option.key],
      defaultZoom: 1,
      positionX: 0,
      positionY: 0,
    });
  };
  onArtworkChanges = (e, i) => {
    this.setState({
      selectedArtwork: this.state.artworkData[i],
      defaultZoom: 1,
      positionX: 0,
      positionY: 0,
    });
  };

  emailCustomerPopupToggle = () => {
    this.setState({
      emailCustomerPopup: !this.state.emailCustomerPopup,
    });
  };
  onCustomerEmailSend = (values) => {
    this.setState({
      emailCustomerPopup: false,
      sendToCustomerEmailValue: values.to_email,
      emailCustomerConfirm: true,
    });
  };
  onChangeAcceptRejectValue = (e) => {
    this.setState({
      eProofAcceptRejectValue: e.target.value,
    });
  };

  componentDidMount = () => {
    setScrollIn();
    this.selectRow(this.props.orderId);
  };

  static getDerivedStateFromProps(props, state) {
    if (props.orderDetails.artworks) {
      //&& isObjectEmpty(state.selectedArtwork) && state.artworkData.length == 0)
      var artwork = [];
      var responded;
      if (props.orderDetails.artworkapprovedbycust_flag === "A") {
        artwork = props.orderDetails.artworks.filter(
          (artwork) => artwork.final_artwork_flag === "Y"
        );
        responded = true;
      } else {
        if (isObjectEmpty(state.selectedArtwork)) {
          artwork.push(props.orderDetails.artworks[0]);
        } else {
          artwork.push(state.selectedArtwork);
        }
      }

      if (props.orderDetails.artworkapprovedbycust_flag === "") {
        responded = false;
      } else {
        responded = true;
      }

      var distibutionObject = {};
      if (
        isObjectEmpty(state.artworkData) &&
        isObjectNotEmpty(props.orderDetails)
      ) {
        distibutionObject = {
          distributed_by_text: props.orderDetails.distributed_by_text,
          distributed_by_flag: props.orderDetails.distributed_by_flag,
        };
      }

      return {
        artworkData: props.orderDetails.artworks,
        selectedArtwork:
          artwork.length === 0 ? props.orderDetails.artworks[0] : artwork[0],
        respondedFlag: responded,
        ...distibutionObject,
      };
    }
    return null;
  }

  componentDidUpdate() {
    if (!isObjectEmpty(this.props.eproofEmailSuccessMessage)) {
      showAlert.success(this.props.eproofEmailSuccessMessage);
      this.props.resetStoreState(SEND_EPROOF_EMAIL_RESET);
      this.setState({
        emailForwardDetails: this.initializeEmailForwardDetails(),
        emailCustomerPopup: false,
      });
      this.formRef.current.setFieldsValue({
        recipient_email: "",
        remarks: "",
      });
    }

    if (!isObjectEmpty(this.props.acceptRejectEproofMessage)) {
      showAlert.success(this.props.acceptRejectEproofMessage);
      this.props.resetStoreState(ACCEPT_REJECT_EPROOF_RESET);
      this.selectRow(this.props.orderId);
      this.setState({
        emailForwardDetails: this.initializeEmailForwardDetails(),
        emailCustomerPopup: false,
        file: null,
        fileData: null,
      });
    }
  }
  onSelectedRowKeysChange = (selectedRowKeys) => {
    if (selectedRowKeys.length > 1) {
      const lastSelectedRowIndex = [...selectedRowKeys].pop();
      this.setState({ selectedRowKeys: lastSelectedRowIndex });
    }
    this.setState({ selectedRowKeys });
  };

  getTotalAuthorizedAmount = () => {
    let amount = 0.0;
    // let extraAuthorizedAmt = 0.00;
    // extraAuthorizedAmt = parseFloat((this.props.orderDetails.absolute_net_amt * 10 / 100).toFixed(2));
    // amount = parseFloat(this.props.orderDetails.absolute_net_amt);
    // amount = amount + extraAuthorizedAmt;
    amount = (parseFloat(this.props.orderDetails.amount) * 1.1).toFixed(2); //10% extra authorizer
    return amount;
  };

  togglePaymentPopup() {
    if (this.state.showPaymentPopup) {
      this.selectRow(this.props.orderId);
    }

    this.setState({
      showPaymentPopup: !this.state.showPaymentPopup,
    });
  }

  acceptRejectEproof = () => {
    var details = {
      artwork_id: this.state.selectedArtwork.id,
      trans_no: this.props.orderDetails.order_number,
      artwork_status: this.state.eProofAcceptRejectValue,
      artwork_comments: this.state.artworkRemarks,
      distributed_by_flag: this.state.distributed_by_flag,
      distributed_by_text:
        this.state.distributed_by_flag === "Y" &&
          this.props.orderDetails.item_distributed_by_flag === "Y"
          ? this.state.distributed_by_text
          : "",
    };
    // console.log(details);
    if (this.isRejectProofSelected() && this.state.file != null) {
      details = {
        artwork_id: this.state.selectedArtwork.id,
        artwork_status: this.state.eProofAcceptRejectValue,
        artwork_comments: this.state.artworkRemarks,
        filedata: this.state.fileData,
      };
      this.props.rejectEproofWithArt(details);
    } else {
      this.props.acceptRejectEproof(details);
    }
  };

  onDistributionClicked = (event) => {
    let flag = event.target.checked ? "Y" : "N";
    this.setState({ distributed_by_flag: flag });
  };

  isRejectProofSelected = () => {
    return this.state.eProofAcceptRejectValue === "R";
  };

  render() {
    const {
      selectedArtwork,
      defaultZoom,
      positionX,
      positionY,
      emailCustomerPopup,
      artworkData,
      selectedRowKeys,
      artworkRemarks,
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      //onChange: this.onSelectedRowKeysChange,
      type: "radio",
    };

    console.log(this.state);

    const columns = [
      {
        title: "Order #",
        dataIndex: "trans_no",
        key: "trans_no",
        width: 120,
      },
      {
        title: "Variant",
        dataIndex: "variant",
        key: "variant",
      },
      {
        title: "Quantity",
        dataIndex: "item_qty",
        key: "item_qty",
        width: 100,
      },
      {
        title: "Inventory Status",
        dataIndex: "available_qty",
        key: "available_qty",
      },
      // {
      //   title: "Replace with",
      //   dataIndex: "replaceWith",
      //   key: "replaceWith",
      //   render: () => (
      //     <Select
      //       className="w-100"
      //       defaultValue="option4"
      //       dropdownRender={(menu) => (
      //         <>
      //           {menu}
      //           <Divider style={{ margin: "8px 0" }} />
      //           <Button
      //             className="selectdropedit-btn"
      //             type="primary"
      //             size="small"
      //           >
      //             <SvgIcon name="pen" viewbox="0 0 16 16" />
      //           </Button>
      //         </>
      //       )}
      //       options={[
      //         {
      //           value: "option1",
      //           label: "Natural/Uniflavored - 250,578",
      //         },
      //         {
      //           value: "option2",
      //           label: "Pappermint - 75,569",
      //         },
      //         {
      //           value: "option3",
      //           label: "Tropical - 151,968",
      //         },
      //         {
      //           value: "option4",
      //           label: "Cherry - 15,632",
      //         },
      //       ]}
      //     />
      //   ),
      // },
    ];

    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        <React.Fragment>
          <Modal
            destroyOnClose={true}
            centered={true}
            style={{ maxWidth: 1120 }}
            bodyStyle={{ padding: "1rem" }}
            width={"100%"}
            closeIcon={
              <SvgIcon
                name="close"
                fill="#dc0100"
                width="13px"
                viewbox="0 0 298.667 298.667"
              />
            }
            className="full-screen-xs-modal eProof-modal footer-less"
            closable={true}
            onCancel={() => {
              this.props.closePopup();
              this.props.resetStoreState(E_PROOF_ORDER_DETAILS_RESET);
            }}
            title="eProof"
            visible={true}
            footer={null}
            loading={this.props.isFetchingData}
          >
            <Spin spinning={this.props.isFetchingData} size="large">
              {this.state.showPaymentPopup && (
                <PaymentInfoModalComponent
                  showIcon={true}
                  buttonText={"Payment Details"}
                  paymentInfo={this.props.orderDetails.payment_info}
                  amount={this.getTotalAuthorizedAmount()}
                  terms={this.props.orderDetails.term_code}
                  payment_authorize_flag={
                    this.props.orderDetails.payment_authorize_flag
                  }
                  payment_capture_flag={
                    this.props.orderDetails.payment_capture_flag
                  }
                  orderId={this.props.orderDetails.id}
                  transactionType={paymentTransactionType.AUTH_ONLY_TRANSACTION}
                  showPaymentPopupByDefault={true}
                  togglePaymentPopup={this.togglePaymentPopup.bind(this)}
                  transactionSuccessCallBack={this.acceptRejectEproof.bind(
                    this
                  )}
                />
              )}
              <div className="details-top">
                <ul>
                  <li>
                    <span className="label">Factory Order</span>
                    <span className="value">
                      <Link
                        className="text-primary"
                        to={componentPaths.orderdetails(
                          this.props.orderDetails.id
                        )}
                      >
                        {this.props.orderDetails.order_number}
                      </Link>
                    </span>
                  </li>
                  <li>
                    <span className="label">PO #</span>
                    <span className="value">
                      {this.props.orderDetails.po_number}
                    </span>
                  </li>
                  <li>
                    <span className="label">Logo #</span>
                    <span className="value">
                      {this.props.orderDetails.logo_name}
                    </span>
                  </li>
                  <li>
                    <span className="label">Product</span>
                    <span className="value">
                      {this.props.orderDetails.product}
                    </span>
                  </li>
                  <li>
                    <span className="label">Imprint Type</span>
                    <span
                      className="value"
                      style={{ textTransform: "capitalize" }}
                    >
                      {this.props.orderDetails.imprint_type &&
                        this.props.orderDetails.imprint_type.toLowerCase()}
                    </span>
                  </li>

                  <li>
                    <span className="label">Contact</span>
                    <span
                      className="value"
                      style={{ textTransform: "capitalize" }}
                    >
                      {this.props.orderDetails.contact &&
                        this.props.orderDetails.contact.toLowerCase()}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="details-bottom">
                <div
                  className={
                    isObjectNotEmpty(
                      isObjectNotEmpty(this.state.selectedArtwork) &&
                      this.state.selectedArtwork.proof_file_name
                    )
                      ? "pdf-artwork artwork-wrapper"
                      : "artwork-wrapper"
                  }
                >
                  {isObjectNotEmpty(this.state.selectedArtwork) && (
                    <React.Fragment>
                      {isObjectNotEmpty(
                        this.state.selectedArtwork.proof_file_name
                      ) ? (
                        <React.Fragment>
                          <div className="arwork-controls">
                            <div className="artwork-control-btns">
                              <Select
                                placeholder="text"
                                suffixIcon={
                                  <SvgIcon
                                    name="caret-alt"
                                    viewbox="0 0 12 7"
                                  />
                                }
                                defaultValue="ARTWORK VERSION"
                                value={getObjectValue(
                                  this.state.selectedArtwork,
                                  "artwork_version"
                                )}
                                onChange={this.onSelectChanges.bind(this)}
                                className="mr-auto eproof-select"
                                style={{
                                  width: "150px",
                                  position: "relative",
                                  zIndex: "1",
                                }}
                                disabled={
                                  this.state.respondedFlag &&
                                    this.props.orderDetails
                                      .artworkapprovedbycust_flag === "A"
                                    ? true
                                    : false
                                }
                              >
                                {artworkData.map((artwork, i) => {
                                  return (
                                    <Option
                                      key={i}
                                      value={artwork.artwork_version}
                                    >
                                      {artwork.artwork_version}
                                    </Option>
                                  );
                                })}
                              </Select>
                              <div className="clip"></div>
                            </div>
                            <Button
                              onClick={() => {
                                window.open(
                                  baseServerAdress.MANUAL_EPROOF +
                                  this.state.selectedArtwork.proof_file_name,
                                  "_blank"
                                );
                              }}
                              size="small"
                              type="primary"
                              className="viewpdf-btn"
                            >
                              <SvgIcon name="eye" viewbox="0 0 43.401 27.233" />{" "}
                              View PDF
                            </Button>
                          </div>
                          {/* <PDFViewer
                            hideRotation={true}
                            navbarOnTop={true}
                            scale={1}
                            scaleStep={0.5}
                            maxScale={5}
                            minScale={1.0}
                            document={{
                              url:
                                baseServerAdress.MANUAL_EPROOF +
                                this.state.selectedArtwork.proof_file_name,
                            }}
                            navigation={(props) => {
                              return CustomPdfNavigation({
                                ...props,
                                handleDownloadProof: this.downloadProof,
                              });
                            }}
                          /> */}
                        </React.Fragment>
                      ) : (
                        <TransformWrapper
                          scale={defaultZoom}
                          positionX={positionX}
                          positionY={positionY}
                        >
                          {({ zoomIn, zoomOut, resetTransform }) => (
                            <React.Fragment>
                              <div className="arwork-controls">
                                <div className="artwork-control-btns">
                                  <Select
                                    placeholder="text"
                                    suffixIcon={
                                      <SvgIcon
                                        name="caret-alt"
                                        viewbox="0 0 12 7"
                                      />
                                    }
                                    defaultValue="ARTWORK VERSION"
                                    value={getObjectValue(
                                      this.state.selectedArtwork,
                                      "artwork_version"
                                    )}
                                    onChange={this.onSelectChanges.bind(this)}
                                    className="mr-auto eproof-select"
                                    style={{ width: "20%" }}
                                    disabled={
                                      this.state.respondedFlag &&
                                        this.props.orderDetails
                                          .artworkapprovedbycust_flag === "A"
                                        ? true
                                        : false
                                    }
                                  >
                                    {artworkData.map((artwork, i) => {
                                      return (
                                        <Option
                                          key={i}
                                          value={artwork.artwork_version}
                                        >
                                          {artwork.artwork_version}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                  <div className="clip"></div>
                                  <button
                                    type="button"
                                    onClick={zoomIn}
                                    title="Zoom In"
                                  >
                                    <SvgIcon
                                      name="zoom-in"
                                      width="15px"
                                      viewbox="0 0 16 15.2"
                                    />{" "}
                                    Zoom In
                                  </button>
                                  <button
                                    type="button"
                                    onClick={zoomOut}
                                    title="Zoom Out"
                                  >
                                    <SvgIcon
                                      name="zoom-out"
                                      width="15px"
                                      viewbox="0 0 16 15.2"
                                    />{" "}
                                    Zoom Out
                                  </button>
                                  <button
                                    type="button"
                                    onClick={resetTransform}
                                    title="Fit to Page"
                                  >
                                    <SvgIcon
                                      width="14px"
                                      name="fit"
                                      viewbox="0 0 17 16"
                                    />{" "}
                                    Fit to Page
                                  </button>
                                  <button
                                    type="button"
                                    tittle="Download Proof"
                                    onClick={this.downloadProof}
                                  >
                                    <SvgIcon
                                      width="14px"
                                      name="download-alt"
                                      viewbox="0 0 15 16"
                                    />{" "}
                                    Download Proof
                                  </button>
                                </div>
                              </div>

                              <TransformComponent>
                                <ImageLoader
                                  src={
                                    this.props.orderDetails.virtual_artwork ===
                                      "Y"
                                      ? baseServerAdress.IMAGE +
                                      this.state.selectedArtwork
                                        .artwork_image_path
                                      : baseServerAdress.EPROOF +
                                      "proof_" +
                                      this.props.orderDetails.order_number +
                                      "_" +
                                      this.state.selectedArtwork
                                        .artwork_version +
                                      ".png"
                                  }
                                  alt="Artwork"
                                  title="Artwork1"
                                />
                              </TransformComponent>
                            </React.Fragment>
                          )}
                        </TransformWrapper>
                      )}
                      <ul className="artwork-list">
                        {artworkData.map((artwork, i) => {
                          return (
                            <li
                              className={
                                artwork.artwork_version ===
                                  selectedArtwork.artwork_version
                                  ? "active"
                                  : ""
                              }
                              key={artwork.artwork_version + i}
                              onClick={(e) => {
                                this.onArtworkChanges(e, i);
                              }}
                              id={artwork.artwork_version}
                            >
                              <span className="artwork-inner">
                                {isObjectNotEmpty(artwork.proof_file_name) ? (
                                  showFileViewer(
                                    baseServerAdress.MANUAL_EPROOF +
                                    artwork.proof_file_name
                                  )
                                ) : (
                                  <ImageLoader
                                    src={
                                      this.props.orderDetails
                                        .virtual_artwork === "Y"
                                        ? baseServerAdress.IMAGE +
                                        artwork.artwork_image_path
                                        : baseServerAdress.EPROOF +
                                        "proof_" +
                                        this.props.orderDetails.order_number +
                                        "_" +
                                        artwork.artwork_version +
                                        ".png"
                                    }
                                    alt="Artwork1"
                                    title="Artwork1"
                                  />
                                )}
                              </span>
                              <span className="artwork-version">
                                {artwork.artwork_version}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </React.Fragment>
                  )}
                </div>
                <div className="details-bottom-right">
                  <Table
                    size="small"
                    tableLayout="fixed"
                    className="common-table"
                    pagination={false}
                    dataSource={this.props.orderDetails.orders}
                    onChange={this.handleTableChange}
                    // loading={this.props.isFetchingData}
                    scroll={{ x: 100 }}
                    onRow={(record) => ({
                      onClick: () => {
                        this.selectRow(record.id);
                      },
                    })}
                    rowKey="id"
                    rowSelection={rowSelection}
                  >
                    <Column
                      title="Order #"
                      dataIndex="trans_no"
                      key="trans_no"
                      width="90px"
                    />
                    <Column
                      title="Proof Status"
                      dataIndex="portal_artwork_response"
                      key="portal_artwork_response"
                      width="80px"
                      render={(portal_artwork_response) => {
                        return (
                          <span style={{ textTransform: "capitalize" }}>
                            {portal_artwork_response.toLowerCase()}
                          </span>
                        );
                      }}
                    />
                    <Column
                      title="Multi Option"
                      dataIndex="multi_description"
                      key="multi_description"
                      width="150px"
                      // fixed="left"

                      render={(multi_description) => {
                        return (
                          <>
                            {isObjectNotEmpty(multi_description) &&
                              multi_description
                                .split("\n")
                                .map((subString) =>
                                  isObjectNotEmpty(subString) ? (
                                    <div>{subString}</div>
                                  ) : (
                                    ""
                                  )
                                )}
                          </>
                        );
                      }}
                    />
                  </Table>
                  <div className="forwared-email-wrapper">
                    <Popover
                      getPopupContainer={(triggernode) => {
                        return triggernode.parentNode;
                      }}
                      visible={emailCustomerPopup}
                      placement="bottomLeft"
                      title={null}
                      trigger="click"
                      content={
                        <Form
                          ref={this.formRef}
                          name="customerEmail"
                          onFinish={() =>
                            this.props.sendEproofEmail(
                              this.props.orderDetails.id,
                              this.state.emailForwardDetails
                            )
                          }
                        >
                          <Form.Item
                            className="mb-2"
                            id="recipient_email"
                            name="recipient_email"
                            rules={[
                              {
                                type: "email",
                                message: "The input is not valid E-mail!",
                              },
                              {
                                required: true,
                                message: "Please input your E-mail!",
                              },
                            ]}
                          >
                            <Input
                              name="recipient_email"
                              addonBefore="To"
                              value={
                                this.state.emailForwardDetails.recipient_email
                              }
                              onChange={this.onChange}
                            />
                          </Form.Item>
                          <Form.Item
                            id="remarks"
                            name="remarks"
                            className="mb-2"
                            rules={[
                              {
                                required: true,
                                message: "Please input your remark!",
                              },
                            ]}
                          >
                            <TextArea
                              name="remarks"
                              rows={2}
                              placeholder="Remark"
                              onChange={this.onChange}
                              value={this.state.emailForwardDetails.remarks}
                            />
                          </Form.Item>
                          <div className="d-flex align-items-center justify-content-end">
                            <Button
                              type="primary"
                              className="ml-auto mr-2"
                              onClick={this.emailCustomerPopupToggle}
                            >
                              Cancel
                            </Button>
                            <Button type="primary" htmlType="submit">
                              Submit
                            </Button>
                          </div>
                        </Form>
                      }
                    >
                      <Button
                        size="large"
                        className={
                          this.state.artworkData.length === 0
                            ? "disabled"
                            : "alternate-btn"
                        }
                        onClick={
                          this.state.artworkData.length !== 0
                            ? this.emailCustomerPopupToggle
                            : null
                        }
                      >
                        Forward to Customer Email
                      </Button>
                    </Popover>
                  </div>
                  {/* {this.state.emailCustomerConfirm && (
                  <Alert
                    className="alert-success mt-3"
                    message={
                      <div>
                        <b>Forwarded</b> to{" "}
                        {this.state.sendToCustomerEmailValue}
                      </div>
                    }
                    type="success"
                    icon={
                      <SvgIcon
                        name="done"
                        fill="#03c281"
                        width="13px"
                        viewbox="0 0 375.147 375.147"
                      />
                    }
                    showIcon={true}
                  />
                )} */}

                  <div className="bordered-box">
                    <Form
                      name="eProofAcceptReject"
                      onFinish={
                        this.state.respondedFlag
                          ? ""
                          : () => {
                            if (
                              this.props.orderDetails.show_payment_popup &&
                              !this.isRejectProofSelected()
                            ) {
                              this.togglePaymentPopup();
                            } else {
                              this.acceptRejectEproof();
                            }
                          }
                      }
                    >
                      <Radio.Group
                        name="acceptReject"
                        onChange={this.onChangeAcceptRejectValue}
                        value={this.state.eProofAcceptRejectValue}
                        defaultValue={"A"}
                      >
                        <Radio value={"A"}>Accept</Radio>
                        <Radio value={"R"}>Reject</Radio>
                      </Radio.Group>
                      {this.isRejectProofSelected() && (
                        <React.Fragment>
                          <Form.Item
                            name="reject_reason"
                            className="mt-3"
                            rules={[
                              {
                                required: true,
                                message: "Please input reason!",
                              },
                            ]}
                          >
                            <TextArea
                              rows={4}
                              value={artworkRemarks}
                              onChange={(e) =>
                                this.setState({
                                  artworkRemarks: e.target.value,
                                })
                              }
                            />
                          </Form.Item>

                          <Form.Item name="reject_Upload" className="mt-2">
                            <div className="rejectfile-upload">
                              <div className="rejectfile-upper">
                                <Button
                                  disabled={
                                    this.state.respondedFlag ||
                                    this.state.artworkData.length === 0
                                  }
                                >
                                  <input
                                    id="filePicker"
                                    type="file"
                                    onChange={this.onChangerejectFile}
                                  />
                                  <UploadOutlined />
                                  <label htmlFor="filePicker">
                                    {" Upload Artwork"}
                                  </label>
                                </Button>
                              </div>
                              {this.state.file && (
                                <div className="rejectfile-bottom">
                                  <div>{this.state.file}</div>
                                  <div
                                    className="remove-rejectfile"
                                    onClick={this.resetFileRjectFile}
                                  >
                                    <SvgIcon
                                      name="close"
                                      viewbox="0 0 298.667 298.667"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </Form.Item>
                        </React.Fragment>
                      )}

                      {this.state.eProofAcceptRejectValue === "A" &&
                        this.props.orderDetails.item_distributed_by_flag ===
                        "Y" && (
                          <Form.Item
                            id="distributed_by_text"
                            name="distributed_by_text"
                            className="mt-3"
                            rules={[
                              {
                                required: false,
                                message: "Please input details!",
                              },
                            ]}
                          >
                            <div>
                              Do you want to print Distributed By :{" "}
                              <Checkbox
                                className="ml-2"
                                checked={this.state.distributed_by_flag === "Y"}
                                onChange={this.onDistributionClicked}
                                disabled={
                                  this.state.respondedFlag ||
                                  this.state.artworkData.length === 0
                                }
                              ></Checkbox>
                            </div>
                            {this.state.distributed_by_flag === "Y" && (
                              <Input
                                name="distributed_by_text"
                                rows={4}
                                disabled={
                                  this.state.respondedFlag ||
                                  this.state.artworkData.length === 0
                                }
                                value={this.state.distributed_by_text}
                                onChange={(e) => {
                                  this.setState({
                                    distributed_by_text: e.target.value,
                                  });
                                }}
                              />
                            )}
                            {this.state.distributed_by_flag === "Y" && (
                              <div className="mt-3">
                                <img
                                  alt="demo"
                                  className="img-fluid"
                                  src={DistributedByImage}
                                />
                              </div>
                            )}
                          </Form.Item>
                        )}
                      <div className="mt-3 d-flex align-items-center justify-content-start">
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={
                            this.state.respondedFlag ||
                            this.state.artworkData.length === 0 ||
                            isloggedInUserViewOnly()
                          }
                          className={
                            this.state.respondedFlag ||
                              this.state.artworkData.length === 0 ||
                              isloggedInUserViewOnly()
                              ? "disabled"
                              : ""
                          }
                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="ordertable mt-3">
                <Table
                  bordered
                  dataSource={this.props.orderDetails.order_inventory_items}
                  columns={columns}
                  scroll={{ x: 767 }}
                  size="small"
                  tableLayout="fixed"
                  className="common-table"
                />
              </div>
            </Spin>
          </Modal>
        </React.Fragment>
      </Spin>
    );
  }
}

const loadingSelector = createLoadingSelector([
  SEND_EPROOF_EMAIL,
  E_PROOF_ORDER_DETAILS,
  ACCEPT_REJECT_EPROOF,
  REJECT_EPROOF_WITH_ART,
]);

const mapStateToProps = (state) => ({
  orderDetails: state.eProofOrderDetails,
  eproofEmailSuccessMessage: state.eproofEmailMessage,
  acceptRejectEproofMessage: state.acceptRejectEproofMessage,
  isFetchingData: loadingSelector(state),
});

export default connect(mapStateToProps, {
  fetchEproofOrderDetails,
  sendEproofEmail,
  resetStoreState,
  acceptRejectEproof,
  rejectEproofWithArt,
})(EProofDetailsComponent);
