import React, { Component } from "react";
import { AutoComplete, Input, Spin, Table } from "antd";
import { Row, Col } from "../../../components/common/grid";
import { SvgIcon } from "../../../components/common";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import "./user-role-supervisors.scss";
import { connect } from "react-redux";
import {
  createLoadingSelector,
  resetStoreState,
  fetchSupervisorMemberList,
  deleteSupervisorUser,
} from "../../../redux/actions";
import {
  DELETE_SUPERVISOR_USER,
  DELETE_SUPERVISOR_USER_RESET,
  SUPERVISOR_MEMBER_LIST,
  SUPERVISOR_MEMBER_LIST_RESET,
} from "../../../redux/actions/type";
import {
  isloggedInUserRoleSupervisior,
  isloggedInUserViewOnly,
  isObjectNotEmpty,
  loggedInUserDetail,
} from "../../../common/common-methods";
import confirm from "antd/lib/modal/confirm";
import { componentPaths } from "../../../constants/path-constants";
import history from "../../../common/history";
import { showAlert } from "../../../common/alert-messages";

const { Column } = Table;

class UserRoleSupervisorsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      supervisiorList: [],
      serachText: "",
    };
  }
  componentDidMount() {
    if (!isloggedInUserViewOnly() && isloggedInUserRoleSupervisior()) {
      this.props.fetchSupervisorMemberList(loggedInUserDetail().id);
    } else {
      history.push(componentPaths.dashboard);
    }
  }
  componentDidUpdate(prevProps) {
    if (isObjectNotEmpty(this.props.deleteSupervisorUserSuccessMessage)) {
      showAlert.success(this.props.deleteSupervisorUserSuccessMessage);
      this.props.resetStoreState(DELETE_SUPERVISOR_USER_RESET);
      this.props.resetStoreState(SUPERVISOR_MEMBER_LIST_RESET);
      this.props.fetchSupervisorMemberList(loggedInUserDetail().id);
    }
  }
  handleDelete = (id) => {
    confirm({
      title: "Do you really want to Remove this item?",
      onOk: () => {
        this.props.deleteSupervisorUser(id);
      },
    });
  };
  handleSearch = (e) => {
    let value = e.target.value;
    this.setState({
      serachText: value,
    });
  };
  filteredSupervisorMemberList = () => {
    let supervisiorList = [];
    let inputValue = this.state.serachText.toLowerCase();

    if (isObjectNotEmpty(this.state.serachText)) {
      if (isObjectNotEmpty(this.props.supervisorMemberList)) {
        this.props.supervisorMemberList.map((details) => {
          if (
            isObjectNotEmpty(details.first_name) &&
            details.first_name.toLowerCase().includes(inputValue)
          ) {
            supervisiorList.push(details);
          } else if (
            isObjectNotEmpty(details.last_name) &&
            details.last_name.toLowerCase().includes(inputValue)
          ) {
            supervisiorList.push(details);
          } else if (
            isObjectNotEmpty(details.user_id) &&
            details.user_id.toLowerCase().includes(inputValue)
          ) {
            supervisiorList.push(details);
          } else if (
            isObjectNotEmpty(details.login) &&
            details.login.toLowerCase().includes(inputValue)
          ) {
            supervisiorList.push(details);
          }
          return null;
        });
      }
    } else {
      supervisiorList = this.props.supervisorMemberList;
    }
    return supervisiorList;
  };
  render() {
    let supervisorMemberList = this.filteredSupervisorMemberList();

    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        <React.Fragment>
          <div className="common-container pb-4">
            <Row className="pt-3">
              <Col xs="12">
                <div className="page-view-panel">
                  <MediaQuery minDeviceWidth={992}>
                    <span className="page-name">User Role Permissions</span>
                  </MediaQuery>
                  <MediaQuery maxDeviceWidth={991}>
                    <Link to="/dashboard" className="common-back-btn">
                      <SvgIcon name="caretLeft" viewbox="0 0 451.846 451.847" />
                      <span>User Role Permissions</span>
                    </Link>
                  </MediaQuery>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="role-search-bar mt-3">
                  <div className="search-rep">
                    <AutoComplete
                      dropdownMatchSelectWidth={"100%"}
                      style={{
                        width: "100%",
                      }}
                    >
                      <Input.Search
                        placeholder="Search for rep"
                        onChange={this.handleSearch}
                      />
                    </AutoComplete>
                  </div>
                  {/* <div className="dropdown-sort">
                    Sort by
                    <Select
                      defaultValue="alphabetically"
                      bordered={false}
                      dropdownMatchSelectWidth={true}
                      dropdownClassName="sortDropdownof-adminrole"
                    >
                      <Option value="alphabetically">Alphabetically</Option>
                      <Option value="date">Date</Option>
                    </Select>
                  </div> */}
                  <div className="add-newrep-btn"></div>
                </div>

                <div>
                  <div className="table-wrapper admin-role-wrapper">
                    <Table
                      tableLayout="fixed"
                      className="common-table"
                      dataSource={supervisorMemberList}
                      pagination={false}
                      scroll={{ y: "calc(100vh - 300px)" }}
                    >
                      <Column
                        title="Rep's Name"
                        dataIndex="first_name"
                        key="first_name"
                        width="120px"
                        render={(first_name, order) => {
                          return order.first_name + " " + order.last_name;
                        }}
                      />
                      <Column
                        title="User ID"
                        dataIndex="user_id"
                        key="user_id"
                        width="100px"
                      />
                      <Column
                        title="Email ID"
                        dataIndex="login"
                        key="login"
                        width="145px"
                      />
                      <Column
                        title="No. of Supervisors"
                        dataIndex="supervisor_count"
                        key="supervisor_count"
                        width="125px"
                      />
                      <Column
                        title={false}
                        dataIndex="id"
                        key="id"
                        width="65px"
                        render={(id, row) => (
                          <SvgIcon
                            className="remove-icon"
                            name="remove"
                            viewbox="0 0 20.073 20.073"
                            onClick={() => this.handleDelete(row.id)}
                          />
                        )}
                      />
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </React.Fragment>{" "}
      </Spin>
    );
  }
}
const loadingSelector = createLoadingSelector([
  SUPERVISOR_MEMBER_LIST,
  DELETE_SUPERVISOR_USER,
]);

const mapStateToProps = (state) => ({
  supervisorMemberList: state.supervisorMemberList,
  isFetchingData: loadingSelector(state),
  deleteSupervisorUserSuccessMessage: state.deleteSupervisorUser,
});

export default connect(mapStateToProps, {
  resetStoreState,
  deleteSupervisorUser,

  fetchSupervisorMemberList,
})(UserRoleSupervisorsView);
